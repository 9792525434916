export const GET_ORDERSHEET_LIST = 'reducers/orderSheet/GET_ORDERSHEET_LIST'
export const GET_ORDERSHEET_DETAIL = 'reducers/orderSheet/GET_ORDERSHEET_DETAIL'
export const SET_CLIENT_IS_BUSINESSMAN =
  'reducers/orderSheet/SET_CLIENT_IS_BUSINESSMAN'

export const getOrderSheetList = (
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
) => ({
  type: GET_ORDERSHEET_LIST,
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
})

export const getOrderSheetDetail = (id, isPrint) => ({
  type: GET_ORDERSHEET_DETAIL,
  id,
  isPrint
})

export const setClientIsBusinessman = (id, isBusinessman) => ({
  type: SET_CLIENT_IS_BUSINESSMAN,
  id,
  isBusinessman
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
