const notices = [
  {
    id: 1,
    createdAt: '2021.10.01',
    title: '페이워크 웹서비스 PC버전 출시 기념 9,900원 이용권 할인 이벤트',
    writer: '페이워크 관리자',
    content:
      '안녕하세요 페이워크 웹 PC버전이 출시되었습니다.서비스 기능에는 - 견적서'
  },
  {
    id: 2,
    createdAt: '2021.11.25',
    title: '비대면 바우처 고객 대상 서비스 이용기간 연장 안내',
    writer: '페이워크 관리자',
    content: '추석연휴 사무실 오픈 합니다.'
  },
  {
    id: 3,
    createdAt: '2021.12.02',
    title: '회원가입 바나나우유 이벤트 100% 당첨',
    writer: '페이워크 관리자',
    content: '월요일 오픈합니다 론칭가능합니다.'
  }
]

export { notices }
