import { takeEvery, fork, put, all } from 'redux-saga/effects'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from '../../reducers/Auth/login'
import { apiError } from '../../reducers/Auth/login'
import { initialize } from '../../reducers/appState'

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";

// const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      // const response = yield call(fireBaseBackend.loginUser, user.email, user.password);
      // yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      // const response = yield call(postJwtLogin, '/post-jwt-login', {email: user.email, password: user.password});
      // localStorage.setItem("authUser", JSON.stringify(response));
      // yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
      // const response = yield call(postFakeLogin, '/post-fake-login', {email: user.email, password: user.password});
      // localStorage.setItem("authUser", JSON.stringify(response));
      // yield put(loginSuccess(response));
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser')

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      // const response = yield call(fireBaseBackend.logout);
      // yield put(logoutUserSuccess(response));
    }
    yield put(initialize())
    history.push('/login')
  } catch (error) {
    yield put(apiError(error))
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)])
}

export default authSaga
