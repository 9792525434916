/** 플랜 정보 호출 */
export const GET_RATEPLAN_PLANS = 'reducers/ratePlan/GET_RATEPLAN_PLANS'
export const getRatePlanPlans = () => ({
	type: GET_RATEPLAN_PLANS
});

const reducer = (state = {}, action) => {
	switch (action.type) {
	  default:
		return state
	}
}
  
export default reducer;