import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown
} from 'reactstrap'
import React from 'react'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
//Date Picker

const index = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="탈퇴 회원 목록 " breadcrumbItem="Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="단어를 입력하세요 "
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap">
                      <thead>
                        <tr>
                          <th>번호</th>
                          <th>회원 유형 </th>
                          <th>닉네임 / 회원명 </th>
                          <th>휴대폰 번호 </th>
                          <th>보트수 </th>
                          <th>총 누적 지출금 </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck1"
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="customCheck1"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </td>
                          <td>보관회원 </td>
                          <td>
                            <p className="mb-1">N03</p>
                            <p className="mb-0">홍길동</p>
                          </td>
                          <td>010-7231-6568</td>
                          <td>4대</td>
                          <td>8,500,000원</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                href="#"
                                className="card-drop"
                                tag="i"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem href="#">
                                  <i className="mdi mdi-apple-keyboard-shift font-size-16 text-success mr-2"></i>
                                  이동
                                </DropdownItem>
                                <DropdownItem href="#">
                                  <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i>
                                  삭제
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck1"
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="customCheck1"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </td>
                          <td>외부회원 </td>
                          <td>
                            <p className="mb-1">N02</p>
                            <p className="mb-0">김성후 </p>
                          </td>
                          <td>010-8720-3800 </td>
                          <td>1대</td>
                          <td>23,500,000원</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                href="#"
                                className="card-drop"
                                tag="i"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem href="#">
                                  <i className="mdi mdi-apple-keyboard-shift font-size-16 text-success mr-2"></i>
                                  이동
                                </DropdownItem>
                                <DropdownItem href="#">
                                  <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i>
                                  삭제
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationItem disabled>
                      <PaginationLink previous href="#" />
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#">1</PaginationLink>
                    </PaginationItem>
                    <PaginationItem active>
                      <PaginationLink href="#">2</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#">3</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#">4</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#">5</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink next href="#" />
                    </PaginationItem>
                  </Pagination>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
