export const DASHBOARD = 'reducers/dashboard/DASHBOARD'
export const GET_GRAPH_DASHBOARD = 'reducers/dashboard/GET_GRAPH_DASHBOARD'
export const GET_USER_LOGIN_DASHBOARD =
  'reducers/dashboard/GET_USER_LOGIN_DASHBOARD'
export const GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD =
  'reducers/dashboard/GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD'
export const GET_BUSINESSMAN_PERCENT_DASHBOARD =
  'reducers/dashboard/GET_BUSINESSMAN_PERCENT_DASHBOARD'
export const GET_PLATFORM_LOGIN_DASHBOARD =
  'reducers/dashboard/GET_PLATFORM_LOGIN_DASHBOARD'
export const GET_REVISIT_USER_DASHBOARD =
  'reducers/dashboard/GET_REVISIT_USER_DASHBOARD'
export const GET_REVISIT_MONTH_USER_DASHBOARD =
  'reducers/dashboard/GET_REVISIT_MONTH_USER_DASHBOARD'
export const GET_RETENTION = 'reducers/dashboard/GET_RETENTION'
export const GET_USER_RETENTION = 'reducers/dashboard/GET_USER_RETENTION'
export const GET_USER_COHORT = 'reducers/dashboard/GET_USER_COHORT'
export const GET_CLIENT_IS_BUSINESS_MAN =
  'reducers/dashboard/GET_CLIENT_IS_BUSINESS_MAN'
export const GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN =
  'reducers/dashboard/GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN'
export const GET_PAYMENT_PERCENT_DASHBOARD =
  'reducers/dashboard/GET_PAYMENT_PERCENT_DASHBOARD'
export const GET_USER_RATIO_DASHBOARD =
  'reducers/dashboard/GET_USER_RATIO_DASHBOARD'
export const GET_NEW_USER_TO_PAY_DASHBOARD =
  'reducers/dashboard/GET_NEW_USER_TO_PAY_DASHBOARD'
export const GET_TRIAL_TO_PAY_DASHBOARD =
  'reducers/dashboard/GET_TRIAL_TO_PAY_DASHBOARD'
export const GET_AVG_WORK_DOCUMENT_DASHBOARD =
  'reducers/dashboard/GET_AVG_WORK_DOCUMENT_DASHBOARD'
export const GET_USER_AVG_WORK_DOCUMENT_DASHBOARD =
  'reducers/dashboard/GET_USER_AVG_WORK_DOCUMENT_DASHBOARD'
export const GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD =
  'reducers/dashboard/GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD'
export const GET_TAX_INVOICE_RATIO_DASHBOARD =
  'reducers/dashboard/GET_TAX_INVOICE_RATIO_DASHBOARD'
export const GET_SALES_DASHBOARD = 'reducers/dashboard/GET_SALES_DASHBOARD'
export const GET_RATE_PLAN_PRICE_DASHBOARD =
  'reducers/dashboard/GET_RATE_PLAN_PRICE_DASHBOARD'
export const GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD =
  'reducers/dashboard/GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD'
export const GET_ACC_GRAPH_DASHBOARD =
  'reducers/dashboard/GET_ACC_GRAPH_DASHBOARD'

export const getDashboard = () => ({
  type: DASHBOARD
})

export const getGraphDashboard = options => ({
  type: GET_GRAPH_DASHBOARD,
  options
})

export const getUserLoginDashboard = options => ({
  type: GET_USER_LOGIN_DASHBOARD,
  options
})

export const getWorkOrderQuantityShareDashboard = options => ({
  type: GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD,
  options
})

export const getBusinessmanPercentDashboard = () => ({
  type: GET_BUSINESSMAN_PERCENT_DASHBOARD
})

export const getPlatformLoginDashboard = options => ({
  type: GET_PLATFORM_LOGIN_DASHBOARD,
  options
})

export const getRevisitUserDashboard = time => ({
  type: GET_REVISIT_USER_DASHBOARD,
  time
})

export const getRevisitMonthUserDashboard = time => ({
  type: GET_REVISIT_MONTH_USER_DASHBOARD,
  time
})

export const getRetention = () => ({
  type: GET_RETENTION
})

export const getUserRetention = (retentionType, year) => ({
  type: GET_USER_RETENTION,
  retentionType,
  year
})

export const getUserCohort = (year, week) => ({
  type: GET_USER_COHORT,
  year,
  week
})

export const getClientIsBusinessman = () => ({
  type: GET_CLIENT_IS_BUSINESS_MAN
})

export const getTradingStatementClientIsBusinessman = () => ({
  type: GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN
})

export const getPaymentPercentDashboard = () => ({
  type: GET_PAYMENT_PERCENT_DASHBOARD
})

export const getUserRatioDashboard = options => ({
  type: GET_USER_RATIO_DASHBOARD,
  options
})

export const getNewUserToPayDashboard = options => ({
  type: GET_NEW_USER_TO_PAY_DASHBOARD,
  options
})

export const getTrialToPayDashboard = options => ({
  type: GET_TRIAL_TO_PAY_DASHBOARD,
  options
})

export const getAvgWorkDocumentDashboard = options => ({
  type: GET_AVG_WORK_DOCUMENT_DASHBOARD,
  options
})

export const getUserAvgWorkDocumentDashboard = options => ({
  type: GET_USER_AVG_WORK_DOCUMENT_DASHBOARD,
  options
})

export const getWorkDocumentCreateUserDashboard = () => ({
  type: GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD
})

export const getTaxInvoiceRatioDashboard = options => ({
  type: GET_TAX_INVOICE_RATIO_DASHBOARD,
  options
})

export const getSalesDashboard = options => ({
  type: GET_SALES_DASHBOARD,
  options
})

export const getRatePlanPriceDashboard = options => ({
  type: GET_RATE_PLAN_PRICE_DASHBOARD,
  options
})

export const getActiveUserWorkDocumentDashboard = options => ({
  type: GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD,
  options
})

export const getAccGraphDashboard = options => ({
  type: GET_ACC_GRAPH_DASHBOARD,
  options
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
