import {
  Button,
  CardBody,
  Container,
  Label,
  Row,
  Table,
  FormGroup,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Input
} from 'reactstrap'
import styled from 'styled-components'

import React, { useEffect, useState } from 'react'
import { getDateRange } from '../../../helpers/utils'
import { NativeSelect } from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/ko'

import Indicator from '../../../components/Common/Indicator'
import Paginator from '../../../components/Common/Paginator'

import Select from 'react-select'
import { getUserReviews } from '../../../api/review'
import { credits } from '../../../constant/credit'

const selectCompany = [{ value: 'company1', label: '일반' }]

const UserCredit = ({
  formData,
  handlePaymentInputs,
  handlePrice,
  createPayment,
  paymentData,
  onSaveFormData,
  updatePayment,
  deletePayment,
  isMember,
  userCompany,
  onChangeCompany
}) => {
  const userId = window.location.pathname.split('/')[2]
  const [creditData, setCreditData] = useState()
  const [renderData, setRenderData] = useState()
  const [searchQuery, setSearchQuery] = useState({
    project: '',
    user: '',
    date: '',
    range: {
      label: '전체',
      value: 'all',
      start: 0,
      end: Infinity
    }
  })
  const [remain, setRemain] = useState(0)
  const [selectGroup, setSelectGroup] = useState([{ options: [] }])
  const [selectOpt, setSelectOpt] = useState(selectCompany[0])
  const [selectBox, setSelectBox] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)

  const [page, setPage] = useState(1)
  const handlePagination = (changePage, pageSize) => {
    if (page !== changePage) {
      setSelectBox([])
      return setPage(changePage)
    }
    return
  }

  const handleSelect = options => {
    const changeData = creditData.filter(ele => ele.company === options.value)

    const changeRemain = changeData[0].data.reduce(
      (ele, cur) => ele + cur.payment - cur.deduction,
      0
    )

    setSelectOpt(options)
    setRemain(changeRemain)
    setRenderData(changeData)
  }

  // console.log('$$ payment detail', selectBox)

  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Label>
            <h3>
              이용내역{' '}
              {/* {Array.isArray(feedData.feedComment) &&
              feedData.feedComment?.length} */}
              (잔액:{' '}
              {paymentData?.userPaymentHistorys
                ? (
                    paymentData?.userPaymentHistorys.reduce(
                      (ele, cur) => ele + cur.rechargePrice,
                      0
                    ) -
                    paymentData?.userPaymentHistorys.reduce(
                      (ele, cur) => ele + cur.paymentPrice,
                      0
                    )
                  ).toLocaleString()
                : 0}
              원)
            </h3>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <FormGroup>
            <NativeSelect
              className="form-control"
              name="boatSale"
              onChange={e => onChangeCompany(e)}
              disableUnderline
            >
              {userCompany.map(ele => (
                <option value={ele}>{ele}</option>
              ))}
            </NativeSelect>
          </FormGroup>
        </Col>
        <Col lg="8">
          <div className="text-sm-right">
            <Button
              type="button"
              color="success"
              className="btn-rounded waves-effect waves-light mb-2 mr-2"
              onClick={() => setCreateModal(true)}
              disabled={!isMember}
            >
              <i className="mdi mdi-plus mr-1"></i> 추가
            </Button>

            <Button
              type="button"
              color="danger"
              className="btn-rounded waves-effect waves-light mb-2 mr-2"
              onClick={() => setDeleteModal(!deleteModal)}
              disabled={selectBox.length === 0}
            >
              <i className="mdi mdi-minus mr-1"></i> 삭제
            </Button>
          </div>
        </Col>
      </Row>

      <Modal
        centered
        scrollable
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
          setSelectBox([])
        }}
      >
        <ModalHeader
          toggle={() => {
            setDeleteModal(!deleteModal)
            setSelectBox([])
          }}
          style={{
            borderBottom: '2px solid #2a3042'
          }}
        >
          <div>내역 삭제</div>
        </ModalHeader>
        <ModalBody
          className="d-flex align-items-center justify-content-center"
          style={{
            height: '150px',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              fontSize: '15px',
              fontWeight: 'bold',
              marginBottom: '8px'
            }}
          >{`선택한 내역을 삭제하시겠습니까?`}</div>
          <div>{`이용내역이 삭제됩니다`}</div>
        </ModalBody>
        <ModalFooter
          onClick={() => setDeleteModal(!deleteModal)}
          style={{
            justifyContent: 'center',
            borderTop: '2px solid #e2e2e2'
          }}
        >
          <Button
            color="danger"
            outline
            onClick={e => {
              deletePayment(e, selectBox)
              setSelectBox([])
            }}
            disabled={!isMember}
          >
            삭제하기
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        scrollable
        isOpen={createModal}
        toggle={() => setCreateModal(!createModal)}
      >
        <ModalHeader
          toggle={() => setCreateModal(!createModal)}
          style={{
            borderBottom: '2px solid #2a3042'
          }}
        >
          <div>내역 추가</div>
        </ModalHeader>
        <ModalBody
          className="d-flex align-items-center justify-content-center"
          style={{
            minHeight: '400px',
            flexDirection: 'column'
          }}
        >
          <Form
            onSubmit={e => {
              createPayment(e)
              setCreateModal(false)
            }}
          >
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>날짜</Label>
                  <Input
                    required
                    name="paymentedAt"
                    type="date"
                    className="form-control"
                    value={formData.paymentedAt}
                    onChange={handlePaymentInputs}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>결제금액</Label>
                  <Input
                    name="paymentPrice"
                    type="text"
                    className="form-control"
                    value={
                      formData.paymentPrice === 0
                        ? '0'
                        : formData.paymentPrice
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    onChange={handlePrice}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>충전금액</Label>
                  <Input
                    name="rechargePrice"
                    type="text"
                    className="form-control"
                    value={
                      formData.rechargePrice === 0
                        ? '0'
                        : formData.rechargePrice
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    onChange={handlePrice}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>종류</Label>
                  <Input
                    name="type"
                    type="text"
                    className="form-control"
                    value={formData.type}
                    onChange={handlePaymentInputs}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>유형</Label>
                  <Input
                    name="category"
                    type="text"
                    className="form-control"
                    value={formData.category}
                    onChange={handlePaymentInputs}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <Button
                  type="submit"
                  color="primary"
                  className="btn-block mr-1 waves-effect waves-light"
                >
                  등록하기
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        centered
        scrollable
        isOpen={updateModal}
        toggle={() => setUpdateModal(!updateModal)}
      >
        <ModalHeader
          toggle={() => setUpdateModal(!updateModal)}
          style={{
            borderBottom: '2px solid #2a3042'
          }}
        >
          <div>내역 수정</div>
        </ModalHeader>
        <ModalBody
          className="d-flex align-items-center justify-content-center"
          style={{
            minHeight: '400px',
            flexDirection: 'column'
          }}
        >
          <Form
            onSubmit={e => {
              updatePayment(e)
              setUpdateModal(false)
            }}
          >
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>날짜</Label>
                  <Input
                    required
                    name="paymentedAt"
                    type="date"
                    className="form-control"
                    value={formData.paymentedAt}
                    onChange={handlePaymentInputs}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>결제금액</Label>
                  <Input
                    name="paymentPrice"
                    type="text"
                    className="form-control"
                    value={
                      formData.paymentPrice === 0
                        ? '0'
                        : formData.paymentPrice
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    onChange={handlePrice}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>충전금액</Label>
                  <Input
                    name="rechargePrice"
                    type="text"
                    className="form-control"
                    value={
                      formData.rechargePrice === 0
                        ? '0'
                        : formData.rechargePrice
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    onChange={handlePrice}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>종류</Label>
                  <Input
                    name="type"
                    type="text"
                    className="form-control"
                    value={formData.type}
                    onChange={handlePaymentInputs}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>유형</Label>
                  <Input
                    name="category"
                    type="text"
                    className="form-control"
                    value={formData.category}
                    onChange={handlePaymentInputs}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <Button
                  type="submit"
                  color="primary"
                  className="btn-block mr-1 waves-effect waves-light"
                  disabled={!isMember}
                >
                  수정하기
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Row>
        <CardBody style={{width:'100%'}}>
          <div className="table-responsive" style={{width:'100%', minHeight: '750px', overflowX: 'auto', whiteSpace:'nowrap'}}>
            <Table className="table table-centered table-nowrap" style={{width:'auto',whiteSpace:'nowrap', borderCollapse:'collapse'}}>
              <thead className="thead-light">
                <tr>
                  <th style={{ width: '5%' }}>
                    <div
                      className="custom-control custom-checkbox"
                      style={{ zIndex: 0 }}
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="all-check"
                        checked={
                          selectBox.length !== 0 &&
                          selectBox.length ===
                            paymentData.userPaymentHistorys
                              ?.sort((a, b) => {
                                let x = new Date(a.paymentedAt).getTime()
                                let y = new Date(b.paymentedAt).getTime()

                                if (x > y) {
                                  return -1
                                } else {
                                  if (x < y) {
                                    return 1
                                  }
                                  return 0
                                }
                              })
                              .slice(10 * (page - 1), 10 * (page - 1) + 10)
                              .length
                        }
                        onChange={e =>
                          e.target.checked
                            ? Array.isArray(
                                paymentData.userPaymentHistorys
                                  ?.sort((a, b) => {
                                    let x = new Date(a.paymentedAt).getTime()
                                    let y = new Date(b.paymentedAt).getTime()

                                    if (x > y) {
                                      return -1
                                    } else {
                                      if (x < y) {
                                        return 1
                                      }
                                      return 0
                                    }
                                  })
                                  .slice(10 * (page - 1), 10 * (page - 1) + 10)
                              ) &&
                              setSelectBox(
                                paymentData.userPaymentHistorys
                                  ?.sort((a, b) => {
                                    let x = new Date(a.paymentedAt).getTime()
                                    let y = new Date(b.paymentedAt).getTime()

                                    if (x > y) {
                                      return -1
                                    } else {
                                      if (x < y) {
                                        return 1
                                      }
                                      return 0
                                    }
                                  })
                                  .slice(10 * (page - 1), 10 * (page - 1) + 10)
                                  .map(u => u.id)
                              )
                            : setSelectBox([])
                        }
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="all-check"
                      >
                        &nbsp;
                      </Label>
                    </div>
                  </th>
                  <th style={{ width: '19%' }}>결제일</th>
                  <th style={{ width: '19%' }}>종류</th>
                  <th style={{ width: '10%' }}>유형</th>
                  <th style={{ width: '10%' }}>결제금액</th>
                  <th style={{ width: '10%' }}>충전금액</th>
                  <th style={{ width: '19%' }}>결제상태</th>
                  <th style={{ width: '19%' }}>실패사유</th>
                  <th style={{ width: '19%' }}>해지사유</th>
                  <th style={{ width: '19%' }}>해지상세사유</th>
                  <th style={{ width: '19%' }}>세부정보</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(paymentData.userPaymentHistorys) &&
                  paymentData.userPaymentHistorys
                    .sort((a, b) => {
                      let x = new Date(a.paymentedAt).getTime()
                      let y = new Date(b.paymentedAt).getTime()

                      if (x > y) {
                        return -1
                      } else {
                        if (x < y) {
                          return 1
                        }
                        return 0
                      }
                    })
                    .slice(10 * (page - 1), 10 * (page - 1) + 10)
                    .map((payment, idx) => {
                      return (
                        <PaymentTR key={idx}>
                          <td style={{ height: '65px' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={payment.id}
                                checked={selectBox.includes(payment.id)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    setSelectBox([...selectBox, payment.id])
                                  } else {
                                    setSelectBox(
                                      selectBox.filter(b => b !== payment.id)
                                    )
                                  }
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor={payment.id}
                                style={{ cursor: 'pointer' }}
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </td>

                          <td
                            onClick={() => {
                              onSaveFormData(payment)
                              setUpdateModal(true)
                            }}
                          >
                            {payment.paymentedAt &&
                              payment.paymentedAt.slice(0, 10)}
                          </td>
                          <td
                            onClick={() => {
                              onSaveFormData(payment)
                              setUpdateModal(true)
                            }}
                          >
                            {payment.type}
                          </td>
                          <td
                            onClick={() => {
                              onSaveFormData(payment)
                              setUpdateModal(true)
                            }}
                          >
                            {payment.category}
                          </td>
                          <td
                            onClick={() => {
                              onSaveFormData(payment)
                              setUpdateModal(true)
                            }}
                          >
                            {payment.paymentPrice !== 0
                              ? payment.paymentPrice.toLocaleString()
                              : null}
                          </td>
                          <td
                            onClick={() => {
                              onSaveFormData(payment)
                              setUpdateModal(true)
                            }}
                          >
                            {payment.rechargePrice !== 0
                              ? payment.rechargePrice.toLocaleString()
                              : null}
                          </td>
                          <td
                          >
                            {payment.isPaymented === true ? 
                               '결제성공'
                              : payment.isPaymented === false ? '결제대기' : '결제실패'}
                          </td>
                          <td
                          >
                            {payment.failedType ?? null}
                          </td>
                          <td
                          >
                            {payment.breakawayType ?? null}
                          </td>
                          <td
                          >
                            {payment.breakawayDescript ?? null}
                          </td>
                          <td
                          >
                            {payment.descript ?? null}
                          </td>
                        </PaymentTR>
                      )
                    })}
              </tbody>
            </Table>
          </div>

          <Paginator
            currentPage={page}
            pageSize={10}
            total={Math.ceil(
              paymentData.userPaymentHistorys &&
                paymentData.userPaymentHistorys.length / 10
            )}
            onChange={handlePagination}
          />
        </CardBody>
      </Row>
    </Container>
  )
}

export default UserCredit

const PaymentTR = styled.tr`
  :hover {
    background: lightGrey;
  }
`
