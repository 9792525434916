import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import DSU from './DSU'
import WSU from './WSU'
import MSU from './MSU'

export default function NorthStarSuMain() {
  const [type, setType] = useState('WSU')
  const onClickButton = useCallback(type => {
    setType(type)
  }, [])

  return (
    <Wrapper>
      <h2>SU 회원가입</h2>
      <p>[Signup User]탈퇴회원을 제외한 누적가입 회원입니다.</p>
      <ButtonWrapper>
        <Button
          color={type === 'MSU' ? 'primary' : 'secondary'}
          active={type === 'MSU'}
          onClick={() => onClickButton('MSU')}
        >
          MSU
        </Button>
        <Button
          color={type === 'WSU' ? 'primary' : 'secondary'}
          active={type === 'WSU'}
          onClick={() => onClickButton('WSU')}
        >
          WSU
        </Button>
        <Button
          color={type === 'DSU' ? 'primary' : 'secondary'}
          active={type === 'DSU'}
          onClick={() => onClickButton('DSU')}
        >
          DSU
        </Button>
      </ButtonWrapper>
      <ChartWrapper>
        {type === 'DSU' ? (
          <DSU type={type} />
        ) : type === 'WSU' ? (
          <WSU type={type} />
        ) : type === 'MSU' ? (
          <MSU type={type} />
        ) : (
          <></>
        )}
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`

const ChartWrapper = styled.div``
