import React, { useEffect, useState } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { NativeSelect } from '@material-ui/core'
import Dropzone from 'react-dropzone'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// components
import Indicator from '../../components/Common/Indicator'

// utils
import { addOrderPrefix } from '../../helpers/utils'

// redux
import { getTesters } from '../../api/tester'
import {
  getCategoryList,
  // createFeed,
  uploadImage
} from '../../api/feed'
import { getTesterList } from '../../reducers/tester'
import { getFeedCategoryList, createFeed } from '../../reducers/feed'

const FeedRegister = ({
  history,
  state,
  params,
  testerList,
  getTesterList,
  feedCategoryList,
  getFeedCategoryList,
  createFeed
}) => {
  const [error, setError] = useState('')
  const [isFetching, setFetching] = useState(true)
  const [formData, setFormData] = useState({
    id: '',
    username: '',
    phone: '',
    email: '',
    title: '',
    content: '',
    tags: null,
    category: 'b3abb3f0-4f40-4853-a2d2-f05c59ba8e22',
    attachments: [],
    searchResult: [],
    images: []
  })
  const [testerData, setTesterData] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [status, setStatus] = useState({
    isError: false,
    color: 'danger',
    errorMsg: '피드 등록 중 문제가 발생했습니다.'
  })

  useEffect(() => {
    getTesterList()
    getFeedCategoryList()
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_TESTER_LIST':
        const filterTester = testerList.sort((a, b) => {
          let x = addOrderPrefix(a.username)
          let y = addOrderPrefix(b.username)

          if (x < y) {
            return -1
          }
          if (x > y) {
            return 1
          }

          return 0
        })

        // if (formData.phone === '' || formData.email === '') {
        //   initTester(filterTester[0])
        // }
        setTesterData(filterTester)

        break

      case 'SUCCESS_GET_FEED_CATEGORY_LIST':
        const filterCategory = feedCategoryList.feedCategoryTemplets
          .filter(f => f.enabled === true)
          .sort(function (a, b) {
            var date_a = new Date(a.createdAt)
            var date_b = new Date(b.createdAt)
            if (date_a > date_b) return -1
            if (date_a < date_b) return 1
            return 0
          })

        setCategoryList(filterCategory)

        break
      case 'FAILED_GET_TESTER_LIST':
        setStatus({ color: 'danger', isError: true, errorMsg: params.message })
        break
      case 'SUCCESS_CREATE_FEED':
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '게시물이 등록되었습니다'
        })
        setFormData({
          ...formData,
          title: '',
          content: '',
          images: []
        })
        break
      default:
        break
    }
  }, [state])

  const handleInputs = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleChange = options => {
    return setFormData(prevState => ({
      ...prevState,
      tags: Array.isArray(options) && options.map(option => option.value)
    }))
  }

  const onSubmit = async e => {
    e.preventDefault()

    const newFeed = {
      title: formData.title,
      content: formData.content,
      targetUserId: formData.id,
      categoryId: formData.category
    }

    createFeed(newFeed, formData.images)

    // const result = await createFeed(newFeed, formData.images)

    // if (result.opcode === 0) {
    //   history.push('/feeds')
    // } else {
    //   setFetching(false)
    //   setError(result.message)
    // }
  }

  // const onSubmit = async e => {
  //   e.preventDefault()

  //   setFetching(true)

  //   const newFeed = {
  //     title: formData.title,
  //     content: formData.content,
  //     targetUserId: formData.id,
  //     categoryId: formData.category
  //   }

  //   const result = await createFeed(newFeed, formData.images)

  //   if (result.opcode === 0) {
  //     history.push('/feeds')
  //   } else {
  //     setFetching(false)
  //     setError(result.message)
  //   }
  // }

  const selectName = e => {
    const getTester = testerData.filter(ele => {
      return ele.id === e.target.value
    })

    setFormData({
      ...formData,
      phone: getTester[0].phone,
      email: getTester[0].email,
      id: getTester[0].id
    })
  }

  const selectCategory = e => {
    setFormData({
      ...formData,
      category: e.target.value
    })
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const handleAcceptedFiles = async (files, fieldName) => {

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )

    let tempFiles = []

    for (let i = 0; i < files.length; i++) {
      tempFiles.push({
        name: files[i].path,
        preview: files[i].preview,
        size: files[i].size
      })
    }

    switch (fieldName) {
      case 'images':
        setFormData({
          ...formData,
          attachments: tempFiles,
          images: files
        })
        break
      default:
        break
    }
  }

  const initTester = first => {
    setFormData({
      ...formData,
      phone: first.phone,
      email: first.email,
      username: first.username,
      id: first.id
    })
  }

  const onChangeTester = e => {
    const target = testerData.filter(ele => {
      return ele.id === e.target.value
    })[0]

    setFormData({
      ...formData,
      phone: target.phone,
      email: target.email,
      username: target.username,
      id: target.id
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ height: '80vh' }}>
          <Link to={'/feeds'}>
            <Breadcrumbs title="< 피드 등록하기" breadcrumbItem="Add Feed" />
          </Link>

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col sm="10" />
                      <Col sm="2">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block mr-1 waves-effect waves-light"
                        >
                          등록하기
                        </Button>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>이름</Label>
                          <NativeSelect
                            className="form-control"
                            name="boatSale"
                            onChange={e => onChangeTester(e)}
                            disableUnderline
                          >
                            {testerData.map(ele => (
                              <option value={ele.id}>{ele.username}</option>
                            ))}
                          </NativeSelect>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>전화번호</Label>
                          <Input
                            required
                            name="phone"
                            type="text"
                            className="form-control"
                            value={formData.username}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>이메일</Label>
                          <Input
                            required
                            name="email"
                            type="text"
                            className="form-control"
                            value={formData.email}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>카테고리</Label>
                          <select
                            className="form-control"
                            name="category"
                            value={formData.category}
                            onChange={e => selectCategory(e)}
                            disableUnderline
                          >
                            {Array.isArray(categoryList) &&
                              categoryList.map(category => (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>제목 </Label>
                          <Input
                            required
                            name="title"
                            type="title"
                            className="form-control"
                            value={formData.title}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>내용</Label>
                          <Input
                            type="textarea"
                            required
                            name="content"
                            className="form-control"
                            value={formData.content}
                            onChange={handleInputs}
                            style={{ minHeight: '150px' }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12">
                        <Label>이미지 업로드</Label>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles, 'images')
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {formData.attachments?.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + '-file'}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      {f.name.length > 0 && (
                                        <a href={f.preview} download>
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </a>
                                      )}
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  testerList: state.appState.data.testerList,
  feedCategoryList: state.appState.data.feedCategoryList
})
const mapDispatchToProps = dispatch => ({
  getTesterList: () => dispatch(getTesterList()),
  getFeedCategoryList: () => dispatch(getFeedCategoryList()),
  createFeed: (newFeed, image) => dispatch(createFeed(newFeed, image))
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedRegister)
