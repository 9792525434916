import { ErrorRes, ViewOutputSizeType, ViewOutputType } from "@payworkteam/server-product-types";
import { instance } from "../baseInstance";
import { AxiosError } from "axios";

/** 거래문서 뷰 */
export const getWorkDocumentViewV3 = async (workDocumentId: string, params: {
	output?: ViewOutputType;
	outputSize?: ViewOutputSizeType;
	viewPortScale?: string | null;
}) => {
	const data: string | AxiosError = await instance.get(`/workDocument/view/${workDocumentId}`, {
	  params
	});
  
	return data;
}