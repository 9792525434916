import {
  put,
  call,
  takeLatest,
  takeEvery,
  select
} from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'
import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_TAXINVOICE_DETAIL,
  GET_TAXINVOICE_LIST,
  SET_TAXINVOICE_IS_BUSINESSMAN,
  GET_TAXINVOICE_VIEW
} from '../reducers/taxInvoice'

export function* getTaxInvoiceList(actions) {
  const {
    page,
    amount,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate
  } = actions

  yield put(setAppState('START_GET_TAXINVOICE_LIST', null, true))

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_TAXINVOICE_LIST', null, false))
  }

  try {
    const params = {
      page: page,
      amount: amount,
      search: search,
      workDocumentType: 'TaxInvoice',
      filterBoClientType: clientType,
      filterTotalPriceStartEqualSign: 'More',
      filterTotalPriceStartValue: startMoney,
      filterStartDate: startDate,
      filterEndDate: endDate
    }
    if (endMoney > 0) {
      params.filterTotalPriceEndEqualSign = 'Less'
      params.filterTotalPriceEndValue = endMoney
    }

    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.GET_TAXINVOICE,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    });
    
    if (response.data.opcode === 0) {
      yield put(saveAppData('taxInvoiceList', response.data))
      yield put(setAppState('SUCCESS_GET_TAXINVOICE_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_TAXINVOICE_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_TAXINVOICE_LIST', e, false))
  }
}

export function* getTaxInvoiceDetail(actions) {
  const { id } = actions
  yield put(setAppState('START_GET_TAXINVOICE_DETAIL', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_TAXINVOICE_DETAIL', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.TAXINVOICE}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    
    if (response.status === 200) {
      yield put(saveAppData('taxInvoiceDetail', response.data.taxInvoice))
      yield call(getTaxInvoiceDetailView, {id: response?.data?.taxInvoice?.id})
      
      yield put(setAppState('SUCCESS_GET_TAXINVOICE_DETAIL', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_TAXINVOICE_DETAIL', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_TAXINVOICE_DETAIL', e, false))
  }
}

export function* setTaxInvoiceIsBusinessman(actions) {
  const { id, isBusinessman } = actions
  yield put(setAppState('START_SET_TAXINVOICE_IS_BUSINESSMAN', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_TAXINVOICE_IS_BUSINESSMAN', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.BILL}/clientIsBusinessman/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        clientIsBusinessman: isBusinessman
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState('SUCCESS_SET_TAXINVOICE_IS_BUSINESSMAN', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_SET_TAXINVOICE_IS_BUSINESSMAN', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_TAXINVOICE_IS_BUSINESSMAN', e, false))
  }
}


/** 세금계산서 View */
export function* getTaxInvoiceDetailView(actions) {
  const { id } = actions
  yield put(setAppState('START_GET_TAXINVOICE_VIEW', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_TAXINVOICE_VIEW', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.TAXINVOICE_VIEW}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      responseType: 'arraybuffer',
    })

    if (response.status === 200) {
      yield put(saveAppData('taxInvoiceDetailView', response.data))
      yield put(setAppState('SUCCESS_GET_TAXINVOICE_VIEW', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_TAXINVOICE_VIEW', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_TAXINVOICE_VIEW', e, false))
  }
}

const taxInvoiceSaga = [
  takeLatest(GET_TAXINVOICE_LIST, getTaxInvoiceList),
  takeLatest(GET_TAXINVOICE_DETAIL, getTaxInvoiceDetail),
  takeLatest(SET_TAXINVOICE_IS_BUSINESSMAN, setTaxInvoiceIsBusinessman),
  takeLatest(GET_TAXINVOICE_VIEW, getTaxInvoiceDetailView),
]

export default taxInvoiceSaga
