import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import * as dateFns from 'date-fns'
import ReactApexChart from 'react-apexcharts'
import { backofficeBaseUrl } from '../../../../global/constants'
import { years, months, daysInMonth } from '../../../../constant/chart'
import { defaultOptions, xaxisOption } from '../../../../helpers/chart'
import CommonTable from '../../../../components/Common/Table'

export default function LoginRetentionMonthly() {
  const apiUrl = `${backofficeBaseUrl}/dashBoard/revisitRetention`
  const [type, setType] = useState('year') // year, month, week
  const [year, setYear] = useState(parseInt(new Date().getFullYear()-1).toString())
  const onChangeYear = useCallback(e => {
    setYear(e.target.value)
  }, [])
  const [month, setMonth] = useState((parseInt(new Date().getMonth())+1).toString())
  const [week, setWeek] = useState('1')
  const [date, setDate] = useState('1');

  const onChangeMonth = useCallback(e => {
    setMonth(e.target.value)
  }, [])
  const [range, setRange] = useState('12')

  const [data, setData] = useState(null)

  const callAPI = useCallback(async () => {
    try {
      if(type==='year' && !year && !month){
         alert('년,월 입력 오류');
         return 
      } else if(type === 'month' && !year && !month && !date){ 
        alert('년,월,일 입력 오류');
        return 
      } else if(type === 'week' && !year && !month){
        alert('년,월 입력 오류');
         return 
      }
      const url = `${apiUrl}?type=${type}&range=${range}&year=${year}&month=${month}&week=${week}&date=${date}`
      const res = await axios.get(url)
      setData(res.data.dashBoard)
    } catch (e) {
      throw e
    }
  }, [type,year, month,week,date, range])

  const labels = ['사용자 수', '리텐션 비율']

  const handleXAxis = (month, x) => {
    const num = (Number(month - 1) + x) % 12
    return num === 0 ? 12 : num
  }

  const [userMonth, setUserMonth] = useState(dateFns.format(new Date(), 'M'))
  const onChangeUserMonth = useCallback(e => {
    setUserMonth(e.target.value)
  }, [])
  const [openTable, setOpenTable] = useState(false)

  const dataForTable = data?.find(
    i => handleXAxis(month, i.month) === Number(userMonth)
  )

  useEffect(() => {
    callAPI()
  }, [type,year, month,week,date, range])

  return (
    <Wrapper>
      <DateWrapper>
        <p>날짜</p>
        <Select value={year} onChange={onChangeYear}>
          {years.map(i => (
            <Option key={i} value={i}>
              {i}년
            </Option>
          ))}
        </Select>
        <Select value={month} onChange={onChangeMonth}>
          {months.map(i => (
            <Option key={i} value={i}>
              {i}월
            </Option>
          ))}
        </Select>
        <RangeName>지정된 날짜로부터</RangeName>
        <RangeInput
          type={'number'}
          value={range}
          onChange={e => setRange(e.target.value)}
        />
        <RangeDate>개월</RangeDate>
      </DateWrapper>
      <ChartWrapper>
        {data && (
          <>
            <ReactApexChart
              key={'retention-monthly'}
              height={300}
              options={{
                ...defaultOptions,
                plotOptions: {
                  bar: {
                    // horizontal: true
                  }
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                yaxis: [
                  { title: { text: labels[0] } },
                  {
                    opposite: true,
                    title: { text: labels[1] }
                  }
                ]
              }}
              series={[
                {
                  name: labels[0],
                  type: 'bar',
                  data: data.map(i => {
                    return {
                      x: `${handleXAxis(month, i.x)}월`,
                      y: `${i.count}명`
                    }
                  })
                },
                {
                  name: labels[1],
                  type: 'line',
                  data: data.map(i => {
                    return {
                      x: `${handleXAxis(month, i.x)}월`,
                      y: `${i.percent}`
                    }
                  })
                }
              ]}
            />
          </>
        )}
      </ChartWrapper>
      <TableWrapper>
        <TableToggleButtonWrapper>
          <span>유저 확인 테이블</span>
          <Select elect value={userMonth} onChange={onChangeUserMonth}>
            {months.map(i => (
              <Option key={i} value={i}>
                {i}월
              </Option>
            ))}
          </Select>
          <TableToggleButton
            onClick={() => setOpenTable(openTable ? false : true)}
          >
            {openTable ? '테이블 닫기' : '테이블 열기'}
          </TableToggleButton>
        </TableToggleButtonWrapper>
        {openTable && dataForTable && (
          <CommonTable
            datas={[
              dataForTable.users.flat().map(i => (
                <a href={`/user-detail/${i.userId}`} target="_blank">
                  {i.userId}
                </a>
              )),
              dataForTable.users.flat().map(i => `${i.username}`),
              dataForTable.users.flat().map(i => `${i.email}`)
              // data.map(i => `${i.percent.toLocaleString()}%`)
            ]}
            colHeaders={['id', '이름', '이메일']}
          />
        )}
      </TableWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 420px;
`

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  & > p {
    margin-bottom: 0;
  }
`

const RangeName = styled.span``

const RangeInput = styled.input`
  width: 40px;
  text-align: center;
`

const RangeDate = styled.span``

const Select = styled.select`
  margin: 0 10px;
`

const Option = styled.option``

const ChartWrapper = styled.div``

const TableWrapper = styled.div``
const TableToggleButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const TableToggleButton = styled.button``
