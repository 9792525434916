import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert
} from 'reactstrap'
import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Indicator from '../../components/Common/Indicator'
import Paginator from '../../components/Common/Paginator'
import PaginatorTemp from '../../components/Common/PaginatorTemp'

// redux
import { getFeedCategoryList } from '../../reducers/feed'
import { getCategoryList } from '../../api/feed'

const CategoryRequest = ({
  history,
  state,
  params,
  feedCategoryList,
  getFeedCategoryList
}) => {
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10,
    search: ''
  })
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [selectOpt, setSelectOpt] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '요청 카테고리 조회 중 문제가 발생했습니다.'
  })
  const [originData, setOriginData] = useState({ list: [], max: 0 })
  const [requestCategoryData, setRequestCategoryData] = useState({
    list: [],
    max: 0
  })

  useEffect(() => {
    getFeedCategoryList()
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_FEED_CATEGORY_LIST':
        const filterCategory = feedCategoryList.feedCategoryTemplets
          .filter(f => f.enabled === false)
          .sort(function (a, b) {
            var date_a = new Date(a.createdAt)
            var date_b = new Date(b.createdAt)
            if (date_a > date_b) return -1
            if (date_a < date_b) return 1
            return 0
          })

        setOriginData({ list: filterCategory, max: filterCategory.length })
        setRequestCategoryData({
          list: filterCategory.slice(
            (fetchOptions.page - 1) * 10,
            fetchOptions.page * 10
          ),
          max: filterCategory.length
        })
        break
      case 'FAILED_SUCCESS_GET_FEED_CATEGORY_LIST':
        setStatus({ ...status, isError: true, errorMsg: params.message })
        break
      default:
        break
    }
  }, [state])

  useEffect(() => {
    const filterData = originData.list.filter(
      f => f.name && f.name.includes(fetchOptions.search)
    )

    setRequestCategoryData({
      ...requestCategoryData,
      list: filterData.slice(
        (fetchOptions.page - 1) * 10,
        fetchOptions.page * 10
      ),
      max: filterData.length
    })
  }, [fetchOptions])

  const handlePagination = (page, pageSize) => {
    if (fetchOptions.page !== page) {
      return setFetchOptions({
        ...fetchOptions,
        page
      })
    }

    return
  }

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    debounceInput(inputValue)
  }

  const debounceInput = useCallback(
    debounce(input => {
      setFetchOptions({ ...fetchOptions, search: input })
    }, 750),
    []
  )


  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="카테고리 요청 목록"
            breadcrumbItem="CategoryRequest"
          />

          <Alert
            color="danger"
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    {/* <Col sm="12">
                      <div className="text-sm-right">
                        <Button
                          style={{ visibility: 'hidden' }}
                          type="button"
                          color="success"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          onClick={() => setDeleteModal(!deleteModal)}
                        >
                          <i className="mdi mdi-plus mr-1"></i> 요청처리
                        </Button>
                      </div>
                    </Col> */}
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            onChange={handleSearch}
                            placeholder="카테고리명"
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Modal
                    centered
                    scrollable
                    isOpen={deleteModal}
                    toggle={() => setDeleteModal(!deleteModal)}
                  >
                    <ModalHeader
                      toggle={() => setDeleteModal(!deleteModal)}
                      style={{
                        borderBottom: '2px solid #2a3042'
                      }}
                    >
                      <div>요청 처리</div>
                    </ModalHeader>
                    <ModalBody
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        height: '150px',
                        fontSize: '18px'
                      }}
                    >{`${
                      Array.isArray(selectOpt) && selectOpt.length > 0
                        ? selectOpt.length
                        : '0'
                    }개의 카테고리 요청을 처리하시겠습니까?`}</ModalBody>
                    <ModalFooter
                      // onClick={handleRequest}
                      style={{
                        cursor: 'pointer',
                        justifyContent: 'center',
                        borderTop: '2px solid #e2e2e2',
                        fontWeight: 'bold',
                        fontSize: '16px'
                      }}
                    >
                      요청처리
                    </ModalFooter>
                  </Modal>

                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table
                      className="table-centered table-nowrap table-hover"
                      // style={{ minHeight: '500px' }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '20px' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length ===
                                    requestCategoryData.list.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(requestCategoryData.list) &&
                                      setSelectedBoxes(
                                        requestCategoryData.list.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          <th scope="col">요청일</th>
                          <th scope="col">카테고리명</th>
                          <th scope="col">고유 ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(requestCategoryData.list) &&
                          requestCategoryData.list.length > 0 &&
                          requestCategoryData.list.map((category, idx) => (
                            <tr key={'_category_' + idx}>
                              <td style={{ height: '65px' }}>
                                <div
                                  className="custom-control custom-checkbox"
                                  style={{ zIndex: 0 }}
                                >
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={category.id}
                                    checked={selectedBoxes.includes(
                                      category.id
                                    )}
                                    onChange={e =>
                                      e.target.checked
                                        ? setSelectedBoxes([
                                            ...selectedBoxes,
                                            category.id
                                          ])
                                        : setSelectedBoxes(
                                            selectedBoxes.filter(
                                              b => b !== category.id
                                            )
                                          )
                                    }
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor={category.id}
                                  >
                                    &nbsp;
                                  </Label>
                                </div>
                              </td>
                              <td>
                                {category.createdAt &&
                                  category.createdAt.slice(0, 10)}
                              </td>
                              <td>{category.name}</td>
                              <td>{category.id}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <PaginatorTemp
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(requestCategoryData.max / 10)}
                      onChange={handlePagination}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  feedCategoryList: state.appState.data.feedCategoryList
})
const mapDispatchToProps = dispatch => ({
  getFeedCategoryList: () => dispatch(getFeedCategoryList())
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryRequest)
