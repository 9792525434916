import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import moment from 'moment'
import 'moment/locale/ko'
import { withRouter } from 'react-router-dom'

import * as INFO from '../../global/projectInfo'
import { workOrderProjectState } from '../../global/formatter'
import { getTradingStatementDetail } from '../../reducers/tradingStatement'

const CashReceiptList = ({
  history,
  project,
  billPage,
  billSearch,
  handleChangeBusiness
}) => {
  const CASHRECEIPT_STATE = {
    pendingApproval: {
      code: null,
      ko: '승인예정',
    },
    issuanceComplete: {
      code: '0000',
      ko: '발급완료',
    },
    issuanceFailed: {
      code: '9999',
      ko: '발급실패',
    },
    pendingIssueCancel: {
      code: null,
      ko: '취소예정',
    },
    IssueCancelComplete: {
      code: '0000',
      ko: '발급취소',
    },
    IssueCancelFailed: {
      code: '9999',
      ko: '취소실패',
    },
  };

  // 고객 유형
  const [selectedGroup, setSelectedGroup] = useState(
    INFO.CLIENT_TYPE[project.boClientType]
  )

  const projectState = useMemo(()=>{
    const ntsresultCode = project.ntsresultCode ?? null;
    const cancelNtsresultCode = project.cancelNtsresultCode ?? null;
    if (project.cancelConfirmTradeDate == null) {
      if (ntsresultCode === CASHRECEIPT_STATE['pendingApproval'].code) {
        return CASHRECEIPT_STATE['pendingApproval'].ko;
      } else if (ntsresultCode === CASHRECEIPT_STATE['issuanceComplete'].code) {
        return CASHRECEIPT_STATE['issuanceComplete'].ko;
      } else {
        return CASHRECEIPT_STATE['issuanceFailed'].ko;
      }
    } else {
      if (
        cancelNtsresultCode === CASHRECEIPT_STATE['pendingIssueCancel'].code
      ) {
        return CASHRECEIPT_STATE['pendingIssueCancel'].ko;
      } else if (
        cancelNtsresultCode === CASHRECEIPT_STATE['IssueCancelComplete'].code
      ) {
        return CASHRECEIPT_STATE['IssueCancelComplete'].ko;
      } else {
        return CASHRECEIPT_STATE['IssueCancelFailed'].ko;
      }
    }
  },[project])

  const userOption = [
    { label: '개인', value: 'Individual' },
    { label: '사업자', value: 'Businessman' },
    { label: '공기관', value: 'PublicInstitutions' },
    { label: '협단체', value: 'Association' }
  ]

  const onChangeValue = e => {
    setSelectedGroup(INFO.CLIENT_TYPE[e.value])
    handleChangeBusiness(e, project.creatorUserId)
  }

  useEffect(() => {
    setSelectedGroup(INFO.CLIENT_TYPE[project.boClientType])
  }, [project.id])

  return (
    <>
      <td
        onClick={() => {
          history.push({
            pathname: `/cashReceipt-detail/${project.id}`,
            state: {
              pageInfo: billPage,
              searchInfo: billSearch,
              prevInfo: 'cashReceipt'
            }
          })
        }}
        style={{
          color: '#556EE6',
          cursor: 'pointer'
        }}
      >
        {project.createdAt &&
          moment(project.createdAt).format('YYYY-MM-DD-HH:mm')}
      </td>
      <td>{projectState}</td>
      <td>{project.creatorEmail}</td>
      <td>{project.businessman ? '사업자' : '개인'}</td>
      <td>
        <Select
          onChange={onChangeValue}
          value={selectedGroup}
          options={userOption}
          classNamePrefix="select2-selection"
        />
      </td>
      <td
        onClick={() => {
          history.push({
            pathname: `/user-detail/${project.creatorUserId}`,
            state: {
              pageInfo: billPage,
              searchInfo: billSearch,
              prevInfo: 'bill'
            }
          })
        }}
        style={{
          color: '#556EE6',
          cursor: 'pointer'
        }}
      >
        {project.companyName}
      </td>
      <td>{project.customerName}</td>
      <td>{`${project?.totalAmount?.toLocaleString() ?? '0'}원`}</td>
      <td>{project.tradeUsage}</td>
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data
})
const mapDispatchToProps = dispatch => ({
  // getTradingStatementDetail: id => dispatch(getTradingStatementDetail(id))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CashReceiptList)
)
