import { Redirect } from 'react-router-dom';
import { UserDetail, UserList, UserRegister, RatePlanUserList } from '../pages/Users'

import NorthStarDashboard from '../pages/Dashboard/NorthStar'
import UserDashboard from '../pages/Dashboard/User'
import DocumentDashboard from '../pages/Dashboard/Document'
import RevenueDashboard from '../pages/Dashboard/Revenue'

import ForgetPwd from '../pages/Authentication/ForgetPassword'
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Notice from '../pages/Notice'
import NoticeRegister from '../pages/Notice/NoticeRegister'
import NoticeDetail from '../pages/Notice/NoticeDetail'
import Pages404 from '../pages/Utility/pages-404'
import Pages500 from '../pages/Utility/pages-500'
import PagesComingsoon from '../pages/Utility/pages-comingsoon'
import PagesMaintenance from '../pages/Utility/pages-maintenance'
import Register from '../pages/Authentication/Register'
import Reviews from '../pages/Reviews'
import ReviewsDetail from '../pages/Reviews/ReviewsDetail'
import userdeactivated from '../pages/UsersDeactivated'
import RevisitList from '../pages/Revisit/RevisitList'

import { AdminList, AdminRegister } from '../pages/Admins'
import { FeedList, FeedDetail, FeedRegister } from '../pages/Feed'
import {
  CategoryList,
  CategoryRegister,
  CategoryRequest
} from '../pages/Category'
import { default as Profile } from '../pages/Profile'
import ProjectWorkOrderForQuantity from '../pages/ProjectQuantity'
import { LMFList } from '../pages/LMF'
import { VoucherList } from '../pages/Voucher'
import { B2BList } from '../pages/B2B'
import ProjectQuantityDetail from '../pages/ProjectQuantity/ProjectQuantityDetail'
import EventList from '../pages/Event/EventList'
import EventItemList from '../pages/Event/EventItemList'

// 거래명세서
import TradingStatement from '../pages/TradingStatement'
import TradingStatementDetail from '../pages/TradingStatement/TradingStatementDeail'

// 영수증
import Receipt from '../pages/Receipt'
import ReceiptDetail from '../pages/Receipt/ReceiptDetail'

// 청구서
import Bill from '../pages/Bill'
import BillDetail from '../pages/Bill/BillDetail'

// 발주서
import OrderSheet from '../pages/OrderSheet'
import OrderSheetDetail from '../pages/OrderSheet/OrderSheetDetail'

// 현금영수증
import CashReceipt from '../pages/CashReceipt'
import CashReceiptDetail from '../pages/CashReceipt/CashReceiptDetail'

// 정산
import {
  DocumentPaymentList,
  SettlementStatements
} from '../pages/DocumentPayment'
import TaxInvoice from '../pages/TaxInvoice'
import TaxInvoiceDetail from '../pages/TaxInvoice/TaxInvoiceDetail'

import { BusinessList } from '../pages/Business';
import BusinessDetail from '../pages/Business/BusinessDetail'
import { BusinessApprovalList } from '../pages/BusinessApproval'
import BusinessApprovalDetail from '../pages/BusinessApproval/BusinessApprovalDetail'
import Test from '../pages/Test';
import { components } from 'react-select';

const userRoutes = [
  { path: '/dashboard/user', component: UserDashboard },
  { path: '/dashboard/document', component: DocumentDashboard },
  { path: '/dashboard/revenue', component: RevenueDashboard },
  { path: '/dashboard/northStartDashboard', component: NorthStarDashboard },

  // 공지사항 Notice
  { path: '/notice', component: Notice },
  { path: '/notice-register', component: NoticeRegister },
  { path: '/notice-detail/:noticeId', component: NoticeDetail },

  ///////////////////////////// 관리자 어드민 ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Users
  { path: '/users', component: UserList },
  { path: '/user-detail/:userId', component: UserDetail },
  { path: '/user-register', component: UserRegister },
  { path: '/users-deactivated', component: userdeactivated },
  { path: "/users-rate-plan", component: RatePlanUserList },

  // Users
  { path: '/business', component: BusinessList },
  { path: '/business-detail/:popbillId', component: BusinessDetail },
  { path: '/businessApproval', component: BusinessApprovalList },
  { path: '/businessApproval-detail/:approvalId', component: BusinessApprovalDetail },

  // Revisit
  { path: '/revisit', component: RevisitList },

  // Admins
  { path: '/admins', component: AdminList },
  { path: '/admin-register', component: AdminRegister },

  // Feeds
  { path: '/feeds', component: FeedList },
  { path: '/feed-detail/:feedId', component: FeedDetail },
  { path: '/feed-register', component: FeedRegister },

  // Category
  { path: '/category', component: CategoryList },
  { path: '/category-register', component: CategoryRegister },
  { path: '/request', component: CategoryRequest },

  // Profile
  { path: '/profile', component: Profile },

  // SimpleProject, ProjectWorkOrderForQuantity
  { path: '/work-order-for-quantity', component: ProjectWorkOrderForQuantity },
  {
    path: '/work-order-for-quantity-detail/:id',
    component: ProjectQuantityDetail
  },

  // 거래명세서
  { path: '/trading-statement', component: TradingStatement },
  {
    path: '/trading-statement-detail/:id',
    component: TradingStatementDetail
  },

  // 영수증
  { path: '/receipt', component: Receipt },
  {
    path: '/receipt-detail/:id',
    component: ReceiptDetail
  },

  // 청구서
  { path: '/bill', component: Bill },
  {
    path: '/bill-detail/:id',
    component: BillDetail
  },

  // 발주서
  { path: '/orderSheet', component: OrderSheet },
  {
    path: '/orderSheet-detail/:id',
    component: OrderSheetDetail
  },

  // 현금영수증
  { path: '/cashReceipt', component: CashReceipt },
  {
    path: '/cashReceipt-detail/:id',
    component: CashReceiptDetail
  },

  // 세금계산서
  { path: '/taxInvoice', component: TaxInvoice },
  {
    path: '/taxInvoice-detail/:id',
    component: TaxInvoiceDetail
  },

  // LMF
  { path: '/lmf', component: LMFList },

  // Voucher
  { path: '/voucher', component: VoucherList },

  // B2B
  { path: '/B2B', component: B2BList },

  // Event
  { path: '/roulette', component: EventList },
  { path: '/items', component: EventItemList },

  // 정산
  { path: '/documentPayment', component: DocumentPaymentList },

  /** 정산내역서 */
  { path: '/settlementStatements', component: SettlementStatements },

  // Project 거래 관리
  // { path: '/projects', component: ProjectsInProgress },
  // { path: '/projects-create', component: ProjectsInProgressCreate },
  // { path: '/projects-detail/:id', component: ProjectDetail },
  // { path: '/projects-completed', component: ProjectsCompleted },
  // { path: '/projects-pending', component: ProjectPending },
  // { path: '/projects-pending-create', component: PendingCreate },

  // 견적서

  // Reviews 후기관리
  { path: '/reviews', component: Reviews },
  { path: '/reviews-detail/:id', component: ReviewsDetail },
  
  // tsx test
  { path: "/test", component: Test },
  
  { path: '/', exact: true, component: () => <Redirect to="/dashboard/northStartDashboard" /> },
]

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },

  { path: '/pages-maintenance', component: PagesMaintenance },
  { path: '/pages-comingsoon', component: PagesComingsoon },
  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 }
]

export { userRoutes, authRoutes }
