import React, { Fragment, useEffect, useState } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ko'

// components
import Breadcrumbs from '../../../components/Common/Breadcrumb'

// redux
import {
  getUserList,
  getAllUserList,
  removeUser,
  completeRemoveUser
} from '../../../reducers/users'

// utils
import { genderState } from '../../../constant/utils'

// temp
import PaginatorProject from '../../../components/Common/PaginatorProject'

const SIGNUP_TYPE = {
  Email: 'mdi mdi-email mr-1',
  Phone: 'mdi mdi-phone mr-1',
  KaKao: 'mdi mdi-apple mr-1',
  Apple: 'mdi mdi-apple mr-1'
}

const UserList = ({
  params,
  state,
  getUserList,
  userList,
  removeUser,
  totalCount
}) => {
  const location = useLocation()
  const history = useHistory()
  const userPage = location.state?.pageInfo ?? 1
  const userSearch = location.state?.searchInfo ?? ''
  const userFiltering = location.state?.filteringInfo
  const userStart = location.state?.startInfo ?? 1
  const userEnd = location.state?.endInfo ?? 11

  // 체크박스 및 모달
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '회원 조회 중 문제가 발생했습니다',
    color: 'danger'
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [filterTitle, setFilterTitle] = useState('전체')

  const [userListData, setUserListData] = useState([])
  const [changeSearch, setChangeSearch] = useState(false)

  // page
  const [totalListCount, setTotalListCount] = useState(0)
  const [page, setPage] = useState(userPage)
  const [search, setSearch] = useState(userSearch)
  const [filtering, setFiltering] = useState(userFiltering)
  const [start, setStart] = useState(userStart)
  const [end, setEnd] = useState(userEnd)
  
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('')

  // 페이지, 필터
  useEffect(() => {
    if (!changeSearch) {
      getUserList(page, 10, search, filtering)
    }
  }, [page, filtering])

  // 검색
  useEffect(() => {
    if (changeSearch) {
      setStart(1)
      setEnd(11)
      getUserList(page, 10, search, filtering)
    }
  }, [changeSearch])

  // 차트 제목 hook
  useEffect(() => {
    setBreadcrumbTitle(`${filterTitle} (${totalListCount? totalListCount.toLocaleString() : '-'}명)`)
  }, [filterTitle, totalListCount])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_USER_LIST':
        setUserListData(userList)
        setTotalListCount(totalCount)
        setChangeSearch(false)

        break
      case 'FAILED_GET_DASHBOARD':
        break
      case 'SUCCESS_REMOVE_USER':
        setStatus({
          ...status,
          color: 'success',
          isError: true,
          errorMsg: '회원 상태가 변경되었습니다'
        })
        setSelectedBoxes([])
        break
      case 'FAILED_REMOVE_USER':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params?.message
        })
        break
      case 'SUCCESS_GET_ALL_USER_LIST':
        break
      default:
        break
    }
  }, [state])

  const handlePagination = page => {
    setPage(page)
  }

  const handleDelete = () => {
    removeUser(selectedBoxes)
  }

  const handlerFilter = input => {
    if (input === 0) {
      setFiltering('')
      setFilterTitle('전체')
    }
    
    if (input === 1) {
      setFiltering('real')
      setFilterTitle('실회원')
    }

    if (input === 2) {
      setFiltering('workOrderForQuantityCount')
      setFilterTitle('견적서 생성순')
    }

    if (input === 3) {
      setFiltering('admin')
      setFilterTitle('관리자')
    }

    if (input === 4) {
      setFiltering('tester')
      setFilterTitle('테스터')
    }

    if (input === 5) {
      setFiltering('isService')
      setFilterTitle('서비스 푸시 동의')
    }

    if (input === 6) {
      setFiltering('isMarketing')
      setFilterTitle('마케팅 정보 제공 동의')
    }
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs 
            title={breadcrumbTitle}
            breadcrumbItem="Users List" />

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            value={search}
                            // onChange={e => handleSearch(e.target.value)}
                            onChange={e => setSearch(e.target.value)}
                            placeholder={'이름/휴대폰/이메일'}
                            onKeyUp={e => {
                              if (e.keyCode === 13) {
                                setChangeSearch(true)
                                setPage(1)
                              }
                            }}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div
                        className="text-sm-right"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Dropdown
                          isOpen={isFilter}
                          toggle={() => setIsFilter(!isFilter)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-light"
                            style={{ marginRight: '10px' }}
                          >
                            <i className="mdi mdi-filter mr-1" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu dropdown-menu-right">
                          <DropdownItem onClick={() => handlerFilter(0)}>
                              전체
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(1)}>
                              실회원
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(2)}>
                              견적서 생성순
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(3)}>
                              관리자
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(4)}>
                              테스터
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(5)}>
                              서비스 푸시 동의
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(6)}>
                              마케팅 정보 제공 동의
                            </DropdownItem>
                            {/* <DropdownItem onClick={() => handlerFilter(7)}>
                                가입일순
                              </DropdownItem>
                              <DropdownItem onClick={() => handlerFilter(8)}>
                                최근 로그인순
                              </DropdownItem>
                              <DropdownItem onClick={() => handlerFilter(9)}>
                                바우처
                              </DropdownItem>
                              <DropdownItem onClick={() => handlerFilter(10)}>
                                접속 횟수
                              </DropdownItem> */}
                          </DropdownMenu>
                        </Dropdown>

                        <Link to={'/user-register'}>
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          >
                            <i className="mdi mdi-plus mr-1"></i> 등록
                          </Button>
                        </Link>
                        <Button
                          type="button"
                          color="danger"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          onClick={() => setDeleteModal(!deleteModal)}
                          disabled={selectedBoxes.length === 0}
                        >
                          <i className="mdi mdi-minus mr-1"></i> 전환
                        </Button>
                      </div>
                    </Col>

                    <Modal
                      centered
                      scrollable
                      isOpen={deleteModal}
                      toggle={() => {
                        setDeleteModal(!deleteModal)
                        setSelectedBoxes([])
                      }}
                    >
                      <ModalHeader
                        toggle={() => {
                          setDeleteModal(!deleteModal)
                          setSelectedBoxes([])
                        }}
                        style={{
                          borderBottom: '2px solid #2a3042'
                        }}
                      >
                        <div>회원 삭제</div>
                      </ModalHeader>
                      <ModalBody
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: '150px',
                          flexDirection: 'column'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '15px',
                            fontWeight: 'bold',
                            marginBottom: '8px'
                          }}
                        >{`${selectedBoxes.length}명의 활성 상태를 변경 하시겠습니까?`}</div>
                        <div>{`회원이 활성 상태가 변경됩니다`}</div>
                      </ModalBody>
                      <ModalFooter
                        onClick={() => setDeleteModal(!deleteModal)}
                        style={{
                          justifyContent: 'center',
                          borderTop: '2px solid #e2e2e2'
                        }}
                      >
                        <Button color="danger" outline onClick={handleDelete}>
                          비활성화하기
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Row>
                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '3%' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length === userListData?.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(userListData) &&
                                      setSelectedBoxes(
                                        userListData.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            이메일
                          </th>
                          <th scope="col" style={{ width: '4%' }}>
                            견적서
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            접속 횟수
                          </th>
                          <th scope="col" style={{ width: '4%' }}>
                            성별
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            생년월일
                          </th>
                          {/* <th scope="col" style={{ width: '15%' }}>
                            휴대폰
                          </th> */}
                          <th scope="col" style={{ width: '8%' }}>
                            회원명
                          </th>
                          <th scope="col" style={{ width: '20%' }}>
                            고유 ID
                          </th>
                          <th scope="col" style={{ width: '2%' }}>
                            서비스
                          </th>
                          <th scope="col" style={{ width: '2%' }}>
                            마케팅
                          </th>
                          <th scope="col" style={{ width: '11%' }}>
                            최근 로그인
                          </th>
                          <th scope="col" style={{ width: '11%' }}>
                            가입일
                          </th>
                          <th scope="col" style={{ width: '7.5%' }}>
                            가입유형
                          </th>
                          <th scope="col" style={{ width: '7.5%' }}>
                            탈퇴
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(userListData) &&
                          userListData.map((user, idx) => (
                            <tr key={'_user_' + idx}>
                              <td style={{ height: '65px' }}>
                                <div
                                  className="custom-control custom-checkbox"
                                  style={{ zIndex: 0 }}
                                >
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={user.id}
                                    checked={selectedBoxes.includes(user.id)}
                                    onChange={e =>
                                      e.target.checked
                                        ? setSelectedBoxes([
                                            ...selectedBoxes,
                                            user.id
                                          ])
                                        : setSelectedBoxes(
                                            selectedBoxes.filter(
                                              b => b !== user.id
                                            )
                                          )
                                    }
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor={user.id}
                                  >
                                    &nbsp;
                                  </Label>
                                </div>
                              </td>

                              <td
                                onClick={() => {
                                  history.push({
                                    pathname: `/user-detail/${user.id}`,
                                    state: {
                                      pageInfo: page,
                                      searchInfo: search,
                                      filteringInfo: filtering,
                                      startInfo: start,
                                      endInfo: end,
                                      prevInfo: 'users'
                                    }
                                    // state: {
                                    //   idInfo: user.id,
                                    //   pageInfo: fetchOptions.page,
                                    //   lastPageInfo: Math.ceil(
                                    //     usersData.max / 10
                                    //   ),
                                    //   searchInfo: fetchOptions.search,
                                    //   isUserDetail: true,
                                    //   filteringInfo: fetchOptions.filtering,
                                    //   prevPage: 'users'
                                    // }
                                  })
                                }}
                                style={{
                                  color: '#556EE6',
                                  cursor: 'pointer'
                                }}
                              >
                                {user.email}
                              </td>
                              <td>{user.workOrderForQuantityCount}</td>
                              <td>{user.userLoginInfoCount}</td>
                              <td>{genderState(user.gender)}</td>
                              <td>
                                {user.birth
                                  ? user.birth.replace(
                                      /(\d{4})(\d{2})(\d{2})/g,
                                      '$1-$2-$3'
                                    )
                                  : '미입력'}
                              </td>
                              {/* <td>
                                {user?.phone
                                  ? user.phone.replace(
                                      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                                      '$1-$2-$3'
                                    )
                                  : ''}
                              </td> */}
                              <td>{user?.username ? user.username : ''}</td>
                              <td>{user.id}</td>
                              <td>{user.setServicePush ? 'O' : 'X'}</td>
                              <td>{user.setMarketingPush ? 'O' : 'X'}</td>
                              <td>
                                {user.loginAt &&
                                  moment(user.loginAt).format(
                                    'YYYY-MM-DD-HH:mm'
                                  )}
                              </td>
                              <td>
                                {user.signupAt &&
                                  moment(user.signupAt).format(
                                    'YYYY-MM-DD-HH:mm'
                                  )}
                              </td>
                              <td>{user.signupType}</td>
                              <td>{user.enabled ? 'X' : 'O'}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <PaginatorProject
                      currentPage={page}
                      pageSize={10}
                      total={Math.ceil(totalListCount / 10)}
                      onChange={handlePagination}
                      start={start}
                      setStart={setStart}
                      end={end}
                      setEnd={setEnd}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  userList: state.appState.data.userList,
  allUserList: state.appState.data.allUserList,
  totalCount: state.appState.data.totalCount
})
const mapDispatchToProps = dispatch => ({
  getUserList: (page, amount, search, filtering) =>
    dispatch(getUserList(page, amount, search, filtering)),
  getAllUserList: () => dispatch(getAllUserList()),
  removeUser: ids => dispatch(removeUser(ids)),
  completeRemoveUser: ids => dispatch(completeRemoveUser(ids))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
