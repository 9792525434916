import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap'
import React, { Fragment, useEffect, useState, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Indicator from '../../components/Common/Indicator'
import Paginator from '../../components/Common/Paginator'
import PaginatorTemp from '../../components/Common/PaginatorTemp'

// redux
import { getAdminList } from '../../reducers/admin'

const AdminList = ({ history, state, params, getAdminList, adminList }) => {
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [originData, setOriginData] = useState({ list: [], max: 0 })
  const [adminData, setAdminData] = useState({ list: [], max: 0 })
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10,
    search: ''
  })

  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '관리자 조회 중 문제가 발생했습니다.'
  })

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_ADMIN_LIST':
        setOriginData({ list: adminList.users, max: adminList.users.length })
        setAdminData({
          list: adminList.users.slice(0, 10),
          max: adminList.users.length
        })
        break
      case 'FAILED_GET_ADMIN_LIST':
        setStatus({ ...status, isError: true, errorMsg: params.message })
        break
      default:
        break
    }
  }, [state])

  useEffect(() => {
    getAdminList()
  }, [])

  const handlePagination = (page, pageSize) => {
    if (fetchOptions.page !== page) {
      return setFetchOptions({
        ...fetchOptions,
        page
      })
    }

    return
  }

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    debounceInput(inputValue)
  }

  const debounceInput = useCallback(
    debounce(input => {
      setFetchOptions({ ...fetchOptions, search: input })
    }, 750),
    []
  )

  useEffect(() => {
    const filterData = originData.list.filter(
      f =>
        (f.username && f.username.includes(fetchOptions.search)) ||
        (f.phone &&
          f.phone
            .replace(/-/g, '')
            .includes(fetchOptions.search.replace(/-/g, ''))) ||
        (f.email && f.email.includes(fetchOptions.search))
    )

    setAdminData({
      ...adminData,
      list: filterData.slice(
        (fetchOptions.page - 1) * 10,
        fetchOptions.page * 10
      ),
      max: filterData.length
    })
  }, [fetchOptions])

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="관리자" breadcrumbItem="Admins List" />

          <Alert
            color="danger"
            isOpen={status.isError}
            toggle={() => setStatus({ ...status, isError: false })}
          >
            {status.errorMsg}
          </Alert>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            onChange={handleSearch}
                            placeholder={'관리자명/휴대폰/이메일'}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '20px' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length ===
                                    adminData.list?.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(adminData.list) &&
                                      setSelectedBoxes(
                                        adminData.list.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          <th scope="col">관리자명</th>
                          <th scope="col">휴대폰</th>
                          <th scope="col">이메일</th>
                          <th scope="col">권한</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(adminData.list) &&
                          adminData.list
                            .slice()
                            .reverse()
                            .map((user, idx) => (
                              <tr key={'_user_' + idx}>
                                <td style={{ height: '65px' }}>
                                  <div
                                    className="custom-control custom-checkbox"
                                    style={{ zIndex: 0 }}
                                  >
                                    <Input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={user.id}
                                      checked={selectedBoxes.includes(user.id)}
                                      onChange={e =>
                                        e.target.checked
                                          ? setSelectedBoxes([
                                              ...selectedBoxes,
                                              user.id
                                            ])
                                          : setSelectedBoxes(
                                              selectedBoxes.filter(
                                                b => b !== user.id
                                              )
                                            )
                                      }
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor={user.id}
                                    >
                                      &nbsp;
                                    </Label>
                                  </div>
                                </td>
                                {/* <td className="text-center">
                                    {renderData.max -
                                      (page.current - 1) * 10 -
                                      idx}
                                  </td> */}
                                <td>
                                  <Link to={`/user-detail/${user.id}`}>
                                    {user.username ? user.username : ''}
                                  </Link>
                                </td>
                                <td>{user.phone ? user.phone : ''}</td>
                                <td>{user.email}</td>
                                <td>
                                  {user.generalAdministrator ? (
                                    <span className="badge badge-pill badge-primary">
                                      마스터
                                    </span>
                                  ) : (
                                    <span className="badge badge-pill badge-dark">
                                      일반
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                    <PaginatorTemp
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(adminData.max / 10)}
                      onChange={handlePagination}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  adminList: state.appState.data.adminList
})
const mapDispatchToProps = dispatch => ({
  getAdminList: () => dispatch(getAdminList())
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
