import React, { useEffect, useState } from 'react'

// tool
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Table
} from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
// component
import Breadcrumbs from '../../components/Common/Breadcrumb'
import PaginatorProject from '../../components/Common/PaginatorProject'

// temp
import { b2bDummyData } from '../../constant/dummy'

const B2BList = () => {
  const history = useHistory()
  const [start, setStart] = useState(1)
  const [end, setEnd] = useState(11)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const [listData, setListData] = useState(b2bDummyData || [])

  const [breadcrumbTitle, setBreadcrumbTitle] = useState('')

  useEffect(() => {
    let filterData = b2bDummyData.filter(
      f => 
      f.username.includes(search) 
      || f.phone.replace(/-/g, '').includes(search)
    )

    setListData(filterData)
  }, [search])

  // 차트 제목 hook
  useEffect(() =>{
    setBreadcrumbTitle(`B2B (${listData.length}명)`)
  }, [listData])

  const handlePagination = page => {
    setPage(page)
  }
  const handleSearch = search => {
    setSearch(search)
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={breadcrumbTitle}
            breadcrumbItem="Voucher"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            className="form-control"
                            placeholder="이름/전화번호"
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: '10%' }}>
                            이름
                          </th>
                          <th scope="col" style={{ width: '20%' }}>
                            전화번호
                          </th>
                          <th scope="col" style={{ width: '55%' }}>
                            내용
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            서비스 가입일
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {listData
                        .slice((page - 1) * 10 , (page-1) * 10 + 10)
                        .map((user, idx) => (
                            <UsersTR
                              key={user.id}
                              delCheck={user.isDelete}
                              style={{
                                background: user.isTemp && 'lightgrey'
                              }}
                            >
                              <td
                                onClick={() => {
                                  history.push({
                                    pathname: `/user-detail/${user.id}`,
                                    // state: {
                                    //   idInfo: user.id,
                                    //   pageInfo: fetchOptions.page,
                                    //   lastPageInfo: Math.ceil(
                                    //     usersData.max / 10
                                    //   ),
                                    //   searchInfo: fetchOptions.search,
                                    //   isUserDetail: true,
                                    //   filteringInfo: fetchOptions.filtering,
                                    //   prevPage: 'B2B'
                                    // }
                                  })
                                }}
                                style={{
                                  color: '#556EE6',
                                  cursor: 'pointer',
                                  height: '65px'
                                }}
                              >
                                {user.username}
                              </td>
                              <td>
                                <p className="mb-0">
                                  {user.phone}
                                </p>
                              </td>
                              <td>
                                <p className="mb-0">
                                  {user.B2BContent}
                                </p>
                              </td>
                              <td>
                                <p className="mb-0">{user.B2BAt}</p>
                              </td>
                            </UsersTR>
                          ))}
                      </tbody>
                    </Table>
                  </div>

                  <PaginatorProject
                    currentPage={page}
                    pageSize={10}
                    total={Math.ceil(b2bDummyData.length / 10)}
                    onChange={handlePagination}
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default B2BList

const UsersTR = styled.tr`
  text-decoration: ${props => props.delCheck && 'line-through'};
  height: 20px;
`
