import React from 'react'
import styled from 'styled-components'
import { Card, CardBody } from 'reactstrap'

export default function CommonNorthStarCard({ title, data, iconType, cardIndex, onClick }) {
  const renderDocumentAvatar = () => (
    <span className="avatar-title">
      <i className={'bx bx-task font-size-24'} />
    </span>
  )
  const renderCreditCardAvatar = () => (
    <span className="avatar-title">
      <i className={'bx bx-credit-card font-size-24'} />
    </span>
  )
  const renderHumanAvatar = () => (
    <span className="avatar-title">
      <i className={'bx bx-user font-size-24'} />
    </span>
  )
  const renderMoneyAvatar = () => (
    <span className="avatar-title">
      <i className={'bx bx-money font-size-24'} />
    </span>
  )
  return (
    <Wrapper onClick={onClick} cardIndex={cardIndex}>
      <Card>
        <CardBody>
          <FlexBlock>
            <TextBlock>
              <Title>{title}</Title>
              <Data>{data}</Data>
            </TextBlock>
            <Avatar>
              {iconType === 'human'
                ? renderHumanAvatar()
                : iconType === 'creditCard'
                ? renderCreditCardAvatar()
                : iconType === 'document'
                ? renderDocumentAvatar()
                : renderMoneyAvatar()}
            </Avatar>
          </FlexBlock>
        </CardBody>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: card${props => props.cardIndex};
`

const FlexBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextBlock = styled.div``

const Title = styled.p`
  color: #74788d;
  font-weight: 500;
`

const Data = styled.h4`
  color: #495057;
  font-weight: 500;
  font-size: 1.21875rem;
  margin-bottom: 0;
`

const Avatar = styled.div`
  margin-left: 15px;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
  }
`
