import axios from 'axios'
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'

import {
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  SUBMIT_PASSWORD_CHANGE,
  SUBMIT_PASSWORD_CHANGE_VERIFY
} from '../reducers/account'
import { setAppState } from '../reducers/appState'

import * as constants from '../global/constants'

// 로그인
export function* login(action) {
  const { email, password } = action

  yield put(setAppState('START_LOGIN', null, true))
  try {
    const response = yield call(axios, {
      method: 'POST',
      validateStatus: () => true,
      url: constants.auth.LOGIN,
      data: {
        email,
        password
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_LOGIN', response.data))
      yield all([localStorage.setItem('token', response.data.accessToken)])
    } else {
      yield put(setAppState('FAILED_LOGIN', response.data, false))
    }
  } catch (err) {
    yield put(setAppState('FAILED_LOGIN', err, false))
  }
}

// 로그아웃
export function* logout(action) {
  yield call([localStorage, 'removeItem'], 'token')
  // yield put(saveAppData('session', false))
}

// 비밀번호 변경 요청
export function* submitPasswordChange(actions) {
  const { email } = actions

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: constants.auth.SUBMIT_PASSWORD_CHANGE,
      validateStatus: () => true,
      data: { email }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState('SUCCESS_SUBMIT_PASSWORD_CHANGE', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_SUBMIT_PASSWORD_CHANGE', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SUBMIT_PASSWORD_CHANGE', e, false))
  }
}

// 비밀번호 변경 인증 코드 전송
export function* submitPasswordChangeVerify(actions) {
  const { email, code } = actions

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: constants.auth.SUBMNT_PASSWORD_CHANGE_VERIFY,
      validateStatus: () => true,
      data: {
        email,
        code
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState(
          'SUCCESS_SUBMIT_PASSWORD_CHANGE_VERIFY',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_SUBMIT_PASSWORD_CHANGE_VERIFY',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SUBMIT_PASSWORD_CHANGE_VERIFY', e, false))
  }
}

// 비밀번호 변경
export function* changePassword(actions) {
  const { password, verifyId } = actions

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: constants.auth.CHANGE_PASSWORD,
      validateStatus: () => true,
      data: { password, verifyId }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_CHANGE_PASSWORD', response.data, false))
    } else {
      yield put(setAppState('FAILED_CHANGE_PASSWORD', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_CHANGE_PASSWORD', e, false))
  }
}

const accountSagas = [
  takeLatest(LOGIN, login),
  takeLatest(LOGOUT, logout),
  takeLatest(SUBMIT_PASSWORD_CHANGE, submitPasswordChange),
  takeLatest(SUBMIT_PASSWORD_CHANGE_VERIFY, submitPasswordChangeVerify),
  takeLatest(CHANGE_PASSWORD, changePassword)
]

export default accountSagas
