import React, { useState, useMemo } from 'react'

// tools
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const PaginatorProject = ({
  currentPage = 1,
  pageSize = 10, // Avaliable maximum number of pages per one step
  total = 0, // Total page count
  onChange,
  start,
  setStart,
  end,
  setEnd
}) => {

  const isLeftDisabled = Boolean(total -1 <= pageSize || currentPage -1 < pageSize)
  // 전체 페이지가 10보다 같거나 작은경우, 현재 페이지가 마지막 구간에 있는경우, 현재 페이지가 전체 페이지보다 같거나 큰경우
  const isRightDisabled = Boolean(total <= pageSize || total <= end || total <= currentPage + 1)

  const pageRange = Array.from(
    {
      length: total >= pageSize ? end - start : total
    },
    (v, i) => i + start
  )

  const handlePaginator = (e, changePage) => {
    e.preventDefault()
    if (!changePage) {
      const selectedPage = parseInt(e.target.innerText)
      return onChange(selectedPage, pageSize)
    }
    return onChange(changePage, pageSize)
  }

  return total > 0 ? (
    <Pagination
      className="pagination pagination-rounded justify-content-center mb-2"
      style={{ position: 'absolute', bottom: '20px' }}
    >
      <PaginationItem disabled={isLeftDisabled}>
        <PaginationLink
          first
          onClick={e => {
            const START = 1
            const END = 1 + Math.min(total,pageSize)
            const DESTINATION = 1

            setStart(START)
            setEnd(END)
            handlePaginator(e, DESTINATION)
          }}
        />
      </PaginationItem>
      <PaginationItem disabled={isLeftDisabled}>
        <PaginationLink
          previous
          onClick={e => {
            // if (start - 10 < 0) {
            //   setStart(1)
            //   setEnd(11)
            // }
            // if (start - 10 >= 0) {
            //   setStart(start - 10)
            //   setEnd(start)
            // }
            // handlePaginator(e, currentPage - 10 < 0 ? 1 : currentPage - 10)

            // if (start - 10 < 0) {
            //   if (currentPage % 10 === 0) {
            //     setStart(1)
            //     setEnd(11)
            //   }
            // }

            // if (start - 10 > 0) {
            //   if ((currentPage - 1) % 10 === 0) {
            //     setStart(start - 10)
            //     // setEnd(end - 10)
            //     setEnd(end % 10 === 0 ? end - 10 : start)
            //   }
            // }

            // handlePaginator(e, currentPage - 1 < 0 ? 1 : currentPage - 1)

            const START = start - pageSize
            const END = START + pageSize
            const DESTINATION = currentPage - pageSize

            setStart(START)
            setEnd(END)
            handlePaginator(e, DESTINATION)
          }}
        />
      </PaginationItem>
      {Array.isArray(pageRange) &&
        pageRange.map(page => (
          <PaginationItem active={currentPage === page} key={page}>
            <PaginationLink onClick={e => handlePaginator(e)}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}

      {/* <PaginationItem disabled={Boolean(currentPage === total)}> */}
      <PaginationItem
        // disabled={Boolean(end - 1 === total || Boolean(total < 11))}
        disabled={isRightDisabled} >
        <PaginationLink
          next
          onClick={e => {
            // if (end + 10 < total) {
            //   setStart(start + 10)
            //   setEnd(end + 10)
            // }
            // if (end + 10 >= total) {
            //   setStart(start + 10)
            //   setEnd(total + 1)
            // }
            // handlePaginator(
            //   e,
            //   currentPage + 10 >= total ? total : currentPage + 10
            // )

            // if (end + 10 < total) {
            //   if (currentPage % 10 === 0) {
            //     setStart(start + 10)
            //     setEnd(end + 10)
            //   }
            // }

            // if (end + 10 >= total) {
            //   if (currentPage % 10 === 0) {
            //     setStart(start + 10)
            //     setEnd(total)
            //   }
            // }

            // handlePaginator(
            //   e,
            //   currentPage + 1 >= total ? total : currentPage + 1
            // )

            const START = start + pageSize
            const END = Math.min(total, START+pageSize)
            const DESTINATION = Math.min(currentPage+pageSize, total-1)

            setStart(START)
            setEnd(END)
            handlePaginator(e, DESTINATION)
          }}
        />
      </PaginationItem>
      <PaginationItem
        // 전체 페이지가 10보다 같거나 작은경우, 현재 페이지가 마지막 구간에 있는경우, 현재 페이지가 전체 페이지보다 같거나 큰경우
        disabled={isRightDisabled}>
        <PaginationLink
          last
          onClick={e => {
            const START = 
              (total - 1) % pageSize === 0 
                ? total - pageSize
                : total - ((total -1) % pageSize)
            const END = total
            const DESTINATION = END - 1

            setStart(START)
            setEnd(END)
            handlePaginator(e, DESTINATION)
          }}
        />
      </PaginationItem>
    </Pagination>
  ) : (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '20vh' }}
    >
      {/* 데이터가 없습니다 */}
      {/* <Indicator height={'30vh'} /> */}
    </div>
  )
}

export default PaginatorProject
