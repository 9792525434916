export const GET_TRADING_STATEMENT_LIST =
  'reducers/tradingStatement/GET_TRADING_STATEMENT_LIST'
export const GET_TRADING_STATEMENT_DETAIL =
  'reducers/tradingStatement/GET_TRADING_STATEMENT_DETAIL'
export const SET_TRADING_CLIENT_IS_BUSINESSMAN =
  'reducers/tradingStatement/SET_TRADING_CLIENT_IS_BUSINESSMAN'

export const getTradingStatementList = (
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
) => ({
  type: GET_TRADING_STATEMENT_LIST,
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
})

export const getTradingStatementDetail = id => ({
  type: GET_TRADING_STATEMENT_DETAIL,
  id
})

export const setTradingStatementClientIsBusinessman = (id, isBusinessman) => ({
  type: SET_TRADING_CLIENT_IS_BUSINESSMAN,
  id,
  isBusinessman
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
