import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import moment from 'moment';
import {
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Table,
	Row,
	Badge,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	Dropdown
} from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getBusinessmans } from '../../api/businessman';
import LinkPaginator from '../../components/Common/LinkPaginator';

/** 사업자 목록 */
const BusinessList = () => {
	const location = useLocation();
	
	/** 현재 주소 추가 경로 */
	const params = new URLSearchParams(location.search);

	/** 상태 목록 */
	const [page, setPage] = useState(Number(params.get('page')) ?? 0);
	const [amount, setAmount] = useState(10);
	const [search, setSearch] = useState(params.get('search') ?? '');
	const [certFilter, setCertFilter] = useState(params.get('certFilter') ?? null);
	const [businessmans, setBusinessmans] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [isCertFilterToggle, setIsCertFilterToggle] = useState(false);

	/** 호출 함수 정의 */
	/** 리스트 호출 */
	const callBusinessmans = async ({
		page,
		amount,
		search,
		certFilter
	}) => {
		await getBusinessmans({
			page,
			amount,
			search,
			certFilter
		})
		.then((res) => {
			const {
				popbills,
				count
			} = res;
			
			if (res.opcode === 0) {
				/** 리스트 state 업데이트 */
				setBusinessmans(popbills);

				/** 카운트 state 업데이트 */
				setTotalCount(count);
			}
		});
	}

	const handlerFilter = (filter) => {
		let certFilter = null;
		switch(filter) {
			/** 전체 */
			case 0: {
				certFilter = null;
				break;
			}

			/** 인증 */
			case 1: {
				certFilter = true;
				break;
			}

			/** 미인증 */
			case 2: {
				certFilter = false;
				break;
			}
		}
		
		window.location.href = `/business?search=${search}&certFilter=${certFilter}`;
	}

	/** 훅 */
	/** 첫 로딩 */
	useEffect(() => {
		/** 리스트 호출 */
		callBusinessmans({
			page,
			amount,
			search,
			certFilter
		});
	}, []);
	
	return (
		<Fragment>
			<div className = "page-content">
				<Container fluid>
					<Breadcrumbs title = {`사업자 목록(${totalCount}개)`} breadcrumbItem="Business List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									{/* 상단 검색 메뉴 */}
									<Row className = "mb-2">
										{/* 검색 Input */}
										<Col sm = "4">
											<div className = "search-box mr-2 mb-2 d-inline-block">
												<div className = "position-releative">
													<Input
														type = "text"
														className = "form-control"
														placeholder = "등록번호/사업명"
														value = {search}
														onChange = {(e) => setSearch(e.target.value)}
														onKeyUp = {(e) => {
															if (e.key === 'Enter') {
																window.location.href = `/business?search=${search}&certFilter=${certFilter}`;
															}
														}}
													/>
													<i className="bx bx-search-alt search-icon" style = {{ left: "23px" }}></i>
												</div>
											</div>
										</Col>

										{/* 검색 필터 */}
										<Col sm="8">
											<div className="text-sm-right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Dropdown isOpen={isCertFilterToggle} toggle={() => setIsCertFilterToggle(!isCertFilterToggle)}>
												<DropdownToggle
													tag="button"
													className="btn btn-light"
													style={{ marginRight: '10px' }}
												>
													<i className="mdi mdi-filter mr-1" />
												</DropdownToggle>
												<DropdownMenu className="dropdown-menu dropdown-menu-right">
													<DropdownItem onClick={() => handlerFilter(0)}>전체</DropdownItem>
													<DropdownItem onClick={() => handlerFilter(1)}>인증</DropdownItem>
													<DropdownItem onClick={() => handlerFilter(2)}>미인증</DropdownItem>
												</DropdownMenu>
												</Dropdown>
											</div>
										</Col>
									</Row>

									{/* 사업장 목록 테이블 */}
									<ListDiv className="table-reponsive">
										<Table className = "table-centered table-nowrap table-hover">
											<thead className = "thead-light">
												<tr>
													{/* 리스트 올 체킹 체크박스 인풋 */}
													<th style = {{ width: "3%" }}>
														<div className = "custom-control custom-checkbox" style = {{ zIndex: 0 }}>
															<Input type = "checkbox" className = "custom-control-input" id = "allCheck" />
															<Label className = "custom-control-label" htmlFor = "allCheck">&nbsp;</Label>
														</div>
													</th>

													{/* 테이블 헤더 */}
													<ListHeader scope = "col" style = {{ width: "10%" }}>사업장등록번호</ListHeader>
													<ListHeader scope = "col" style = {{ width: "10%" }}>사업장명</ListHeader>
													<ListHeader scope = "col" style = {{ width: "5%" }}>대표자명</ListHeader>
													<ListHeader scope = "col" style = {{ width: "10%" }}>업종</ListHeader>
													<ListHeader scope = "col" style = {{ width: "10%" }}>업태</ListHeader>
													<ListHeader scope = "col" style = {{ width: "10%" }}>등록일</ListHeader>
													<ListHeader scope = "col" style = {{ width: "5%" }}>인증서 등록 여부</ListHeader>
												</tr>
											</thead>

											{/* 사업장 목록 */}
											<tbody>
												{businessmans && businessmans.map((businessman, index) => {
													return (
														<tr key = {index}>
															{/* 리스트 올 체킹 체크박스 인풋 */}
															<td style = {{ height: "65px" }}>
																<div className = "custom-control custom-checkbox" style = {{ zIndex: 0 }}>
																	<Input type = "checkbox" className = "custom-control-input" id = {`check${index}`} />
																	<Label className = "custom-control-label" htmlFor = {`check${index}`}>&nbsp;</Label>
																</div>
															</td>

															{/* 사업장 목록 */}
															{/* 사업자 등록번호 */}
															<ListData
																onClick={() => {
																	window.location.href = `/business-detail/${businessman.id}`;
																}}
																style = {{ color: "#556EE6", cursor: "pointer" }}
															>{businessman.companyRegistrationNumber}</ListData>
															{/* 사업자명 */}
															<ListData>{businessman.companyName}</ListData>
															{/* 대표자명 */}
															<ListData>{businessman.representative}</ListData>
															{/* 업종 */}
															<ListData>{businessman.sectors}</ListData>
															{/* 업태 */}
															<ListData>{businessman.businessCondition}</ListData>
															{/* 등록일 */}
															<ListData>{moment(businessman.createdAt).format('YYYY-MM-DD HH:mm')}</ListData>
															{/* 인증서 등록 여부 */}
															<CertLabel certIssuerDN = {businessman.certIssuerDN} />
														</tr>
													)
												})}
											</tbody>
										</Table>
										
										{/* 페이지 네비게이션 */}
										<LinkPaginator
											searchParams = {{
												currentPage: page,
												search,
												certFilter
											}}
											visibleCount = {amount}
											totalCount = {Math.ceil(totalCount / amount)}
											urlPrefix = {"/business"}
										/>
									</ListDiv>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment>
	)
}

const mapStateToProps = (state) => ({
	appState: state.appState,
	state: state.appState.state,
	params: state.appState.params,
	data: state.appState.data
});

/** style components */
/** 테이블 리스트 Div */
const ListDiv = styled.div`
	min-height: 800px;
	width: 100%;
`;

/** 테이블 리스트 헤더 */
const ListHeader = styled.th`
	text-align: center;
`;

/** 테이블 리스트 데이터 */
const ListData = styled.td`
	text-align: center;
	max-width: 40px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

/** 인증서 등록 라벨 */
const CertLabel = ({ certIssuerDN }) => {
	const CertLabelBadge = styled(Badge)`
		font-size: 100%;
	`;
	
	if (certIssuerDN) {
		return (
			<ListData>
				<CertLabelBadge color = "primary">인증</CertLabelBadge>
			</ListData>
		)
	}

	else {
		return (
			<ListData>
				<CertLabelBadge color = "danger">미인증</CertLabelBadge>
			</ListData>
		)
	}
}

export default connect (mapStateToProps)(BusinessList);