export const LOGIN = 'reducers/account/LOGIN'
export const LOGOUT = 'reducers/account/LOGOUT'
export const SUBMIT_PASSWORD_CHANGE = 'reducers/account/SUBMIT_PASSWORD_CHANGE'
export const SUBMIT_PASSWORD_CHANGE_VERIFY =
  'reducers/account/SUBMIT_PASSWORD_CHANGE_VERIFY'
export const CHANGE_PASSWORD = 'reducers/account/CHANGE_PASSWORD'

export const login = (email, password) => ({
  type: LOGIN,
  email,
  password
})

export const logout = () => ({
  type: LOGOUT
})

export const submitPasswordChange = email => ({
  type: SUBMIT_PASSWORD_CHANGE,
  email
})

export const submitPasswordChangeVerify = (email, code) => ({
  type: SUBMIT_PASSWORD_CHANGE_VERIFY,
  email,
  code
})

export const changePassword = (password, verifyId) => ({
  type: CHANGE_PASSWORD,
  password,
  verifyId
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
