export const getEnterString = str => {
  return str.replace(/<br\/>/g, '\n')
}

export const num2han = num => {
  num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + ''

  if (num == '0') return '영'

  const number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']
  const unit = ['', '만', '억', '조']
  const smallUnit = ['천', '백', '십', '']
  const result = [] //변환된 값을 저장할 배열
  let unitCnt = Math.ceil(num.length / 4) //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
  num = num.padStart(unitCnt * 4, '0') //4자리 값이 되도록 0을 채운다
  const regexp = /[\w\W]{4}/g //4자리 단위로 숫자 분리
  const array = num.match(regexp)
  //낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)

  const _makeHan = text => {
    let str = ''
    for (let i = 0; i < text.length; i++) {
      let num = text[i]
      if (num == '0')
        //0은 읽지 않는다
        continue
      str += number[num] + smallUnit[i]
    }
    return str
  }

  for (let i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
    let hanValue = _makeHan(array[i]) //한글로 변환된 숫자
    if (hanValue == '')
      //값이 없을땐 해당 단위의 값이 모두 0이란 뜻.
      continue
    result.unshift(hanValue + unit[unitCnt]) //unshift는 항상 배열의 앞에 넣는다.
  }
  //여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사

  return result.join('')
}

export const convertBrandingColor = (hex, opacity) => {
  let color = ''
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    color = hex.substring(1).split('')
    if (color.length === 3) {
      color = [color[0], color[0], color[1], color[1], color[2], color[2]]
    }
    color = `0x${color.join('')}`
    return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(
      ','
    )},${opacity})`
  }
}

export const getSliceString = str => {
  let outputStr = str.replace(/<br\/>/g, '\n')

  outputStr = [outputStr.slice(0, 7), ' ', outputStr.slice(7)].join('')

  return outputStr
}

export const workOrderProjectState = state => {
  let string = ''

  switch (state) {
    case 0:
      string = '임시저장'
      break
    case 1:
      string = '미승낙'
      break
    case 2:
      string = '진행'
      break
    case 3:
      string = '만료'
      break
    case 4:
      string = '삭제'
      break
    case 5:
      string = '완료'
      break
    default:
      break
  }

  return string
}
