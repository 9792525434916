import { put, call, takeLatest } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'
import { GET_ADMIN_LIST, TOGGLE_ADMIN } from '../reducers/admin'

import { setAppState, saveAppData } from '../reducers/appState'

export function* getAdminList() {
  yield put(setAppState('START_GET_ADMIN_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ADMIN_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.ADMIN_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('adminList', response.data))
      yield put(setAppState('SUCCESS_GET_ADMIN_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_ADMIN_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ADMIN_LIST', e, false))
  }
}

export function* toggleAdmin(actions) {
  const { id } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.TOGGLE_ADMIN}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { id }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_TOGGLE_ADMIN', response.data))
    } else {
      yield put(setAppState('FAILED_TOGGLE_ADMIN', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_TOGGLE_ADMIN', e))
  }
}

const adminSagas = [
  takeLatest(GET_ADMIN_LIST, getAdminList),
  takeLatest(TOGGLE_ADMIN, toggleAdmin)
]

export default adminSagas
