import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'

// global
import * as INFO from '../../../global/projectInfo'

// images
import CameraImage from '../../../assets/images/ic_camera_g1_30.png'

// temp
import { tempUsers, kimData } from '../../../constant/dummy'

// components
import UserCredit from './UserCredit'
import UserLogin from './UserLogin'

// utils
import { voucherCompany } from '../../../constant/utils'

// redux
import { connect } from 'react-redux'
import {
  getUserDetail,
  getUserPayAppDetail,
  updateUser,
  setAdmin,
  toggleTestUser,
  setNickname,
  setProfileImage,
  getCertification,
  getUserPaymentHistory,
  setUserPayment,
  updateUserPayment,
  removeUserPayment,
  removeRatePlanUser,
  setRatePlanUser,
  getRatePlanList,
  setUserPassword,
  setUserKakaoCreditReward,
  setUserEmailCreditReward,
  setUserTaxInvoiceCreditReward
} from '../../../reducers/users'
import { getWorkOrderDetail } from '../../../reducers/workOrder';
import { saveAppData } from '../../../reducers/appState';
import { getRatePlanPlans } from '../../../reducers/ratePlan';

// tools
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Form,
  Button
} from 'reactstrap'
import styled from 'styled-components'
import Select from 'react-select'
import classnames from 'classnames'

// time
import moment from 'moment'
import 'moment/locale/ko'
import UserDetailSettlementStatements from './documentPeyments'
import UserDetailWorkDocuments from './workDocuments'
import UserDetailBusinessmans from './businessmans'
import UserDetailSbaAlternativeCreditEvaluation from './sbaAlternativeCreditEvaluation'

const selectFeed = [
  { value: 'feedEnabled', label: 'O' },
  { value: 'feedDisabled', label: 'X' }
]

const selectAdmin = [
  { value: 'adminEnabled', label: 'O' },
  { value: 'adminDisabled', label: 'X' }
]

const selectTester = [
  { value: 'testerEnabled', label: 'O' },
  { value: 'testerDisabled', label: 'X' }
]

const UserDetail = ({
  state,
  params,
  profile,
  userDetail,
  getUserDetail,
  userPayApp,
  getUserPayAppDetail,
  saveAppData,
  updateUser,
  setAdmin,
  toggleTestUser,
  setNickname,
  setProfileImage,
  getCertification,
  getUserPaymentHistory,
  paymentHistory,
  setUserPayment,
  updateUserPayment,
  workOrderDetail,
  getWorkOrderDetail,
  removeUserPayment,
  removeRatePlanUser,
  setRatePlanUser,
  ratePlanList,
  getRatePlanList,
  setUserPassword,
  setUserKakaoCreditReward,
  setUserEmailCreditReward,
  setUserTaxInvoiceCreditReward,
  ratePlanPlans,
  getRatePlanPlans,
  errorInfo
}) => {
  const userId = window.location.pathname.split('/')[2]
  const [activeTab, setActiveTab] = useState('1')
  const [isFetching, setFetching] = useState(true)
  const [userData, setUserData] = useState({})
  const [projectDetail, setProjectDetail] = useState(null)
  const [projectData, setProjectData] = useState({
    simpleProjects: [],
  })
  const [feedData, setFeedData] = useState({
    userFeedDontLikeCategory: [],
    userFeedNotice: [],
    userFeedBlock: [],
    userFeedSearch: [],
    userProfileImage: []
  })
  const [paymentData, setPaymentData] = useState([])

  const [isFeed, setIsFeed] = useState(selectFeed)
  const [isAdmin, setIsAdmin] = useState(selectAdmin)
  const [isTester, setIsTester] = useState(selectTester)
  const [adminInit, setAdminInit] = useState(false)
  const [testerInit, setTesterInit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [updateAlert, setUpdateAlert] = useState({
    color: 'success',
    isAlert: false,
    errorMsg: '회원정보가 수정되었습니다'
  })
  const [error, setError] = useState('')
  const [surplus, setSurplus] = useState('')
  const [isUploadImage, setIsUploadImage] = useState(false)
  const [uploadImage, setUploadImage] = useState([])
  const [tempUrl, setTempUrl] = useState('')
  const [plans, setPlans] = useState([])

  //페이앱
  const [payAppData, setPayAppData] = useState({
    payAppUserId: '',
    userpwd: ''
  })

  const [status, setStatus] = useState({
    isError: false,
    color: 'danger',
    errorMsg: '유저 정보 조회 중 문제가 발생했습니다.'
  })

  const [formData, setFormData] = useState({
    type: '', // 종류
    category: '', // 유형
    paymentPrice: 0, // 결제
    rechargePrice: 0, // 충전
    paymentedAt: '', // 결제일
    targetId: ''
  })

  // 요금제
  const [removeRatePlanVisible, setRemoveRatePlanVisible] = useState(false)
  const [ratePlanVisible, setRatePlanVisible] = useState(false)
  const [ratePlanExpireDate, setRatePlanExpireDate] = useState(moment().add(1, 'd').toDate())
  const [ratePlanType, setRatePlanType] = useState(null)

  // 페이지네이션
  const location = useLocation()
  const history = useHistory()

  const pageInfo = location.state?.pageInfo ?? 1
  const searchInfo = location.state?.searchInfo ?? ''
  const filteringInfo = location.state?.filteringInfo ?? ''
  const startInfo = location.state?.startInfo ?? ''
  const endInfo = location.state?.endInfo ?? ''
  const prevInfo = location.state?.prevInfo ?? ''

  const idInfo = location.state?.idInfo ? location.state.idInfo : ''
  const lastPageInfo = location.state?.lastPageInfo
    ? location.state.lastPageInfo
    : 1

  const isUserDetail = location.state?.isUserDetail ? true : false

  // 실회원 체크
  const [isMember, setIsMember] = useState(true)
  const [userCompany, setUserCompany] = useState(['일반'])

  /** did mount */
  useEffect(() => {
    getRatePlanPlans();
  }, []);

  /** plans update */
  useEffect(() => {
    if (ratePlanPlans?.length > 0) {
      setPlans(ratePlanPlans);
      setRatePlanType(ratePlanPlans[0]);
    }
  }, [ratePlanPlans]);
  
  useEffect(() => {
    // saveAppData('userPayApp', [])
    getUserDetail(userId)
    getUserPayAppDetail(userId)
    getUserPaymentHistory(userId, { page: 1, amount: 9999, search: '' })
    setUserCompany(voucherCompany(userId))
    
  }, [userId])

  useEffect(() => {
    switch (state) {
      // case 'SUCCESS_GET_WORK_ORDER_DETAIL':
      //   console.log('$$ inini')
      //   setProjectDetail(workOrderDetail.workOrderForQuantity)
      //   break
      case 'SUCCESS_GET_USER_DETAIL':
        // temp
        if (userId === '61678e67-1ee9-47a6-8e07-dc533d684e63') {
          let filterUser = tempUsers.filter(f => f.id === userId)[0]
          setIsMember(false)
          setUserData(userDetail.user)
          // setUserData(filterUser)

          // console.log('$$ kim', kimData)

          setPaymentData({
            userPaymentHistoryCount: 20,
            userPaymentHistorys: kimData[0].userPaymentHistorys
          })
        } else {
          setUserData(userDetail.user)
          setProjectData({
            simpleProjects: userDetail.simpleProjects,
          })

          // console.log('$$ user detail', userDetail)

          // setFeedData({
          //   userFeedDontLikeCategory:
          //     userDetail.user.userFeed.userFeedDontLikeCategory,
          //   userFeedNotice: userDetail.user.userFeed.userFeedNotice,
          //   userFeedBlock: userDetail.user.userFeed.userFeedDontLikeCategory,
          //   userFeedSearch: userDetail.user.userFeed.userFeedSearch
          // })

          setAdminInit(userDetail.user.admin)
          setIsAdmin(userDetail.user.admin ? selectAdmin[0] : selectAdmin[1])
          // setIsFeed(
          //   userDetail.user.userFeed.feedEnabled ? selectFeed[0] : selectFeed[1]
          // )
          setTesterInit(userDetail.user.isTestAccount)
          setIsTester(
            userDetail.user.isTestAccount ? selectTester[0] : selectTester[1]
          )
        }
        break
      case 'SUCCESS_GET_USER_PAY_APP_DETAIL':
        setPayAppData({
          payAppUserId: userPayApp.payAppUserId,
          userpwd: userPayApp.userpwd
        })
        break
      case 'FAILED_GET_USER_PAY_APP_DETAIL':
        setPayAppData({
          payAppUserId: '',
          userpwd: ''
        })
        break

      case 'FAILED_GET_USER_DETAIL':
        // check temp user
        let checker = true

        for (let i = 0; i < tempUsers.length; i++) {
          if (tempUsers[i].id === userId) {
            checker = false
          }
        }

        if (checker) {
          // setStatus({
          //   color: 'danger',
          //   isError: true,
          //   errorMsg: params.message
          // })
        } else {
          let filterUser = tempUsers.filter(f => f.id === userId)[0]
          setIsMember(false)
          setUserData(filterUser)
          setPaymentData(filterUser.paymentData)
        }

        // setStatus({ color: 'danger', isError: true, errorMsg: params.message })
        break
      case 'SUCCESS_UPDATE_USER':
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '회원정보가 수정되었습니다'
        })
        break
      case 'FAILED_UPDATE_USER':
        setStatus({
          color: 'danger',
          isError: true,
          errorMsg:
            params.message === '선택한 유저가 존재하지 않아요.'
              ? '해당 유저가 비활성화 상태입니다'
              : params.message
        })
        break
      case 'SUCCESS_SET_NICKNAME':
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '닉네임이 변경되었습니다'
        })
        getUserDetail(userId)
        break
      case 'FAILED_SET_NICKNAME':
        setStatus({
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        break
      case 'SUCCESS_PROFILE_IMAGE':
        setIsUploadImage(false)
        setUploadImage([])
        setTempUrl('')
        getUserDetail(userId)
        break
      case 'SUCCESS_SET_CERTIFICATION':
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '인증 내역이 초기화되었습니다'
        })
        break
      case 'FAILED_SET_CERTIFICATION':
        setStatus({
          color: 'danger',
          isError: true,
          errorMsg: '초기화 가능한 인증 내역이 없습니다'
        })
      case 'SUCCESS_GET_USER_PAYMENT_HISTORY':
        setPaymentData(paymentHistory)

        break
      case 'SUCCESS_SET_USER_PAYMENT':
        getUserPaymentHistory(userId, { page: 1, amount: 9999, search: '' })
        setFormData({
          type: '',
          category: '',
          paymentPrice: 0,
          rechargePrice: 0,
          paymentedAt: '',
          targetId: ''
        })
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '이용 내역이 추가되었습니다'
        })
        break
      case 'SUCCESS_UPDATE_USER_PAYMENT':
        getUserPaymentHistory(userId, { page: 1, amount: 9999, search: '' })
        setFormData({
          type: '',
          category: '',
          paymentPrice: 0,
          rechargePrice: 0,
          paymentedAt: '',
          targetId: ''
        })
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '이용 내역이 수정되었습니다'
        })
        break
      case 'SUCCESS_REMOVE_USER_PAYMENT':
        getUserPaymentHistory(userId, { page: 1, amount: 9999, search: '' })
        setFormData({
          type: '',
          category: '',
          paymentPrice: 0,
          rechargePrice: 0,
          paymentedAt: '',
          targetId: ''
        })
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '이용 내역이 삭제되었습니다'
        })
        break

      case 'SUCCESS_REMOVE_RATE_PLAN_USER':
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '요금제가 해지되었습니다'
        })

        getUserDetail(userId)
        break

      case 'SUCCESS_SET_RATE_PLAN_USER':
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '요금제가 부여되었습니다'
        })
        setRatePlanExpireDate(new Date())
        setRatePlanType(null)

        getUserDetail(userId)
        break

      case 'FAILED_SET_RATE_PLAN_USER':
        const message = "요금제 부여에 실패했습니다. 자세한 오류는 표기할려면 추가 개발해야합니다.";
        
        setStatus({
          color: 'danger',
          isError: true,
          errorMsg: message
        });

        break;

      default:
        break
    }
  }, [state])

  const handleUpdate = e => {
    e.preventDefault()

    const updateInfo = {
      username: userData?.username ? userData.username : '',
      email: userData?.email,
      phone: userData?.phone ? userData?.phone : ''
    }

    updateUser(userData.id, updateInfo)

    // console.log('$$ update', updateInfo)

    if (adminInit !== userData.admin) {
      setAdmin(userData.id)
    }

    if (testerInit !== userData.isTestAccount) {
      toggleTestUser(userData.id)
    }

    if (isUploadImage) {
      setProfileImage(userData.id, uploadImage)
    }
  }

  const handleInputs = e => {
    e.persist()
    return setUserData(prevState => ({
      ...prevState,
      [e.target?.name]: e.target?.value
    }))
  }

  const handlePhoneNumber = e => {
    e.persist()

    let temp = e.target.value.replace(/[^0-9]/g, '')
    if (temp.slice(0, 2) === '82') {
      temp = '0' + temp.slice(2)
    } else if (temp.slice(0, 3) === '082') {
      temp = '0' + temp.slice(3)
    }
    let res = ''

    if (
      userData.phone &&
      userData.phone.length > e.target.value.length &&
      userData.phone[e.target.value.length] === '-'
    ) {
      res = e.target.value.slice(0, e.target.value.length - 1)
    } else {
      for (let i = 0; i < temp.length; i++) {
        res += temp[i]
        if (i === 2 || i === 6) {
          res += '-'
        }
      }
    }

    setUserData({ ...userData, phone: res })
  }

  const handleAdmin = options => {
    setIsAdmin(options)

    return setUserData(prevState => ({
      ...prevState,
      admin: options.value === 'adminEnabled' ? true : false
    }))
  }

  const handleTester = options => {
    setIsTester(options)

    return setUserData(prevState => ({
      ...prevState,
      isTestAccount: options.value === 'testerEnabled' ? true : false
    }))
  }

  const handlerCertification = () => {
    getCertification(userData.phone)
  }

  const handlePayApp = e => {
    // e.persist()
    // return setPayAppData(prevState => ({
    //   ...prevState,
    //   [e.target?.name]: e.target?.value
    // }))
  }

  const onRemovePayAppClick = async () => {
    // const data = await deleteUserPayApp(userId)
    // if (data.opcode === 0) {
    //   setPayAppData({ payAppUserId: '', userpwd: '' })
    // } else {
    //   setError(data.message)
    // }
  }

  const handleNickname = () => {
    setNickname(userData.id)
  }


  const convertBirth = birth => {
    let str =
      birth.slice(0, 4) + '-' + birth.slice(4, 6) + '-' + birth.slice(6, 8)

    return str
  }

  const onChangeProfileImage = e => {
    setIsUploadImage(true)
    setUploadImage(e.target.files[0])
    setTempUrl(URL.createObjectURL(e.target.files[0]))
  }

  const handlePaymentInputs = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handlePrice = e => {
    let targetData = formData[e.target.name]
    let inputValue = e.target.value

    const singlePriceText = targetData
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    if (inputValue.length < singlePriceText.length) {
      if (inputValue.length === singlePriceText.length - 1) {
        for (let i = 0; i < inputValue.length; i++) {
          if (
            inputValue[i] !== singlePriceText[i] &&
            singlePriceText[i] === ','
          ) {
            inputValue =
              inputValue.slice(0, i) +
              inputValue.slice(i + 1, inputValue.length)
            break
          }
        }
      }
    }

    inputValue = inputValue.replace(/\D/g, '')
    inputValue = parseInt(inputValue || 0)

    setFormData({ ...formData, [e.target.name]: inputValue })
  }

  const createPayment = e => {
    e.preventDefault()

    let filterData = {
      type: formData.type,
      category: formData.category,
      paymentPrice: formData.paymentPrice,
      rechargePrice: formData.rechargePrice,
      paymentedAt: new Date(formData.paymentedAt)
    }

    setUserPayment(userId, filterData)
  }

  const onSaveFormData = target => {
    setFormData({
      type: target.type,
      category: target.category,
      paymentPrice: target.paymentPrice,
      rechargePrice: target.rechargePrice,
      paymentedAt: target.paymentedAt.slice(0, 10),
      targetId: target.id
    })
  }

  const updatePayment = e => {
    e.preventDefault()

    let filterData = {
      type: formData.type,
      category: formData.category,
      paymentPrice: formData.paymentPrice,
      rechargePrice: formData.rechargePrice,
      paymentedAt: new Date(formData.paymentedAt),
      targetId: formData.targetId
    }

    updateUserPayment(userId, filterData)
  }

  const deletePayment = (e, ids) => {
    e.preventDefault()

    removeUserPayment(userId, ids)
  }

  const onChangeCompany = e => {
    let inputValue = e.target.value

    if (inputValue === '비즈부스터') {
      setPaymentData({
        userPaymentHistoryCount: 20,
        userPaymentHistorys: kimData[0].userPaymentHistorys
      })
    }

    if (inputValue === '누리듀') {
      setPaymentData({
        userPaymentHistoryCount: 20,
        userPaymentHistorys: kimData[1].userPaymentHistorys
      })
    }

    if (inputValue === '몬스터부동산중개') {
      setPaymentData({
        userPaymentHistoryCount: 20,
        userPaymentHistorys: kimData[2].userPaymentHistorys
      })
    }

    return
  }

  const getAllWorkOrder = async () => {
    for (let i = 0; i < projectData.workOrderForQuantitys.length; i++) {
      await getWorkOrderDetail(projectData.workOrderForQuantitys[i].id, true)
    }
  }

  const onRemoveRatePlan = async () => {
    removeRatePlanUser(userId)
  }

  const onSetRatePlan = async () => {
    console.log('$$ target ratePlanType', ratePlanType)
    const planId = ratePlanType.id;

    setRatePlanUser(
      userId,
      planId,
      moment(ratePlanExpireDate).format('YYYY-MM-DD')
    )
  }

  /** 패스워드 변경 */
  /** 패스워드 변경 상태 */
  const [changePassword, setChangePassword] = useState(false);
  const [setPassword, setSetPassword] = useState(null);

  /** 패스워드 변경 설정 */
  const onSetChangePassword = async() => {
    setUserPassword({
      userId,
      password: setPassword
    });

    setChangePassword(!changePassword)
  }

  const handleChangeRatePlanStatus = flag => {
    setRatePlanType(flag)
  }

  /** 리워드 */
  const [rewardModal, setRewardModal] = useState(false);
  const [kakaoCredit, setKakaoCredit] = useState(0);
  const [emailCredit, setEmailCredit] = useState(0);
  const [taxInvoiceCredit, setTaxInvoiceCredit] = useState(0);

  /** 리워드 반영 */
  const onCreditReward = async() => {
    /** 카카오 리워드가 있으면 세팅 */
    if (kakaoCredit > 0) {
      setUserKakaoCreditReward({
        userId,
        coin: Number(kakaoCredit)
      });
    }

    /** 이메일 리워드가 있으면 세팅 */
    if (emailCredit > 0) {
      setUserEmailCreditReward({
        userId,
        coin: Number(emailCredit)
      });
    }

    /** 세금계산서 리워드가 있으면 세팅 */
    if (taxInvoiceCredit > 0) {
      setUserTaxInvoiceCreditReward({
        userId,
        coin: Number(taxInvoiceCredit)
      });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <Alert
            color={updateAlert.color}
            isOpen={updateAlert.isAlert}
            toggle={() =>
              setUpdateAlert(prevState => ({
                ...prevState,
                isAlert: false
              }))
            }
          >
            {updateAlert.errorMsg}
          </Alert>

          <Modal
            centered
            scrollable
            isOpen={deleteModal}
            toggle={() => {
              setDeleteModal(!deleteModal)
            }}
          >
            <ModalHeader
              toggle={() => {
                setDeleteModal(!deleteModal)
              }}
              style={{
                borderBottom: '2px solid #2a3042'
              }}
            >
              <div>페이앱 계정 삭제</div>
            </ModalHeader>
            <ModalBody
              className="d-flex align-items-center justify-content-center"
              style={{
                height: '150px',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  marginBottom: '8px'
                }}
              >{`${userData?.username}의 페이앱 계정을 삭제하시겠습니까?`}</div>
              <div>{`페이앱 계정이 삭제됩니다`}</div>
            </ModalBody>
            <ModalFooter
              onClick={() => setDeleteModal(!deleteModal)}
              style={{
                justifyContent: 'center',
                borderTop: '2px solid #e2e2e2'
              }}
            >
              <Button color="danger" outline onClick={onRemovePayAppClick}>
                삭제하기
              </Button>
            </ModalFooter>
          </Modal>

          {/* 요금제 */}
          <Modal
            centered
            scrollable
            isOpen={removeRatePlanVisible}
            toggle={() => {
              setRemoveRatePlanVisible(!removeRatePlanVisible)
            }}
          >
            <ModalHeader
              toggle={() => {
                setRemoveRatePlanVisible(!removeRatePlanVisible)
              }}
              style={{
                borderBottom: '2px solid #2a3042'
              }}
            >
              <div>요금제 강제 해지</div>
            </ModalHeader>
            <ModalBody
              className="d-flex align-items-center justify-content-center"
              style={{
                height: '150px',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  marginBottom: '8px'
                }}
              >{`${userData?.username}의 ${
                INFO.RATE_PLAN_TYPE[userData?.userRatePlan?.ratePlan?.type]
              } 요금제를 해지 하시겠습니까?`}</div>
              <div>{`요금제가 해지 됩니다`}</div>
            </ModalBody>
            <ModalFooter
              onClick={() => setRemoveRatePlanVisible(!removeRatePlanVisible)}
              style={{
                justifyContent: 'center',
                borderTop: '2px solid #e2e2e2'
              }}
            >
              <Button color="danger" outline onClick={onRemoveRatePlan}>
                해지하기
              </Button>
            </ModalFooter>
          </Modal>

          {/* 패스워드 변경 모달 */}
          <Modal
            centered
            isOpen={changePassword}
            toggle={() => setChangePassword(!changePassword)}
          >
            <ModalHeader
              toggle={() => setChangePassword(!changePassword)}
              style={{
                borderBottom: '2px solid #2a3042'
              }}
            >
              <div>변경될 유저 비밀번호 입력</div>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="changePassword" className="control-label">비밀번호</Label>
                <Input
                  type="password"
                  name="changePassword"
                  placeholder="변경될 비밀번호"
                  onChange={e => setSetPassword(e.target.value)}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter
              onClick={() => setChangePassword(!changePassword)}
              style={{
                justifyContent: 'center',
                borderTop: '2px solid #e2e2e2'
              }}
            >
              <Button
                color="success"
                outline
                onClick={onSetChangePassword}
                style={{ fontSize: '16px' }}
              >
                변경하기
              </Button>
              <Button
                color="danger"
                outline
                onClick={() => setChangePassword(!changePassword)}
                style={{ fontSize: '16px' }}
              >
                취소하기
              </Button>
            </ModalFooter>
          </Modal>

          {/* 요금제 부여 모달 */}
          <Modal
            centered
            scrollable
            isOpen={ratePlanVisible}
            toggle={() => setRatePlanVisible(!ratePlanVisible)}
          >
            <ModalHeader
              toggle={() => setRatePlanVisible(!ratePlanVisible)}
              style={{
                borderBottom: '2px solid #2a3042'
              }}
            >
              <div style={{ fontSize: '20px' }}>요금제 부여</div>
            </ModalHeader>
            <ModalBody
              className="d-flex"
              style={{
                height: '550px',
                flexDirection: 'column'
              }}
            >
              <Row>
                <FormGroup className="col-12">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    유형
                  </Label>
                  <Select
                    onChange={e => handleChangeRatePlanStatus(e)}
                    value={ratePlanType}
                    options={plans}
                  />
                </FormGroup>

                <FormGroup className="col-12">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    만료일
                  </Label>
                  <DatePicker
                    className="form-control d-block"
                    placeholderText="선택"
                    selected={
                      ratePlanExpireDate && new Date(ratePlanExpireDate)
                    }
                    onChange={date => {
                      console.log('$$ change', date)
                      setRatePlanExpireDate(date)
                      // setSearchQuery(prevState => ({
                      //   ...prevState,
                      //   date: `${date.getFullYear()}-${(
                      //     '0' +
                      //     (date.getMonth() + 1)
                      //   ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
                      // }))
                    }}
                  />
                </FormGroup>
              </Row>

              <div
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  marginBottom: '8px'
                }}
              ></div>
            </ModalBody>
            <ModalFooter
              onClick={() => setRatePlanVisible(!ratePlanVisible)}
              style={{
                justifyContent: 'center',
                borderTop: '2px solid #e2e2e2'
              }}
            >
              <Button
                color="danger"
                outline
                onClick={onSetRatePlan}
                style={{ fontSize: '16px' }}
              >
                부여하기
              </Button>
            </ModalFooter>
          </Modal>

          {/* 리워드 부여 모달 */}
          <Modal
            centered
            isOpen={rewardModal}
            toggle={() => setRewardModal(!rewardModal)}
          >
            <ModalHeader
              toggle={() => setRewardModal(!rewardModal)}
              style={{
                borderBottom: '2px solid #2a3042'
              }}
            >
              <div>리워드 설정</div>
            </ModalHeader>
            <ModalBody>
              {/* 카카오톡 리워드 */}
              <FormGroup>
                <Label htmlFor="kakaoCreditReward" className="control-label">카카오톡 공유 리워드</Label>
                <Input
                  type="number"
                  name="kakaoCreditReward"
                  placeholder="추가할 카카오 공유 리워드"
                  onChange={e => setKakaoCredit(e.target.value)}
                  value={kakaoCredit}
                />
              </FormGroup>

              {/* 이메일 리워드 */}
              <FormGroup>
                <Label htmlFor="emailCreditReward" className="control-label">이메일 공유 리워드</Label>
                <Input
                  type="number"
                  name="emailCreditReward"
                  placeholder="추가할 이메일 공유 리워드"
                  onChange={e => setEmailCredit(e.target.value)}
                  value={emailCredit}
                />
              </FormGroup>

              {/* 세금계산서 리워드 */}
              <FormGroup>
                <Label htmlFor="taxInvoiceCreditReward" className="control-label">세금계산서 리워드</Label>
                <Input
                  type="number"
                  name="taxInvoiceCreditReward"
                  placeholder="추가할 세금계산서 리워드"
                  onChange={e => setTaxInvoiceCredit(e.target.value)}
                  value={taxInvoiceCredit}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter
              onClick={() => setRewardModal(!rewardModal)}
              style={{
                justifyContent: 'center',
                borderTop: '2px solid #e2e2e2'
              }}
            >
              <Button
                color="success"
                outline
                onClick={onCreditReward}
                style={{ fontSize: '16px' }}
              >
                반영하기
              </Button>
              <Button
                color="danger"
                outline
                onClick={() => setRewardModal(!rewardModal)}
                style={{ fontSize: '16px' }}
              >
                취소하기
              </Button>
            </ModalFooter>
          </Modal>
          
          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1')
                      }}
                    >
                      <i className="bx bx-user d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">회원 상세</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        setActiveTab('2')
                      }}
                    >
                      <i className="bx bx-briefcase-alt-2 d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">거래 문서</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        setActiveTab('3')
                      }}
                    >
                      <i className="bx bx-briefcase-alt-2 d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">사업자 관리</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => {
                        setActiveTab('4')
                      }}
                    >
                      <i className="bx bx-add-to-queue d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">월별 정산내역서</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '5' })}
                      onClick={() => {
                        setActiveTab('5')
                      }}
                    >
                      <i className="bx bxs-credit-card d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">이용 내역 </p>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === '6' })}
                      onClick={() => {
                        setActiveTab('6')
                      }}
                    >
                      <i className="bx bx bx-key d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">로그인 내역 </p>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === '7' })}
                      onClick={() => {
                        setActiveTab('7')
                      }}
                    >
                      <i className="bx bx bx-analyse d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">추이분석 기반 임상 신용평가 </p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Form onSubmit={handleUpdate}>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Label>
                                  프로필{' '}
                                  {userData &&
                                    userData?.generalAdministrator && (
                                      <i className="mdi mdi-crown" />
                                    )}
                                </Label>
                                <div className="mt-4 mt-md-0">
                                  {isUploadImage ? (
                                    <img
                                      className="img-thumbnail rounded-circle avatar-lg"
                                      alt=""
                                      src={tempUrl}
                                    />
                                  ) : userData?.userProfileImage ? (
                                    <img
                                      className="img-thumbnail rounded-circle avatar-lg"
                                      alt=""
                                      src={`https://s3.ap-northeast-2.amazonaws.com/media.paywork.io/${userData?.userProfileImage.path}/${userData?.userProfileImage.filename}`}
                                    />
                                  ) : (
                                    <div className="avatar-lg">
                                      <span
                                        className="avatar-title rounded-circle"
                                        style={{ fontSize: '2.5rem' }}
                                      >
                                        {userData?.username?.charAt(0)}
                                      </span>
                                    </div>
                                  )}

                                  {/* {userData.userProfileImage ? (
                                    <img
                                      className="img-thumbnail rounded-circle avatar-lg"
                                      alt=""
                                      src={`https://s3.ap-northeast-2.amazonaws.com/media.paywork.io/${userData.userProfileImage.path}/${userData.userProfileImage.filename}`}
                                    />
                                  ) : isUploadImage ? (
                                    <img
                                      className="img-thumbnail rounded-circle avatar-lg"
                                      alt=""
                                      src={tempUrl}
                                    />
                                  ) : (
                                    <div className="avatar-lg">
                                      <span
                                        className="avatar-title rounded-circle"
                                        style={{ fontSize: '2.5rem' }}
                                      >
                                        {userData.username?.charAt(0)}
                                      </span>
                                    </div>
                                  )} */}

                                  <ImageChangeButton>
                                    <div className="mt-4 mt-md-0">
                                      <label htmlFor="profile-file">
                                        <i
                                          className="mdi mdi-file-image-outline"
                                          style={{ fontSize: '18px' }}
                                        />
                                      </label>
                                      <input
                                        id="profile-file"
                                        type="file"
                                        onChange={onChangeProfileImage}
                                        disabled={!isMember}
                                      />
                                    </div>
                                  </ImageChangeButton>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label>회원명</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="username"
                                  value={
                                    userData?.username ? userData?.username : ''
                                  }
                                  onChange={handleInputs}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>성별</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="gender"
                                  value={
                                    userData?.gender === '0' ||
                                    userData?.gender === null
                                      ? '선택안함'
                                      : userData?.gender === '1'
                                      ? '남성'
                                      : '여성'
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>생년월일</Label>
                                <Input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  value={
                                    userData?.birth
                                      ? convertBirth(userData?.birth)
                                      : '미입력'
                                  }
                                  onChange={handleInputs}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>활성</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="enabled"
                                  value={userData?.enabled ? 'O' : 'X'}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>탈퇴일</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="withdrawalDate"
                                  // value={userData?.withdrawalDate || ''}
                                  value={
                                    userData.withdrawalDate &&
                                    moment(userData.withdrawalDate).format(
                                      'YYYY-MM-DD-HH:mm'
                                    )
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="2">
                              <FormGroup>
                                <Label>휴대폰</Label>

                                <Button
                                  type="button"
                                  size="sm"
                                  style={{
                                    marginLeft: '6px'
                                  }}
                                  color="secondary"
                                  onClick={handlerCertification}
                                  disabled={!isMember}
                                >
                                  <i className="mdi mdi-refresh mr-1" />
                                  인증 초기화
                                </Button>

                                <Input
                                  type="text"
                                  className="form-control"
                                  name="phone"
                                  value={userData?.phone ? userData?.phone : ''}
                                  // onChange={handleInputs}
                                  onChange={handlePhoneNumber}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>이메일</Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  value={userData?.email}
                                  onChange={handleInputs}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>직업</Label>
                                <Input
                                  disabled
                                  className="form-control"
                                  value={
                                    userData?.userFeed && userData?.userFeed.job
                                      ? userData?.userFeed.job
                                      : '미입력'
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>로그인 횟수</Label>
                                <Input
                                  disabled
                                  className="form-control"
                                  name="userLoginInfo"
                                  value={userData?.userLoginInfo?.length}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>보낸 견적서 생성건수</Label>
                                <Input
                                  disabled
                                  className="form-control"
                                  value={projectData?.documentCount || 0}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* 요금제 */}
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label>요금제</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="username"
                                  value={
                                    userData?.userRatePlan
                                      ? INFO.RATE_PLAN_TYPE[
                                          userData?.userRatePlan?.ratePlan?.type
                                        ]
                                      : '무료'
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>결제일</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="gender"
                                  value={
                                    userData?.userRatePlan
                                      ? moment(
                                          userData?.userRatePlan?.createdAt
                                        ).format('YYYY-MM-DD-HH:mm')
                                      : ''
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="1">
                              <FormGroup>
                                <Label>패스워드 부여</Label>
                                <Button
                                  className="btn btn-info btn-sm btn-block waves-effect waves-light"
                                  onClick={() => setChangePassword(true)}
                                >
                                  변경 <i className="mdi mdi-account-key" />
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col lg="1">
                              <FormGroup>
                                <Label>요금제 부여</Label>
                                <Button
                                  className="btn btn-success btn-sm btn-block waves-effect waves-light"
                                  disabled={userData?.userRatePlan}
                                  onClick={() => setRatePlanVisible(true)}
                                >
                                  부여 <i className="mdi mdi-check mr-1" />
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col lg="1">
                              <FormGroup>
                                <Label>요금제 해지</Label>
                                <Button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light"
                                  disabled={!userData?.userRatePlan}
                                  onClick={() => setRemoveRatePlanVisible(true)}
                                >
                                  해지 <i className="mdi mdi-cancel mr-1" />
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col lg="1">
                              <FormGroup>
                                <Label>리워드 부여</Label>
                                <Button
                                  className="btn btn-success btn-sm btn-block waves-effect waves-light"
                                  onClick={() => setRewardModal(true)}
                                >
                                  부여 <i className="mdi mdi-check mr-1" />
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label>관리자</Label>
                                <Select
                                  options={selectAdmin}
                                  // defaultValue={isAdmin}
                                  value={isAdmin}
                                  isMulti={false}
                                  name="isAdmin"
                                  onChange={handleAdmin}
                                  // isDisabled={
                                  //   profile && !profile.generalAdministrator
                                  // }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>테스터</Label>
                                <Select
                                  options={selectTester}
                                  // defaultValue={isTester}
                                  value={isTester}
                                  isMulti={false}
                                  name="isTester"
                                  onChange={handleTester}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>서비스 푸시 알림</Label>
                                <Input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  value={userData?.setServicePush ? 'O' : 'X'}
                                  onChange={handleInputs}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>이벤트, 마케팅 정보 알림</Label>
                                <Input
                                  disabled
                                  className="form-control"
                                  value={userData?.setMarketingPush ? 'O' : 'X'}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label>종류</Label>
                                <Input
                                  disabled
                                  className="form-control disabled"
                                  value={
                                    userData?.userOtherInfo?.at(0)?.type ===
                                    'Businessman'
                                      ? '사업자'
                                      : userData?.userOtherInfo?.at(0)?.type ===
                                        'Freelancer'
                                      ? '프리랜서'
                                      : '미입력'
                                  }
                                />
                              </FormGroup>
                            </Col>{' '}
                            <Col lg="3">
                              <FormGroup>
                                <Label>규모</Label>
                                <Input
                                  disabled
                                  className="form-control disabled"
                                  value={
                                    userData?.userOtherInfo?.at(0)?.size ===
                                    'Individual'
                                      ? '개인'
                                      : userData?.userOtherInfo?.at(0)?.size ===
                                        'Less5'
                                      ? '5인 이하 팀'
                                      : userData?.userOtherInfo?.at(0)?.size ===
                                        'More5'
                                      ? '5인 이상 팀'
                                      : '미입력'
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>연령대</Label>
                                <Input
                                  disabled
                                  className="form-control disabled"
                                  value={
                                    userData?.userOtherInfo?.at(0)?.ageGroup ??
                                    '미입력'
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>제공 서비스</Label>
                                <Input
                                  disabled
                                  className="form-control disabled"
                                  value={
                                    userData?.userOtherInfo?.at(0)?.text ??
                                    '미입력'
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>마지막 접속일</Label>
                                <Input
                                  disabled
                                  className="form-control disabled"
                                  defaultValue={
                                    moment(userData?.loginAt).format(
                                      'YYYY-MM-DD-HH:mm'
                                    )
                                    // userData?.loginAt?.slice(0, 10)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>이용 횟수</Label>
                                <Input
                                  disabled
                                  className="form-control disabled"
                                  defaultValue={
                                    paymentData?.userPaymentHistoryCount
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>누적 충전금액</Label>
                                <Input
                                  disabled
                                  className="form-control disabled"
                                  defaultValue={
                                    paymentData?.userPaymentHistorys &&
                                    paymentData?.userPaymentHistorys
                                      .reduce(
                                        (ele, cur) => ele + cur.rechargePrice,
                                        0
                                      )
                                      .toLocaleString()
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Label>잔액</Label>
                                <Input
                                  disabled
                                  className="form-control disabled"
                                  defaultValue={
                                    paymentData?.userPaymentHistorys &&
                                    (
                                      paymentData?.userPaymentHistorys.reduce(
                                        (ele, cur) => ele + cur.rechargePrice,
                                        0
                                      ) -
                                      paymentData?.userPaymentHistorys.reduce(
                                        (ele, cur) => ele + cur.paymentPrice,
                                        0
                                      )
                                    ).toLocaleString()
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          
                          {/* 남은 크레딧 개수 */}
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <Label>남은 카카오 공유톡</Label>
                                <Input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  name="kakaoCoinInfo"
                                  value={userData?.kakaoRegularCoin + userData?.kakaoCreditCoin}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <Label>남은 이메일 공유톡</Label>
                                <Input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  name="emailCoinInfo"
                                  value={userData?.emailRegularCoin + userData?.emailCreditCoin}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <Label>남은 세금계산서 공유톡</Label>
                                <Input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  name="taxInvoiceCoinInfo"
                                  value={userData?.regularTaxInvoiceCoin + userData?.creditTaxInvoiceCoin}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <Label>페이앱 계정</Label>
                                {/* <Button
                                  type="button"
                                  size="sm"
                                  style={{
                                    marginLeft: '6px',
                                    visibility:
                                      payAppData.payAppUserId === ''
                                        ? 'hidden'
                                        : 'visible'
                                  }}
                                  color="danger"
                                  onClick={() => setDeleteModal(true)}
                                  onClick={onRemovePayAppClick}
                                >
                                  Remove
                                </Button> */}
                                <Input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  name="payAppUserId"
                                  value={
                                    payAppData.payAppUserId === ''
                                      ? '없음'
                                      : payAppData.payAppUserId
                                  }
                                  onChange={handlePayApp}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <Label>페이앱 비밀번호</Label>
                                <Input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  name="userpwd"
                                  value={
                                    payAppData.userpwd === ''
                                      ? '없음'
                                      : payAppData.userpwd
                                  }
                                  onChange={handlePayApp}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row></Row>
                          <Row>
                            <Col lg="12">
                              <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                                  disabled={!isMember}
                                >
                                  수정하기
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>
                      <TabPane tabId="2">
                        <UserDetailWorkDocuments />
                      </TabPane>
                      <TabPane tabId="3">
                        <UserDetailBusinessmans />
                      </TabPane>
                      <TabPane tabId="4">
                        <UserDetailSettlementStatements
                          userEmail={userData.email}
                        />
                      </TabPane>
                      <TabPane tabId="5">
                        <UserCredit
                          formData={formData}
                          handlePaymentInputs={handlePaymentInputs}
                          handlePrice={handlePrice}
                          createPayment={createPayment}
                          paymentData={paymentData}
                          onSaveFormData={onSaveFormData}
                          updatePayment={updatePayment}
                          deletePayment={deletePayment}
                          isMember={isMember}
                          userCompany={userCompany}
                          onChangeCompany={onChangeCompany}
                        />
                      </TabPane>
                      <TabPane tabId="6">
                        <UserLogin loginData={userData.userLoginInfo} />
                      </TabPane>
                      <TabPane tabId="7">
                        <UserDetailSbaAlternativeCreditEvaluation
                          username={userData.username}
                          userEmail={userData.email}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  userDetail: state.appState.data.userDetail,
  userPayApp: state.appState.data.userPayApp,
  paymentHistory: state.appState.data.paymentHistory,
  profile: state.appState.data.profile,
  workOrderDetail: state.appState.data.workOrderDetail,
  ratePlanList: state.appState.data.ratePlanList,
  ratePlanPlans: state.appState.data.ratePlanPlans,
  errorInfo: state.appState.data.errorInfo
})

const mapDispatchToProps = dispatch => ({
  getRatePlanList: () => dispatch(getRatePlanList()),
  getUserDetail: id => dispatch(getUserDetail(id)),
  getUserPayAppDetail: id => dispatch(getUserPayAppDetail(id)),
  saveAppData: (key, value) => dispatch(saveAppData(key, value)),
  updateUser: (id, updateInfo) => dispatch(updateUser(id, updateInfo)),
  setAdmin: id => dispatch(setAdmin(id)),
  toggleTestUser: id => dispatch(toggleTestUser(id)),
  setNickname: id => dispatch(setNickname(id)),
  setProfileImage: (targetId, image) =>
    dispatch(setProfileImage(targetId, image)),
  getCertification: phone => dispatch(getCertification(phone)),
  getUserPaymentHistory: (id, options) =>
    dispatch(getUserPaymentHistory(id, options)),
  setUserPayment: (id, paymentData) =>
    dispatch(setUserPayment(id, paymentData)),
  updateUserPayment: (id, paymentId) =>
    dispatch(updateUserPayment(id, paymentId)),
  removeUserPayment: (id, paymentIds) =>
    dispatch(removeUserPayment(id, paymentIds)),
  removeRatePlanUser: userId => dispatch(removeRatePlanUser(userId)),
  setRatePlanUser: (userId, ratePlanId, date) => dispatch(setRatePlanUser(userId, ratePlanId, date)),
  getWorkOrderDetail: (id, isPrint) => dispatch(getWorkOrderDetail(id, isPrint)),
  setUserPassword: (id, password) => dispatch(setUserPassword(id, password)),
  setUserKakaoCreditReward: (id, coin) => dispatch(setUserKakaoCreditReward(id, coin)),
  setUserEmailCreditReward: (id, coin) => dispatch(setUserEmailCreditReward(id, coin)),
  setUserTaxInvoiceCreditReward: (id, coin) => dispatch(setUserTaxInvoiceCreditReward(id, coin)),
  getRatePlanPlans: () => dispatch(getRatePlanPlans()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)

const ImageChangeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    width: 96px;
    display: flex;
    justify-content: center;
  }
  label {
    margin-bottom: 0;
    width: 30px;
    height: 30px;
    /* background-image: url(${CameraImage});
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input {
    display: none;
  }
`

const PDFWrapper = styled.div`
  display: flex;
`
