export let API_URL = "";

/** 옛날놈들 쓰는듯. axios module 다이렉트로 쓰는애들의 라우터만 관리 중 */
/** server url path */
if (process.env.NODE_ENV !== 'production') {
  // API_URL = 'https://api-staging.paywork.io'
  API_URL = 'https://api-backoffice.paywork.io'
} else {
  // API_URL = 'https://api-staging.paywork.io'
  API_URL = 'https://api-backoffice.paywork.io'
}

// API_URL = "http://0.0.0.0:4483";
// API_URL = "http://ec2-3-39-195-69.ap-northeast-2.compute.amazonaws.com:4483"
// API_URL = "https://api-backoffice.paywork.io";

/** base url 선언 */
export const backofficeBaseUrl = API_URL;

/** 일부 base url 선언 */
export const ratePlanUrl = `${backofficeBaseUrl}/user/ratePlan`;
const authBaseUrl = `${backofficeBaseUrl}/auth`;
const feedUrl = `${backofficeBaseUrl}/feed`;
const eventBaseUrl = 'https://api-event.paywork.io';
const dashboardUrl = `${backofficeBaseUrl}/dashboard`;

export const backoffice = {
  // auth
  AUTH_LOGIN: `${backofficeBaseUrl}/auth/login/local`,

  //dashboard
  DASHBOARD: `${dashboardUrl}`,
  GET_GRAPH_DASHBOARD: `${dashboardUrl}/workOrderForQuantity`,
  GET_USER_LOGIN_DASHBOARD: `${dashboardUrl}/userLoginInfo`,
  GET_WORK_ORDER_QUANTITY_SHARE: `${dashboardUrl}/workOrderForQuantityShare`,
  GET_PLATFORM_LOGIN_DASHBOARD: `${dashboardUrl}/userLoginInfoCountForPlatform`,
  GET_BUSINESSMAN_PERCENT_DASHBOARD: `${backofficeBaseUrl}/user/businessmanPercent`,
  USER_RETENTION: `${backofficeBaseUrl}/user/retention`,
  GET_CLIENT_IS_BUSINESS_MAN: `${backofficeBaseUrl}/workOrderForQuantity/dashboard/realAccount/clientType`,
  GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN: `${backofficeBaseUrl}/tradingStatement/dashboard/realAccount/clientType`,
  PAYMENT_PERCENT: `${backofficeBaseUrl}/dashboard/card/signupAndRatePlanCount`,
  NEW_MEMBER_TO_PAY: `${backofficeBaseUrl}/dashboard/newMember2Pay`,
  NEW_MEMBER_TO_TRIAL: `${backofficeBaseUrl}/dashboard/newMember2Trial`,
  TRIAL_TO_PAY: `${backofficeBaseUrl}/dashboard/trial2Pay`,
  AVG_WORK_DOCUMENT: `${backofficeBaseUrl}/dashboard/workDocumentCountAvg`,
  USER_AVG_WORK_DOCUMENT: `${backofficeBaseUrl}/dashboard/userWorkDocumentCountAvg`,
  CREATE_USER_WORK_DOCUMENT: `${backofficeBaseUrl}/dashboard/workDocumentCreatedUserCount`,
  DOCUMENT_SHARE: `${backofficeBaseUrl}/dashboard/documentShareCount`,
  DOCUMENT_CREATE: `${backofficeBaseUrl}/dashboard/documentCount`,
  WEEK_ACTIVE_USER: `${backofficeBaseUrl}/dashboard/weekActiveUser`,
  USER_RATIO: `${dashboardUrl}/userOverallStatus`,
  TAX_INVOICE_RATIO_DASHBOARD: `${backofficeBaseUrl}/workDocument/taxInvoiceRatio`,
  FREE_USER_ACTIVE_RATIO: `${dashboardUrl}/weekActiveUser/card`,
  TOTAL_SALES: `${backofficeBaseUrl}/dashboard/totalSales`,
  SALES: `${backofficeBaseUrl}/dashboard/sales`,
  PAYMENT_SALES: `${backofficeBaseUrl}/dashboard/payment`,
  RATE_PLAN_REVENUE: `${dashboardUrl}/ratePlanRevenue`,
  PAYMENT_METHOD_RATIO: `${backofficeBaseUrl}/workDocument/methodOfPaymentRatio`,
  ACTIVE_USER_WORK_DOCUMENT: `${backofficeBaseUrl}/dashboard/activeUserWorkDocumentCountAvg`,
  GET_ACC_GRAPH_DASHBOARD: `${dashboardUrl}/workDocumentCumulativeCount`,
  GET_ACC_USER_GRAPH_DASHBOARD: `${dashboardUrl}/userSignupCumulativeCount`,
  GET_ACTIVE_USER: `${dashboardUrl}/activeUser`,
  GET_LOGIN_USER: `${dashboardUrl}/loginUser`,
  GET_RETENTION: `${dashboardUrl}/retention`,
  SET_USER_PASSWORD: `${backofficeBaseUrl}/user/password`,
  SET_USER_KAKAOCREDITREWARD: `${backofficeBaseUrl}/user/kakaoCreditCoin`,
  SET_USER_EMAILREWARD: `${backofficeBaseUrl}/user/emailCreditCoin`,
  SET_USER_TAXINVOICEREWARD: `${backofficeBaseUrl}/user/taxInvoiceCreditCoin`,
  GET_DASHBOARD_RATEPLANSUBSCRIPTIONSTATUS: `${dashboardUrl}/ratePlanSubscriptionStatus`,

  //document
  GET_WORK_DOCUMENT: `${backofficeBaseUrl}/workDocument`,

  //cashReceipt
  GET_CASHRECEIPT: `${backofficeBaseUrl}/cashReceipt`,

  //taxinvoice
  GET_TAXINVOICE: `${backofficeBaseUrl}/taxInvoice`,

  //simple project
  SIMPLE_PROJECT_LIST: `${backofficeBaseUrl}/simpleProject`,
  SIMPLE_PROJECT_DETAIL: `${backofficeBaseUrl}/simpleProject`,

  //admin
  ADMIN_LIST: `${backofficeBaseUrl}/user/admin`,
  SET_ADMIN: `${backofficeBaseUrl}/user/admin`,
  SET_CERTIFICATION: `${backofficeBaseUrl}/verify`,

  // tester
  TESTER_LIST: `${backofficeBaseUrl}/user/testAccount`,

  //user
  USER_LIST: `${backofficeBaseUrl}/user`,
  CREATE_USER: `${backofficeBaseUrl}/user`,
  GET_USER_DETAIL: `${backofficeBaseUrl}/user`,
  GET_USER_PAY_APP_DETAIL: `${backofficeBaseUrl}/user/payapp`,
  UPDATE_USER: `${backofficeBaseUrl}/user`,
  TOGGLE_USER: `${backofficeBaseUrl}/user/enabled`,
  COMPLETE_REMOVE_USER: `${backofficeBaseUrl}/user`,
  SET_NICKNAME: `${backofficeBaseUrl}/feed/user/nickname`,
  SET_PROFILE_IMAGE: `${backofficeBaseUrl}/user/setUserInfo/profileImage`,
  REVISIT_DAY_USER_LIST: `${backofficeBaseUrl}/user/revisit/day`,
  REVISIT_MONTH_USER_LIST: `${backofficeBaseUrl}/user/revisit/month`,
  RATE_PLAN_USER: `${backofficeBaseUrl}/user/ratePlan`,

  //test user
  TEST_USER_LIST: `${backofficeBaseUrl}/user/testAccount`,
  TOGGLE_TEST_USER: `${backofficeBaseUrl}/user/testAccount`,

  WORK_DOCUMENT: `${backofficeBaseUrl}/workDocument`,
  WORK_DOCUMENT_DETAIL: `${backofficeBaseUrl}/workDocument`,

  // workOrderForQuantity
  WORK_ORDER_LIST: `${backofficeBaseUrl}/workOrderForQuantity/project`,
  WORK_ORDER_LIST_REAL_ACCOUNT: `${backofficeBaseUrl}/workOrderForQuantity/project/realAccount`,
  WORK_ORDER_DETAIL: `${backofficeBaseUrl}/workOrderForQuantity/project`,
  WORK_ORDER_CIENT_IS_BUSINESSMAN: `${backofficeBaseUrl}/workOrderForQuantity/project/clientIsBusinessman`,

  // WORK_DOCUMENT_HTML: `${API_URL}/workDocument/preHTML`,
  WORK_DOCUMENT_HTML: `${API_URL}/workDocument/pdf`,

  // tradingStatement
  TRADING_STATEMENT: `${backofficeBaseUrl}/tradingStatement/project`,

  // receipt
  RECEIPT: `${backofficeBaseUrl}/receipt/project`,

  // bill
  BILL: `${backofficeBaseUrl}/bill/project`,

  // orderSheet
  ORDERSHEET_DETAIL: `${backofficeBaseUrl}/orderSheet/project`,

  // cashReceipt
  CASHRECEIPT: `${backofficeBaseUrl}/cashReceipt`,
  CASHRECEIPT_VIEW: `${backofficeBaseUrl}/cashReceipt/view`,

  // TAXINVOICE
  TAXINVOICE: `${backofficeBaseUrl}/taxInvoice`,
  TAXINVOICE_VIEW: `${backofficeBaseUrl}/taxInvoice/view`,

  //feed

  // payment
  GET_USER_PAYMENT_HISTORY: `${backofficeBaseUrl}/user/userPaymentHistory`,
  SET_USER_PAYMENT: `${backofficeBaseUrl}/user/userPaymentHistory`,
  UPDATE_USER_PAYMENT: `${backofficeBaseUrl}/user/userPaymentHistory`,
  DOCUMENT_PAYMENT: `${backofficeBaseUrl}/documentPayment`,

  // event
  GET_ROULETTE_LIST: `${eventBaseUrl}/backoffice/roulette`,
  GET_NEW_ROULETTE_LIST: `${eventBaseUrl}/backoffice/roulette/V2`,
  GET_ROULETTE_PARTICIPATION_LIST: `${eventBaseUrl}/backoffice/roulette/raffle/forPriorityOfParticipation`,
  GET_ROULETTE_SIGNUP_LIST: `${eventBaseUrl}/backoffice/roulette/raffle/forSignupAt`,
  GET_ROULETTE_WORK_ORDER_QT_CREATE_LIST: `${eventBaseUrl}/backoffice/roulette/raffle/forWorkOrderForQuantityCreatedAt`,
  GET_ROULETTE_ITEM_LIST: `${eventBaseUrl}/backoffice/roulette/item`,
  CREATE_ROULETTE_ITEM: `${eventBaseUrl}/backoffice/roulette/item`,

  // adminCategory
  ADMIN_CATEGORY: `${backofficeBaseUrl}/user/adminCategory`,

  // server-swagger
  // SWAGGER_LIST: `${oldBackofficeBaseUrl}/server/swagger`,

  // work-document
  BUSINESSMAN_WORK_DOCUMENT: `${backofficeBaseUrl}/user/boClientIsBusinessman`,
  CLIENT_TYPE_WORK_DOCUMENT: `${backofficeBaseUrl}/user/boClientType`,
}

export const auth = {
  LOGIN: `${authBaseUrl}/login/local`,
  SUBMIT_PASSWORD_CHANGE: `${authBaseUrl}/passwordChange`,
  SUBMNT_PASSWORD_CHANGE_VERIFY: `${authBaseUrl}/passwordChange/verify`,
  CHANGE_PASSWORD: `${authBaseUrl}/passwordChange/change`,
  GET_PROFILE: `${authBaseUrl}`
}

export const feed = {
  GET_FEED_LIST: `${backofficeBaseUrl}/feed/board`,
  REMOVE_CATEGORY: `${backofficeBaseUrl}/feed/category`,
  RANDOM_NICKNAME: `${backofficeBaseUrl}/feed/user/nickname`,
  CREATE_FEED_CATEGORY: `${backofficeBaseUrl}/feed/category`,
  REMOVE_FEED: `${feedUrl}/board`,
  // GET_FEED_LIST: `${backofficeBaseUrl}/feed/board`,
  IMAGE: `${feedUrl}/image`,
  CREATE_FEED: `${feedUrl}/board/category`,
  GET_FEED_CATEGORY_LIST: `${feedUrl}/category`,
  // GET_FEED_DETAIL: `${feedUrl}/board`,
  GET_FEED_DETAIL: `${backofficeBaseUrl}/feed/board`,
  DECLARE_FEED_LIST: `${backofficeBaseUrl}/feed//user/declaration`,
  SET_FEED_COMMENT: `${feedUrl}/board/comment`,
  REMOVE_FEED_COMMENT: `${feedUrl}/board/comment`,
  SET_FEED_REACTION: `${backofficeBaseUrl}/feed/board/reaction`
}
