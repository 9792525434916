import React from 'react'
import styled from 'styled-components'

import UserNewbieDashboardGraph from './Graph'
import UserNewbieDashboardSummary from './Summary'

export default function UserNewbieDashboard() {
  return (
    <Wrapper>
      <h2>신규 회원</h2>
      <UserNewbieDashboardSummary />
      
      {/* 20240307 대표님 IR 발표 요청으로 인해 미노출 처리 */}
      {/* <UserNewbieDashboardGraph /> */}
    </Wrapper>
  )
}

const Wrapper = styled.div``