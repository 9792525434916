import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import moment from 'moment'
import 'moment/locale/ko'
import { withRouter } from 'react-router-dom'
import * as INFO from '../../global/projectInfo'
import { workOrderProjectState } from '../../global/formatter'
import { getTradingStatementDetail } from '../../reducers/tradingStatement'

const ReceiptList = ({
  history,
  project,
  receiptPage,
  receiptSearch,
  handleChangeBusiness
}) => {
  // 고객 유형
  const [selectedGroup, setSelectedGroup] = useState(
    INFO.CLIENT_TYPE[project.boClientType]
  )

  const userOption = [
    { label: '개인', value: 'Individual' },
    { label: '사업자', value: 'Businessman' },
    { label: '공기관', value: 'PublicInstitutions' },
    { label: '협단체', value: 'Association' }
  ]

  const onChangeValue = e => {
    setSelectedGroup(INFO.CLIENT_TYPE[e.value])
    handleChangeBusiness(e, project.creatorUserId)
  }

  useEffect(() => {
    setSelectedGroup(INFO.CLIENT_TYPE[project.boClientType])
  }, [project.id])

  return (
    <>
      <td
        onClick={() => {
          history.push({
            pathname: `/receipt-detail/${project.id}`,
            state: {
              pageInfo: receiptPage,
              searchInfo: receiptSearch,
              prevInfo: 'receipt'
            }
          })
        }}
        style={{
          color: '#556EE6',
          cursor: 'pointer'
        }}
      >
        {project.createdAt &&
          moment(project.createdAt).format('YYYY-MM-DD-HH:mm')}
      </td>
      <td>{workOrderProjectState(project.projectState)}</td>
      <td>{project.creatorEmail}</td>
      <td>{project.businessman ? '사업자' : '개인'}</td>
      <td>
        <Select
          onChange={onChangeValue}
          value={selectedGroup}
          options={userOption}
          classNamePrefix="select2-selection"
        />
      </td>
      <td
        onClick={() => {
          history.push({
            pathname: `/user-detail/${project.creatorUserId}`,
            state: {
              pageInfo: receiptPage,
              searchInfo: receiptSearch,
              prevInfo: 'receipt'
            }
          })
        }}
        style={{
          color: '#556EE6',
          cursor: 'pointer'
        }}
      >
        {project.creatorName}
      </td>
      <td>{project.clientName}</td>
      <td>{`${project?.appliedTotalPrice?.toLocaleString() ?? '0'}원`}</td>
      <td>{project.projectName}</td>
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data
})
const mapDispatchToProps = dispatch => ({
  // getTradingStatementDetail: id => dispatch(getTradingStatementDetail(id))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReceiptList)
)
