export const SET_APP_STATE = 'reducers/appState/SET_APP_STATE'
export const SAVE_APP_DATA = 'reducers/appState/SAVE_APP_DATA'
export const INITIALIZE = 'reducers/appState/INITIALIZE'

const INIT_STATE = {
  state: 'INITIALIZE',
  params: undefined,
  data: {
    isLoading: false
  }
}

export const setAppState = (state, params, isLoading) => ({
  type: SET_APP_STATE,
  state,
  params,
  isLoading
})

export const saveAppData = (key, data) => ({
  type: SAVE_APP_DATA,
  key,
  data
})

export const initialize = () => ({
  type: INITIALIZE
})

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // props save
    case SET_APP_STATE:
      return Object.assign({}, state, {
        ...state,
        state: action.state,
        params: action.params,
        data: { ...state.data, isLoading: action.isLoading }
      })

    case SAVE_APP_DATA:
      return Object.assign({}, state, {
        ...state,
        data: {
          ...state.data,
          [action.key]: action.data
        }
      })
    case INITIALIZE:
      return Object.assign({}, INIT_STATE)

    default:
      return state
  }
}

export default reducer
