import React from 'react'
import styled from 'styled-components'
import UserPatternBusinessRateDashboard from './BusinessRate'
import UserPatternEstimateClientTypeDashboard from './EstimateClientType'
import UserPatternTradingStatementClientTypeDashboard from './TradingStatementClientType'

export default function UserPatternDashboard() {
  return (
    <Wrapper>
      <h2>사용자 유형 분석</h2>
      <ChartWrapper>
        <UserPatternBusinessRateDashboard />
        <UserPatternEstimateClientTypeDashboard />
        <UserPatternTradingStatementClientTypeDashboard />
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`
