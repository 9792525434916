import React, { Fragment, useEffect, useState, useCallback } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import 'moment/locale/ko'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Link, withRouter, useLocation } from 'react-router-dom'
import PaginatorProject from '../../components/Common/PaginatorProject'

// redux
import { getFeedList, removeFeed } from '../../reducers/feed'
import {
  getRouletteList,
  getNewRouletteList,
  getAllRouletteList,
  getRouletteParticipationList,
  getRouletteDetail
} from '../../reducers/event'
import { options } from 'toastr'

const Csv_Header = [
  { label: '참가일', key: 'createdAt' },
  { label: '상품명', key: 'notationName' },
  { label: '이름', key: 'username' },
  { label: '전화번호', key: 'phone' },
  { label: '이메일', key: 'email' },
  { label: '견적서 작성 횟수', key: 'workOrderForQuantityCount' },
  { label: '룰렛 참여 횟수', key: 'raffleCount' }
]

const Csv_Header_All = [
  { label: '참가일', key: 'createdAt' },
  { label: '상품명', key: 'notationName' },
  { label: '이름', key: 'username' },
  { label: '전화번호', key: 'phone' },
  { label: '이메일', key: 'email' },
  { label: '견적서 작성 횟수', key: 'workOrderForQuantityCount' },
  { label: '룰렛 참여 횟수', key: 'raffleCount' },
  { label: '가입일', key: 'signupAt' }
]

const Csv_Header_Participation = [
  { label: '참가일', key: 'createdAt' },
  { label: '이름', key: 'username' },
  { label: '전화번호', key: 'phone' },
  { label: '이메일', key: 'email' },
  { label: '룰렛 참여 횟수', key: 'raffleCount' },
  { label: '가입일', key: 'signupAt' }
]

const Csv_Header_Signup = [
  { label: '참가일', key: 'createdAt' },
  { label: '이름', key: 'username' },
  { label: '전화번호', key: 'phone' },
  { label: '이메일', key: 'email' },
  { label: '가입일', key: 'signupAt' }
]

export const EventList = ({
  history,
  state,
  params,
  feedList,
  getFeedList,
  removeFeed,
  getRouletteList,
  getAllRouletteList,
  rouletteList,
  rouletteAllList,
  getNewRouletteList,
  newRouletteList,
  getRouletteParticipationList,
  rouletteParticipationList,
  getRouletteDetail,
  rouletteDetail
}) => {
  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '피드 조회 중 문제가 발생했습니다.',
    color: 'danger'
  })

  const [selectedBoxes, setSelectedBoxes] = useState([])

  const [rouletteData, setRouletteData] = useState({ list: [], max: 0 })
  const [originData, setOriginData] = useState({ list: [], max: 0 })
  const [deleteModal, setDeleteModal] = useState(false)
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10,
    search: '',
    filtering: 'all'
  })
  const [csvData, setCsvData] = useState([])
  const [isDrop, setIsDrop] = useState(null)
  const [isDropIndex, setIsDropIndex] = useState(null)
  const [detailData, setDetailData] = useState([])

  // pagination
  const location = useLocation()
  const userPage = location.state?.userPage ? location.state.userPage : 1
  const userLastPage = location.state?.userLastPage
    ? location.state.userLastPage
    : 1
  const userSearch = location.state?.userSearch ? location.state.userSearch : ''
  const userCheck = location.state?.userCheck ? true : false
  let userFiltering = location.state?.userFiltering
    ? location.state.userFiltering
    : ''
  const [start, setStart] = useState(1)
  const [end, setEnd] = useState(11)
  const [isFilter, setIsFilter] = useState(false)
  const [filterTitle, setFilterTitle] = useState('룰렛 참여자')

  const [searchKeyword, setSearchKeyword] = useState('')

  useEffect(() => {
    // 상세 페이지에서 이동

    if (userCheck) {
      setSearchKeyword(userSearch)

      setFetchOptions({
        ...fetchOptions,
        page: userPage,
        amount: fetchOptions.amount,
        search: userSearch,
        filtering: userFiltering
      })

      if (userPage > 10) {
        setStart(Math.floor(userPage / 10) * 10 + 1)
        setEnd(
          Math.floor(userPage / 10) * 10 + 10 <= userLastPage
            ? Math.floor(userPage / 10) * 10 + 11
            : userLastPage + 1
        )
      }
    } else {
      getNewRouletteList(fetchOptions)
    }

    // getAllRouletteList()
  }, [])

  useEffect(() => {
    // console.log('$$ effect', state)

    switch (state) {
      case 'SUCCESS_GET_ROULETTE_LIST':
        // setRouletteData({
        //   list: rouletteList.rouletteRaffes,
        //   max: rouletteList.count
        // })

        // getAllRouletteList()

        break
      case 'SUCCESS_GET_ALL_ROULETTE_LIST':
        let csvFiltering = []

        // 참여자 리스트업(참가일, 이메일, 전화번호, 횟수, 사용자명)
        // 가입일 리스트업(참가일, 이메일, 전화번호, 사용자명)

        if (fetchOptions.filtering === 'all') {
          for (let i = 0; i < rouletteAllList.rouletteRaffles.length; i++) {
            csvFiltering.push({
              createdAt: moment(
                rouletteAllList.rouletteRaffles[i].createdAt
              ).format('YYYY-MM-DD-HH:mm'),
              notationName: rouletteAllList.rouletteRaffles[i].rouletteItem
                ? rouletteAllList.rouletteRaffles[i].rouletteItem?.notationName
                : '꽝',
              username: rouletteAllList.rouletteRaffles[i].username
                ? rouletteAllList.rouletteRaffles[i].username
                : '',
              phone: rouletteAllList.rouletteRaffles[i].phone
                ? rouletteAllList.rouletteRaffles[i].phone
                : '',
              email: rouletteAllList.rouletteRaffles[i].email,
              workOrderForQuantityCount:
                rouletteAllList.rouletteRaffles[i].workOrderForQuantityCount,
              raffleCount: rouletteAllList.rouletteRaffles[i].raffleCount,
              signupAt: moment(
                rouletteAllList.rouletteRaffles[i].signupAt
              ).format('YYYY-MM-DD-HH:mm')
            })
          }
        } else if (fetchOptions.filtering === 'participation') {
          for (let i = 0; i < rouletteAllList.rouletteRaffles.length; i++) {
            csvFiltering.push({
              createdAt: moment(
                rouletteAllList.rouletteRaffles[i].createdAt
              ).format('YYYY-MM-DD-HH:mm'),
              username: rouletteAllList.rouletteRaffles[i].username
                ? rouletteAllList.rouletteRaffles[i].username
                : '',
              phone: rouletteAllList.rouletteRaffles[i].phone
                ? rouletteAllList.rouletteRaffles[i].phone
                : '',
              email: rouletteAllList.rouletteRaffles[i].email,
              raffleCount: rouletteAllList.rouletteRaffles[i].raffleCount,
              signupAt: moment(
                rouletteAllList.rouletteRaffles[i].signupAt
              ).format('YYYY-MM-DD-HH:mm')
            })
          }
        } else if (
          fetchOptions.filtering === 'signup' ||
          fetchOptions.filtering === 'create'
        ) {
          for (let i = 0; i < rouletteAllList.rouletteRaffles.length; i++) {
            csvFiltering.push({
              createdAt: moment(
                rouletteAllList.rouletteRaffles[i].createdAt
              ).format('YYYY-MM-DD-HH:mm'),
              username: rouletteAllList.rouletteRaffles[i].username
                ? rouletteAllList.rouletteRaffles[i].username
                : '',
              phone: rouletteAllList.rouletteRaffles[i].phone
                ? rouletteAllList.rouletteRaffles[i].phone
                : '',
              email: rouletteAllList.rouletteRaffles[i].email,
              signupAt: moment(
                rouletteAllList.rouletteRaffles[i].signupAt
              ).format('YYYY-MM-DD-HH:mm')
            })
          }
        }

        setCsvData(csvFiltering)
        break
      case 'FAILED_GET_ROULETTE_LIST':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        break

      case 'SUCCESS_GET_NEW_ROULETTE_LIST':
        setRouletteData({
          list: newRouletteList.rouletteRaffles,
          max: newRouletteList.count
        })

        getAllRouletteList(fetchOptions)

        break

      case 'SUCCESS_GET_ROULETTE_PARTICIPATION_LIST':
        setRouletteData({
          list: newRouletteList.rouletteRaffles,
          max: newRouletteList.count
        })

        break

      case 'SUCCESS_GET_ROULETTE_DETAIL':
        setDetailData(rouletteDetail)
        setIsDrop(isDropIndex)
        break

      default:
        break
    }
  }, [state])

  useEffect(() => {
    // console.log('$$ fetch', fetchOptions)

    getNewRouletteList(fetchOptions)

    // if (userFiltering) {
    //   if (userFiltering === 'participation') {
    //     getRouletteParticipationList(fetchOptions)
    //   } else {
    //     getNewRouletteList(fetchOptions)
    //   }
    // } else {
    //   if (fetchOptions.filtering === 'participation') {
    //     getRouletteParticipationList(fetchOptions)
    //   } else {
    //     getNewRouletteList(fetchOptions)
    //   }
    // }

    // if (fetchOptions.filtering === 'participation') {
    //   getRouletteParticipationList(fetchOptions)
    // } else {
    //   getNewRouletteList(fetchOptions)
    // }
  }, [fetchOptions])

  const handlePagination = (page, pageSize) => {
    setIsDrop(null)

    if (fetchOptions.page !== page) {
      return setFetchOptions({
        ...fetchOptions,
        page
      })
    }

    return
  }

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    setIsDrop(null)
    setSearchKeyword(inputValue)

    userFiltering = ''

    debounceInput(inputValue, fetchOptions)
  }

  const debounceInput = useCallback(
    debounce((input, fetchOptions) => {
      setStart(1)
      setEnd(11)

      setFetchOptions({ ...fetchOptions, search: input, page: 1 })
    }, 750),
    []
  )

  const handleDelete = () => {
    setDeleteModal(false)
    removeFeed(selectedBoxes)
  }

  const handlerFilter = input => {
    if (input === 0) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '',
        filtering: 'all'
      })
      setFilterTitle('룰렛 참여자')
      setSearchKeyword('')
      setIsDrop(null)
    }

    if (input === 3) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '치킨',
        filtering: 'all'
      })
      setFilterTitle('룰렛 치킨 당첨자')
      setSearchKeyword('치킨')
      setIsDrop(null)
    }
    if (input === 4) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '스타',
        filtering: 'all'
      })
      setFilterTitle('룰렛 스타벅스 당첨자')
      setSearchKeyword('스타벅스')
      setIsDrop(null)
    }
    if (input === 5) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '바나',
        filtering: 'all'
      })
      setFilterTitle('룰렛 바나나우유 당첨자')
      setSearchKeyword('바나나우유')
      setIsDrop(null)
    }
    if (input === 6) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '츄파',
        filtering: 'all'
      })
      setFilterTitle('룰렛 츄파춥스 당첨자')
      setSearchKeyword('츄파춥스')
      setIsDrop(null)
    }
    if (input === 7) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '엑스',
        filtering: 'all'
      })
      setFilterTitle('룰렛 엑스크루 당첨자')
      setSearchKeyword('엑스크루')
      setIsDrop(null)
    }

    if (input === 8) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '',
        filtering: 'participation'
      })
      setFilterTitle('룰렛 참여자(참여 횟수)')
      setSearchKeyword('')
      setIsDrop(null)
    }

    if (input === 9) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '',
        filtering: 'signup'
      })
      setFilterTitle('룰렛 참여자(가입일)')
      setSearchKeyword('')
      setIsDrop(null)
    }
    if (input === 10) {
      setFetchOptions({
        ...fetchOptions,
        page: 1,
        search: '',
        filtering: 'create'
      })
      setFilterTitle('룰렛 참여자(견적서 발행일)')
      setSearchKeyword('')
      setIsDrop(null)
    }
  }

  const handlerRouletteDetail = id => {
    getRouletteDetail(id)
  }

  // console.log('$$ detail', detailData)

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`${filterTitle} ${rouletteData.max && rouletteData.max}명`}
            breadcrumbItem="Event List"
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            // value={fetchOptions.search}
                            value={searchKeyword}
                            onChange={handleSearch}
                            placeholder="상품/이름/전화번호/메일"
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>

                    <Col sm="8">
                      <div
                        className="text-sm-right"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        {Array.isArray(csvData) && csvData.length > 0 && (
                          <>
                            <CSVLink
                              data={csvData}
                              // headers={Csv_Header}
                              headers={
                                fetchOptions.filtering === 'all'
                                  ? Csv_Header_All
                                  : fetchOptions.filtering === 'participation'
                                  ? Csv_Header_Participation
                                  : Csv_Header_Signup
                              }
                            >
                              <Button
                                type="button"
                                color="light"
                                className="btn-rounded waves-effect waves-light mb-2 mr-2"
                              >
                                <i className="mdi mdi-microsoft-excel" />
                              </Button>
                            </CSVLink>

                            <Dropdown
                              isOpen={isFilter}
                              toggle={() => setIsFilter(!isFilter)}
                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-light"
                                style={{ marginRight: '10px' }}
                              >
                                <i className="mdi mdi-filter mr-1" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                <DropdownItem onClick={() => handlerFilter(0)}>
                                  전체
                                </DropdownItem>
                                <DropdownItem onClick={() => handlerFilter(3)}>
                                  치킨
                                </DropdownItem>
                                <DropdownItem onClick={() => handlerFilter(4)}>
                                  스타벅스
                                </DropdownItem>
                                <DropdownItem onClick={() => handlerFilter(5)}>
                                  바나나우유
                                </DropdownItem>
                                <DropdownItem onClick={() => handlerFilter(6)}>
                                  츄파춥스
                                </DropdownItem>
                                <DropdownItem onClick={() => handlerFilter(7)}>
                                  엑스크루
                                </DropdownItem>
                                <DropdownItem onClick={() => handlerFilter(8)}>
                                  이벤트 참여 횟수
                                </DropdownItem>
                                <DropdownItem onClick={() => handlerFilter(9)}>
                                  가입일
                                </DropdownItem>
                                <DropdownItem onClick={() => handlerFilter(10)}>
                                  견적서 발행일
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Modal
                    centered
                    scrollable
                    isOpen={deleteModal}
                    toggle={() => setDeleteModal(!deleteModal)}
                  >
                    <ModalHeader
                      toggle={() => setDeleteModal(!deleteModal)}
                      style={{
                        borderBottom: '2px solid #2a3042'
                      }}
                    >
                      <div>피드 삭제</div>
                    </ModalHeader>
                    <ModalBody
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        height: '150px',
                        fontSize: '18px'
                      }}
                    >{`${selectedBoxes.length}개의 피드를 삭제하시겠습니까?`}</ModalBody>
                    <ModalFooter
                      onClick={handleDelete}
                      style={{
                        cursor: 'pointer',
                        justifyContent: 'center',
                        borderTop: '2px solid #e2e2e2',
                        fontWeight: 'bold',
                        fontSize: '16px'
                      }}
                    >
                      삭제하기
                    </ModalFooter>
                  </Modal>

                  <Alert
                    color={status.color}
                    isOpen={status.isError}
                    toggle={() =>
                      setStatus(prevState => ({
                        ...prevState,
                        isError: false
                      }))
                    }
                  >
                    {status.errorMsg}
                  </Alert>

                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '5%' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length ===
                                    rouletteData.list.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(rouletteData.list) &&
                                      setSelectedBoxes(
                                        rouletteData.list.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          {fetchOptions.filtering === 'all' && (
                            <>
                              <th scope="col" style={{ width: '15%' }}>
                                참가일
                              </th>
                              <th scope="col" style={{ width: '10%' }}>
                                상품명
                              </th>
                              <th scope="col" style={{ width: '10%' }}>
                                이름
                              </th>
                              <th scope="col" style={{ width: '10%' }}>
                                전화번호
                              </th>
                              <th scope="col" style={{ width: '15%' }}>
                                이메일
                              </th>
                              <th scope="col" style={{ width: '10%' }}>
                                룰렛 참여 횟수
                              </th>
                              <th scope="col" style={{ width: '10%' }}>
                                견적서 작성 횟수
                              </th>
                              <th scope="col" style={{ width: '15%' }}>
                                가입일
                              </th>
                            </>
                          )}
                          {fetchOptions.filtering === 'participation' && (
                            <>
                              <th scope="col" style={{ width: '15%' }}>
                                참가일
                              </th>
                              <th scope="col" style={{ width: '15%' }}>
                                이름
                              </th>
                              <th scope="col" style={{ width: '15%' }}>
                                전화번호
                              </th>
                              <th scope="col" style={{ width: '20%' }}>
                                이메일
                              </th>
                              <th scope="col" style={{ width: '15%' }}>
                                룰렛 참여 횟수
                              </th>
                              <th scope="col" style={{ width: '15%' }}>
                                가입일
                              </th>
                            </>
                          )}

                          {fetchOptions.filtering === 'signup' && (
                            <>
                              <th scope="col" style={{ width: '15%' }}>
                                참가일
                              </th>
                              <th scope="col" style={{ width: '15%' }}>
                                이름
                              </th>
                              <th scope="col" style={{ width: '20%' }}>
                                전화번호
                              </th>
                              <th scope="col" style={{ width: '25%' }}>
                                이메일
                              </th>
                              <th scope="col" style={{ width: '20%' }}>
                                가입일
                              </th>
                            </>
                          )}

                          {fetchOptions.filtering === 'create' && (
                            <>
                              <th scope="col" style={{ width: '15%' }}>
                                참가일
                              </th>
                              <th scope="col" style={{ width: '15%' }}>
                                이름
                              </th>
                              <th scope="col" style={{ width: '20%' }}>
                                전화번호
                              </th>
                              <th scope="col" style={{ width: '25%' }}>
                                이메일
                              </th>
                              <th scope="col" style={{ width: '20%' }}>
                                가입일
                              </th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(rouletteData.list) &&
                          rouletteData.list.slice().map((item, idx) => (
                            <>
                              <tr
                                key={'_feed_' + idx}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (idx === isDrop) {
                                    setIsDrop(null)
                                  } else {
                                    handlerRouletteDetail(item.userId)
                                    setIsDropIndex(idx)
                                  }
                                }}
                              >
                                <td style={{ height: '65px' }}>
                                  <div
                                    className="custom-control custom-checkbox"
                                    style={{ zIndex: 0 }}
                                  >
                                    <Input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={item.id}
                                      checked={selectedBoxes.includes(item.id)}
                                      onChange={e =>
                                        e.target.checked
                                          ? setSelectedBoxes([
                                              ...selectedBoxes,
                                              item.id
                                            ])
                                          : setSelectedBoxes(
                                              selectedBoxes.filter(
                                                b => b !== item.id
                                              )
                                            )
                                      }
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor={item.id}
                                    >
                                      &nbsp;
                                    </Label>
                                  </div>
                                </td>
                                <td>
                                  {item.createdAt &&
                                    moment(item.createdAt).format(
                                      'YYYY-MM-DD-HH:mm'
                                    )}
                                </td>

                                {fetchOptions.filtering === 'all' && (
                                  <td>
                                    {item.rouletteItem
                                      ? item.rouletteItem.notationName
                                      : '꽝'}
                                  </td>
                                )}

                                <td
                                  onClick={() => {
                                    history.push({
                                      pathname: `/user-detail/${item.userId}`,
                                      state: {
                                        idInfo: item?.userId,
                                        pageInfo: fetchOptions.page,
                                        lastPageInfo: Math.ceil(
                                          rouletteData.max / 10
                                        ),
                                        searchInfo: fetchOptions.search,
                                        isUserDetail: true,
                                        filteringInfo: fetchOptions.filtering,
                                        prevPage: 'roulette'
                                      }
                                    })
                                  }}
                                  style={{
                                    color: '#556EE6',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {item.username ? item.username : ''}
                                </td>
                                <td>{item.phone ? item.phone : ''}</td>
                                <td>{item.email}</td>

                                {fetchOptions.filtering === 'all' && (
                                  <>
                                    <td>{item.raffleCount}</td>
                                    <td>{item.workOrderForQuantityCount}</td>
                                  </>
                                )}

                                {fetchOptions.filtering === 'participation' && (
                                  <>
                                    <td>{item.raffleCount}</td>
                                  </>
                                )}

                                <td>
                                  {item.signupAt &&
                                    moment(item.signupAt).format(
                                      'YYYY-MM-DD-HH:mm'
                                    )}
                                </td>
                              </tr>

                              {Array.isArray(rouletteDetail) &&
                                rouletteDetail.map((ele, index) => {
                                  return (
                                    <DropContainer isView={isDrop === idx}>
                                      <td></td>
                                      <td>
                                        {ele.createdAt &&
                                          moment(ele.createdAt).format(
                                            'YYYY-MM-DD-HH:mm'
                                          )}
                                      </td>
                                      <td>
                                        {ele.rouletteItem
                                          ? ele.rouletteItem.notationName
                                          : '꽝'}
                                      </td>

                                      <td>
                                        {/* {index === 0 && ele.username} */}
                                      </td>
                                      <td>
                                        {/* {index === 0 && ele.phone} */}
                                      </td>
                                      <td>
                                        {/* {index === 0 && ele.email} */}
                                      </td>
                                      <td>
                                        {/* {index === 0 && ele.raffleCount} */}
                                      </td>
                                      <td>
                                        {/* {index === 0 &&
                                          ele.workOrderForQuantityCount} */}
                                      </td>
                                      {fetchOptions.filtering === 'all' && (
                                        <td>
                                          {/* {index === 0 &&
                                            ele.signupAt &&
                                            moment(ele.signupAt).format(
                                              'YYYY-MM-DD-HH:mm'
                                            )} */}
                                        </td>
                                      )}
                                    </DropContainer>
                                  )
                                })}
                            </>
                          ))}
                      </tbody>
                    </Table>
                    <PaginatorProject
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(rouletteData.max / 10)}
                      onChange={handlePagination}
                      start={start}
                      setStart={setStart}
                      end={end}
                      setEnd={setEnd}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  feedList: state.appState.data.feedList,
  rouletteList: state.appState.data.rouletteList,
  rouletteAllList: state.appState.data.rouletteAllList,
  newRouletteList: state.appState.data.newRouletteList,
  rouletteDetail: state.appState.data.rouletteDetail
  // rouletteParticipationList: state.appState.data.rouletteParticipationList
})

const mapDispatchToProps = dispatch => ({
  removeFeed: ids => dispatch(removeFeed(ids)),
  getFeedList: options => dispatch(getFeedList(options)),
  getRouletteList: options => dispatch(getRouletteList(options)),
  getAllRouletteList: fetchOptions =>
    dispatch(getAllRouletteList(fetchOptions)),
  getNewRouletteList: options => dispatch(getNewRouletteList(options)),
  getRouletteParticipationList: options =>
    dispatch(getRouletteParticipationList(options)),
  getRouletteDetail: options => dispatch(getRouletteDetail(options))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventList)
)

const DropContainer = styled.tr`
  display: ${props => !props.isView && 'none'};
  background-color: #eff2f7;
  height: 16px;
`
