import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { backoffice } from '../../../../global/constants'
import CommonPie from '../../../../components/Common/Pie'

export default function UserNewbieDashboardSummary() {
  const [data, setData] = useState(null)
  const callAPI = useCallback(async () => {
    try {
      const url = `${backoffice.NEW_MEMBER_TO_PAY}/card`
      const res = await axios.get(url)
      setData(res.data.dashBoard)
    } catch (e) {
      throw e
    }
  }, [])

  const labels = ['유료전환', '무료사용']

  useEffect(() => {
    callAPI()
  }, [])
  return (
    <Wrapper>
      <p>유료전환수 (최근 100일)</p>
      {data && (
        <CommonPie
          type={'donut'}
          labels={labels}
          data={[data.conversionCount, data.signupCount - data.conversionCount]}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
