import React from 'react'
import styled from 'styled-components'
import DocumentPatternTaxStatementDashboard from './Tax'
import DocumentPatternPayDashboard from './Pay'
export default function DocumentPatternDashboard() {
  return (
    <Wrapper>
      <h2>기타</h2>
      <ChartWrapper>
        <DocumentPatternTaxStatementDashboard />
        <DocumentPatternPayDashboard />
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
