import React from 'react'
import { connect } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { axiosInit } from './helpers/axios'
import { authRoutes, userRoutes } from './routes/allRoutes'
import Authmiddleware from './routes/middleware/Authmiddleware'
import NonAuthLayout from './components/NonAuthLayout'
import VerticalLayout from './components/VerticalLayout'
import Indicator from './components/Common/IndicatorAdmin';
import {QueryClient, QueryClientProvider} from 'react-query';

import './assets/scss/theme.scss'

const queryClient = new QueryClient();

const App = props => {
  const NonAuthmiddleware = ({
    component: Component,
    layout: VerticalLayout
  }) => (
    <Route
      render={props => {
        return (
          <VerticalLayout>
            <Component {...props} />
          </VerticalLayout>
        )
      }}
    />
  )

  axiosInit()

  return (
    <QueryClientProvider client={queryClient}>
      <React.Fragment>
        <Indicator />
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <NonAuthmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    </QueryClientProvider>
  )
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  }
}

export default connect(mapStateToProps, null)(App)
