import { Fragment, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, Table } from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import { SettlementStatementsSelectType } from "../../../types/model/user/settlementStatement";
import { useMutation, useQuery } from "react-query";
import { IPostDocumentPaymentViewSettlementStatementReq, Opcode, SettlementStatement, ViewOutputSizeType, ViewOutputType } from "@payworkteam/server-product-types";
import { getDocumentPaymentSettlementStatementsV3, getDocumentPaymentViewSettlementStatementV3 } from "../../../api/documentPayment";
import moment from "moment";
import { useHistory } from "react-router-dom";
import LinkPaginator from "../../../components/Common/LinkPaginator";

interface Props {

}

/** table header datas */
const TheadDatas = [
	{ label: "대상 기간", key: "date" },
	{ label: "유저명", key: "creator" },
	{ label: "고객명", key: "client" },
	{ label: "건 수", key: "count" },
	{ label: "총 금액", key: "amt" },
	{ label: "결제수수료(나이스페이)", key: "nicePayFeeSupplyAmount" },
	{ label: "결제수수료(페이워크)", key: "payworkFeeSupplyAmount" },
	{ label: "페이워크 수수료 + 결제 수수료", key: "feeSupplyTotal"},
	{ label: "수수료 부가세", key: "feeTaxTotal" },
	{ label: "총 수수료", key: "feeTotal" },
	{ label: "정산 금액", key: "depositAmt"}
]

/** 전체 정산내역서 */
export default function SettlementStatements({

}: Props) {
	const history = useHistory();

	/** app states */
	const urlOption = new URLSearchParams(location.search);
	const [page, setPage] = useState(urlOption.get('page') ? Number(urlOption.get('page')) : 0);
	const [amount, setAmount] = useState(10);
	const [type, setType] = useState(SettlementStatementsSelectType[0].value);
	const [search, setSearch] = useState('');
	const [isFilter, setIsFilter] = useState(false);

	/** refs */
	const searchRef = useRef(null)

	const [handleData, setHandleData] = useState<Array<{
		userId: string;
		date: string;
		creator: string;
		client: string;
		count: number;
		amt: number;
		nicePayFeeSupplyAmount: number;
		payworkFeeSupplyAmount: number;
		feeSupplyTotal: number;
		feeTaxTotal: number;
		feeTotal: number;
		depositAmt: number;
		settlementStatement: {
			date: string;
			count: number;
			email: string;
			clientName?: string;
			clientCompanyName?: string;
			clientCompanyRegistrationNumber?: string
			amt: number;
			depositAmt: number;
			nicePayFeeSupplyAmount: number;
			nicePayFeeTotal: number;
			nicePayFeeVat: number;
			payworkFeeSupplyAmount: number;
			payworkFeeTotal: number;
			payworkFeeVat: number;
		};
	}>>([]);

	/** querys */
	/** 정산내역서 리스트 호출 */
	const {
		data: documentPaymentSettlementStatements,
	} = useQuery(["getDocumentPaymentSettlementStatementsV3", page, amount, type, search], async () => {
		
		const {
			opcode,
			message,
			settlementStatements,
			count,
			totalDepositAmt
		} = await getDocumentPaymentSettlementStatementsV3({
			page: page,
			amount,
			type,
			search
		});

		/** exception */
		if (opcode !== Opcode.SUCCESS) {
			console.error("$$ getDocumentPaymentSettlementStatementsV3 error => ", message);
			return;
		}

		setHandleData(onHandleData(settlementStatements));

		return {
			settlementStatements,
			count,
			totalDepositAmt
		}
	});

	/** 정산내역서 출력 */
	const {
		mutateAsync: asyncDownload,
	} = useMutation(async (props: {
		payload: {
			settlementStatement: SettlementStatement;
			year: number;
			month: number;
		};
		output?: ViewOutputType;
		outputSize?: ViewOutputSizeType;
		viewPortScale?: string | null;
	}) => {
		const {
			payload,
			output,
			outputSize,
			viewPortScale
		} = props;

		const {
			settlementStatement,
			year,
			month
		} = payload;
		
		/** 선택된 데이터가 없으면 거부 */
		if (!settlementStatement) {
			return;
		}

		/** 날짜가 없으면 거부 */
		if (!year || !month) {
			return;
		}

		const data = await getDocumentPaymentViewSettlementStatementV3(payload, {
			output,
			outputSize,
			viewPortScale
		});
		
		return data;
	});
	
	/** functions */
	/** 검색 수행 */
	const onSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
		/** 엔터 입력이 아니면 반려 */
		if (event.key !== 'Enter') {
			return;
		}
		
		/** 검색 */
		const value = event.currentTarget.value;
		setSearch(value);
	}

	/** 요청 회원 추적 */
	const onUserInfo = (creatorUserId: string) => {
		history.push({
			pathname: `/user-detail/${creatorUserId}`,
		});
	}
	
	/** handle data */
	const onHandleData = (settlementStatements: Array<{
		userId: string;
		username: string;
		email: string;
		date: string;
		count: number;
		clientName?: string;
			  clientCompanyName?: string;
			  clientCompanyRegistrationNumber?: string;
			  amt: number;
			  depositAmt: number;
			  nicePayFeeSupplyAmount: number;
			  nicePayFeeVat: number;
			  nicePayFeeTotal: number;
			  payworkFeeSupplyAmount: number;
			  payworkFeeVat: number;
			  payworkFeeTotal: number;
	  }>): (Array<{
		userId:	string;
		date: string;
		creator: string;
		client: string;
		count: number;
		amt: number;
		nicePayFeeSupplyAmount: number;
		payworkFeeSupplyAmount: number;
		feeSupplyTotal: number;
		feeTaxTotal: number;
		feeTotal: number;
		depositAmt: number;
		settlementStatement: {
			date: string;
			count: number;
			email: string;
			clientName?: string;
			clientCompanyName?: string;
			clientCompanyRegistrationNumber?: string
			amt: number;
			depositAmt: number;
			nicePayFeeSupplyAmount: number;
			nicePayFeeTotal: number;
			nicePayFeeVat: number;
			payworkFeeSupplyAmount: number;
			payworkFeeTotal: number;
			payworkFeeVat: number;
		}
	  }>) => {
		return settlementStatements.map((settlementStatement) => {
			const {
				date,
				userId,
				username,
				email,
				count,
				clientName,
				clientCompanyName,
				clientCompanyRegistrationNumber,
				amt,
				depositAmt,
				nicePayFeeSupplyAmount,
				nicePayFeeTotal,
				nicePayFeeVat,
				payworkFeeSupplyAmount,
				payworkFeeTotal,
				payworkFeeVat,
			} = settlementStatement;
			
			return {
				userId,
				date,
				creator: username ?? email,
				client: (clientCompanyName ? `${clientCompanyName} / ${clientName}` : clientName) ?? "",
				count,
				amt,
				nicePayFeeSupplyAmount,
				payworkFeeSupplyAmount,
				feeSupplyTotal: payworkFeeSupplyAmount + nicePayFeeSupplyAmount,
				feeTaxTotal: payworkFeeVat + nicePayFeeVat,
				feeTotal: payworkFeeTotal + nicePayFeeTotal,
				depositAmt,
				settlementStatement
			}
		});
	}

	/** 정산내역서 다운로드 */
	const downloadSettlementStatement = async (settlementStatement: {
		date: string;
		count: number;
		email: string;
		clientName?: string;
		clientCompanyName?: string;
		clientCompanyRegistrationNumber?: string
		amt: number;
		depositAmt: number;
		nicePayFeeSupplyAmount: number;
		nicePayFeeTotal: number;
		nicePayFeeVat: number;
		payworkFeeSupplyAmount: number;
		payworkFeeTotal: number;
		payworkFeeVat: number;
	}) => {
		const {
			date,
			count,
			email,
			clientName,
			clientCompanyName,
			clientCompanyRegistrationNumber,
			amt,
			depositAmt,
			nicePayFeeSupplyAmount,
			nicePayFeeTotal,
			nicePayFeeVat,
			payworkFeeSupplyAmount,
			payworkFeeTotal,
			payworkFeeVat,
		} = settlementStatement;
		
		/** parse date */
		const parseDate = moment(settlementStatement.date, "YYYYMM");
		
		const payload: IPostDocumentPaymentViewSettlementStatementReq = {
			settlementStatement: {
				count,
				clientName: clientName ?? email,
				clientCompanyName: clientCompanyName ?? "",
				clientCompanyRegistrationNumber: clientCompanyRegistrationNumber ?? "",
				amt,
				depositAmt,
				nicePayFeeSupplyAmount,
				nicePayFeeTotal,
				nicePayFeeVat,
				payworkFeeSupplyAmount,
				payworkFeeTotal,
				payworkFeeVat,
			},
			year: parseDate.year(),
			month: parseDate.month() + 1,
		}
		
		/** buffer call */
		const buffer = await asyncDownload({
			payload,
			output: ViewOutputType.PDF_BASE64,
			outputSize: ViewOutputSizeType.A4,
			viewPortScale: null
		});

		/** exception error */
		if (typeof buffer !== "string") {
			console.log("$$ asyncDownload failed => ", buffer);
			return;
		}

		/** 다운로드 절차 */
		// base64 데이터를 Blob으로 변환
        const byteCharacters = atob(buffer);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Blob을 URL로 변환
        const blobUrl = URL.createObjectURL(blob);

        // 임시 링크 생성 후 클릭하여 다운로드
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'example.pdf'; // 파일명 지정
        document.body.appendChild(link);
        link.click();

        // 다운로드 후 임시 링크 제거
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl); // 메모리 해제
	}
	
	return (
		<Fragment>
			<div className="page-content">
				<Container fluid>
					<Breadcrumb
						title={SettlementStatementsSelectType.find((selectType) => selectType.value === type)?.label}
						breadcrumbItem="SettlementStatements"
					/>
				</Container>

				<Row>
					<Col lg="12">
						<Card>
							<CardBody>
								{/* 상단 메뉴 */}
								<HeadBody>
									{/* 검색창 */}
									<SearchBox>
										<div className="search-box mr-2 mb-2 d-inline-block">
											<div className="position-relative">
											<Input
												type="text"
												className="form-control"
												innerRef={searchRef}
												placeholder={'회원명/고객명/전화번호'}
												onKeyUp={onSearch}
											/>
											<i className="bx bx-search-alt search-icon"></i>
											</div>
										</div>
									</SearchBox>

									<HeaderEnd>
										<Dropdown
											isOpen={isFilter}
											toggle={() => setIsFilter(!isFilter)}
										>
											<DropdownToggle
												tag="button"
												className="btn btn-light"
												style={{ marginRight: '10px' }}
											>
												<i className="mdi mdi-filter mr-1" />
											</DropdownToggle>
											
											<DropdownMenu className="dropdown-menu dropdown-menu-right">
												{SettlementStatementsSelectType.map((selectType) => (
													<DropdownItem onClick={() => setType(selectType.value)}>
														{selectType.label}
													</DropdownItem>
												))}
											</DropdownMenu>
										</Dropdown>

										{/* 엑셀 다운로드 */}
										<CSVLink data={handleData} headers={TheadDatas}>
											<Button type="button" color="light">
												<i className="mdi mdi-microsoft-excel" />
											</Button>
										</CSVLink>
									</HeaderEnd>
								</HeadBody>

								{/* 테이블 */}
								<Row>
									<Table>
										<thead className="thead-light">
											<tr>
												{TheadDatas.map((theadData) => !((type === "month") && (theadData.key === "client")) && (
													<Thead>{theadData.label}</Thead>
												))}
											</tr>
										</thead>

										<tbody>
											{handleData.map((dt, index) => {
												const {
													userId,
													date,
													creator,
													client,
													count,
													amt,
													nicePayFeeSupplyAmount,
													payworkFeeSupplyAmount,
													feeSupplyTotal,
													feeTaxTotal,
													feeTotal,
													depositAmt,
													settlementStatement
												} = dt;
												
												return (
													<tr key={index}>
														<td className="text-center" style={{ color: "#4E62DC", cursor: "pointer" }} onClick={() => downloadSettlementStatement(settlementStatement)}>{date}</td>
														<td className="text-center" style={{ color: "#4E62DC", cursor: "pointer" }} onClick={() => onUserInfo(userId)}>{creator}</td>
														{type !== "month" && <td className="text-center">{client}</td>}
														<td className="text-center">{count}건</td>
														<td className="text-center">{amt.toLocaleString()}원</td>
														<td className="text-center">{nicePayFeeSupplyAmount.toLocaleString()}원</td>
														<td className="text-center">{payworkFeeSupplyAmount.toLocaleString()}원</td>
														<td className="text-center">{feeSupplyTotal.toLocaleString()}원</td>
														<td className="text-center">{feeTaxTotal.toLocaleString()}원</td>
														<td className="text-center">{feeTotal.toLocaleString()}원</td>
														<td className="text-center">{depositAmt.toLocaleString()}원</td>
													</tr>
												)
											})}
										</tbody>
									</Table>
								</Row>

								<LinkPaginator
									searchParams={{
										currentPage: page,
										// paymentStatus: filtering
									}}
									visibleCount={amount}
									totalCount={Math.ceil((documentPaymentSettlementStatements?.count || 0) / amount)}
									urlPrefix={'/settlementStatements'}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</Fragment>
	)
}

const HeadBody = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const SearchBox = styled.div`
	flex: 1;
`;

const HeaderEnd = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

const ExcelDownload = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const Thead = styled.th`
	text-align: center;
`;