import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { Opcode, UserRequestBusinessmanState } from "@payworkteam/server-product-types";

// tools
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Badge
} from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ko'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import LinkPaginator from '../../components/Common/LinkPaginator';

// redux
import {
  getAllUserList,
  removeUser,
  completeRemoveUser
} from '../../reducers/users'

// temp
import { getBusinessmanRequestList } from '../../api/businessman'

const BusinessApprovalList = () => {
  const location = useLocation()
  const history = useHistory()
  
  /** 현재 주소 추가경로 */
  const params = new URLSearchParams(location.search);

  /** 페이지 추가 옵션 반영 */
  const currentPage = Number(params.get('page')) ?? 0;
  const currentSearch = params.get('search') ?? '';
  const pageAmount = 10;
  const userFiltering = params.get('userRequestBusinessmanState') ?? UserRequestBusinessmanState.Waiting;
  
  const userStart = location.state?.startInfo ?? 1
  const userEnd = location.state?.endInfo ?? 11

  // 체크박스 및 모달
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [filterTitle, setFilterTitle] = useState('전체')

  const [userListData, setUserListData] = useState([])
  const [changeSearch, setChangeSearch] = useState(false)

  // page
  const [totalListCount, setTotalListCount] = useState(0)
  const [page, setPage] = useState(currentPage)
  const [search, setSearch] = useState(currentSearch)
  const [filtering, setFiltering] = useState(userFiltering)
  const [start, setStart] = useState(userStart)
  const [end, setEnd] = useState(userEnd)

  // 페이지, 필터
  useEffect(() => {
    if (!changeSearch) {
      getBusinessApprovalList({
        page: page,
        amount: 10,
        state: filtering
      })
    }
  }, [page, filtering])

  // 검색
  useEffect(() => {
    if (changeSearch) {
      setStart(1)
      setEnd(11)
      getBusinessApprovalList({
        page: page,
        amount: 10,
        search,
        state: filtering
      })
    }
  }, [changeSearch])

  /** 페이지 리스트 훅 */
  useEffect(() => {
    getBusinessApprovalList({ page, amount: 10, search, state: filtering })
  }, []);

  /** 페이지 리스트 호출 */
  const getBusinessApprovalList = async ({ page, amount, search, state }) => {
    await getBusinessmanRequestList({ page, amount, search, state }).then(
      res => {
        if (res.opcode === 0) {
          /** 리스트 state 업데이트 */
          setUserListData(res.userRequestBusinessmans)
          
          /** 카운트 state 업데이트 */
          setTotalListCount(res.count)
        }
      }
    )
  }

  /** 상태 변경 */
  const handlerFilter = index => {
    let setUserBusinessmanState = UserRequestBusinessmanState.Waiting;
    
    /** 처리 중 */
    if (index === 0) {
      setUserBusinessmanState = UserRequestBusinessmanState.Waiting;
    }

    /** 수정완료 */
    if (index === 1) {
      setUserBusinessmanState = UserRequestBusinessmanState.Processed;
    }

    /** 반려완료 */
    if (index === 2) {
      setUserBusinessmanState = UserRequestBusinessmanState.Refusal;
    }

    handleUrl({
      page: 0,
      search: '',
      userRequestBusinessmanState: setUserBusinessmanState
    });
  }

  /** 상태 변경에 따른 url 업데이트 */
  const handleUrl = (props) => {
    const {
      page,
      search,
      userRequestBusinessmanState,
    } = props;

    setPage(page);
    setSearch(search);
    setFiltering(userRequestBusinessmanState);

    const editParmas = new URLSearchParams();
    editParmas.append('page', page);
    editParmas.append('search', search);
    editParmas.append('userRequestBusinessmanState', userRequestBusinessmanState);
    window.location.replace(`?${editParmas.toString()}`);
  }

  const StateLabel = ({ state }) => {
    if (state === 'Processed') {
      return (
        <h4>
          <Badge color="primary">수정완료</Badge>
        </h4>
      )
    } else if (state === 'Refusal') {
      return (
        <h4>
          <Badge color="danger">반려완료</Badge>
        </h4>
      )
    } else {
      return (
        <h4>
          <Badge color="warning">처리 중</Badge>
        </h4>
      )
    }
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={filterTitle} breadcrumbItem="BusinessApproval List" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    {/* 검색 Input */}
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            placeholder={'이름/휴대폰/이메일'}
                            onKeyUp={e => {
                              if (e.keyCode === 13) {
                                setChangeSearch(true)
                                setPage(0)
                              }
                            }}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>

                    {/* 검색 필터 */}
                    <Col sm="8">
                      <div
                        className="text-sm-right"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Dropdown
                          isOpen={isFilter}
                          toggle={() => setIsFilter(!isFilter)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-light"
                            style={{ marginRight: '10px' }}
                          >
                            <i className="mdi mdi-filter mr-1" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu dropdown-menu-right">
                            <DropdownItem onClick={() => handlerFilter(0)}>
                              처리 중
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(1)}>
                              수정완료
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(2)}>
                              반려완료
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                    
                    {/* 모달 */}
                    <Modal
                      centered
                      scrollable
                      isOpen={deleteModal}
                      toggle={() => {
                        setDeleteModal(!deleteModal)
                        setSelectedBoxes([])
                      }}
                    >
                      <ModalHeader
                        toggle={() => {
                          setDeleteModal(!deleteModal)
                          setSelectedBoxes([])
                        }}
                        style={{
                          borderBottom: '2px solid #2a3042'
                        }}
                      >
                        <div>회원 삭제</div>
                      </ModalHeader>
                      <ModalBody
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: '150px',
                          flexDirection: 'column'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '15px',
                            fontWeight: 'bold',
                            marginBottom: '8px'
                          }}
                        >{`${selectedBoxes.length}명의 활성 상태를 변경 하시겠습니까?`}</div>
                        <div>{`회원이 활성 상태가 변경됩니다`}</div>
                      </ModalBody>
                    </Modal>
                  </Row>

                  {/* 테이블 목록 */}
                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '3%' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length === userListData?.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(userListData) &&
                                      setSelectedBoxes(
                                        userListData.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            사업자등록번호
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            이름
                          </th>
                          <th scope="col" style={{ width: '4%' }}>
                            전화번호
                          </th>
                          <th scope="col" style={{ width: '8%' }}>
                            수정 문의 요청일
                          </th>
                          <th scope="col" style={{ width: '20%' }}>
                            상태
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(userListData) &&
                          userListData.map((user, idx) => (
                            <tr key={'_user_' + idx}>
                              {/* 사업자 등록번호 */}
                              <td style={{ height: '65px' }}>
                                <div
                                  className="custom-control custom-checkbox"
                                  style={{ zIndex: 0 }}
                                >
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={user.id}
                                    checked={selectedBoxes.includes(user.id)}
                                    onChange={e =>
                                      e.target.checked
                                        ? setSelectedBoxes([
                                            ...selectedBoxes,
                                            user.id
                                          ])
                                        : setSelectedBoxes(
                                            selectedBoxes.filter(
                                              b => b !== user.id
                                            )
                                          )
                                    }
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor={user.id}
                                  >
                                    &nbsp;
                                  </Label>
                                </div>
                              </td>

                              {/* 버튼? */}
                              <td
                                onClick={() => {
                                  history.push({
                                    pathname: `/businessApproval-detail/${user.id}`,
                                    state: {
                                      pageInfo: page,
                                      searchInfo: search,
                                      filteringInfo: filtering,
                                      startInfo: start,
                                      endInfo: end,
                                      prevInfo: 'users'
                                    }
                                  })
                                }}
                                style={{
                                  color: '#556EE6',
                                  cursor: 'pointer'
                                }}
                              >
                                {user.companyRegistrationNumber}
                              </td>

                              {/* 이름 */}
                              <td>{user.name}</td>

                              {/* 전화번호 */}
                              <td>{user.phone}</td>

                              {/* 수정 문의 요청일 */}
                              <td>
                                {user.createdAt &&
                                  moment(user.createdAt).format(
                                    'YYYY-MM-DD-HH:mm'
                                  )}
                              </td>

                              {/* 상태 */}
                              <td>
                                <StateLabel state={user.state} />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    
                    {/* 페이지 네비게이션 */}
                    <LinkPaginator
                      searchParams={{
                        currentPage: page,
                        search: search,
                        userRequestBusinessmanState: filtering
                      }}
                      visibleCount={pageAmount}
                      totalCount={Math.ceil(totalListCount / pageAmount)}
                      urlPrefix={'/businessApproval'}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  allUserList: state.appState.data.allUserList,
  totalCount: state.appState.data.totalCount
})
const mapDispatchToProps = dispatch => ({
  getAllUserList: () => dispatch(getAllUserList()),
  removeUser: ids => dispatch(removeUser(ids)),
  completeRemoveUser: ids => dispatch(completeRemoveUser(ids))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessApprovalList)
