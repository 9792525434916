import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import * as dateFns from 'date-fns'
import ReactApexChart from 'react-apexcharts'
import { backoffice } from '../../../../../global/constants'
import { years, weeks } from '../../../../../constant/chart'
import { defaultOptions, xaxisOption } from '../../../../../helpers/chart'
import CommonTable from '../../../../../components/Common/Table'

export default function UserNewbieGraphWeekly() {
  const apiUrl = `${backoffice.NEW_MEMBER_TO_PAY}`
  const [year, setYear] = useState(dateFns.format(new Date(), 'yyyy'))
  const onChangeYear = useCallback(e => {
    setYear(e.target.value)
  }, [])

  const [data, setData] = useState(null)

  const callAPI = useCallback(async () => {
    try {
      const url = `${apiUrl}?type=week&year=${year}`
      const res = await axios.get(url)
      setData(res.data.dashBoard)
    } catch (e) {
      throw e
    }
  }, [year])

  const labels = ['회원가입', '유료전환']
  const [openTable, setOpenTable] = useState(false)

  useEffect(() => {
    callAPI()
  }, [year])

  return (
    <Wrapper>
      <DateWrapper>
        <p>날짜</p>
        <Select value={year} onChange={onChangeYear}>
          {years.map(i => (
            <Option key={i} value={i}>
              {i}년
            </Option>
          ))}
        </Select>
      </DateWrapper>
      <ChartWrapper>
        {data ? (
          <>
            <ReactApexChart
              key={'user-newbie-graph-weekly'}
              height={300}
              options={{
                ...defaultOptions,
                xaxis: xaxisOption(weeks())
              }}
              series={[
                {
                  name: labels[0],
                  type: 'column',
                  data: data.map(i => {
                    return {
                      x: `${i.week}주`,
                      y: i.signupCount
                    }
                  })
                },
                {
                  name: labels[1],
                  type: 'line',
                  data: data.map(i => {
                    return {
                      x: `${i.week}주`,
                      y: i.conversionCount
                    }
                  })
                }
              ]}
            />
            <TableWrapper>
              <TableToggleButtonWrapper>
                <TableToggleButton
                  onClick={() => setOpenTable(openTable ? false : true)}
                >
                  {openTable ? '테이블 닫기' : '테이블 열기'}
                </TableToggleButton>
              </TableToggleButtonWrapper>
              {openTable && (
                <CommonTable
                  datas={[
                    data.map(i => i.signupCount),
                    data.map(i => i.conversionCount)
                  ]}
                  colHeaders={labels}
                  rowNames={data.map(j => `${j.week}주`)}
                />
              )}
            </TableWrapper>
          </>
        ) : (
          <></>
        )}
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 420px;
`

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  & > p {
    margin-bottom: 0;
  }
`

const Select = styled.select`
  margin: 0 10px;
`

const Option = styled.option``

const ChartWrapper = styled.div``

const TableWrapper = styled.div``
const TableToggleButtonWrapper = styled.div`
  text-align: right;
`

const TableToggleButton = styled.button``
