import axios from 'axios'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const API_ERROR = 'LOGIN_API_ERROR'

export const loginUser = (user, history) => async () => {
  await axios
    .post('/auth/login/local', user)
    .then(res => {
      const { accessToken } = res.data

      localStorage.setItem('token', accessToken)
      return {
        type: LOGIN_SUCCESS,
        payload: { user, history }
      }
    })
    .catch(err => console.log(err))
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user
  }
}

export const logoutUser = history => {
  localStorage.removeItem('token')

  return {
    type: LOGOUT_USER,
    payload: { history }
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error
  }
}

const initialState = {
  error: '',
  loading: false
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
