import { all, put, call, takeLatest, takeEvery } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_FEED_LIST,
  RANDOM_NICKNAME,
  GET_FEED_CATEGORY_LIST,
  REMOVE_CATEGORY_LIST,
  CREATE_FEED_CATEGORY,
  GET_FEED_DETAIL,
  DECLARE_FEED_LIST,
  CREATE_FEED,
  REMOVE_FEED,
  SET_FEED_COMMENT,
  REMOVE_FEED_COMMENT,
  SET_FEED_REACTION,
  SET_FEED_COMMENT_REACTION
} from '../reducers/feed'

export function* getFeedList(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_FEED_LIST', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_FEED_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.feed.GET_FEED_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: options.page - 1,
        // amount: 9999,
        amount: options.amount,
        search: options.search
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('feedList', response.data))
      yield put(setAppState('SUCCESS_GET_FEED_LIST', response.data))
    } else {
      yield put(setAppState('FAILED_GET_FEED_LIST', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_FEED_LIST', e))
  }
}

export function* randomNickname(actions) {
  const { id } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.feed.RANDOM_NICKNAME}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { id } // 확인 필요
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_RANDOM_NICKNAME', response.data))
    } else {
      yield put(setAppState('FAILED_RANDOM_NICKNAME', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_RANDOM_NICKNAME', e))
  }
}

export function* getFeedCategoryList(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_FEED_CATEGORY_LIST', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_FEED_CATEGORY_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.feed.GET_FEED_CATEGORY_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('feedCategoryList', response.data))
      yield put(setAppState('SUCCESS_GET_FEED_CATEGORY_LIST', response.data))
    } else {
      yield put(setAppState('FAILED_GET_FEED_CATEGORY_LIST', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_FEED_CATEGORY_LIST', e))
  }
}

export function* removeCategoryList(actions) {
  const { ids } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_REMOVE_CATEGORY', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_REMOVE_CATEGORY', null, false))
  }

  try {
    const response = yield all(
      ids.map(id => {
        return call(axios, {
          method: 'POST',
          validateStatus: () => true,
          url: `${constants.feed.REMOVE_CATEGORY}/${id}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
          // params: { feedCategoryTempletId: id }
        })
      })
    )

    for (let i = 0; i < response.length; i++) {
      if (response[i].data.opcode !== 0) {
        yield put(setAppState('FAILED_REMOVE_CATEGORY', null, false))
      }
    }
    yield put(setAppState('SUCCESS_REMOVE_CATEGORY', response, false))
  } catch (e) {
    yield put(setAppState('FAILED_REMOVE_CATEGORY', e, false))
  }
}

export function* createFeedCategory(actions) {
  const { name } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_CREATE_FEED_CATEGORY', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_CREATE_FEED_CATEGORY', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.feed.CREATE_FEED_CATEGORY}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { name }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_CREATE_FEED_CATEGORY', response.data))
    } else {
      yield put(setAppState('FAILED_CREATE_FEED_CATEGORY', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_CREATE_FEED_CATEGORY', e))
  }
}

// 피드 상세 조회
export function* getFeedDetail(actions) {
  const { id } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_FEED_DETAIL', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_FEED_DETAIL', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      // url: `${constants.feed.GET_FEED_DETAIL}/${id}`,
      url: `${constants.feed.GET_FEED_DETAIL}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('feedDetail', response.data.feed))
      yield put(setAppState('SUCCESS_GET_FEED_DETAIL', response.data, false))
      yield call(declareFeedList)
    } else {
      yield put(setAppState('FAILED_GET_FEED_DETAIL', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_FEED_DETAIL', e, false))
  }
}

// 해당 피드 신고자 리스트
export function* declareFeedList() {
  // const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_DECLARE_FEED_LIST', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_DECLARE_FEED_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.feed.DECLARE_FEED_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: 0,
        amount: 9999,
        search: ''
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('declareList', response.data))
      yield put(setAppState('SUCCESS_DECLARE_FEED_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_DECLARE_FEED_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_DECLARE_FEED_LIST', e, false))
  }
}

// 피드 등록
export function* createFeed(actions) {
  const { newFeed, image } = actions
  let feedFiles = []
  let uploadResponses = []

  yield put(setAppState('START_CREATE_FEED', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_CREATE_FEED', null, false))
  }

  if (image.length > 0) {
    // files
    uploadResponses = yield call(uploadFiles, image)
    if (uploadResponses) {
      uploadResponses.map(response => {
        feedFiles.push(response.data.feedImages[0].id)
      })
    }
  }

  const { title, content, targetUserId, categoryId } = newFeed

  try {
    const response = yield call(axios, {
      method: 'POST',
      validateStatus: () => true,
      url: `${constants.feed.CREATE_FEED}/${categoryId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        title,
        content,
        targetUserId,
        feedImageIds: feedFiles
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_CREATE_FEED', response.data.feed, false))
    } else {
      yield put(setAppState('FAILED_CREATE_FEED', response.data, false))
    }
  } catch (err) {
    yield put(setAppState('FAILED_CREATE_FEED', null, false))
  }
}

// 업로드 파일
function* uploadFiles(files) {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_IMAGE_UPLOAD', null, false))
  }

  try {
    const responseImage = yield all(
      files.map(file => {
        const formData = new FormData()
        formData.append('image', file)

        return call(axios, {
          validateStatus: () => true,
          url: `${constants.feed.IMAGE}`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: formData
        })
      })
    )
    return responseImage
  } catch (err) {
    yield put(setAppState('FAILED_IMAGE_UPLOAD', null, false))
    return false
  }
}

export function* removeFeed(actions) {
  const { ids } = actions
  yield put(setAppState('START_REMOVE_FEED', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_REMOVE_FEED', null, false))
  }

  try {
    const response = yield all(
      ids.map(id => {
        return call(axios, {
          method: 'DELETE',
          validateStatus: () => true,
          url: `${constants.feed.REMOVE_FEED}/${id}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
      })
    )

    for (let i = 0; i < response.length; i++) {
      if (response[i].data.opcode !== 0) {
        yield put(setAppState('FAILED_REMOVE_FEED', null, false))
      }
    }
    yield put(setAppState('SUCCESS_REMOVE_FEED', null, false))
  } catch (e) {
    yield put(setAppState('FAILED_REMOVE_FEED', e, false))
  }
}

export function* setFeedComment(actions) {
  const { feedId, commentData, commentId } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_FEED_COMMENT', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_FEED_COMMENT', null, false))
  }

  const { comment, feedCommentId, targetUserId } = commentData

  try {
    let response = ''

    if (commentId.length > 0) {
      response = yield call(axios, {
        method: 'POST',
        url: `${constants.feed.SET_FEED_COMMENT}/${feedId}/${commentId}`,
        validateStatus: () => true,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          comment,
          targetUserId
        }
      })
    } else {
      response = yield call(axios, {
        method: 'POST',
        url: `${constants.feed.SET_FEED_COMMENT}/${feedId}/${null}}`,
        validateStatus: () => true,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          comment,
          targetUserId
        }
      })
    }

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_FEED_COMMENT', response.data, false))
    } else {
      yield put(setAppState('FAILED__SET_FEED_COMMENT', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED__SET_FEED_COMMENT', e, false))
  }
}

export function* removeFeedComment(actions) {
  const { feedId, feedCommentId } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_REMOVE_FEED_COMMENT', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_REMOVE_FEED_COMMENT', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'DELETE',
      url: `${constants.feed.REMOVE_FEED_COMMENT}/${feedId}/${feedCommentId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState('SUCCESS_REMOVE_FEED_COMMENT', response.data, false)
      )
    } else {
      yield put(setAppState('FAILED_REMOVE_FEED_COMMENT', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_REMOVE_FEED_COMMENT', e, false))
  }
}

export function* setFeedReaction(actions) {
  const { feedId, reactionData } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_FEED_REACTION', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_FEED_REACTION', null, false))
  }

  const { reaction, targetUserId } = reactionData

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.feed.SET_FEED_REACTION}/${feedId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        reaction,
        targetUserId
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_FEED_REACTION', response.data, false))
    } else {
      yield put(setAppState('FAILED_SET_FEED_REACTION', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_FEED_REACTION', e, false))
  }
}

export function* setFeedCommentReaction(actions) {
  const { feedId, feedCommentId, reactionData } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_FEED_COMMENT_REACTION', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_FEED_COMMENT_REACTION', null, false))
  }

  const { reaction, targetUserId } = reactionData

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.feed.SET_FEED_REACTION}/${feedId}/${feedCommentId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        reaction,
        targetUserId
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState('SUCCESS_SET_FEED_COMMENT_REACTION', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_SET_FEED_COMMENT_REACTION', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_FEED_COMMENT_REACTION', e, false))
  }
}

const feedSagas = [
  takeLatest(GET_FEED_LIST, getFeedList),
  takeLatest(REMOVE_CATEGORY_LIST, removeCategoryList),
  takeLatest(DECLARE_FEED_LIST, declareFeedList),
  takeLatest(RANDOM_NICKNAME, randomNickname),
  takeLatest(CREATE_FEED_CATEGORY, createFeedCategory),
  takeLatest(GET_FEED_CATEGORY_LIST, getFeedCategoryList),
  takeLatest(GET_FEED_DETAIL, getFeedDetail),
  takeLatest(CREATE_FEED, createFeed),
  takeLatest(REMOVE_FEED, removeFeed),
  takeLatest(SET_FEED_COMMENT, setFeedComment),
  takeLatest(REMOVE_FEED_COMMENT, removeFeedComment),
  takeLatest(SET_FEED_REACTION, setFeedReaction),
  takeLatest(SET_FEED_COMMENT_REACTION, setFeedCommentReaction)
]

export default feedSagas
