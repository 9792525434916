import { all, put, call, takeLatest } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_DOCUMENT_PAYMENT_LIST,
  GET_DOCUMENT_PAYMENT_BALANCE,
  DEPOSIT_DOCUMENT_PAYMENT_BALANCE,
  DELETE_DOCUMENT_PAYMENT
} from '../reducers/payment'

export function* getDocumentPaymentList(actions) {
  const { page, amount, paymentStatus } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_DOCUMENT_PAYMENT_LIST', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_DOCUMENT_PAYMENT_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.DOCUMENT_PAYMENT}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: page,
        amount: amount,
        paymentStatus: paymentStatus
      }
    });

    if (response.data.opcode === 0) {
      yield put(saveAppData('documentPaymentList', response.data))
      yield put(
        setAppState('SUCCESS_GET_DOCUMENT_PAYMENT_LIST', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_DOCUMENT_PAYMENT_LIST', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_DOCUMENT_PAYMENT_LIST', e))
  }
}

export function* getDocumentPaymentBalance(actions) {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_DOCUMENT_PAYMENT_BALANCE', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_DOCUMENT_PAYMENT_BALANCE', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.DOCUMENT_PAYMENT}/balanceInquiry`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('documentPaymentBalance', response.data))
      yield put(
        setAppState(
          'SUCCESS_GET_DOCUMENT_PAYMENT_BALANCE',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState('FAILED_GET_DOCUMENT_PAYMENT_BALANCE', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_DOCUMENT_PAYMENT_BALANCE', e))
  }
}

export function* depositDocumentPaymentBalance(actions) {
  const { paymentId, amt, amtDate } = actions

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_DEPOSIT_DOCUMENT_PAYMENT_BALANCE', null, true))
  if (!accessToken) {
    yield put(
      setAppState('FAILED_DEPOSIT_DOCUMENT_PAYMENT_BALANCE', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.DOCUMENT_PAYMENT}/deposited/${paymentId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {},
      data: {
        amt,
        amtDate
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState(
          'SUCCESS_DEPOSIT_DOCUMENT_PAYMENT_BALANCE',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_DEPOSIT_DOCUMENT_PAYMENT_BALANCE',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_DEPOSIT_DOCUMENT_PAYMENT_BALANCE', e))
  }
}

export function* deleteDocumentPayment(actions) {
  const { paymentId } = actions;

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_DELETE_DOCUMENT_PAYMENT', null, true))
  if (!accessToken) {
    yield put(
      setAppState('FAILED_DELETE_DOCUMENT_PAYMENT', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'DELETE',
      url: `${constants.backoffice.DOCUMENT_PAYMENT}/delete/${paymentId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState(
          'SUCCESS_DELETE_DOCUMENT_PAYMENT',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_DELETE_DOCUMENT_PAYMENT',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_DELETE_DOCUMENT_PAYMENT', e))
  }
}

const usersSagas = [
  takeLatest(GET_DOCUMENT_PAYMENT_LIST, getDocumentPaymentList),
  takeLatest(GET_DOCUMENT_PAYMENT_BALANCE, getDocumentPaymentBalance),
  takeLatest(DEPOSIT_DOCUMENT_PAYMENT_BALANCE, depositDocumentPaymentBalance),
  takeLatest(DELETE_DOCUMENT_PAYMENT, deleteDocumentPayment)
]

export default usersSagas
