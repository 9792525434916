import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import Daily from './Daily'
import Weekly from './Weekly'
import Monthly from './Monthly'

export default function DocumentGraphDashboard() {
  const [type, setType] = useState('daily')
  const onClickButton = useCallback(type => {
    setType(type)
  }, []);

  return (
    <Wrapper>
      <h2>거래문서 수</h2>
      <ButtonWrapper>
        <Button
          color={type === 'monthly' ? 'primary' : 'secondary'}
          active={type === 'monthly'}
          onClick={() => onClickButton('monthly')}
        >
          Monthly
        </Button>
        <Button
          color={type === 'weekly' ? 'primary' : 'secondary'}
          active={type === 'weekly'}
          onClick={() => onClickButton('weekly')}
        >
          Weekly
        </Button>
        <Button
          color={type === 'daily' ? 'primary' : 'secondary'}
          active={type === 'daily'}
          onClick={() => onClickButton('daily')}
        >
          Daily
        </Button>
      </ButtonWrapper>
      <ChartWrapper>
        {type === 'daily' ? (
          <Daily />
        ) : type === 'weekly' ? (
          <Weekly />
        ) : type === 'monthly' ? (
          <Monthly />
        ) : (
          <></>
        )}
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`

const ChartWrapper = styled.div``
