import { put, call, takeLatest } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_ROULETTE_LIST,
  GET_NEW_ROULETTE_LIST,
  GET_ROULETTE_PARTICIPATION_LIST,
  GET_ALL_ROULETTE_LIST,
  GET_ROULETTE_ITEM_LIST,
  CREATE_ROULETTE_ITEM,
  DELETE_ROULETTE_ITEM,
  UPDATE_ROULETTE_ITEM,
  GET_ROULETTE_SIGNUP_LIST,
  GET_ROULETTE_DETAIL
} from '../reducers/event'

export function* getRouletteList(actions) {
  const { options } = actions

  yield put(setAppState('START_GET_ROULETTE_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ROULETTE_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: constants.backoffice.GET_ROULETTE_LIST,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: options.page - 1,
        amount: options.amount,
        search: options.search
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('rouletteList', response.data))
      yield put(setAppState('SUCCESS_GET_ROULETTE_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_ROULETTE_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ROULETTE_LIST', e))
  }
}

export function* getNewRouletteList(actions) {
  const { options } = actions

  yield put(setAppState('START_GET_NEW_ROULETTE_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_NEW_ROULETTE_LIST', null, false))
  }

  let targetUrl = ''

  switch (options.filtering) {
    case 'all':
      targetUrl = constants.backoffice.GET_NEW_ROULETTE_LIST
      break

    case 'participation':
      targetUrl = constants.backoffice.GET_ROULETTE_PARTICIPATION_LIST
      break

    case 'signup':
      targetUrl = constants.backoffice.GET_ROULETTE_SIGNUP_LIST
      break

    case 'create':
      targetUrl = constants.backoffice.GET_ROULETTE_WORK_ORDER_QT_CREATE_LIST
      break

    default:
      break
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      // url: constants.backoffice.GET_NEW_ROULETTE_LIST,
      url: targetUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: options.page - 1,
        amount: options.amount,
        search: options.search
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('newRouletteList', response.data))
      yield put(
        setAppState('SUCCESS_GET_NEW_ROULETTE_LIST', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_NEW_ROULETTE_LIST', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_NEW_ROULETTE_LIST', e))
  }
}

export function* getRouletteParticipationList(actions) {
  const { options } = actions

  yield put(setAppState('START_GET_ROULETTE_PARTICIPATION_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(
      setAppState('FAILED_GET_ROULETTE_PARTICIPATION_LIST', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: constants.backoffice.GET_ROULETTE_PARTICIPATION_LIST,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: options.page - 1,
        amount: options.amount,
        search: options.search
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('newRouletteList', response.data))
      yield put(
        setAppState(
          'SUCCESS_GET_ROULETTE_PARTICIPATION_LIST',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_ROULETTE_PARTICIPATION_LIST',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ROULETTE_PARTICIPATION_LIST', e))
  }
}

export function* getRouletteSignupList(actions) {
  const { options } = actions

  yield put(setAppState('START_GET_ROULETTE_SIGNUP_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ROULETTE_SIGNUP_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: constants.backoffice.GET_ROULETTE_SIGNUP_LIST,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: options.page - 1,
        amount: options.amount,
        search: options.search
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('newRouletteList', response.data))
      yield put(
        setAppState('SUCCESS_GET_ROULETTE_SIGNUP_LIST', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_ROULETTE_SIGNUP_LIST', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ROULETTE_SIGNUP_LIST', e))
  }
}

export function* getAllRouletteList(actions) {
  const { options } = actions

  yield put(setAppState('START_GET_ALL_ROULETTE_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ALL_ROULETTE_LIST', null, false))
  }

  let targetUrl = ''

  switch (options.filtering) {
    case 'all':
      targetUrl = constants.backoffice.GET_NEW_ROULETTE_LIST
      break

    case 'participation':
      targetUrl = constants.backoffice.GET_ROULETTE_PARTICIPATION_LIST
      break

    case 'signup':
      targetUrl = constants.backoffice.GET_ROULETTE_SIGNUP_LIST
      break

    case 'create':
      targetUrl = constants.backoffice.GET_ROULETTE_WORK_ORDER_QT_CREATE_LIST
      break

    default:
      break
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      // url: constants.backoffice.GET_NEW_ROULETTE_LIST,
      url: targetUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: 0,
        amount: 99999,
        search: options.search
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('rouletteAllList', response.data))
      yield put(
        setAppState('SUCCESS_GET_ALL_ROULETTE_LIST', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_ALL_ROULETTE_LIST', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ALL_ROULETTE_LIST', e))
  }
}

export function* getRouletteDetail(actions) {
  const { id } = actions

  yield put(setAppState('START_GET_ROULETTE_DETAIL', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ROULETTE_DETAIL', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: constants.backoffice.GET_NEW_ROULETTE_LIST,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: 0,
        amount: 99999,
        search: ''
      }
    })

    if (response.data.opcode === 0) {
      const filtering = response.data.rouletteRaffles.filter(
        f => f.userId === id
      )

      yield put(saveAppData('rouletteDetail', filtering))
      yield put(
        setAppState('SUCCESS_GET_ROULETTE_DETAIL', response.data, false)
      )
    } else {
      yield put(setAppState('FAILED_GET_ROULETTE_DETAIL', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ROULETTE_DETAIL', e))
  }
}

export function* getRouletteItemList() {
  yield put(setAppState('START_GET_ROULETTE_ITEM_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ROULETTE_ITEM_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: constants.backoffice.GET_ROULETTE_ITEM_LIST,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('rouletteItemList', response.data.rouletteItems))
      yield put(
        setAppState('SUCCESS_GET_ROULETTE_ITEM_LIST', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_ROULETTE_ITEM_LIST', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ROULETTE_ITEM_LIST', e))
  }
}

export function* createRouletteItem(actions) {
  const { data } = actions

  yield put(setAppState('START_CREATE_ROULETTE_ITEM', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_CREATE_ROULETTE_ITEM', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      validateStatus: () => true,
      url: constants.backoffice.CREATE_ROULETTE_ITEM,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        item: data.item,
        quantity: data.quantity,
        numberOfCaseToStart: data.numberOfCaseToStart,
        numberOfCaseToEnd: data.numberOfCaseToEnd
      }
    })

    if (response.data.opcode === 0) {
      yield call(getRouletteItemList, {})
      yield put(
        setAppState('SUCCESS_CREATE_ROULETTE_ITEM', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_CREATE_ROULETTE_ITEM', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_CREATE_ROULETTE_ITEM', e))
  }
}

export function* deleteRouletteItem(actions) {
  const { id } = actions

  yield put(setAppState('START_DELETE_ROULETTE_ITEM', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_DELETE_ROULETTE_ITEM', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'DELETE',
      validateStatus: () => true,
      url: `${constants.backoffice.CREATE_ROULETTE_ITEM}/${id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield call(getRouletteItemList, {})
      yield put(
        setAppState('SUCCESS_DELETE_ROULETTE_ITEM', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_DELETE_ROULETTE_ITEM', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_DELETE_ROULETTE_ITEM', e))
  }
}

export function* updateRouletteItem(actions) {
  const { data } = actions

  yield put(setAppState('START_UPDATE_ROULETTE_ITEM', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_UPDATE_ROULETTE_ITEM', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      validateStatus: () => true,
      url: `${constants.backoffice.CREATE_ROULETTE_ITEM}/${data.targetId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        item: data.item,
        notationName: data.notationName,
        quantity: data.quantity,
        numberOfCaseToStart: data.numberOfCaseToStart,
        numberOfCaseToEnd: data.numberOfCaseToEnd,
        randEnabled: data.randEnabled
      }
    })

    if (response.data.opcode === 0) {
      yield call(getRouletteItemList, {})
      yield put(
        setAppState('SUCCESS_UPDATE_ROULETTE_ITEM', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_UPDATE_ROULETTE_ITEM', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_UPDATE_ROULETTE_ITEM', e))
  }
}

const EventSagas = [
  takeLatest(GET_ROULETTE_LIST, getRouletteList),
  takeLatest(GET_NEW_ROULETTE_LIST, getNewRouletteList),
  takeLatest(GET_ALL_ROULETTE_LIST, getAllRouletteList),
  takeLatest(GET_ROULETTE_ITEM_LIST, getRouletteItemList),
  takeLatest(GET_ROULETTE_PARTICIPATION_LIST, getRouletteParticipationList),
  takeLatest(CREATE_ROULETTE_ITEM, createRouletteItem),
  takeLatest(DELETE_ROULETTE_ITEM, deleteRouletteItem),
  takeLatest(UPDATE_ROULETTE_ITEM, updateRouletteItem),
  takeLatest(GET_ROULETTE_DETAIL, getRouletteDetail)
]

export default EventSagas
