import 'react-datepicker/dist/react-datepicker.css'

import {
  Button,
  CardBody,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap'
import React, { useEffect, useState } from 'react'

import DatePicker from 'react-datepicker'
//Import Breadcrumb
import Indicator from '../../components/Common/Indicator'
import { Link } from 'react-router-dom'
import Paginator from '../../components/Common/Paginator'
import Select from 'react-select'
import { dateOptions } from '../../helpers/utils'
import { getReviews } from '../../api/review'

const Reviews = ({}) => {
  const [reviewsData, setReviewsData] = useState([])
  const [isFetching, setFetching] = useState(true)
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10
  })
  const [searchQuery, setSearchQuery] = useState({
    project: '',
    user: '',
    date: '',
    range: {
      label: '전체',
      value: 'all',
      start: 0,
      end: Infinity
    }
  })

  const handlePagination = (page, pageSize) => {
    if (fetchOptions.page !== page) {
      return setFetchOptions(prevState => ({
        ...prevState,
        page
      }))
    }
    return
  }

  useEffect(() => {
    setFetching(true)
    ;(async () => {
      const result = await getReviews(fetchOptions)
      if (result) {
        setReviewsData(result)
        setFetching(false)
      }
    })()
  }, [fetchOptions])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="mb-2">
            <CardBody>
              <Row className="mb-4">
                <div className="col-xl col-sm-4">
                  <Label>거래 명</Label>
                  <div className="search-box position-relative">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="거래 명"
                      style={{ borderRadius: '0.25rem' }}
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>

                <div className="col-xl col-sm-4">
                  <Label>작성자</Label>
                  <div className="position-relative search-box">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="작성자"
                      style={{ borderRadius: '0.25rem' }}
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
                <div className="col-xl col-sm-4">
                  <Label>기간</Label>
                  <Row>
                    <div className="col-sm-4 pr-0">
                      <Select
                        classNamePrefix="select2-selection form-control"
                        placeholder="선택"
                        title="기간"
                        value={{
                          value: searchQuery.range.value,
                          label: searchQuery.range.label
                        }}
                        options={dateOptions}
                      />
                    </div>
                    <div className="col-sm-8">
                      <DatePicker
                        className="form-control d-block"
                        placeholderText="선택"
                        selected={
                          searchQuery.date && new Date(searchQuery.date)
                        }
                        onChange={date => {
                          setSearchQuery(prevState => ({
                            ...prevState,
                            date: `${date.getFullYear()}-${(
                              '0' +
                              (date.getMonth() + 1)
                            ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
                          }))
                        }}
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-smd-1 align-self-end">
                  <div className="mt-3">
                    <Button type="button" color="success" className="w-sm">
                      검색
                    </Button>
                  </div>
                </div>
              </Row>
              {isFetching ? (
                <Indicator height={'30vh'} />
              ) : (
                <div className="table-responsive">
                  <Table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: '20px' }}>
                          <div
                            className="custom-control custom-checkbox"
                            style={{ zIndex: 0 }}
                          >
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              &nbsp;
                            </Label>
                          </div>
                        </th>
                        <th>No</th>
                        <th className="text-center">거래 명</th>
                        <th className="text-center">작성자</th>
                        <th className="text-center">어려웠던 점</th>
                        <th className="text-center">일시</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(reviewsData.reviews) &&
                        reviewsData.reviews.map((review, idx) => {
                          return (
                            <tr key={idx + 1}>
                              <td>
                                <div
                                  className="custom-control custom-checkbox"
                                  style={{ zIndex: 0 }}
                                >
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={review.id}
                                  />
                                  <Label className="custom-control-label">
                                    &nbsp;
                                  </Label>
                                </div>
                              </td>
                              <td>
                                {fetchOptions.page === 1
                                  ? idx + 1
                                  : idx +
                                    1 *
                                      ((fetchOptions.page - 1) *
                                        fetchOptions.amount) +
                                    1}
                              </td>
                              <td className="text-center">
                                <Link to={`/projects-detail/${review.id}`}>
                                  {review.project.name}
                                </Link>
                              </td>
                              <td className="text-center">
                                {review.user.username
                                  ? review.user.username
                                  : ''}
                              </td>
                              <td className="text-center">
                                <Link
                                  to={{
                                    pathname: `/reviews-detail/${review.id}`,
                                    state: { id: review.id }
                                  }}
                                >
                                  {Array.isArray(review.options) &&
                                    review.options
                                      .map(option => {
                                        if (
                                          option.name ===
                                          '거래하면서 가장 어려웠던 점은 무엇인가요?'
                                        ) {
                                          return option.contents
                                        }
                                      })
                                      .join(' ')}
                                </Link>
                              </td>
                              <td className="text-center">
                                {review.createdAt}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                  <Paginator
                    currentPage={fetchOptions.page}
                    pageSize={fetchOptions.amount}
                    total={reviewsData.max}
                    onChange={handlePagination}
                  />
                </div>
              )}
            </CardBody>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Reviews
