import React from 'react'
import styled from 'styled-components'
import DocumentCountCreateDashboard from './Create'
import DocumentCountShareDashboard from './Share'

export default function DocumentCountDashboard() {
  return (
    <Wrapper>
      <h2>거래문서 핵심지표 </h2>
      <ChartWrapper>
        <DocumentCountCreateDashboard />
        <DocumentCountShareDashboard />
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`
