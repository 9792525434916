import { all, put, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { setAppState, saveAppData } from '../reducers/appState';
import { Opcode } from "@payworkteam/server-product-types";
import { backofficeBaseUrl } from "../global/constants";
import {
	GET_RATEPLAN_PLANS,
} from '../reducers/ratePlan';

export function* getRatePlanPlans() {
	const accessToken = yield call([localStorage, 'getItem'], 'token') || '';
	
	yield put(setAppState("START_GET_RATEPLAN_PLANS", null, true));
	if (!accessToken) {
		yield put(setAppState("FAILED_GET_RATEPLAN_PLANS", null, false));
	}
	
	try {
		const response = yield call(axios, {
			method: "GET",
			url: `${backofficeBaseUrl}/ratePlan/plans`,
			validateStatus: () => true,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (response.data.opcode === Opcode.SUCCESS) {
			/** data handle */
			const handleData = response.data.plans.map((plan) => {
				return {
					id: plan.id,
					label: plan.name,
					value: plan.name
				}
			});
			
			yield put(saveAppData("ratePlanPlans", handleData));
			yield put(setAppState("SUCCESS_GET_RATEPLAN_PLANS", null, false));
		}

		else {
			yield put(setAppState("FAILED_GET_RATEPLAN_PLANS", response.data, false));
		}
	}

	catch(err) {
		console.log("get rateplan plans => ", err);
		yield put(setAppState("FAILED_GET_RATEPLAN_PLANS", err, false));
	}
}

const ratePlansSagas = [
	takeLatest(GET_RATEPLAN_PLANS, getRatePlanPlans),
]

export default ratePlansSagas;