export const GET_ROULETTE_LIST = 'reducers/event/GET_ROULETTE_LIST'
export const GET_NEW_ROULETTE_LIST = 'reducers/event/GET_NEW_ROULETTE_LIST'
export const GET_ROULETTE_PARTICIPATION_LIST =
  'reducers/event/GET_ROULETTE_PARTICIPATION_LIST'
export const GET_ROULETTE_SIGNUP_LIST =
  'reducers/event/GET_ROULETTE_SIGNUP_LIST'
export const GET_ALL_ROULETTE_LIST = 'reducers/event/GET_ALL_ROULETTE_LIST'
export const GET_ROULETTE_ITEM_LIST = 'reducers/event/GET_ROULETTE_ITEM_LIST'
export const CREATE_ROULETTE_ITEM = 'reducers/event/CREATE_ROULETTE_ITEM'
export const DELETE_ROULETTE_ITEM = 'reducers/event/DELETE_ROULETTE_ITEM'
export const UPDATE_ROULETTE_ITEM = 'reducers/event/UPDATE_ROULETTE_ITEM'
export const GET_ROULETTE_DETAIL = 'reducers/event/GET_ROULETTE_DETAIL'

export const getRouletteList = options => ({
  type: GET_ROULETTE_LIST,
  options
})

export const getNewRouletteList = options => ({
  type: GET_NEW_ROULETTE_LIST,
  options
})

export const getRouletteParticipationList = options => ({
  type: GET_ROULETTE_PARTICIPATION_LIST,
  options
})

export const getRouletteSignupList = options => ({
  type: GET_ROULETTE_SIGNUP_LIST,
  options
})

export const getAllRouletteList = options => ({
  type: GET_ALL_ROULETTE_LIST,
  options
})

export const getRouletteDetail = id => ({
  type: GET_ROULETTE_DETAIL,
  id
})

export const getRouletteItemList = () => ({
  type: GET_ROULETTE_ITEM_LIST
})

export const createRouletteItem = data => ({
  type: CREATE_ROULETTE_ITEM,
  data
})

export const deleteRouletteItem = data => ({
  type: DELETE_ROULETTE_ITEM,
  data
})

export const updateRouletteItem = data => ({
  type: UPDATE_ROULETTE_ITEM,
  data
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
