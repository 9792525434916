import { Fragment, useEffect, useState } from "react";
import styled from 'styled-components';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { connect } from "react-redux";

import { getBusinessmans, patchBusinessman } from '../../api/businessman';
import {
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Row,
	Button
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";

/** 사업자 상세 페이지 */
const BusinessDetail = () => {
	/** 현재 주소 추가경로 */
	const popbillId = window.location.pathname.split('/')[2];

	/** 상태목록 */
	/** 사업자 정보 */
	const [businessman, setBusinessman] = useState(null);

	/** 사업자 정보 수정 토글 */
	const [isModifyToggle, setIsModifyToggle] = useState(false);
	
	/** 사업자 수정 정보 */
	const [modifyBusinessman, setModifyBusinessman] = useState(null);
	
	/** 호출 함수 정의 */
	/** 대상 호출 */
	const callBusinessman = async (popbillId) => {
		await getBusinessmans({
			page: 0,
			amount: 1,
			search: popbillId
		})
		.then((res) => {
			const {
				popbills,
			} = res;
			
			if (res.opcode !== 0) {
				return false;
			}

			const popbill = popbills[0];
			if (popbill) {
				/** 리스트 state 업데이트 */
				setBusinessman(popbill);
			}
		});
	}

	/** 수정 요청 */
	const callModifyBusinessman = async () => {
		const confirm = window.confirm("수정을 진행하면 해당 사업자를 이용중인 모든 사용자의 사업장 정보가 수정됩니다. 진행하시겠습니까?");
		if (!confirm) {
			return false;
		}
		
		await patchBusinessman({
			popbillId,
			body: {
				companyName: modifyBusinessman.companyName,
				representative: modifyBusinessman.representative,
				companyAddress: modifyBusinessman.companyAddress,
				sectors: modifyBusinessman.sectors,
				businessCondition: modifyBusinessman.businessCondition
			}
		})
		.then((res) => {
			if (res.opcode !== 0) {
				alert(`수정에 실패했어요. [${res.message}]`);
				return false;
			}

			/** 수정 요청 후 상세 조회 */
			callBusinessman(popbillId);

			/** 토글 상태 해제 */
			setIsModifyToggle(false);

			/** 알림 */
			alert("수정이 완료되었어요.")
			
			return true;
		});
	}

	/** 훅 */
	/** 첫 로딩 */
	useEffect(() => {
		/** 상세 조회 */
		callBusinessman(popbillId);
	}, []);

	/** 사업자 정보 훅 */
	useEffect(() => {
		/** 비어있으면 인풋 데이터 상속 */
		if (businessman && !modifyBusinessman) {
			setModifyBusinessman({
				companyName: businessman.companyName,
				representative: businessman.representative,
				companyAddress: businessman.companyAddress,
				sectors: businessman.sectors,
				businessCondition: businessman.businessCondition
			});
		}
	}, [businessman]);
	
	return (
		<Fragment>
			<div className = "page-content">
				<Breadcrumb title={ (businessman && `사업자 상세 (${businessman.companyName} - ${businessman.companyRegistrationNumber})`) || `사업자 상세 ()` } breadcrumbItem="Business Detail" />
				<Container fluid>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									{/* 주요 상세 정보 */}
									<InfoHeder>사업장 정보</InfoHeder>
									<DetailInfoGridTemplet>
										{/* 사업장등록 번호 */}
										{ !isModifyToggle &&
										<DetailInfoGridItem>
											<Label>사업장등록 번호</Label>
											<Input type="text" value = { (businessman && businessman.companyRegistrationNumber) || "" } disabled />
										</DetailInfoGridItem>
										}
										
										{/* 사업장명 */}
										<DetailInfoGridItem>
											<Label>사업장명</Label>
											<Input
												type="text"
												value = {
													(!isModifyToggle && businessman) ? businessman.companyName :
													(isModifyToggle && modifyBusinessman) ? modifyBusinessman.companyName :
													""
												}
												disabled={!isModifyToggle}
												onChange={(e) => {
													setModifyBusinessman({
														...modifyBusinessman,
														companyName: e.target.value
													});
												}}
											/>
										</DetailInfoGridItem>
										
										{/* 대표자명 */}
										<DetailInfoGridItem>
											<Label>대표자명</Label>
											<Input
												type="text"
												value = {
													(!isModifyToggle && businessman) ? businessman.representative :
													(isModifyToggle && modifyBusinessman) ? modifyBusinessman.representative :
													""
												}
												disabled={!isModifyToggle}
												onChange={(e) => {
													setModifyBusinessman({
														...modifyBusinessman,
														representative: e.target.value
													});
												}}
											/>
										</DetailInfoGridItem>

										{/* 사업장주소 */}
										<DetailInfoGridItem>
											<Label>사업장주소</Label>
											<Input
												type="text"
												value = {
													(!isModifyToggle && businessman) ? businessman.companyAddress :
													(isModifyToggle && modifyBusinessman) ? modifyBusinessman.companyAddress :
													""
												}
												disabled={!isModifyToggle}
												onChange={(e) => {
													setModifyBusinessman({
														...modifyBusinessman,
														companyAddress: e.target.value
													});
												}}
											/>
										</DetailInfoGridItem>

										{/* 업종 */}
										<DetailInfoGridItem>
											<Label>업종</Label>
											<Input
												type="text"
												value = {
													(!isModifyToggle && businessman) ? businessman.sectors :
													(isModifyToggle && modifyBusinessman) ? modifyBusinessman.sectors :
													""
												}
												disabled={!isModifyToggle}
												onChange={(e) => {
													setModifyBusinessman({
														...modifyBusinessman,
														sectors: e.target.value
													});
												}}
											/>
										</DetailInfoGridItem>
										
										{/* 업태 */}
										<DetailInfoGridItem>
											<Label>업태</Label>
											<Input
												type="text"
												value = {
													(!isModifyToggle && businessman) ? businessman.businessCondition :
													(isModifyToggle && modifyBusinessman) ? modifyBusinessman.businessCondition :
													""
												}
												disabled={!isModifyToggle}
												onChange={(e) => {
													setModifyBusinessman({
														...modifyBusinessman,
														businessCondition: e.target.value
													});
												}}
											/>
										</DetailInfoGridItem>
										
										{/* 등록일 */}
										{ !isModifyToggle &&
										<DetailInfoGridItem>
											<Label>등록일</Label>
											<Input type="text" value = { (businessman && businessman.createdAt) || "" } disabled />
										</DetailInfoGridItem>
										}

										{/* 업체 관리자 이름 */}
										{ !isModifyToggle &&
										<DetailInfoGridItem>
											<Label>업체 관리자 이름</Label>
											<Input type="text" value = { (businessman && businessman.managerName) || "" } disabled />
										</DetailInfoGridItem>
										}

										{/* 업체 관리자 전화번호 */}
										{ !isModifyToggle &&
										<DetailInfoGridItem>
											<Label>업체 관리자 전화번호</Label>
											<Input type="text" value = { (businessman && businessman.managerPhone) || "" } disabled />
										</DetailInfoGridItem>
										}

										{/* 업체 관리자 이메일 */}
										{ !isModifyToggle &&
										<DetailInfoGridItem>
											<Label>업체 관리자 이메일</Label>
											<Input type="text" value = { (businessman && businessman.managerEmail) || "" } disabled />
										</DetailInfoGridItem>
										}
									</DetailInfoGridTemplet>

									{/* 주요 상세 정보 */}
									{ !isModifyToggle && <div>
									<InfoHeder>사업자 인증서 정보</InfoHeder>
									<DetailInfoGridTemplet>
										{/* 인증서 발급자 DN */}
										<DetailInfoGridItem>
											<Label>인증서 발급자 DN</Label>
											<Input type="text" value = { (businessman && businessman.certIssuerDN) || "" } disabled />
										</DetailInfoGridItem>

										{/* 등록 인증서 DN */}
										<DetailInfoGridItem>
											<Label>등록 인증서 DN</Label>
											<Input type="text" value = { (businessman && businessman.certSubjectDN) || "" } disabled />
										</DetailInfoGridItem>

										{/* 인증서 종류 */}
										<DetailInfoGridItem>
											<Label>인증서 종류</Label>
											<Input type="text" value = { (businessman && businessman.certIssuerName) || "" } disabled />
										</DetailInfoGridItem>

										{/* 인증서 oid */}
										<DetailInfoGridItem>
											<Label>인증서 oid</Label>
											<Input type="text" value = { (businessman && businessman.certOid) || "" } disabled />
										</DetailInfoGridItem>

										{/* 인증서 등록 담당자 성명 */}
										<DetailInfoGridItem>
											<Label>인증서 등록 담당자 성명</Label>
											<Input type="text" value = { (businessman && businessman.certRegContactName) || "" } disabled />
										</DetailInfoGridItem>

										{/* 인증서 등록 일시 */}
										<DetailInfoGridItem>
											<Label>인증서 등록 일시</Label>
											<Input type="text" value = { (businessman && businessman.certRegDT) || "" } disabled />
										</DetailInfoGridItem>

										{/* 인증서 만료 일시 */}
										<DetailInfoGridItem>
											<Label>인증서 만료 일시</Label>
											<Input type="text" value = { (businessman && businessman.certExpireDT) || "" } disabled />
										</DetailInfoGridItem>
									</DetailInfoGridTemplet>
									</div>}

									{/* 수정하기 */}
									<div style={{ margin: "20px" }}>
										{/* 수정하기 상태 활성화 버튼 */}
										{ !isModifyToggle &&
										<Button
											color="primary"
											className="btn btn-primary btn-lg btn-block waves-effect waves-light"
											onClick={() => {
												setIsModifyToggle(true);
											}
										}>
											수정하기
										</Button>
										}

										{/* 수정하기 상태 */}
										{ isModifyToggle &&
										<div>
											<ModifyButton
												color="primary"
												className="btn btn-primary btn-lg waves-effect waves-light"
												onClick={() => {
													callModifyBusinessman();
												}
											}>
												수정
											</ModifyButton>
											<ModifyButton
												color="danger"
												className="btn btn-danger btn-lg waves-effect waves-light"
												onClick={() => {
													setIsModifyToggle(false);
												}
											}>
												취소
											</ModifyButton>
										</div>
										}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment>
	)
}

const mapStateToProps = (state) => ({
	appState: state.appState,
	state: state.appState.state,
	params: state.appState.params,
	data: state.appState.data
});

/** style components */
/** 정보 세분화 타이틀 */
const InfoHeder = styled.h2`
	margin-bottom: 20px;
	margin-top: 20px;
`

/** 상세 정보 그리드 */
const DetailInfoGridTemplet = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 1rem;
`;

const DetailInfoGridItem = styled.div`
	margin-left: 18px;
	margin-right: 18px;
	margin-bottom: 10px;
`;

/** 수정 버튼 스타일 */
const ModifyButton = styled(Button)`
	width: 50%;
`;

export default connect(mapStateToProps)(BusinessDetail);