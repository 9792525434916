import React, { useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { connect } from 'react-redux'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Select from 'react-select'
import { Link } from 'react-router-dom'

const categoryGroup = [
  {
    label: '온라인 ',
    options: [
      { label: '마스터 ', value: 'Master' },
      { label: '일반', value: 'Normal' }
    ]
  }
]

const AdminRegister = ({ history }) => {
  const [error, setError] = useState('')
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    grade: null
  })

  const handleInputs = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleChange = options => {

    return setFormData(prevState => ({
      ...prevState,
      tags: Array.isArray(options) && options.map(option => option.value)
    }))
  }

  const onSubmit = async event => {
    event.preventDefault()
    // const result = await createUser(formData);

    // if (result?.user) {
    //   history.push("/admins");
    // } else {
    //   console.log(result);
    //   setError(result);
    // }
    // return setFormData({
    //   username: "",
    //   email: "",
    //   phone: "",
    //   description: "",
    //   tags: null,
    // });
  }

  const handleSearch = e => {
    const input = e.target.value
    // if (inputPhone.replace(/-/g, "").length === 11) {
    //   let filterData = usersData.filter((f) => f.phone.replace(/-/g, "").includes(String(inputPhone.replace(/-/g, ""))));
    //   if (filterData.length > 0) {
    //     setInputData({ ...inputData, phoneNumber: inputPhone, searchResult: filterData[0], userName: filterData[0].userName });
    //     // getBoatData(filterData[0].userName);
    //   } else {
    //     setInputData({ ...inputData, phoneNumber: inputPhone, userName: "" });
    //   }
    // } else {
    //   setInputData({
    //     ...inputData,
    //     searchResult: "",
    //     phoneNumber: inputPhone,
    //     userName: "",
    //   });
    // }

    setFormData({ ...formData, username: input })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ height: '80vh' }}>
          <Link to={'/admins'}>
            <Breadcrumbs title="< 관리자 등록하기" breadcrumbItem="Add Admin" />
          </Link>
          <Alert isOpen={error} color={'danger'}>
            {error}
          </Alert>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>관리자명</Label>
                          {/* <Input
                            required
                            name="username"
                            type="text"
                            className="form-control"
                            placeholder={alphaKoreanPlaholder}
                            pattern={alphaKoreanPattern}
                            value={formData.username}
                            onChange={handleInputs}
                          /> */}
                          <Input
                            className="form-control"
                            name="username"
                            value={formData.username}
                            onChange={handleSearch}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>휴대폰 번호</Label>
                          <Input
                            required
                            className="form-control"
                            type="tel"
                            name="phone"
                            placeholder="010-0000-0000"
                            pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                            minLength={13}
                            maxLength={13}
                            value={formData.phone}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>이메일 </Label>
                          <Input
                            required
                            name="email"
                            type="email"
                            className="form-control"
                            value={formData.email}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>권한 </Label>
                          <Select
                            isMulti={false}
                            defaultValue={formData.grade}
                            onChange={handleChange}
                            options={categoryGroup}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block mr-1 waves-effect waves-light"
                        >
                          등록하기
                        </Button>
                      </Col>
                    </Row>
                    {/*<Button type="submit" color="secondary" className="waves-effect">Cancel</Button>*/}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data
})
const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRegister)
