import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import React, { useEffect, useState } from 'react'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Link, useParams } from 'react-router-dom'
import avatar3 from '../../assets/images/users/avatar-3.jpg'
import { getReview } from '../../api/review'

const dateOptions = [
  { value: 'AK', label: '전체' },
  { value: 'HI', label: '일주일' },
  { value: 'CA', label: '1개월' },
  { value: 'NV', label: '2개월' },
  { value: 'NV', label: '3개월' },
  { value: 'NV', label: '6개월' },
  { value: 'OR', label: '1년' }
]

const ReviewsDetail = props => {
  const test = useParams()
  const [review, setReview] = useState({
    star: 0,
    createdAt: '',
    options: [
      {
        name: '',
        contents: ''
      }
    ],
    questions: [
      {
        name: '페이워크에게 제안할 기능을 알려주세요!',
        contents: ''
      },
      {
        name: '페이워크 앱이 거래하는데 도움을 주었나요?',
        contents: ''
      },
      {
        name: '거래 진행 중 도움말이 유용했나요?',
        contents: ''
      }
    ],
    user: {
      username: '',
      phone: ''
    },
    project: {
      name: '',
      price: 0
    }
  })
  const [isFetching, setFetching] = useState(true)
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10
  })

  // const getReview = async () => {
  //   let config = {
  //     headers: {
  //       Authorization:
  //         "Bearer " +
  //         "qubeEG0bc+2MA2pFtN072LoSxZeauKgpRpUUuvwLEyBtWREsEiRuaxGkSDKv47m2jU4reRL5+QeTDQLkXk+E2Kk//h+w/y4b89nkj2XphEmkx/d8/cl5K9FrmNfjxjY=",
  //     },
  //   };
  //   await axios
  //     .get("/backoffice/project", config)
  //     .then((result) => console.log(result.data.projects))
  //     .catch((err) => console.log(err));
  // };

  useEffect(() => {
    setFetching(true)
    ;(async () => {
      const result = await getReview(props.location.state.id, fetchOptions)
      if (result.opcode === 0) {
        setReview(result.review)
      }
    })()
  }, [fetchOptions])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container box>
          <Link to={'/reviews'}>
            <Breadcrumbs title="<  후기상세" />
          </Link>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label>작성자</Label>
                        <Row className="px-2 align-items-center">
                          <img
                            className="img-thumbnail rounded-circle avatar-sm mr-2"
                            alt="Skote"
                            src={avatar3}
                          />
                          <div
                            className="d-flex"
                            style={{ flexDirection: 'column' }}
                          >
                            <Label>
                              {review.user.username ? review.user.username : ''}
                            </Label>
                            <Label style={{ margin: 0 }}>
                              {review.user.phone}
                            </Label>
                          </div>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label>거래명</Label>
                          <Input
                            type="text"
                            className="form-control"
                            value={review.project.name}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label>가격</Label>
                          <Input
                            type="text"
                            className="form-control"
                            value={review.project.price}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label>별점</Label>
                          <Input
                            type="text"
                            className="form-control"
                            value={review.star}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {review.options.map((item, index) => (
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>{item.name}</Label>
                            <textarea
                              className="form-control"
                              rows="2"
                              value={`${item.contents}`}
                            ></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                    ))}
                    {review.questions.map((item, index) => (
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>{item.name}</Label>
                            <textarea
                              className="form-control"
                              rows="2"
                              value={`${item.contents}`}
                            ></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                    ))}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReviewsDetail
