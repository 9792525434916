import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import Daily from './Daily'
import WSU from './WSU'
import Monthly from './Monthly'

export default function Conversion() {
  const [type, setType] = useState('Monthly')
  const onClickButton = useCallback(type => {
    setType(type)
  }, [])

  const [totlaPrice, setTotalPrice] = useState(0)
  const [range, setRange] = useState('')

  return (
    <Wrapper>
      <Title>
        <h2>서류 작성 전환율</h2>
      </Title>
      <p>전체 회원 서류작성 전환율</p>
      <ButtonWrapper>
        <Button
          color={type === 'Monthly' ? 'primary' : 'secondary'}
          active={type === 'Monthly'}
          onClick={() => {
            setTotalPrice(0)
            setRange('')
            onClickButton('Monthly')
          }}
        >
          Monthly
        </Button>
        {/* <Button
          color={type === 'WSU' ? 'primary' : 'secondary'}
          active={type === 'WSU'}
          onClick={() => onClickButton('WSU')}
        >
          weekly
        </Button> */}
        <Button
          color={type === 'Daily' ? 'primary' : 'secondary'}
          active={type === 'Daily'}
          onClick={() => {
            setTotalPrice(0)
            setRange('')
            onClickButton('Daily')
          }}
        >
          Daily
        </Button>
      </ButtonWrapper>
      <ChartWrapper>
        {type === 'Daily' ? (
          <Daily
            type={type}
            setTotalPrice={setTotalPrice}
            setRange={setRange}
          />
        ) : // type === 'WSU' ? (
        //   <WSU type={type} />
        // ) :
        type === 'Monthly' ? (
          <Monthly
            type={type}
            setTotalPrice={setTotalPrice}
            setRange={setRange}
          />
        ) : (
          <></>
        )}
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Title = styled.span`
  display: flex;
  flex-directino: row;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`

const ChartWrapper = styled.div``
