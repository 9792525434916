import React, { useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { connect } from 'react-redux'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Link } from 'react-router-dom'

const categoryGroup = [
  {
    label: '온라인 ',
    options: [
      { label: '개발 ', value: 'Development' },
      { label: '디자인', value: 'Design' },
      { label: '영상', value: 'Video' }
    ]
  },
  {
    label: '오프라인',
    options: [
      { label: '수리', value: 'Repair' },
      { label: '정비', value: 'Flashlight' },
      { label: '설치', value: 'Toilet Paper' }
    ]
  }
]

const Profile = ({ history }) => {
  const [error, setError] = useState('')
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    password: ''
  })

  const handleInputs = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleChange = options => {
    return setFormData(prevState => ({
      ...prevState,
      tags: Array.isArray(options) && options.map(option => option.value)
    }))
  }

  const onSubmit = async event => {
    event.preventDefault()
    // console.log(formData);
    // const result = await createUser(formData);
    // console.log(result);
    // if (result?.user) {
    //   history.push("/users");
    // } else {
    //   // console.log(result);
    //   setError(result);
    // }
    // return setFormData({
    //   username: "",
    //   email: "",
    //   phone: "",
    //   kakaoId: "",
    //   description: "",
    //   tags: null,
    // });
  }

  // 에러 메시지: "영문과 한글만 사용하여 2~16자 이내로 입력해주세요."
  const alphaKoreanPattern = '^[ㄱ-ㅎ|가-힣|a-z|A-Z]{1,16}$'
  const alphaKoreanPlaholder = '한글과 영문 (2자 ~ 16자)'

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ height: '80vh' }}>
          <Link to={'/dashboard'}>
            <Breadcrumbs
              title="< 멤버 정보 변경"
              breadcrumbItem="Add Profile"
            />
          </Link>
          <Alert isOpen={error} color={'danger'}>
            {error}
          </Alert>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>회원명</Label>
                          <Input
                            required
                            name="username"
                            type="text"
                            className="form-control"
                            // placeholder={alphaKoreanPlaholder}
                            // pattern={alphaKoreanPattern}
                            value={formData.username}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>휴대폰 번호</Label>
                          <Input
                            required
                            className="form-control"
                            type="tel"
                            name="phone"
                            placeholder="010-0000-0000"
                            pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                            minLength={13}
                            maxLength={13}
                            value={formData.phone}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>이메일 </Label>
                          <Input
                            required
                            name="email"
                            type="email"
                            className="form-control"
                            value={formData.email}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>비밀번호 </Label>
                          <Input
                            required
                            name="password"
                            type="password"
                            className="form-control"
                            value={formData.password}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block mr-1 waves-effect waves-light"
                        >
                          변경
                        </Button>
                      </Col>
                    </Row>
                    {/*<Button type="submit" color="secondary" className="waves-effect">Cancel</Button>*/}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data
})
const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
