import React, { useEffect, useState } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from 'reactstrap'
import Select from 'react-select'
import { InputAdornment, InputBase, NativeSelect } from '@material-ui/core'
import styled from 'styled-components'

// components
import Indicator from '../../../components/Common/Indicator'
import Paginator from '../../../components/Common/Paginator'

// utils
import { sortingData } from '../../../helpers/utils'

// api
import { createComment, deleteComment } from '../../../api/feed'
import { getTesters } from '../../../api/tester'

// images
import Emoji1 from '../assets/images/emoji1_30.png'

const emojiList = [Emoji1]

const FeedComments = ({
  testerData,
  formData,
  feedData,
  usersData,
  onChangeCreateModal,
  onSaveComment,
  createModal,
  handleInputs,
  onChangeTester,
  targetComment,
  setTargetComment,
  handleDelete,
  setDeleteModal,
  deleteModal,
  commentReactionModal,
  onChangeCommentReactionModal,
  onChangeCommentReactionTester,
  handleFeedCommentReaction
}) => {
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [page, setPage] = useState(1)

  const handlePagination = (changePage, pageSize) => {
    if (page !== changePage) {
      return setPage(changePage)
    }
    return
  }

  return (
    <Container fluid>
      <Row>
        <div className="col-xl col-sm-6">
          <Label>
            <h3>
              댓글&nbsp;
              {Array.isArray(feedData.feedComment) &&
                feedData.feedComment?.length}
              개
            </h3>
          </Label>
        </div>
        <Col sm="8">
          <div className="text-sm-right">
            <Button
              type="button"
              color="success"
              className="btn-rounded waves-effect waves-light mb-2 mr-2"
              onClick={onChangeCommentReactionModal}
              disabled={selectedBoxes.length === 0}
            >
              <i className="mdi mdi-emoticon-happy-outline"></i> 공감
            </Button>
            <Button
              type="button"
              color="success"
              className="btn-rounded waves-effect waves-light mb-2 mr-2"
              onClick={onChangeCreateModal}
            >
              <i className="mdi mdi-plus mr-1"></i> 댓글
            </Button>

            <Button
              type="button"
              color="danger"
              className="btn-rounded waves-effect waves-light mb-2 mr-2"
              onClick={() => setDeleteModal(!deleteModal)}
              disabled={selectedBoxes.length === 0}
            >
              <i className="mdi mdi-minus mr-1"></i> 삭제
            </Button>
          </div>
        </Col>

        <Modal
          centered
          scrollable
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(!deleteModal)
            setSelectedBoxes([])
          }}
        >
          <ModalHeader
            toggle={() => {
              setDeleteModal(!deleteModal)
              setSelectedBoxes([])
            }}
            style={{
              borderBottom: '2px solid #2a3042'
            }}
          >
            <div>댓글 삭제</div>
          </ModalHeader>
          <ModalBody
            className="d-flex align-items-center justify-content-center"
            style={{
              height: '150px',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                marginBottom: '8px'
              }}
            >{`선택한 댓글을 삭제하시겠습니까?`}</div>
            <div>{`댓글이 삭제됩니다`}</div>
          </ModalBody>
          <ModalFooter
            onClick={() => setDeleteModal(!deleteModal)}
            style={{
              justifyContent: 'center',
              borderTop: '2px solid #e2e2e2'
            }}
          >
            <Button
              color="danger"
              outline
              onClick={e => {
                handleDelete(e)
                setSelectedBoxes([])
              }}
            >
              삭제하기
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          centered
          scrollable
          isOpen={commentReactionModal}
          toggle={onChangeCommentReactionModal}
        >
          <ModalHeader
            toggle={onChangeCommentReactionModal}
            style={{
              borderBottom: '2px solid #2a3042'
            }}
          >
            <div>공감 추가</div>
          </ModalHeader>
          <ModalBody
            className="d-flex align-items-center justify-content-center"
            style={{
              minHeight: '400px',
              flexDirection: 'column'
            }}
          >
            <Form>
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label>테스트 계정</Label>
                    <NativeSelect
                      className="form-control"
                      name="boatSale"
                      onChange={e => onChangeCommentReactionTester(e)}
                      disableUnderline
                    >
                      {testerData.map(ele => (
                        <option value={ele.id}>{ele.username}</option>
                      ))}
                    </NativeSelect>
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <Label>공감</Label>
                    <ReactionWrapper>
                      {emojiList.map((emotion, i) => {
                        return (
                          <img
                            src={emotion}
                            alt=""
                            style={{
                              width: '24px',
                              height: '24px',
                              cursor: 'pointer'
                            }}
                            onClick={e => handleFeedCommentReaction(e, i)}
                          />
                        )
                      })}
                    </ReactionWrapper>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        <Modal
          centered
          scrollable
          isOpen={createModal}
          toggle={onChangeCreateModal}
        >
          <ModalHeader
            toggle={onChangeCreateModal}
            style={{
              borderBottom: '2px solid #2a3042'
            }}
          >
            <div>댓글 추가</div>
          </ModalHeader>
          <ModalBody
            className="d-flex align-items-center justify-content-center"
            style={{
              minHeight: '400px',
              flexDirection: 'column'
            }}
          >
            <Form onSubmit={onSaveComment}>
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label>테스트 계정</Label>
                    <NativeSelect
                      className="form-control"
                      name="boatSale"
                      onChange={e => onChangeTester(e)}
                      disableUnderline
                    >
                      {testerData.map(ele => (
                        <option value={ele.id}>{ele.username}</option>
                      ))}
                    </NativeSelect>
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <Label>댓글</Label>
                    <Input
                      required
                      name="comment"
                      type="text"
                      className="form-control"
                      placeholder="댓글을 입력해주세요"
                      value={formData.comment}
                      onChange={handleInputs}
                    />
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-block mr-1 waves-effect waves-light"
                  >
                    등록하기
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        <Col lg="12">
          <Card>
            <CardBody>
              <div className="table-responsive" style={{ minHeight: '850px' }}>
                <Table className="table table-centered table-nowrap">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: '5%' }}>
                        <div
                          className="custom-control custom-checkbox"
                          style={{ zIndex: 0 }}
                        ></div>
                      </th>
                      <th className="text-center" style={{ width: '10%' }}>
                        생성일
                      </th>
                      <th className="text-center" style={{ width: '30%' }}>
                        내용
                      </th>
                      <th className="text-center" style={{ width: '10%' }}>
                        이름
                      </th>
                      <th className="text-center" style={{ width: '15%' }}>
                        전화번호
                      </th>
                      <th className="text-center" style={{ width: '15%' }}>
                        닉네임
                      </th>
                      <th className="text-center" style={{ width: '5%' }}>
                        공감
                      </th>
                      <th className="text-center" style={{ width: '5%' }}>
                        대댓글
                      </th>
                      <th className="text-center" style={{ width: '5%' }}>
                        삭제
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(feedData.feedComment) &&
                      sortingData(feedData.feedComment)
                        .slice(10 * (page - 1), 10 * (page - 1) + 10)
                        .map((comment, idx) => {
                          return (
                            <tr key={idx + 1}>
                              <td style={{ height: '65px' }}>
                                <div
                                  className="custom-control custom-checkbox"
                                  style={{ zIndex: 0 }}
                                >
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={comment.id}
                                    checked={targetComment.includes(comment.id)}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setSelectedBoxes([comment.id])
                                        setTargetComment(comment.id)
                                      } else {
                                        setSelectedBoxes(
                                          selectedBoxes.filter(
                                            b => b !== comment.id
                                          )
                                        )
                                        setTargetComment(
                                          selectedBoxes.filter(
                                            b => b !== comment.id
                                          )
                                        )
                                      }
                                    }}
                                  />

                                  {!comment.isRemoved && (
                                    <Label
                                      className="custom-control-label"
                                      htmlFor={comment.id}
                                    >
                                      &nbsp;
                                    </Label>
                                  )}
                                </div>
                              </td>
                              <td className="text-center">
                                {comment.createdAt &&
                                  comment.createdAt.slice(0, 10)}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  wordBreak: 'break-all',
                                  whiteSpace: 'pre-wrap'
                                }}
                              >
                                {comment.content}
                              </td>
                              <td className="text-center">
                                {Array.isArray(usersData) &&
                                  usersData.length > 0 &&
                                  usersData.filter(
                                    f => f.id === comment.userId
                                  )[0].username}
                              </td>
                              <td className="text-center">
                                {Array.isArray(usersData) &&
                                  usersData.length > 0 &&
                                  usersData.filter(
                                    ele => ele.id === comment.userId
                                  )[0].phone}
                              </td>
                              <td className="text-center">
                                {comment.feedUserInfoPrint &&
                                  comment.feedUserInfoPrint.nickname}
                              </td>
                              <td className="text-center">
                                {comment.feedCommentReaction.length > 0
                                  ? `${comment.feedCommentReaction.length}개`
                                  : '0개'}
                              </td>
                              <td className="text-center">
                                {comment.feedCommentId ? 'O' : 'X'}
                              </td>
                              <td className="text-center">
                                {comment.isRemoved ? 'O' : 'X'}
                              </td>
                            </tr>
                          )
                        })}
                  </tbody>
                </Table>
                <Paginator
                  currentPage={page}
                  pageSize={10}
                  total={Math.ceil(
                    feedData.feedComment && feedData.feedComment.length / 10
                  )}
                  onChange={handlePagination}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default FeedComments

const ReactionWrapper = styled.div`
  display: flex;
  /* justify-content: space-evenly; */
`
