import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import logo from '../../assets/images/logo.svg'
// import profile from '../../assets/images/profile-img.png'

// images
import profile from '../../assets/images/logo/logo_img_b.png'

import {
  changePassword,
  submitPasswordChange,
  submitPasswordChangeVerify
} from '../../reducers/account'

const ForgetPasswordPage = ({
  history,
  state,
  changePassword,
  submitPasswordChange,
  submitPasswordChangeVerify,
  params
}) => {
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    code: '',
    verifyId: '',
    password: ''
  })
  const [emailCheck, setEmailCheck] = useState(false)
  const [codeCheck, setCodeCheck] = useState(false)
  const [error, setError] = useState('')
  const [status, setStatus] = useState({
    color: 'danger',
    isError: false,
    errorMsg: ''
  })

  const handleInputs = e => {
    // setError('')
    setStatus({ ...status, isError: false })
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (state === 'SUCCESS_SUBMIT_PASSWORD_CHANGE') {
      setEmailCheck(true)
      setStatus({
        ...status,
        color: 'success',
        isError: true,
        errorMsg: '휴대폰으로 인증번호가 전송되었습니다'
      })
    } else if (state === 'SUCCESS_SUBMIT_PASSWORD_CHANGE_VERIFY') {
      setCodeCheck(true)
      setFormData({ ...formData, verifyId: params.verifyId, code: '' })
    } else if (state === 'SUCCESS_CHANGE_PASSWORD') {
      setFormData({ ...formData, verifyId: params.verifyId })
      history.push('/login')
    } else if (state === 'FAILED_CHANGE_PASSWORD') {
      setStatus({
        ...status,
        color: 'danger',
        isError: true,
        errorMsg: '비밀번호 변경이 실패하였습니다'
      })
    } else if (state === 'FAILED_SUBMIT_PASSWORD_CHANGE') {
      setStatus({
        ...status,
        color: 'danger',
        isError: true,
        errorMsg: '관리자로 등록되지 않은 이메일입니다'
      })
    } else if (state === 'FAILED_SUBMIT_PASSWORD_CHANGE_VERIFY') {
      setStatus({
        ...status,
        color: 'danger',
        isError: true,
        errorMsg: '인증번호가 잘못 입력되었습니다'
      })
    }
  }, [state])

  const handlerEmailSubmit = e => {
    e.persist()
    submitPasswordChange(formData.email)
  }

  const handlerCodeSubmit = e => {
    e.persist()
    submitPasswordChangeVerify(formData.email, formData.code)
  }

  const handlerChangePassword = e => {
    e.persist()
    changePassword(formData.password, formData.verifyId)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">비밀번호 변경</h5>
                        <p>페이워크 관리자 비밀번호 변경</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}
                      >
                        <img
                          src={profile}
                          width="150"
                          height="50"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Alert
                      color={status.color}
                      isOpen={status.isError}
                      toggle={() => setStatus({ ...status, isError: false })}
                    >
                      {status.errorMsg}
                    </Alert>

                    {/* <Alert isOpen={error} color={'danger'}>
                      {error}
                    </Alert> */}

                    <AvForm
                      className="form-horizontal mt-4"
                      // onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <label>
                        {emailCheck && codeCheck ? '인증번호' : '이메일'}
                      </label>
                      <PhoneInputWrap>
                        <InputStyle
                          // name="email"
                          name={emailCheck && codeCheck ? 'verifyId' : 'email'}
                          placeholder={
                            emailCheck && codeCheck
                              ? '인증번호를 입력해주세요'
                              : '이메일을 입력해주세요'
                          }
                          type="text"
                          value={
                            emailCheck && codeCheck
                              ? formData.verifyId
                              : formData.email
                          }
                          onChange={e => {
                            if (emailCheck && codeCheck) {
                              handlerChangePassword(e)
                            } else {
                              handleInputs(e)
                            }
                          }}
                        />

                        <button
                          className="verify-btn"
                          type="button"
                          onClick={e => handlerEmailSubmit(e)}
                        >
                          인증번호 전송
                        </button>
                      </PhoneInputWrap>

                      <label style={{ marginTop: '10px' }}>
                        {emailCheck && codeCheck ? '비밀번호' : '인증번호 '}
                      </label>
                      <InputStyle
                        name={emailCheck && codeCheck ? 'password' : 'code'}
                        value={
                          emailCheck && codeCheck
                            ? formData.password
                            : formData.code
                        }
                        type={emailCheck && codeCheck ? 'password' : 'text'}
                        onChange={handleInputs}
                        placeholder={
                          emailCheck && codeCheck
                            ? '변경할 비밀번호를 입력해주세요'
                            : '인증번호를 입력해주세요'
                        }
                      />
                      <Row className="form-group">
                        <Col className="text-center">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            style={{ marginTop: '30px' }}
                            onClick={e => {
                              if (emailCheck && codeCheck) {
                                handlerChangePassword(e)
                              } else {
                                handlerCodeSubmit(e)
                              }
                            }}
                          >
                            {emailCheck && codeCheck
                              ? '비밀번호 변경'
                              : '인증번호 전송'}
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to&nbsp;
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>
                </p>
                <p>© {new Date().getFullYear()} 페이워크 어드민</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  isloading: state.appState.data.isloading,
  data: state.appState.data
})

const mapDispatchToProps = dispatch => ({
  submitPasswordChange: email => dispatch(submitPasswordChange(email)),
  submitPasswordChangeVerify: (email, code) =>
    dispatch(submitPasswordChangeVerify(email, code)),
  changePassword: (password, verifyId) =>
    dispatch(changePassword(password, verifyId))
})

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(ForgetPasswordPage)
)

// export default withRouter(ForgetPasswordPage);

const PhoneInputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  width: 380px;
  /* height: 40px; */
  height: 36px;
  border-radius: 10px;
  /* border: 1.5px solid black; */
  border: 1px solid #ced4da;
  box-shadow: none;

  input {
    width: 380px;
    /* padding: 0 20px; */
    height: 34px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 14px;
    outline: 0;
    border: 0;
    color: black;

    &:focus {
      border: 0;
      outline: none;
    }
  }

  .verify-btn {
    padding: 2px 0;
    min-width: 85px;
    font-size: 14px;
    line-height: 14px;
    border: 0;
    background-color: #fff;
  }
`

const InputStyle = styled.input`
  width: 380px;
  padding: 2px 20px 0;
  /* height: 40px; */
  height: 36px;
  font-size: 14px;
  line-height: 14px;
  outline: 0;
  box-shadow: none;
  border-radius: 10px;
  /* border: 1.5px solid black; */
  border: 1px solid #ced4da;

  &::-webkit-input-placeholder {
  }
  &:focus {
    /* border: "1.5px solid black"; */
    /* box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.07); */
  }
`
