import React, { useState, useEffect } from 'react'

// tools
import { Spinner } from 'reactstrap'

// const Indicator = ({ type = 'grow', color = 'primary', height = '100vh' }) => {
//   return (
//     <div
//       className="d-flex justify-content-center align-items-center"
//       style={{ height: height }}
//     >
//       <Spinner type={type} color={color} />
//     </div>
//   )
// }

const Indicator = ({ type = 'grow', color = 'primary', height = '100vh' }) => {
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds >= 0) {
        setSeconds(seconds + 1)
      }
    }, 1000)
    return () => clearInterval(countdown)
  }, [seconds])

  const RenderSpinner = () => {
    return <Spinner type={type} color={color} />
  }

  // console.log('$$ indicator', seconds)

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: height }}
    >
      {seconds < 3 ? <RenderSpinner /> : <div>데이터가 없습니다</div>}
    </div>
  )
}

export default Indicator
