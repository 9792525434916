import React, { Fragment, useEffect, useRef, useState } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  FormGroup
} from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv';
import moment from 'moment';
import 'moment/locale/ko';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

// components
import Breadcrumbs from '../../../components/Common/Breadcrumb'

// redux
import {
  getDocumentPaymentList,
  getDocumentPaymentBalance,
  depositDocumentPaymentBalance,
  deleteDocumentPayment
} from '../../../reducers/payment'

// temp
import PaginatorProject from '../../../components/Common/PaginatorProject'
import LinkPaginator from '../../../components/Common/LinkPaginator'

// global
import * as INFO from '../../../global/paymentInfo'
import banks from '../../../global/banks'

const DOCUMENT_BADGE_TYPE = {
  Estimate: 'badge-warning',
  TradingStatement: 'badge-info',
  Bill: 'badge-primary'
}

const DOCUMENT_TITLE_TYPE = {
  Estimate: '견적서',
  TradingStatement: '거래명세서',
  Bill: '청구서'
}

/** 상단 리스트 분류 텍스트 */
const PAYMENT_TYPE = {
  PaymentRequest: '결제요청',
  Paymented: '결제완료',
  DepositRequest: '출금대기',
  Deposited: '출금완료'
}

const tableHeaders = [
  { label: '거래문서 유형', key: 'type' },
  { label: '거래명', key: 'projectName' },
  { label: '요청 회원', key: 'creatorName' },
  { label: '결제요청일', key: 'createdAt' },
  { label: '결제완료일', key: 'paymentedAt' },
  { label: '출금요청일', key: 'depositRequestedAt' },
  { label: '출금완료(예정)일', key: 'depositedAt' },
  { label: '출금요청금액', key: 'amt' },
  { label: '정산금액', key: 'depositAmt' },
  { label: '은행', key: 'bank' },
  { label: '계좌번호', key: 'bankNumber' },
  { label: '예금주', key: 'bankAccountHolder' },
]

const DocumentDepositList = ({
  params,
  state,
  userPlanList,
  totalPlanCount,
  getDocumentPaymentList,
  documentPaymentList,
  getDocumentPaymentBalance,
  documentPaymentBalance,
  depositDocumentPaymentBalance,
  deleteDocumentPayment
}) => {
  const location = useLocation()
  const history = useHistory()
  const userStart = location.state?.startInfo ?? 1
  const userEnd = location.state?.endInfo ?? 11
  const fetchingAmount = 10;

  /** 현재 페이지 옵션 */
  const urlOption = new URLSearchParams(location.search);
  const [page, setPage] = useState(urlOption.get('page') ? Number(urlOption.get('page')) : 0);
  const [filtering, setFiltering] = useState(urlOption.get('paymentStatus') ? urlOption.get('paymentStatus') : INFO.PAYMENT_STATUS.REQUEST);
  
  // 체크박스 및 모달
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '출금 처리 중 문제가 발생했습니다',
    color: 'danger'
  })
  const [isFilter, setIsFilter] = useState(false)

  const [userListData, setUserListData] = useState([])

  const [changeSearch, setChangeSearch] = useState(false)

  const [totalListCount, setTotalListCount] = useState(0)
  const [paymentListData, setPaymentListData] = useState([])

  const [search, setSearch] = useState(urlOption.get('search') ?? '')
  const [start, setStart] = useState(userStart)
  const [end, setEnd] = useState(userEnd)

  /** 현재 나이스페이 전체 금액 */
  const [balance, setBalance] = useState(null)

  /** 현재 추정 순 수익 */
  const [balanceAvaliableFunds, setBalanceAvaliableFunds] = useState(null)
  const [requestModalVisible, setRequestModalVisible] = useState(false)
  const [requestInfo, setRequestInfo] = useState({
    bankName: '',
    bankNumber: '',
    bankHolder: '',
    requestPrice: 0,
    depositPrice: 0,
    commission: 1.3,
    paymentId: '',
    date: new Date(new Date().setDate(new Date().getDate() + 1))
  })

  /** 엑셀 다운로드 실행 상태 */
  const [isExcel, setIsExcel] = useState(false);
  const [csvData, setCsvData] = useState([]);

  // 페이지, 필터
  useEffect(() => {
    if (!changeSearch) {
      getDocumentPaymentList(page, 10, filtering)
    }
  }, [page, filtering])

  // 검색
  useEffect(() => {
    if (changeSearch) {
      setStart(1)
      setEnd(11)
      // getRatePlanUserList(page, 10, search, filtering)
    }
  }, [changeSearch])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_RATE_PLAN_USER_LIST':
        setUserListData(userPlanList)
        setTotalListCount(totalPlanCount)
        setChangeSearch(false)

        break
      case 'SUCCESS_GET_DOCUMENT_PAYMENT_LIST':
        /** 엑셀 다운로드 상태면 훅 반려 */
        if (isExcel) {
          return;
        }

        setPaymentListData(documentPaymentList.workOrderForQuantityPayments)
        setTotalListCount(documentPaymentList.count)
        getDocumentPaymentBalance()
        
        break;
      case 'SUCCESS_GET_DOCUMENT_PAYMENT_BALANCE':
        setBalance(documentPaymentBalance.amt)
        setBalanceAvaliableFunds(documentPaymentBalance.avaliableFunds)
        break

      case 'SUCCESS_DEPOSIT_DOCUMENT_PAYMENT_BALANCE':
        setStatus({
          ...status,
          errorMsg: '출금처리 되었습니다',
          isError: true,
          color: 'success'
        })

        getDocumentPaymentList(page, 10, filtering)

        setRequestInfo({
          bankName: '',
          bankNumber: '',
          bankHolder: '',
          requestPrice: 0,
          depositPrice: 0,
          commission: 1.3,
          paymentId: '',
          date: new Date(new Date().setDate(new Date().getDate() + 1))
        })
        break

      case 'FAILED_DEPOSIT_DOCUMENT_PAYMENT_BALANCE':
        setStatus({
          ...status,
          errorMsg: params.errMessage,
          isError: true,
          color: 'danger'
        })
        break

      /** 결제 요청 제거 성공 */
      case "SUCCESS_DELETE_DOCUMENT_PAYMENT": {
        setStatus({
          ...status,
          errorMsg: '정산 정보가 정상적으로 삭제되었습니다',
          isError: true,
          color: 'success'
        });

        /** select box clear */
        setSelectedBoxes([]);
        
        /** list reload */
        getDocumentPaymentList(page, 10, filtering);
        
        break;
      }

      /** 결제 요청 제거 실패 */
      case "FAILED_DELETE_DOCUMENT_PAYMENT": {
        setStatus({
          ...status,
          errorMsg: '정산 정보가 삭제되는 중 문제가 발생했습니다',
          isError: true,
          color: 'danger'
        });
        
        break;
      }
        
      default:
        break
    }
  }, [state])

  /** 엑셀 다운로드 처리 소스 */
  const csvLinkRef = useRef();

  /** 엑셀 다운로드 dispath 및 엑셀 다운로드로 상태 변경 */
  const handleDownloadCsv = async () => {
    await getDocumentPaymentList(page, totalListCount, filtering);
    setIsExcel(true);
  }

  /** 엑셓 다운로드 상태 훅 */
  useEffect(() => {
    if (!isExcel) {
      return;
    }
    
    /** 엑셀 정산 리스트가 업데이트되면 csvData 설정 */
    if ((documentPaymentList.workOrderForQuantityPayments.length === totalListCount) && (csvData.length !== documentPaymentList.workOrderForQuantityPayments.length)) {
      const filterData = csvFiltering(documentPaymentList.workOrderForQuantityPayments)

      /** 엑셀 데이터 업데이트 */
      setCsvData(filterData);
    }
    
    /** csvData 설정이 완료되면 엑셀 다운로드 실행 */
    else if ((documentPaymentList.workOrderForQuantityPayments.length === totalListCount) && (csvData.length === documentPaymentList.workOrderForQuantityPayments.length)) {
      csvLinkRef.current.link.click();

      /** 엑셀 버튼 호출상태 초기화 */
      setIsExcel(false);
    }
  }, [isExcel, documentPaymentList, state, csvData]);
  
  /** 테이블 필터링 */
  const csvFiltering = (data) => data.map((csvItem) => {
    const {
      workOrderForQuantity,
      creatorName,
      createdAt,
      paymentedAt,
      depositRequestedAt,
      depositedAt,
      amt,
      depositAmt,
      bankAccountNumber,
      bankAccountHolder,
      bankCode
    } = csvItem;

    const {
      type,
      projectName,
      clientName
    } = workOrderForQuantity;

    /** 은행 코드 검색 */
    const bankName = banks.find((item) => item.code === bankCode)?.name ?? '';

    /** csv filter */
    const filter = {
      /** 거래문서 유형 */
      type: DOCUMENT_TITLE_TYPE[type],

      /** 거래명 */
      projectName: projectName !== "" ? projectName : clientName,

      /** 요청 회원 */
      creatorName: creatorName,

      /** 결제요청일 */
      createdAt: createdAt ? moment(createdAt).format('YYYY.MM.DD dddd a hh:mm:ss') ?? "" : "",

      /** 결제완료일 */
      paymentedAt: paymentedAt ? moment(paymentedAt).format('YYYY.MM.DD dddd a hh:mm:ss') : "",

      /** 출금요청일 */
      depositRequestedAt: depositRequestedAt ? moment(depositRequestedAt).format('YYYY.MM.DD dddd a hh:mm:ss') : "",

      /** 출금완료(예정)일 */
      depositedAt: depositAmt ? moment(depositedAt).format('YYYY.MM.DD dddd a hh:mm:ss') : "",

      /** 출금요청금액 */
      amt: amt ? `${amt.toLocaleString()}원` : ``,

      /** 정산금액 */
      depositAmt: depositAmt ? `${depositAmt.toLocaleString()}원` : ``,

      /** 은행 */
      bank: bankName,

      /** 계좌번호 */
      bankNumber: bankAccountNumber,

      /** 예금주 */
      bankAccountHolder: bankAccountHolder,
    };
    
    return filter;
  });

  /** 정산 관리 url 업데이트 */
  const handleUrl = (props) => {
    const {
      page,
      paymentStatus
    } = props;
    
    setPage(page);
    setStart(1);
    setEnd(11);
    setFiltering(paymentStatus);
    
    const editedParams = new URLSearchParams();
    editedParams.append('page', page);
    editedParams.append('paymentStatus', paymentStatus);
    window.location.replace(`?${editedParams.toString()}`);
  }
  
  /** 정산관리 필터 변경 핸들러 */
  const handlerFilter = input => {
    let paymentStatus = INFO.PAYMENT_STATUS.REQUEST;
    
    /** 결제대기 */
    if (input === 1) {
      paymentStatus = INFO.PAYMENT_STATUS.REQUEST;
    }
    
    /** 결제완료 */
    if (input === 2) {
      paymentStatus = INFO.PAYMENT_STATUS.PAYMENTED;
    }

    /** 출금대기 */
    if (input === 3) {
      paymentStatus = INFO.PAYMENT_STATUS.DEPOSIT_REQUEST;
    }

    /** 출금완료(예정) */
    if (input === 4) {
      paymentStatus = INFO.PAYMENT_STATUS.DEPOSITED;
    }

    /** 페이지 이동 Action */
    handleUrl({
      page: 0,
      paymentStatus
    });
  }

  const handleCommission = e => {
    setRequestInfo({
      ...requestInfo,
      commission: e.target.value,
      depositPrice: Math.floor(
        requestInfo.requestPrice * (1 - e.target.value / 100)
      )
    })
  }

  const handleConfirm = () => {
    const { depositPrice, paymentId, date } = requestInfo

    depositDocumentPaymentBalance(
      paymentId,
      depositPrice,
      moment(date).format('YYYYMMDD')
    )
  }

  const handleDate = date => {
    const formattingDate = date

    setRequestInfo({
      ...requestInfo,
      date: formattingDate
    })
  }

  /** 선택된 정산정보 제거 */
  const onDeletePayments = () => {
    if (selectedBoxes.length === 0) {
      setStatus({
        ...status,
        errorMsg: '삭제할 정산 정보를 선택해주세요',
        isError: true,
        color: 'danger'
      });

      return;
    }

    const confirmResult1 = window.confirm(
      "선택한 정산 정보를 삭제하시겠습니까?"
    );

    if (!confirmResult1) {
      return;
    }

    const confirmResult2 = window.confirm(
      "삭제된 정산 정보는 복구할 수 없습니다. 또한 금액 환불은 나이스페이 관리자 홈페이지에서 별도로 진행해주세요.\n\n계속하시겠습니까?"
    );
    
    if (confirmResult2) {
      selectedBoxes.map(paymentId => {
        deleteDocumentPayment(paymentId);
      });
    }
  }
  
  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`${PAYMENT_TYPE[filtering]}`}
            breadcrumbItem="DocumentPaymentList"
          />

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <Modal
            centered
            scrollable
            isOpen={requestModalVisible}
            toggle={() => setRequestModalVisible(!requestModalVisible)}
          >
            <ModalHeader
              toggle={() => setRequestModalVisible(!requestModalVisible)}
              style={{
                borderBottom: '2px solid #2a3042'
              }}
            >
              <div style={{ fontSize: '20px' }}>출금 요청</div>
            </ModalHeader>
            <ModalBody
              className="d-flex"
              style={{
                height: '650px',
                flexDirection: 'column'
              }}
            >
              <Row>
                <FormGroup className="col-4">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    은행
                  </Label>
                  <Input
                    className="form-control"
                    name="content"
                    style={{ fontSize: '14px' }}
                    value={requestInfo.bankName}
                    disabled
                  />
                </FormGroup>

                <FormGroup className="col-5">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    계좌번호
                  </Label>
                  <Input
                    className="form-control"
                    name="content"
                    style={{ fontSize: '14px' }}
                    value={requestInfo.bankNumber}
                    disabled
                  />
                </FormGroup>

                <FormGroup className="col-3">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    예금주
                  </Label>
                  <Input
                    className="form-control"
                    name="content"
                    style={{ fontSize: '14px' }}
                    value={requestInfo.bankHolder}
                    disabled
                  />
                </FormGroup>

                <FormGroup className="col-12">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    출금요청금액
                  </Label>
                  <Input
                    className="form-control"
                    name="content"
                    style={{ fontSize: '14px' }}
                    value={
                      requestInfo.requestPrice &&
                      requestInfo.requestPrice.toLocaleString()
                    }
                    disabled
                  />
                </FormGroup>

                <FormGroup className="col-8">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    {`정산금액(수수료 ${requestInfo.commission}%)`}
                  </Label>
                  <Input
                    className="form-control"
                    name="content"
                    style={{ fontSize: '14px' }}
                    value={
                      requestInfo.depositPrice &&
                      requestInfo.depositPrice.toLocaleString()
                    }
                    disabled
                  />
                </FormGroup>

                <FormGroup className="col-4">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    수수료
                  </Label>
                  <Input
                    className="form-control"
                    name="content"
                    style={{ fontSize: '14px' }}
                    value={requestInfo.commission}
                    onChange={handleCommission}
                    type="number"
                  />
                </FormGroup>

                <FormGroup className="col-12">
                  <Label className="control-label" style={{ fontSize: '16px' }}>
                    출금요청일(오늘 기준 다음날로 선택, 공휴일인 경우 그 다음날)
                  </Label>
                  <DatePicker
                    className="form-control d-block"
                    placeholderText="선택"
                    selected={requestInfo?.date ?? new Date()}
                    onChange={date => {
                      handleDate(date)
                    }}
                  />
                </FormGroup>
              </Row>

              <div
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  marginBottom: '8px'
                }}
              ></div>
            </ModalBody>
            <ModalFooter
              onClick={() => setRequestModalVisible(!requestModalVisible)}
              style={{
                justifyContent: 'center',
                borderTop: '2px solid #e2e2e2'
              }}
            >
              <Button
                color="danger"
                outline
                onClick={handleConfirm}
                style={{ fontSize: '16px' }}
              >
                확인
              </Button>
            </ModalFooter>
          </Modal>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          {balance && (
                            <div style={{ fontSize: '14px' }}>
                              {`나이스페이 전체 계좌 금액: ${balance.toLocaleString()}원`}{' '}
                            </div>
                          )}
                          {balanceAvaliableFunds && (
                            <div style={{ fontSize: '14px' }}>
                              {`정산 사용 가능 금액: ${balanceAvaliableFunds.toLocaleString()}원`}
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div
                        className="text-sm-right"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          type="button"
                          color="danger"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          onClick={onDeletePayments}
                          disabled={selectedBoxes.length === 0}
                        >
                          <i className="mdi mdi-minus mr-1"></i> 삭제
                        </Button>

                        <Dropdown
                          isOpen={isFilter}
                          toggle={() => setIsFilter(!isFilter)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-light"
                            style={{ marginRight: '10px' }}
                          >
                            <i className="mdi mdi-filter mr-1" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu dropdown-menu-right">
                            <DropdownItem onClick={() => handlerFilter(1)}>
                              결제요청
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(2)}>
                              결제완료
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(3)}>
                              출금대기
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(4)}>
                              출금완료
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>

                        <Button
                          type="button"
                          color="light"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          onClick={handleDownloadCsv}
                        >
                          <i className="mdi mdi-microsoft-excel" />
                        </Button>
                        <CSVLink filename={`${moment(new Date()).format('YYYYMMDD')}_${filtering}`}
                          ref={csvLinkRef}
                          data={csvData}
                          headers={tableHeaders}
                          >
                        </CSVLink>
                      </div>
                    </Col>
                  </Row>
                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '3%' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length === userListData?.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(userListData) &&
                                      setSelectedBoxes(
                                        userListData.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            거래문서 유형
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            거래명
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            요청 회원
                          </th>
                          <th scope="col" style={{ with: "15%" }}>
                            결제요청일
                          </th>
                          <th scope="col" style={{ with: "15%" }}>
                            결제완료일
                          </th>
                          <th scope="col" style={{ with: "15%" }}>
                            출금요청일
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            출금완료(예정)일
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            출금요청금액
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            정산금액
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            은행
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            계좌번호
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            예금주
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(paymentListData) &&
                          paymentListData.map((payment, idx) => {
                            let bankName = ''
                            let bankImage = ''

                            banks.map(bankItem => {
                              if (bankItem.code === payment.bankCode) {
                                bankName = bankItem.name
                                bankImage = bankItem.image
                              }
                            })

                            return (
                              <tr key={'_payment_' + idx}>
                                {/* 거래문서 유형 */}
                                <td style={{ height: '65px' }}>
                                  <div
                                    className="custom-control custom-checkbox"
                                    style={{ zIndex: 0 }}
                                  >
                                    <Input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={payment.id}
                                      checked={selectedBoxes.includes(
                                        payment.id
                                      )}
                                      onChange={e =>
                                        e.target.checked
                                          ? setSelectedBoxes([
                                              ...selectedBoxes,
                                              payment.id
                                            ])
                                          : setSelectedBoxes(
                                              selectedBoxes.filter(
                                                b => b !== payment.id
                                              )
                                            )
                                      }
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor={payment.id}
                                    >
                                      &nbsp;
                                    </Label>
                                  </div>
                                </td>

                                {/* 거래명 */}
                                <td>
                                  <span
                                    className={`badge ${
                                      DOCUMENT_BADGE_TYPE[
                                        payment?.workOrderForQuantity?.type
                                      ]
                                    }`}
                                    style={{
                                      fontSize: '14px'
                                    }}
                                  >
                                    {
                                      DOCUMENT_TITLE_TYPE[
                                        payment?.workOrderForQuantity?.type
                                      ]
                                    }
                                  </span>
                                </td>
                                <td>
                                  {payment.workOrderForQuantity.projectName !== "" ?
                                  payment?.workOrderForQuantity.projectName : payment?.clientName}
                                </td>

                                {/* 요청 회원 */}
                                <td
                                  onClick={() => {
                                    history.push({
                                      pathname: `/user-detail/${payment?.workOrderForQuantity?.creatorUserId}`,
                                      state: {
                                        pageInfo: page,
                                        searchInfo: search,
                                        filteringInfo: filtering,
                                        startInfo: start,
                                        endInfo: end,
                                        prevInfo: 'deposit'
                                      }
                                    })
                                  }}
                                  style={{
                                    color: '#556EE6',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {`${
                                    payment?.creatorName ?? '알 수 없음'
                                  }`}
                                </td>

                                {/* 결제요청일 */}
                                <td>
                                  {payment?.createdAt && moment(payment?.createdAt).format('YYYY.MM.DD dddd a hh:mm:ss')}
                                </td>

                                {/* 결제완료일 */}
                                <td>
                                  {payment?.paymentedAt && moment(payment?.paymentedAt).format('YYYY.MM.DD dddd a hh:mm:ss')}
                                </td>

                                {/* 출금요청일 */}
                                <td>
                                  {payment?.depositRequestedAt && moment(payment?.depositRequestedAt).format('YYYY.MM.DD dddd a hh:mm:ss')}
                                </td>

                                {/* 출금완료(예정)일 */}
                                <td>
                                  {payment?.depositedAt && moment(payment?.depositedAt).format('YYYY.MM.DD dddd a hh:mm:ss')}
                                </td>

                                {/* 출금요청금액 */}
                                <td>
                                  {payment.amt &&
                                    `${payment.amt.toLocaleString()}원`}
                                </td>

                                {/* 정산금액 */}
                                <td>
                                  {payment.depositAmt &&
                                    `${payment.depositAmt.toLocaleString()}원`}
                                </td>

                                {/* 은행 */}
                                <td>
                                  {bankName !== '' && bankName}
                                  {bankImage !== '' && (
                                    <img
                                      src={bankImage}
                                      style={{
                                        marginLeft: '2px',
                                        width: '18px',
                                        height: '18px'
                                      }}
                                    />
                                  )}
                                </td>

                                {/* 계좌번호 */}
                                <td>
                                  {payment.bankNumber && payment.bankNumber}
                                </td>

                                {/* 예금주 */}
                                <td>
                                  {payment.bankAccountHolder &&
                                    payment.bankAccountHolder}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </Table>
                    {/* <PaginatorProject
                      currentPage={page}
                      pageSize={10}
                      total={Math.ceil(totalListCount / 10)}
                      onChange={handleUrl}
                      start={start}
                      setStart={setStart}
                      end={end}
                      setEnd={setEnd}
                    /> */}
                    <LinkPaginator
                      searchParams={{
                        currentPage: page,
                        paymentStatus: filtering
                      }}
                      visibleCount={fetchingAmount}
                      totalCount={Math.ceil(totalListCount / fetchingAmount)}
                      urlPrefix={'/documentPayment'}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  userPlanList: state.appState.data.userPlanList,
  totalPlanCount: state.appState.data.totalPlanCount,
  documentPaymentList: state.appState.data.documentPaymentList,
  documentPaymentBalance: state.appState.data.documentPaymentBalance
})

const mapDispatchToProps = (dispatch) => ({
  getDocumentPaymentList: (page, amount, paymentStatus) => dispatch(getDocumentPaymentList(page, amount, paymentStatus)),
  getDocumentPaymentBalance: () => dispatch(getDocumentPaymentBalance()),
  depositDocumentPaymentBalance: (paymentId, amt, amtDate) => dispatch(depositDocumentPaymentBalance(paymentId, amt, amtDate)),
  deleteDocumentPayment: (paymentId) => dispatch(deleteDocumentPayment(paymentId))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDepositList)