import { all } from 'redux-saga/effects'

import LayoutSagas from './Layout/layout'
import AuthSagas from './Auth/login'
import ForgetSagas from './Auth/forgetpwd'
import ProfileSagas from './Auth/profile'
import DashboardSagas from './dashboard'
import AdminSagas from './admin'
import TesterSagas from './tester'
import FeedSagas from './feed'
import UsersSagas from './users'
import WorkOrderSagas from './workOrder'
import accountSagas from './account'
import AuthSaga from './auth'
import EventSagas from './event'
import TradingStatementSaga from './tradingStatement'
import serverSaga from './server'
import ReceiptSaga from './receipt'
import BillSaga from './bill'
import PaymentSaga from './payment'
import CashReceiptSaga from './cashReceipt'
import OrderSheetSaga from './orderSheet'
import TaxInvoiceSaga from './taxInvoice'
import RatePlan from './ratePlan'

export default function* rootSaga() {
  yield all([
    //public
    // AccountSagas(),
    AuthSagas(),
    ProfileSagas(),
    ForgetSagas(),
    LayoutSagas(),
    ...accountSagas,
    ...DashboardSagas,
    ...AdminSagas,
    ...TesterSagas,
    ...FeedSagas,
    ...UsersSagas,
    ...WorkOrderSagas,
    ...AuthSaga,
    ...EventSagas,
    ...TradingStatementSaga,
    ...serverSaga,
    ...ReceiptSaga,
    ...BillSaga,
    ...PaymentSaga,
    ...CashReceiptSaga,
    ...OrderSheetSaga,
    ...TaxInvoiceSaga,
    ...RatePlan
  ])
}
