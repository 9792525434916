export const GET_BILL_LIST = 'reducers/bill/GET_BILL_LIST'
export const GET_BILL_DETAIL = 'reducers/bill/GET_BILL_DETAIL'
export const SET_BILL_IS_BUSINESSMAN = 'reducers/bill/SET_BILL_IS_BUSINESSMAN'

export const getBillList = (
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
) => ({
  type: GET_BILL_LIST,
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
})

export const getBillDetail = id => ({
  type: GET_BILL_DETAIL,
  id
})

export const setBillIsBusinessman = (id, isBusinessman) => ({
  type: SET_BILL_IS_BUSINESSMAN,
  id,
  isBusinessman
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
