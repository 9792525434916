export const GET_FEED_LIST = 'reducers/feed/GET_FEED_LIST'
export const REMOVE_CATEGORY_LIST = 'reducers/feed/REMOVE_CATEGORY_LIST'
export const DECLARE_FEED_LIST = 'reducers/feed/DECLARE_FEED'
export const RANDOM_NICKNAME = 'reducers/feed/RANDOM_NICKNAME'
export const CREATE_FEED_CATEGORY = `reducers/feed/CREATE_FEED_CATEGORY`
// export const GET_FEED_LIST = 'reducers/feed/GET_FEED_LIST'
export const GET_FEED_CATEGORY_LIST = 'reducers/feed/GET_FEED_CATEGORY_LIST'
export const GET_FEED_DETAIL = 'reducers/feed/GET_FEED_DETAIL'
export const CREATE_FEED = 'reducers/feed/CREATE_FEED'
export const REMOVE_FEED = 'reducers/feed/REMOVE_FEED'
export const SET_FEED_COMMENT = 'reducers/feed/SET_FEED_COMMENT'
export const REMOVE_FEED_COMMENT = 'reducers/feed/REMOVE_FEED_COMMENT'
export const SET_FEED_REACTION = 'reducers/feed/SET_FEED_REACTION'
export const SET_FEED_COMMENT_REACTION =
  'reducers/feed/SET_FEED_COMMENT_REACTION'

// 카테고리 목록
export const getFeedList = (options = { page: 1, amount: 10, search: '' }) => ({
  type: GET_FEED_LIST,
  options
})

export const randomNickname = id => ({
  type: RANDOM_NICKNAME,
  id
})

export const ceateFeedCategory = name => ({
  type: CREATE_FEED_CATEGORY,
  name
})

// feed
// export const getFeedList = (options = { page: 1, amount: 10, search: '' }) => ({
//   type: GET_FEED_LIST,
//   options
// })

// 카테고리 목록
export const getFeedCategoryList = () => ({
  type: GET_FEED_CATEGORY_LIST
})

// 카테고리 삭제
export const removeCategoryList = ids => ({
  type: REMOVE_CATEGORY_LIST,
  ids
})

// 카테고리 추가
export const createFeedCategory = name => ({
  type: CREATE_FEED_CATEGORY,
  name
})

// 피드 상세
export const getFeedDetail = id => ({
  type: GET_FEED_DETAIL,
  id
})

// 피드 신고 리스트
export const declareFeedList = () => ({
  type: DECLARE_FEED_LIST
})

// 피드 등록
export const createFeed = (newFeed, image) => ({
  type: CREATE_FEED,
  newFeed,
  image
})

// 피드 삭제
export const removeFeed = ids => ({
  type: REMOVE_FEED,
  ids
})

// 피드 댓글 등록
export const setFeedComment = (feedId, commentData, commentId) => ({
  type: SET_FEED_COMMENT,
  feedId,
  commentData,
  commentId
})

// 피드 댓글 삭제
export const removeFeedComment = (feedId, feedCommentId) => ({
  type: REMOVE_FEED_COMMENT,
  feedId,
  feedCommentId
})

// 피드 게시물 공감
export const setFeedReaction = (feedId, reactionData) => ({
  type: SET_FEED_REACTION,
  feedId,
  reactionData
})

// 피드 댓글 공감
export const setFeedCommentReaction = (
  feedId,
  feedCommentId,
  reactionData
) => ({
  type: SET_FEED_COMMENT_REACTION,
  feedId,
  feedCommentId,
  reactionData
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
