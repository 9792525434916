import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// globals
import demographic from '../../../global/demographic'
import { API_URL } from '../../../global/constants'
import * as INFO from '../../../global/projectInfo'

// redux
import { connect } from 'react-redux'
import {
  getUserDetail,
  setAdminCategory
} from '../../../reducers/users'
import {
  getOrderSheetDetail,
  setClientIsBusinessman
} from '../../../reducers/orderSheet'

// util
import { History_Type, Bank_Code } from '../../../constant/utils'

// tools
import {
  Alert,
  CardTitle,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Form,
  Button
} from 'reactstrap'
import classnames from 'classnames'
import styled from 'styled-components'
import Select from 'react-select'

// time
import moment from 'moment'
import 'moment/locale/ko'
import WorkDocumentPreview from '../../WorkDocumentPreview'

const BAState = {
  BEFORE: 0,
  AFTER: 1
}
const OrderSheetDetail = ({
  state,
  params,
  orderSheetDetail,
  getOrderSheetDetail,
  setAdminCategory,
  setClientIsBusinessman,
  workDocumentHTML
}) => {
  const projectId = window.location.pathname.split('/')[2]
  const [activeTab, setActiveTab] = useState('2')

  const [workOrderData, setWorkOrderData] = useState([])

  // 거래 분류
  const [selectedMulti, setSelectedMulti] = useState([])

  // 고객 유형
  const [selectedGroup, setSelectedGroup] = useState(null)
  const userOption = [
    { label: '개인', value: false },
    { label: '사업자', value: true }
  ]

  const [status, setStatus] = useState({
    isError: false,
    color: 'danger',
    errorMsg: '견적서 조회 중 문제가 발생했습니다.'
  })

  const location = useLocation()
  const history = useHistory()

  const pageInfo = location.state?.pageInfo ?? 1
  const searchInfo = location.state?.searchInfo ?? ''
  const filteringInfo = location.state?.filteringInfo ?? ''
  const startInfo = location.state?.startInfo ?? ''
  const endInfo = location.state?.endInfo ?? ''
  const prevInfo = location.state?.prevInfo ?? ''

  useEffect(() => {
    console.log('$$ detail', projectId)

    getOrderSheetDetail(projectId, false)
  }, [])

  useEffect(() => {
    if (workOrderData.boClientIsBusinessman) {
      setSelectedGroup({ label: '사업자', value: true })
    } else {
      setSelectedGroup({ label: '개인', value: false })
    }
  }, [workOrderData])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_ORDERSHEET_DETAIL':
        setWorkOrderData(orderSheetDetail.workOrderForQuantity)
        break
      case 'FAILED_GET_ORDERSHEET_DETAIL':
        setStatus({ color: 'danger', isError: true, errorMsg: params.message })
        break
      default:
        break
    }
  }, [state])

  const workOrderProjectState = state => {
    let string = ''

    switch (state) {
      case 0:
        string = '임시저장'
        break
      case 1:
        string = '미승낙'
        break
      case 2:
        string = '진행'
        break
      case 3:
        string = '만료'
        break
      case 4:
        string = '삭제'
        break
      case 5:
        string = '완료'
        break
      default:
        break
    }

    return string
  }

  const workOrderProjectHistoryState = (history, targetArr) => {
    let strSwitch = ''

    switch (history) {
      case 0:
        strSwitch = 'on'
        targetArr.map(item => {
          if (item.BAState === BAState.AFTER) {
            strSwitch = item.checked ? 'on' : 'off'
          }
        })
        return `작업물 알림톡을 ${strSwitch}하였습니다`
      case 1:
        strSwitch = 'on'
        targetArr.map(item => {
          if (item.BAState === BAState.AFTER) {
            strSwitch = item.checked ? 'on' : 'off'
          }
        })
        return `수정 알림톡을 ${strSwitch}하였습니다`
      case 2:
        return `정산 요청톡을 추가하였습니다`
      case 3:
        return `정산 요청톡을 수정하였습니다`
      case 4:
        return `정산 요청톡을 삭제하였습니다`
      case 5:
        strSwitch = 'on'
        targetArr.map(item => {
          if (item.BAState === BAState.AFTER) {
            strSwitch = item.checked ? 'on' : 'off'
          }
        })
        return `정산 요청톡을 ${strSwitch}하였습니다`
      case 6:
        return `참고 사항 내용을 추가하였습니다`
      case 7:
        return `참고 사항 내용을 수정했습니다`
      case 8:
        return `참고 사항 내용을 삭제했습니다`
      case 9:
        return `견적서를 추가했습니다`
      case 10:
        return `견적서를 수정했습니다`
      case 11:
        return `견적서를 삭제했습니다`
      case 12:
        return `업무를 시작했습니다`
      case 13:
        return `업무를 종료했습니다`
      case 14:
        return `견적서 공유톡을 보넀습니다`
      case 15:
        return `받을 분이 견적서 공유톡을 확인했습니다`
      case 16:
        return `견적서 제목을 수정했습니다`
      case 17:
        return `이름을 수정했습니다`
      case 18:
        return `받을 분의 이름을 수정했습니다`
      case 19:
        return `받을 분의 전화번호를 수정했습니다`
      case 20:
        return `계좌번호를 수정했습니다`
      case 21:
        return `은행을 수정했습니다`
      case 22:
        return `예금주명을 수정했습니다`
      case 23:
        return `시작일을 수정했습니다`
      case 24:
        return `종료일을 수정했습니다`
      case 25:
        return `첨부파일을 추가했습니다`
      case 26:
        return `첨부파일을 수정했습니다`
      case 27:
        return `첨부파일을 삭제했습니다`
      case 28:
        return `작업물을 추가하였습니다`
      case 29:
        return `작업물을 삭제하였습니다`
      case 30:
        return `상대방이 견적서를 확인했습니다`
      case 31:
        return `견적서 공유톡을 보넀어요`
      case 32:
        return `이메일로 견적서를 보넀어요`
      case 33:
        return `받을 분이 견적서를 확인했어요`
      case 37:
        return `로고가 등록되었어요`
      case 38:
        return `로고가 삭제되었어요`
      case 39:
        return `서명이 등록되었어요`

      default:
        return ''
    }
  }

  const downloadHandler = () => {
    getOrderSheetDetail(workOrderData.id, true)
  }

  const handleMultiSelected = selectedMulti => {
    setSelectedMulti(selectedMulti)
  }

  let discountPrice =
    workOrderData.totalPrice - Number(workOrderData.discountPrice)

  let arr = []
  useEffect(() => {
    for (let i = 0; i < selectedMulti.length; i++) {
      arr.push(selectedMulti[i].value)
    }
  }, [selectedMulti])

  const onSaveAdminCategory = () => {
    arr.forEach(item => {
      setAdminCategory(workOrderData.creatorUserId, item)
    })
  }

  const handleSelectGroup = selectedGroup => {
    setSelectedGroup(selectedGroup)
  }

  const onSaveUserType = () => {
    if (selectedGroup === null) return

    setClientIsBusinessman(workOrderData.id, selectedGroup.value)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <ProjectQuantityBreadcrumb
            title={`<  ${workOrderData?.creatorName || ''}`}
            pageInfo={pageInfo}
            searchInfo={searchInfo}
            filteringInfo={filteringInfo}
            startInfo={startInfo}
            endInfo={endInfo}
            prevInfo={prevInfo}
          /> */}

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1')
                      }}
                    >
                      <i className="bx bx-detail d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">상세 정보</p>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        setActiveTab('2')
                      }}
                    >
                      <i className="bx bx-book-open d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">미리보기</p>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        setActiveTab('3')
                      }}
                    >
                      <i className="bx bxs-pen d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">설정</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div
                          // style={{ minHeight: '850px' }}
                          style={{ minHeight: '1000px' }}
                        >
                          <Form
                          // onSubmit={handleUpdate}
                          >
                            <CardTitle style={{ fontWeight: 700 }}>
                              회원 정보
                            </CardTitle>
                            <Row>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>이름</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="creatorName"
                                    value={
                                      workOrderData?.creatorName
                                        ? workOrderData?.creatorName
                                        : ''
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>전화번호</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    value={
                                      workOrderData.user &&
                                      workOrderData?.user?.phone
                                        ? workOrderData?.user?.phone
                                        : ''
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>이메일</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    value={
                                      workOrderData.user &&
                                      workOrderData.user.email
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>구분</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="businessman"
                                    value={
                                      workOrderData.businessman
                                        ? '사업자'
                                        : '개인'
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <CardTitle style={{ fontWeight: 700 }}>
                              고객 정보
                            </CardTitle>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label>이름</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="clientName"
                                    value={
                                      workOrderData.clientName &&
                                      workOrderData.clientName
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <Label>전화번호</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="clientPhone"
                                    value={
                                      workOrderData.clientPhone &&
                                      workOrderData.clientPhone
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <CardTitle style={{ fontWeight: 700 }}>
                              담당자 정보
                            </CardTitle>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <Label>이름</Label>
                                  <Input
                                    type="text"
                                    name="creatorName"
                                    value={workOrderData?.creatorName || ''}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <Label>전화번호</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="creatorPhone"
                                    value={workOrderData?.creatorPhone || ''}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <Label>이메일</Label>
                                  <Input
                                    type="text"
                                    name="creatorEmail"
                                    value={workOrderData?.creatorEmail || ''}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <CardTitle style={{ fontWeight: 700 }}>
                              견적 정보
                              <Button
                                type="button"
                                size="sm"
                                style={{
                                  marginLeft: '6px'
                                }}
                                color="secondary"
                                onClick={() => {
                                  window.open(
                                    `${API_URL}/workDocument/pdf/${workOrderData.id}`
                                  )
                                }}
                              >
                                <i className="mdi mdi-file-pdf mr-1" />
                                PDF
                              </Button>
                            </CardTitle>
                            <Row>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>제목</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="projectName"
                                    value={
                                      workOrderData.projectName &&
                                      workOrderData.projectName
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>생성일</Label>
                                  <Input
                                    type="text"
                                    name="createdAt"
                                    value={
                                      workOrderData.createdAt &&
                                      moment(workOrderData.createdAt).format(
                                        'YYYY-MM-DD-HH:mm'
                                      )
                                      // workOrderData.startedAt.slice(0, 10)
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>상태</Label>
                                  <Input
                                    type="text"
                                    name="projectState"
                                    value={
                                      workOrderData.projectState &&
                                      workOrderProjectState(
                                        workOrderData.projectState
                                      )
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>서식</Label>
                                  <Input
                                    type="text"
                                    name="formCode"
                                    value={
                                      workOrderData.formCode === '' ||
                                      workOrderData.formCode ===
                                        INFO.FORM_CODE.KIM_WO_KEU
                                        ? '김워크'
                                        : '최진중'
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>소계</Label>
                                  <Input
                                    type="text"
                                    name="totalPrice"
                                    value={
                                      workOrderData.totalPrice &&
                                      workOrderData.totalPrice.toLocaleString()
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>할인금액</Label>
                                  <Input
                                    type="text"
                                    name="discountPrice"
                                    value={
                                      workOrderData.discountPrice &&
                                      workOrderData.discountPrice.toLocaleString()
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>총공급가액</Label>
                                  <Input
                                    type="text"
                                    name="supplyPrice"
                                    value={discountPrice.toLocaleString()}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>
                                    합계
                                    {workOrderData.taxClassification ===
                                    INFO.TAX_TYPE.VAT_INCLUDED
                                      ? '금액(공급가액 + 세액)'
                                      : workOrderData.taxClassification ===
                                        INFO.TAX_TYPE.VAT_NOT_INCLUDED
                                      ? '금액(공급가액 + 세액)'
                                      : '(부가세 없음)'}
                                  </Label>
                                  <Input
                                    type="text"
                                    name="sumPrice"
                                    value={
                                      workOrderData.taxClassification ===
                                      INFO.TAX_TYPE.VAT_INCLUDED
                                        ? (
                                            discountPrice +
                                            Math.floor(discountPrice * 0.1)
                                          ).toLocaleString()
                                        : discountPrice.toLocaleString()
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="3">
                                <FormGroup>
                                  <Label>은행</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                      workOrderData.bankCode &&
                                      Bank_Code[workOrderData.bankCode]
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>계좌번호</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                      workOrderData.bankNumber &&
                                      workOrderData.bankNumber
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>예금주</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                      workOrderData.bankAccountHolder &&
                                      workOrderData.bankAccountHolder
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <Label>승낙</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                      workOrderData.clientConfirm ? 'O' : 'X'
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <CardTitle style={{ fontWeight: 700 }}>
                              알림톡 정보
                            </CardTitle>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label>ID</Label>
                                  <Input
                                    type="text"
                                    name="startedAt"
                                    value={
                                      workOrderData?.workOrderForQuantityAlimeTalk
                                        ? workOrderData
                                            .workOrderForQuantityAlimeTalk.id
                                        : '미생성'
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <Label>생성일</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="projectName"
                                    value={
                                      workOrderData?.workOrderForQuantityAlimeTalk
                                        ? workOrderData
                                            .workOrderForQuantityAlimeTalk
                                            .createdAt &&
                                          moment(
                                            workOrderData
                                              .workOrderForQuantityAlimeTalk
                                              .createdAt
                                          ).format('YYYY-MM-DD-HH:mm')
                                        : // workOrderData.workOrderForQuantityAlimeTalk.createdAt.slice(
                                          //   0,
                                          //   10
                                          // )
                                          '미생성'
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <Label>승인 여부</Label>
                                  <Input
                                    type="text"
                                    name="startedAt"
                                    value={
                                      workOrderData.clientConfirm ? 'O' : 'X'
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <Label>공유 횟수</Label>
                                  <Input
                                    type="text"
                                    name="projectState"
                                    value={
                                      workOrderData?.workOrderForQuantityShare
                                        ? workOrderData
                                            .workOrderForQuantityShare.length
                                        : 0
                                    }
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <CardTitle style={{ fontWeight: 700 }}>
                              항목 상세 정보
                            </CardTitle>
                            <Row>
                              {Array.isArray(
                                workOrderData.workOrderForQuantityEstimate
                              ) &&
                                workOrderData.workOrderForQuantityEstimate.map(
                                  (estimate, i) => {
                                    return (
                                      <>
                                        <Col lg="3">
                                          <FormGroup>
                                            <Label>항목 {i + 1}</Label>
                                            <div>품명</div>
                                            <Input
                                              type="text"
                                              value={estimate.itemTitle}
                                              disabled
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg="3">
                                          <FormGroup>
                                            <Label
                                              style={{ visibility: 'hidden' }}
                                            >
                                              항목 {i + 1}
                                            </Label>
                                            <div>규격</div>
                                            <Input
                                              type="text"
                                              value={estimate.itemContent}
                                              disabled
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                          <FormGroup>
                                            <Label
                                              style={{ visibility: 'hidden' }}
                                            >
                                              항목 {i + 1}
                                            </Label>
                                            <div>수량</div>
                                            <Input
                                              type="text"
                                              value={estimate.quantity}
                                              disabled
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                          <FormGroup>
                                            <Label
                                              style={{ visibility: 'hidden' }}
                                            >
                                              항목 {i + 1}
                                            </Label>
                                            <div>단가</div>
                                            <Input
                                              type="text"
                                              value={
                                                estimate.singlePrice &&
                                                estimate.singlePrice.toLocaleString()
                                              }
                                              disabled
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                          <FormGroup>
                                            <Label
                                              style={{ visibility: 'hidden' }}
                                            >
                                              항목 {i + 1}
                                            </Label>
                                            <div>금액</div>
                                            <Input
                                              type="text"
                                              value={
                                                estimate.singlePrice &&
                                                estimate.quantity &&
                                                (
                                                  estimate.singlePrice *
                                                  estimate.quantity
                                                ).toLocaleString()
                                              }
                                              disabled
                                            />
                                          </FormGroup>
                                        </Col>
                                      </>
                                    )
                                  }
                                )}
                            </Row>
                            <CardTitle style={{ fontWeight: 700 }}>
                              히스토리
                            </CardTitle>
                            <Row>
                              {Array.isArray(
                                workOrderData.workOrderForQuantityHistory
                              ) &&
                                workOrderData.workOrderForQuantityHistory.map(
                                  (history, i) => {
                                    return (
                                      <>
                                        <Col lg="8">
                                          <FormGroup>
                                            <Input
                                              type="text"
                                              value={workOrderProjectHistoryState(
                                                history.historyType,
                                                history[
                                                  History_Type[
                                                    history.historyType
                                                  ]
                                                ]
                                              )}
                                              disabled
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                          <FormGroup>
                                            <Input
                                              type="text"
                                              value={
                                                history.createdAt &&
                                                // history.createdAt.slice(0, 10)
                                                moment(
                                                  history.createdAt
                                                ).format('YYYY-MM-DD-HH:mm')
                                              }
                                              disabled
                                            />
                                          </FormGroup>
                                        </Col>
                                      </>
                                    )
                                  }
                                )}
                            </Row>
                          </Form>
                        </div>
                      </TabPane>

                      <TabPane tabId="2">
                        <WorkDocumentPreview workDocumentId={projectId} />
                      </TabPane>

                      <TabPane tabId="3">
                        <div className="row mb-8">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-2 col-form-label"
                          >
                            거래 분류
                          </Label>
                          <Col sm={9}>
                            <SelectContainer>
                              <div className="select">
                                <Select
                                  value={selectedMulti}
                                  isMulti={true}
                                  onChange={handleMultiSelected}
                                  options={demographic}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={false}
                                />
                              </div>
                              <Button onClick={onSaveAdminCategory}>
                                저장
                              </Button>
                            </SelectContainer>
                          </Col>
                        </div>
                        <div className="row mb-8">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-2 col-form-label"
                          >
                            고객 유형
                          </Label>
                          <Col sm={9}>
                            <SelectContainer>
                              <div className="select">
                                <Select
                                  value={selectedGroup}
                                  onChange={handleSelectGroup}
                                  options={userOption}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                              <Button onClick={onSaveUserType}>저장</Button>
                            </SelectContainer>
                          </Col>
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  declareList: state.appState.data.declareList,
  orderSheetDetail: state.appState.data.orderSheetDetail,
  workDocumentHTML: state.appState.data.workDocumentHTML
})

const mapDispatchToProps = dispatch => ({
  getUserDetail: id => dispatch(getUserDetail(id)),
  getOrderSheetDetail: (id, isPrint) =>
    dispatch(getOrderSheetDetail(id, isPrint)),
  setAdminCategory: (id, category) => dispatch(setAdminCategory(id, category)),
  setClientIsBusinessman: (id, isBusinessman) =>
    dispatch(setClientIsBusinessman(id, isBusinessman))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSheetDetail)

const SelectContainer = styled.div`
  display: flex;

  margin-bottom: 30px;
  .select {
    flex: 1;
    margin-right: 10px;
  }
  button {
    width: 100px;
  }
`
