import React, { useEffect, useState } from 'react'

// redux
import { connect } from 'react-redux'
import { getTaxInvoiceDetailView } from '../../../reducers/taxInvoice'
import 'moment/locale/ko'

const TaxInvoicePreview = ({ taxInvoiceDetailView }) => {
  const [image, setImage] = useState();

  const onCreateImgUrl = (data) => {
    try{
      const bufferToBase64 = Buffer.from(data, 'binary').toString('base64');
      setImage(bufferToBase64);
    }
    catch(e){
      console.log(e)
    }
  };

  useEffect(()=>{
    onCreateImgUrl(taxInvoiceDetailView)
  }, [taxInvoiceDetailView]);

  return (
    <div
      style={{
        marginTop: 70,
        width: '100%',
        padding: '20px 20px 100px',
        backgroundColor: '#fff'
      }}
    >
      {taxInvoiceDetailView && (
        <img style={{ width: "70%", margin: "auto", display: "block" }} src={`data:image/png;base64,${image}`} />
      )}
    </div>
  )
}
const mapStateToProps = state => ({
	taxInvoiceDetailView: state.appState.data.taxInvoiceDetailView
})

/** taxInvoice View Dispath */
const mapDispatchToProps = dispatch => ({
	getTaxInvoiceDetailView: id => dispatch(getTaxInvoiceDetailView(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaxInvoicePreview)