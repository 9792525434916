import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { backoffice } from '../../../../global/constants'
import CommonPie from '../../../../components/Common/Pie'

export default function DocumentCountShareDashboard() {
  const [data, setData] = useState(null)
  const callAPI = useCallback(async () => {
    try {
      const url = `${backoffice.DOCUMENT_SHARE}/card`
      const res = await axios.get(url)
      setData(res.data.dashBoard)
    } catch (e) {
      throw e
    }
  }, [])

  const labels = ['견적서', '거래명세서', '영수증', '청구서']

  useEffect(() => {
    callAPI()
  }, [])
  return (
    <Wrapper>
      <p>문서 공유</p>
      {data && (
        <CommonPie
          type={'donut'}
          labels={labels}
          data={[
            data.estimateTotalCount,
            data.tradingStatementTotalCount,
            data.receiptTotalCount,
            data.billTotalCount
          ]}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
