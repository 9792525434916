import {
  put,
  call,
  takeLatest,
  takeEvery,
  select
} from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_ORDERSHEET_LIST,
  GET_ORDERSHEET_DETAIL,
  SET_CLIENT_IS_BUSINESSMAN,
} from '../reducers/orderSheet'
import { getWorkDocumentHtml } from '../sagas/workOrder'

export function* getOrderSheetList(actions) {
  const {
    page,
    amount,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate
  } = actions
  yield put(setAppState('START_GET_ORDERSHEET_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ORDERSHEET_LIST', null, false))
  }

  try {
    const params = {
      page: page,
      amount: amount,
      search: search,
      workDocumentType: 'OrderSheet',
      filterBoClientType: clientType,
      filterTotalPriceStartEqualSign: 'More',
      filterTotalPriceStartValue: startMoney,
      filterStartDate: startDate,
      filterEndDate: endDate
    }
    if (endMoney > 0) {
      params.filterTotalPriceEndEqualSign = 'Less'
      params.filterTotalPriceEndValue = endMoney
    }

    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.GET_WORK_DOCUMENT,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('orderSheetList', response.data))
      yield put(
        setAppState('SUCCESS_GET_ORDERSHEET_LIST', response.data, false)
      )
    } else {
      yield put(setAppState('FAILED_GET_ORDERSHEET_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ORDERSHEET_LIST', e, false))
  }
}

export function* getOrderSheetDetail(actions) {
  const { id, isPrint } = actions
  yield put(setAppState('START_GET_ORDERSHEET_DETAIL', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ORDERSHEET_DETAIL', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.WORK_DOCUMENT_DETAIL}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('orderSheetDetail', response.data))

      const newData = {
        ...response.data.workOrderForQuantity
        // type: 'Receipt'
      }
      yield call(getWorkDocumentHtml, { data: newData })
      yield put(
        setAppState('SUCCESS_GET_ORDERSHEET_DETAIL', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_ORDERSHEET_DETAIL', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ORDERSHEET_DETAIL', e, false))
  }
}

export function* setClientIsBusinessman(actions) {
  const { id, isBusinessman } = actions
  yield put(setAppState('START_SET_CLIENT_IS_BUSINESSMAN', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_CLIENT_IS_BUSINESSMAN', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      // url: `${constants.backoffice.BUSINESSMAN_WORK_DOCUMENT}/${id}`,
      url: `${constants.backoffice.CLIENT_TYPE_WORK_DOCUMENT}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        userId: id
        // ratePlanId: ratePlanId
      },
      data: {
        boClientType: isBusinessman
        // boClientIsBusinessman: isBusinessman
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('workOrderList', response.data))
      yield put(
        setAppState('SUCCESS_SET_CLIENT_IS_BUSINESSMAN', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_SET_CLIENT_IS_BUSINESSMAN', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_CLIENT_IS_BUSINESSMAN', e, false))
  }
}

const orderSheetSagas = [
  takeLatest(GET_ORDERSHEET_LIST, getOrderSheetList),
  takeEvery(GET_ORDERSHEET_DETAIL, getOrderSheetDetail),
  takeEvery(SET_CLIENT_IS_BUSINESSMAN, setClientIsBusinessman),
]

export default orderSheetSagas
