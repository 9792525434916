import { Col, Row } from 'reactstrap'

export default function Breadcrumb(props: any) {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-18">{props.title}</h1>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {/*<Link to={"/projects-create"}>*/}
              {/*    <button type="button" className="btn btn-warning w-md waves-effect waves-light">등록하기</button>*/}
              {/*</Link>*/}
            </ol>
            {/* <ol className="breadcrumb m-0">*/}
            {/*         <BreadcrumbItem>*/}
            {/*            <Link to="#">{  itle}</Link>*/}
            {/*        </BreadcrumbItem>*/}
            {/*        <BreadcrumbItem active>*/}
            {/*            <Link to="#">{props.breadcrumbItem}</Link>*/}
            {/*        </BreadcrumbItem>*/}
            {/*</ol>*/}
          </div>
        </div>
      </Col>
    </Row>
  )
}