import { Container, Form, Label, Row, Table } from "reactstrap";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getUserDocumentPaymentSettlementStatementsV3 } from "../../../../api/users";
import { IPostDocumentPaymentViewSettlementStatementReq, Opcode, SettlementStatement, ViewOutputSizeType, ViewOutputType } from "@payworkteam/server-product-types";
import { getDocumentPaymentViewSettlementStatementV3 } from "../../../../api/documentPayment";
import moment from "moment";
import Paginator from "../../../../components/Common/Paginator";
import Select from 'react-select';
import styled from "styled-components";
import { SettlementStatementsSelectType } from "../../../../types/model/user/settlementStatement";

interface Props {
	userEmail: string;
}

/** 유저 별 정산내역서 */
export default function UserDetailSettlementStatements({
	userEmail,
}: Props) {
	/** app status */
	const [page, setPage] = useState(1);
	const [amount, setAmount] = useState(10);
	const [type, setType] = useState(SettlementStatementsSelectType[0].value);
	const userId = window.location.pathname.split('/')[2];
	
	/** querys */
	/** 정산내역서 리스트 호출 */
	const {
		data: userDocumentPaymentSettlementStatements,
	} = useQuery(["getUserDocumentPaymentSettlementStatements", userId, page, amount, type], async () => {
		if (!userId) {
			return;
		}
		
		const {
			opcode,
			message,
			settlementStatements,
			count,
			totalDepositAmt
		} = await getUserDocumentPaymentSettlementStatementsV3(userId, {
			page: page - 1,
			amount,
			type
		});

		/** exception */
		if (opcode !== Opcode.SUCCESS) {
			console.error("$$ getUserDocumentPaymentSettlementStatementsV3 error => ", message);
			return;
		}

		return {
			settlementStatements,
			count,
			totalDepositAmt
		}
	});

	/** 정산내역서 출력 */
	const {
		mutateAsync: asyncDownload,
	} = useMutation(async (props: {
		payload: {
			settlementStatement: SettlementStatement;
			year: number;
			month: number;
		};
		output?: ViewOutputType;
		outputSize?: ViewOutputSizeType;
		viewPortScale?: string | null;
	}) => {
		const {
			payload,
			output,
			outputSize,
			viewPortScale
		} = props;

		const {
			settlementStatement,
			year,
			month
		} = payload;
		
		/** 선택된 데이터가 없으면 거부 */
		if (!settlementStatement) {
			return;
		}

		/** 날짜가 없으면 거부 */
		if (!year || !month) {
			return;
		}

		const data = await getDocumentPaymentViewSettlementStatementV3(payload, {
			output,
			outputSize,
			viewPortScale
		});
		
		return data;
	});

	/** functions */
	/** 정산내역서 다운로드 */
	const downloadSettlementStatement = async (settlementStatement: {
		date: string;
		count: number;
		clientName?: string;
		clientCompanyName?: string;
		clientCompanyRegistrationNumber?: string
		amt: number;
		depositAmt: number;
		nicePayFeeSupplyAmount: number;
		nicePayFeeTotal: number;
		nicePayFeeVat: number;
		payworkFeeSupplyAmount: number;
		payworkFeeTotal: number;
		payworkFeeVat: number;
	}) => {
		const {
			date,
			count,
			clientName,
			clientCompanyName,
			clientCompanyRegistrationNumber,
			amt,
			depositAmt,
			nicePayFeeSupplyAmount,
			nicePayFeeTotal,
			nicePayFeeVat,
			payworkFeeSupplyAmount,
			payworkFeeTotal,
			payworkFeeVat,
		} = settlementStatement;
		
		/** parse date */
		const parseDate = moment(settlementStatement.date, "YYYYMM");
		
		const payload: IPostDocumentPaymentViewSettlementStatementReq = {
			settlementStatement: {
				count,
				clientName: clientName ?? userEmail,
				clientCompanyName: clientName ?? "",
				clientCompanyRegistrationNumber: clientName ?? "",
				amt,
				depositAmt,
				nicePayFeeSupplyAmount,
				nicePayFeeTotal,
				nicePayFeeVat,
				payworkFeeSupplyAmount,
				payworkFeeTotal,
				payworkFeeVat,
			},
			year: parseDate.year(),
			month: parseDate.month() + 1,
		}
		
		/** buffer call */
		const buffer = await asyncDownload({
			payload,
			output: ViewOutputType.PDF_BASE64,
			outputSize: ViewOutputSizeType.A4,
			viewPortScale: null
		});

		/** exception error */
		if (typeof buffer !== "string") {
			console.log("$$ asyncDownload failed => ", buffer);
			return;
		}

		/** 다운로드 절차 */
		// base64 데이터를 Blob으로 변환
        const byteCharacters = atob(buffer);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Blob을 URL로 변환
        const blobUrl = URL.createObjectURL(blob);

        // 임시 링크 생성 후 클릭하여 다운로드
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'example.pdf'; // 파일명 지정
        document.body.appendChild(link);
        link.click();

        // 다운로드 후 임시 링크 제거
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl); // 메모리 해제
	}

	/** 타입 변경 */
	const onChangeType = (type: string) => {
		setType(type);
		setPage(1);
	}
	
	return (
		<Container fluid>
			<Form className="mb-4" style={{ width: "100%" }}>
				{/* title */}
				<Row className="d-flex justify-content-between">
					<Label>
						<h3>
							전체 {userDocumentPaymentSettlementStatements?.count || 0}건
						</h3>
					</Label>
					
				</Row>
				<Row>
					<div className="col-sm-4 px-0">
						<Select
							options={SettlementStatementsSelectType}
							defaultValue={SettlementStatementsSelectType[0]}
							isMulti={false}
							onChange={(e) => onChangeType(e?.value as string)}
						/>
					</div>
				</Row>
			</Form>

			<Row>
				<Table class="table table-centered table-nowrap">
					<thead className="thead-light">
						<tr>
							<Thead>대상 기간</Thead>
							{type !== "detail" && <Thead>건 수</Thead>}
							{type !== "month" && <Thead>고객명</Thead>}
							<Thead>총 금액</Thead>
							<Thead>페이워크 수수료</Thead>
							<Thead>페이워크 수수료 합계</Thead>
							<Thead>페이워크 수수료 + NP 수수료</Thead>
							<Thead>NP 수수료 부가세</Thead>
							<Thead>NP 수수료 합계</Thead>
							<Thead>정산 금액</Thead>
						</tr>
					</thead>
					
					<tbody>
						{userDocumentPaymentSettlementStatements?.settlementStatements.map((settlementStatement, index) => {
							const {
								date,
								amt,
								depositAmt,
								nicePayFeeSupplyAmount,
								nicePayFeeTotal,
								nicePayFeeVat,
								payworkFeeSupplyAmount,
								payworkFeeTotal,
								payworkFeeVat,
							} = settlementStatement;
							
							return (
								<tr key={index}>
									<td className="text-center" style={{ color: "#4E62DC", cursor: "pointer" }} onClick={() => downloadSettlementStatement(settlementStatement)}>{date}</td>
									{type !== "detail" && <td className="text-center">{settlementStatement.count}건</td>}
									{type !== "month" && <td className="text-center">{settlementStatement.clientCompanyName ? `${settlementStatement.clientCompanyName} / ${settlementStatement.clientName}` : settlementStatement.clientName}</td>}
									<td className="text-center">{amt.toLocaleString()}원</td>
									<td className="text-center">{payworkFeeSupplyAmount.toLocaleString()}원</td>
									<td className="text-center">{payworkFeeTotal.toLocaleString()}원</td>
									<td className="text-center">{(payworkFeeTotal + nicePayFeeTotal).toLocaleString()}원</td>
									<td className="text-center">{nicePayFeeVat.toLocaleString()}원</td>
									<td className="text-center">{nicePayFeeTotal.toLocaleString()}원</td>
									<td className="text-center">{depositAmt.toLocaleString()}원</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
			</Row>

			{/* pagination */}
			<Paginator
				currentPage={page}
				pageSize={amount}
				total={Math.ceil((userDocumentPaymentSettlementStatements?.count || 0) / amount)}
				onChange={(page: number) => setPage(page)}
			/>
		</Container>
	)
}

const Thead = styled.th`
	text-align: center;
`;