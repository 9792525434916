import apiClient from './index'
import { instance } from './baseInstance';
import { Opcode } from '@payworkteam/server-product-types';
import { UserBusinessmanModel, WorkOrderForQuantityModel } from "@payworkteam/server-product-entity";
import { ISbaAlternativeCreditEvaluation, ISbaAlternativeCreditEvaluationResult } from '../types/model/user/sba';

const basePath = '/backoffice/user';

export const getUsers = async (
  options = { page: 1, amount: 10, search: '' }
) => {
  try {
    const { data } = await apiClient().get(`${basePath}`, {
      params: options
    })

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

export const getUser = async (id: string) => {
  try {
    const { data } = await apiClient().get(`${basePath}/${id}`, {
      params: { id }
    })

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

export const createUser = async (newUser: any) => {
  try {
    const { data } = await apiClient().post(`${basePath}`, newUser)

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

export const deleteUser = async (ids: Array<string>) => {
  try {
    const responseDataList = await Promise.all(
      ids.map(id => {
        // return apiClient().get(`${basePath}/enabled/${id}`, { params: { id } })
        return apiClient().get(`${basePath}/enabled/${id}`)
      })
    )

    return responseDataList
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

export const updateUser = async (id: string, updateUser: any) => {
  try {
    const { data } = await apiClient().post(`${basePath}/${id}`, updateUser, {
      params: { id }
    })

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

export const deleteUsers = async (ids: Array<string>) => {
  try {
    const { data } = await apiClient().delete(`${basePath}`, {
      data: { userIds: ids }
    })

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

// 프로젝트 목록
export const getUserProjects = async (id: any, options = { completed: false }) => {
  try {
    const { data } = await apiClient().get(`${basePath}/${id}/project`, {
      params: options
    })

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

// 연결된 인증 방식
// TODO
export const getUserProjects2 = async (id: string, options = { completed: true }) => {
  try {
    const { data } = await apiClient().get(`${basePath}/${id}/project`, {
      params: options
    })

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

/** 요금제 관련 유저 목록 */
export const getUserRatePlanUsers = async (props = {
  query: {
    page: 0,
    amount: 10,
    search: null,
    type: null
  }
}) => {
  try {
    const {
      query
    } = props;

    const {
      page,
      amount,
      search,
      type
    } = query;
    
    const url = `/user/ratePlanUsers`;
    const option = {
      params: {
        page,
        amount,
        search,
        type
      }
    }

    const { data } = await apiClient().get(url, option);
    
    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

/** 유저 문서 리스트 */
export const getUserWorkDocumentListV3 = async (userId: string, params: {
  page: number;
  amount: number;
}) => {
	const data: {
    opcode: Opcode;
    message: string;
    workDocuments: Array<WorkOrderForQuantityModel>;
    count: number;
  } = await instance.get(`/user/workDocument/list/${userId}`, {
	  params
	});
  
	return data;
}

/** 유저 사업자 리스트 */
export const getUserBusinessmanList = async (userId: string, params: {
  page: number;
  amount: number;
}) => {
  const data: {
    opcode: Opcode;
    message: string;
    businessmans: Array<UserBusinessmanModel>;
    count: number;
  } = await instance.get(`/user/businessman/list/${userId}`, {
    params
  });
  
  return data;
}

/** 유저 정산내역서 리스트 */
export const getUserDocumentPaymentSettlementStatementsV3 = async (userId: string, params: {
  page: number;
  amount: number;
  type?: string;
}) => {
	const data: {
    opcode: Opcode;
    message: string;
    settlementStatements: Array<{
      userId: string;
		  username: string;
      email: string;
      date: string;
      count: number;
      clientName?: string;
			clientCompanyName?: string;
			clientCompanyRegistrationNumber?: string;
			amt: number;
			depositAmt: number;
			nicePayFeeSupplyAmount: number;
			nicePayFeeVat: number;
			nicePayFeeTotal: number;
			payworkFeeSupplyAmount: number;
			payworkFeeVat: number;
			payworkFeeTotal: number;
    }>;
    count: number;
    totalDepositAmt: number;
  } = await instance.get(`/user/documentPayment/settlementStatements/${userId}`, {
	  params
	});
  
	return data;
}

/** sba r&d 대안신용평가를 위한 추이분석 (v3) */
export const getUserSbaAlternativeCreditEvaluation = async (userId: string) => {
  const data: {
    opcode: Opcode;
    message: string;
    data: Array<ISbaAlternativeCreditEvaluation>;
    recent3MonthScore: Array<ISbaAlternativeCreditEvaluationResult>;
    last3MonthScore: Array<ISbaAlternativeCreditEvaluationResult>;
    finalScore: number;
  } = await instance.get(`/user/sbaAlternativeCreditEvaluation/${userId}`);

  return data;
}