export const GET_ADMIN_LIST = 'reducers/admin/GET_ADMIN_LIST'
export const TOGGLE_ADMIN = 'reducers/admin/TOGGLE_ADMIN'

export const getAdminList = () => ({
  type: GET_ADMIN_LIST
})

export const toggleAdmin = id => ({
  type: TOGGLE_ADMIN,
  id
})

const reducer = (state = {}, action) => {
  // console.log('admin', action)
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
