import React, { useState, useEffect } from 'react'

// globals
import { API_URL } from '../../../global/constants'

// redux
import { connect } from 'react-redux'
import { getTradingStatementDetail } from '../../../reducers/tradingStatement'
import {
  Alert,
  CardTitle,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Form,
  Button
} from 'reactstrap'
import moment from 'moment'

const TradingStatementInfo= ({
  tradingStatementDetail
}) => {

  console.log(tradingStatementDetail)

  return (

    <Form
    // onSubmit={handleUpdate}
    >
      <CardTitle style={{ fontWeight: 700 }}>
        회원 정보
      </CardTitle>
      <Row>
        <Col lg="3">
          <FormGroup>
            <Label>이름</Label>
            <Input
              type="text"
              className="form-control"
              name="creatorName"
              value={
                tradingStatementDetail?.creatorName
                  ? tradingStatementDetail?.creatorName
                  : ''
              }
              disabled
            />
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Label>전화번호</Label>
            <Input
              type="text"
              className="form-control"
              name="phone"
              value={
                tradingStatementDetail.user &&
                tradingStatementDetail?.user?.phone
                  ? tradingStatementDetail?.user?.phone
                  : ''
              }
              disabled
            />
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Label>이메일</Label>
            <Input
              type="text"
              className="form-control"
              name="phone"
              value={
                tradingStatementDetail.user &&
                tradingStatementDetail.user.email
              }
              disabled
            />
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Label>구분</Label>
            <Input
              type="text"
              className="form-control"
              name="businessman"
              value={
                tradingStatementDetail.businessman
                  ? '사업자'
                  : '개인'
              }
              disabled
            />
          </FormGroup>
        </Col>
      </Row>

      <CardTitle style={{ fontWeight: 700 }}>
        고객 정보
      </CardTitle>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label>이름</Label>
            <Input
              type="text"
              className="form-control"
              name="clientName"
              value={
                tradingStatementDetail.clientName &&
                tradingStatementDetail.clientName
              }
              disabled
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label>전화번호</Label>
            <Input
              type="text"
              className="form-control"
              name="clientPhone"
              value={
                tradingStatementDetail.clientPhone &&
                tradingStatementDetail.clientPhone
              }
              disabled
            />
          </FormGroup>
        </Col>
      </Row>
      
      {
        tradingStatementDetail?.user.userManager && tradingStatementDetail?.user.userManager.length !== 0 && (
          <>
            <CardTitle style={{ fontWeight: 700 }}>
              담당자 정보
            </CardTitle>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <Label>이름</Label>
                  <Input
                    type="text"
                    name="creatorName"
                    value={tradingStatementDetail?.user.userManager[0].name || ''}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label>전화번호</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name="creatorPhone"
                    value={tradingStatementDetail?.user.userManager[0].phone || ''}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label>이메일</Label>
                  <Input
                    type="text"
                    name="creatorEmail"
                    value={tradingStatementDetail?.user.userManager[0].email || ''}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>          
          </>
        )
      }

      <CardTitle style={{ fontWeight: 700 }}>
        알림톡 정보
      </CardTitle>
      
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label>ID</Label>
            <Input
              type="text"
              name="startedAt"
              value={
                tradingStatementDetail?.workOrderForQuantityAlimeTalk
                  ? tradingStatementDetail
                      .workOrderForQuantityAlimeTalk.id
                  : '미생성'
              }
              disabled
            />
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label>생성일</Label>
            <Input
              type="text"
              className="form-control"
              name="projectName"
              value={
                tradingStatementDetail?.workOrderForQuantityAlimeTalk
                  ? tradingStatementDetail
                      .workOrderForQuantityAlimeTalk
                      .createdAt &&
                    moment(
                      tradingStatementDetail
                        .workOrderForQuantityAlimeTalk
                        .createdAt
                    ).format('YYYY-MM-DD-HH:mm')
                  : // workOrderData.workOrderForQuantityAlimeTalk.createdAt.slice(
                    //   0,
                    //   10
                    // )
                    '미생성'
              }
              disabled
            />
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label>승인 여부</Label>
            <Input
              type="text"
              name="startedAt"
              value={
                tradingStatementDetail.clientConfirm ? 'O' : 'X'
              }
              disabled
            />
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label>공유 횟수</Label>
            <Input
              type="text"
              name="projectState"
              value={
                tradingStatementDetail?.workOrderForQuantityShare
                  ? tradingStatementDetail
                      .workOrderForQuantityShare.length
                  : 0
              }
              disabled
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}
const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  tradingStatementDetail: state.appState.data.tradingStatementDetail
})

const mapDispatchToProps = dispatch => ({
  getTradingStatementDetail: id => dispatch(getTradingStatementDetail(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TradingStatementInfo)
