import { voucherData } from './dummy'

export const sortingData = type => {
  const sorting = type.sort((a, b) => {
    let x = new Date(a.createdAt).getTime()
    let y = new Date(b.createdAt).getTime()

    if (x > y) {
      return -1
    } else {
      if (x < y) {
        return 1
      }
      return 0
    }
  })

  return sorting
}

export const sortingUsers = type => {
  const sorting = type.sort((a, b) => {
    let x = new Date(a.signupAt).getTime()
    let y = new Date(b.signupAt).getTime()

    if (x > y) {
      return -1
    } else {
      if (x < y) {
        return 1
      }
      return 0
    }
  })

  return sorting
}

export const filterUsers = (data, filtering) => {
  let finalData = []
  if (filtering === 'all') {
    finalData = data
  } else if (filtering === 'workOrderForQuantityCount') {
    finalData = data.sort(
      (a, b) => b.workOrderForQuantityCount - a.workOrderForQuantityCount
    )
  } else if (filtering === 'realUser') {
    finalData = data.filter(f => f.admin === false)
  } else if (filtering === 'admin') {
    finalData = data.filter(f => f.admin === true)
  } else if (filtering === 'tester') {
    finalData = data.filter(f => f.isTestAccount === true)
  } else if (filtering === 'isService') {
    finalData = data.filter(f => f.setServicePush === true)
  } else if (filtering === 'isMarketing') {
    finalData = data.filter(f => f.setMarketingPush === true)
  } else if (filtering === 'signupAt') {
    finalData = sortingSignup(data)
  } else if (filtering === 'loginAt') {
    finalData = sortingLogin(data)
  } else if (filtering === 'voucher') {
    let filterVoucher = []
    for (let i = 0; i < voucherData.length; i++) {
      let temp = data.filter(f => f.id === voucherData[i].id)[0]
      if (temp) {
        filterVoucher.push(temp)
      }
    }
    finalData = filterVoucher
  } else if (filtering === 'userLoginInfoCount') {
    finalData = data.sort((a, b) => b.userLoginInfoCount - a.userLoginInfoCount)
  }
}

const sortingSignup = type => {
  const sorting = type.sort((a, b) => {
    let x = new Date(a.signupAt).getTime()
    let y = new Date(b.signupAt).getTime()

    if (x > y) {
      return 1
    } else {
      if (x < y) {
        return -1
      }
      return 0
    }
  })

  return sorting
}

const sortingLogin = type => {
  const sorting = type.sort((a, b) => {
    let x = new Date(a.loginAt).getTime()
    let y = new Date(b.loginAt).getTime()

    if (x > y) {
      return -1
    } else {
      if (x < y) {
        return 1
      }
      return 0
    }
  })

  return sorting
}

export const Badge_Type = [
  'badge-info',
  'badge-primary',
  'badge-secondary',
  'badge-dark'
]

export const getFileSize = x => {
  var s = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB']
  var e = Math.floor(Math.log(x) / Math.log(1024))
  return (x / Math.pow(1024, e)).toFixed(2) + ' ' + s[e]
}

export const History_Type = {
  0: 'workOrderForQuantityHistoryWorkAlimeTalkChecked',
  1: 'workOrderForQuantityHistoryModifyAlimeTalkChecked',
  2: 'workOrderForQuantityHistoryPaymentAlimeTalkAdd',
  3: 'workOrderForQuantityHistoryPaymentAlimeTalkModify',
  4: 'workOrderForQuantityHistoryPaymentAlimeTalkRemove',
  5: 'workOrderForQuantityHistoryPaymentAlimeTalkChecked',
  6: 'workOrderForQuantityHistoryTermAdd',
  7: 'workOrderForQuantityHistoryTermModify',
  8: 'workOrderForQuantityHistoryTermRemove',
  9: 'workOrderForQuantityHistoryEstimateAdd',
  10: 'workOrderForQuantityHistoryEstimateModify',
  11: 'workOrderForQuantityHistoryEstimateRemove',
  12: 'workOrderForQuantityHistoryProjectStarted',
  13: 'workOrderForQuantityHistoryProjectCompleted',
  14: 'workOrderForQuantityHistoryAlimeTalkSendAt',
  15: 'workOrderForQuantityHistoryAlimeTalkViewing',
  16: 'workOrderForQuantityHistoryModifyProjectName',
  17: 'workOrderForQuantityHistoryModifyProjectCreatorName',
  18: 'workOrderForQuantityHistoryModifyProjectClientName',
  19: 'workOrderForQuantityHistoryModifyProjectClientPhone',
  20: 'workOrderForQuantityHistoryModifyProjectBankNumber',
  21: 'workOrderForQuantityHistoryModifyProjectBankCode1',
  22: 'workOrderForQuantityHistoryModifyProjectBankAccountHolder2',
  23: 'workOrderForQuantityHistoryModifyProjectStartedAt',
  24: 'workOrderForQuantityHistoryModifyProjectCompletedAt',
  25: 'workOrderForQuantityHistoryApproveAdd',
  26: 'workOrderForQuantityHistoryApproveModify',
  27: 'workOrderForQuantityHistoryApproveRemove',
  28: 'workOrderForQuantityHistoryContractAdd',
  29: 'workOrderForQuantityHistoryContractRemove'
}

export const Bank_Code = {
  '004': '국민은행',
  '003': '기업은행',
  '011': 'NH농협',
  '088': '신한은행',
  '027': '한국씨티은행',
  '023': 'SC은행',
  '090': '카카오뱅크',
  '020': '우리은행',
  '081': '하나은행',
  '039': '경남은행',
  '034': '광주은행',
  '037': '전북은행',
  '031': '대구은행',
  '032': '부산은행',
  '007': '수협은행',
  '002': 'KDB산업은행',
  '071': '우체국',
  '035': '제주은행',
  '045': '새마을금고',
  '048': '신협',
  '0238': '미래에셋대우',
  103: 'SBI저축은행',
  '089': '케이뱅크'
}

export const categoryState = state => {
  switch (state) {
    case '245241e7-cbce-4108-bb82-b571d8654fa4':
      return '견적 비교'
    case '0876d320-66bb-48a9-b9ec-23a3bb15f142':
      return '함께 일해요'
    case '76c371d9-f410-4ebb-81f4-032284a7b191':
      return '업무 QnA'
    case 'c623262b-0c32-4721-be00-b1fac7fe4502':
      return '자유게시판'
    case '0ded9cec-dbd9-4ea4-ad18-6561ecbfaad4':
      return '꿀팁저장소'
    case 'e425f74d-effd-43b5-9e1d-c67d3a91dc1b':
      return '업무 일상'
    case 'edc6448b-3655-402f-9186-57e87df40ab8':
      return '협업 찾기 '
    default:
      return ''
  }
}

export const genderState = state => {
  switch (state) {
    case '2':
      return '여성'

    case '1':
      return '남성'

    default:
      return '미선택'
  }
}

export const voucherCompany = userId => {
  switch (userId) {
    case '0fd0b9e8-28f4-4a4e-890f-690752438b60':
      return ['서울보트']

    case 'b6f7aa87-9da7-448a-816c-0c7ba96fb902':
      return ['엠케이랩']

    case 'a2a1f13a-a963-411a-88a9-581c6b7412f9':
      return ['모놀리스엑스디']

    case '0fd0b9e8-28f4-4a4e-888f-690752438b58':
      return ['손박사지압원']

    case '61678e67-1ee9-47a6-8e07-dc533d684e63':
      return ['비즈부스터', '누리듀', '몬스터부동산중개']

    case '3d74cf98-87d9-478c-a2a7-4effaa8e30c6':
      return ['힐링씨티']

    case '0fd0b9e8-28f4-4a4e-886f-690752438b56':
      return ['블랭크스튜디오']

    case '19adce42-4187-451f-8093-091075cd9aea':
      return ['주식회사 티앤티플러스']

    case 'da062ebb-6938-484c-8b70-3c2331b0d912':
      return ['케이앰아이']

    case 'c3299ba7-ccca-4208-8f7a-989059e6c288':
      return ['모놀리스']

    case '0fd0b9e8-28f4-4a4e-884f-690752438b54':
      return ['케이엠아이에드']

    case '1ce967d2-8f2b-410a-88a4-e132cfe7a120':
      return ['주식회사레버티']

    case '0fd0b9e8-28f4-4a4e-883f-690752438b53':
      return ['주식회사킵핏']

    case 'fc37ace6-db27-4289-a943-db34e56b3e6a':
      return ['넥스트라인코드']

    case 'be800544-5583-4ab6-a49b-1abc8756c167':
      return ['주식회사 88후드']

    case 'ef6c2b80-b6ca-45be-8005-dabe850a2f43':
      return ['위모빌리티주식회사']

    case '0fd0b9e8-28f4-4a4e-881f-690752438b51':
      return ['마음See']

    case '0fd0b9e8-28f4-4a4e-880f-690752438b50':
      return ['도미토리소프트']

    case '0fd0b9e8-28f4-4a4e-879f-690752438b49':
      return ['자기다움브와']

    case '04d33654-2cca-4e92-b7c4-0f9f97e72a23':
      return ['주식회사 와이인터내셔날']

    case '4cee4a80-5dfe-481f-acee-bcc470177e0a':
      return ['이니셜포인트']

    case '0fd0b9e8-28f4-4a4e-878f-690752438b48':
      return ['클레어앤컴퍼니']

    case '0fd0b9e8-28f4-4a4e-877f-690752438b47':
      return ['이코리아']

    case '8100d563-42ba-47c8-b423-8d1deff4f89e':
      return ['디오데오']

    case 'cc0d96cb-211d-45c3-b72f-f39faebcdfbd':
      return ['주식회사 위블']

    case 'fb8c9197-8e5d-41a3-9c33-46e488871760':
      return ['우트(주)']

    case 'faa90162-439d-4b50-850e-1c84b90723ac':
      return ['비발디수리샘']

    case '99bbabb0-c4da-4e5c-be14-9d893fc80ebe':
      return ['드워프펍']

    case '0fd0b9e8-28f4-4a4e-876f-690752438b46':
      return ['퍼스트홈파티스스튜디오 정관구']

    case '51673dce-5112-4bb4-842b-0ed14594959d':
      return ['포플']

    case '85b037a8-f128-4717-9bac-947e603f9aed':
      return ['앙트레']

    default:
      return ['일반']
  }
}

export const b2bData = userId => {
  switch (userId) {
    case '0fd0b9e8-28f4-4a4e-890f-690752438b60':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-21' }

    case 'b6f7aa87-9da7-448a-816c-0c7ba96fb902':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-20' }

    case 'a2a1f13a-a963-411a-88a9-581c6b7412f9':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-01' }

    case '0fd0b9e8-28f4-4a4e-888f-690752438b58':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-22' }

    case '61678e67-1ee9-47a6-8e07-dc533d684e63':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-14' }

    case '3d74cf98-87d9-478c-a2a7-4effaa8e30c6':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-20' }

    case '0fd0b9e8-28f4-4a4e-886f-690752438b56':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-29' }

    case '19adce42-4187-451f-8093-091075cd9aea':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-30' }

    case 'da062ebb-6938-484c-8b70-3c2331b0d912':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-30' }

    case 'c3299ba7-ccca-4208-8f7a-989059e6c288':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-30' }

    case '0fd0b9e8-28f4-4a4e-884f-690752438b54':
      return { B2BContent: '', B2BAt: '' }

    case '1ce967d2-8f2b-410a-88a4-e132cfe7a120':
      return { B2BContent: '', B2BAt: '' }

    case 'fc37ace6-db27-4289-a943-db34e56b3e6a':
      return { B2BContent: '실속형 (1년)', B2BAt: '2021-12-30' }

    case '0fd0b9e8-28f4-4a4e-878f-690752438b48':
      return { B2BContent: '일반형 (6개월)', B2BAt: '2021-12-20' }

    case 'fb8c9197-8e5d-41a3-9c33-46e488871760':
      return { B2BContent: '일반형 (6개월)', B2BAt: '2021-12-22' }

    case '51673dce-5112-4bb4-842b-0ed14594959d':
      return { B2BContent: '', B2BAt: '' }

    default:
      return { B2BContent: '', B2BAt: '' }
  }
}

export const getDateDiff = (d1, d2) => {
  const date1 = new Date(d1)
  const date2 = new Date(d2)

  const diffDate = date1.getTime() - date2.getTime()

  return Math.abs(diffDate / (1000 * 60 * 60 * 24)) // 밀리세컨 * 초 * 분 * 시 = 일
}
