import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import Yearly from './Yearly'
import Monthly from './Monthly'
import Weekly from './Weekly'

export default function NorthStarRetention() {
  const [type, setType] = useState('monthly')
  const onClickButton = useCallback(type => {
    setType(type)
  }, [])

  return (
    <Wrapper>
      <h2>KPI AU Retention</h2>
      <p>
        저번달에 KPI AU(거래문서 + 결제)였던 회원이 이번달에도 KPI AU가 되었는지
      </p>
      <ButtonWrapper>
        {/* <Button
          color={type === 'yearly' ? 'primary' : 'secondary'}
          active={type === 'yearly'}
          onClick={() => onClickButton('yearly')}
        >
          Yearly
        </Button> */}
        <Button
          color={type === 'monthly' ? 'primary' : 'secondary'}
          active={type === 'monthly'}
          onClick={() => onClickButton('monthly')}
        >
          Monthly
        </Button>
        {/* <Button
          color={type === 'weekly' ? 'primary' : 'secondary'}
          active={type === 'weekly'}
          onClick={() => onClickButton('weekly')}
        >
          Weekly
        </Button> */}
      </ButtonWrapper>
      <ChartWrapper>
        {type === 'yearly' ? (
          <Yearly />
        ) : type === 'weekly' ? (
          <Weekly />
        ) : type === 'monthly' ? (
          <Monthly />
        ) : (
          <></>
        )}
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`

const ChartWrapper = styled.div``
