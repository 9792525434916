import React, { useState, useEffect } from 'react'

// tools
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Form
} from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'
import CryptoJS from 'crypto-js'

// components
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import Indicator from '../../../components/Common/Indicator'
import FeedReaction from './FeedReaction'
import FeedComments from './FeedComments'
import FeedReports from './FeedReports'
import AttachmentSlider from './AttachmentSlider'
import FeedBreadcrumb from './FeedBreadcrumb'

// api
import {
  getFeed,
  // declareFeedList,
  createComment,
  deleteComment
} from '../../../api/feed'
import { getTesters } from '../../../api/tester'
import { getUsers } from '../../../api/users'

// redux
import {
  getFeedDetail,
  declareFeedList,
  setFeedComment,
  removeFeedComment,
  setFeedReaction,
  setFeedCommentReaction
} from '../../../reducers/feed'
import { getUserList, getUserDetail } from '../../../reducers/users'
import { getTesterList } from '../../../reducers/tester'

// util
import { sortingData } from '../../../constant/utils'
import { categoryState, genderState } from '../../../constant/utils'
import { addOrderPrefix } from '../../../helpers/utils'

// temp
import img1 from '../../../assets/images/small/img-1.jpg'
import img2 from '../../../assets/images/small/img-2.jpg'
import img3 from '../../../assets/images/small/img-3.jpg'

const items = [
  {
    // src: img1,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    // src: img2,
    altText: 'Slide 2',
    caption: 'Slide 2'
  },
  {
    // src: img3,
    altText: 'Slide 3',
    caption: 'Slide 3'
  }
]

const FeedDetail = ({
  history,
  state,
  params,
  feedDetail,
  getFeedDetail,
  declareFeedList,
  declareList,
  getUserList,
  userDetail,
  getUserDetail,
  testerList,
  userList,
  getTesterList,
  setFeedComment,
  removeFeedComment,
  setFeedReaction,
  setFeedCommentReaction
}) => {
  const feedId = window.location.pathname.split('/')[2]
  const [activeTab, setActiveTab] = useState('1')
  const [isFetching, setFetching] = useState(false)
  const [error, setError] = useState('')
  // 댓글
  const [createModal, setCreateModal] = useState(false)
  const [feedData, setFeedData] = useState({})
  const [userData, setUserData] = useState([])
  const [allUserData, setAllUserData] = useState([])
  const [testerData, setTesterData] = useState([])
  const [declareData, setDeclareData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)

  // 공감
  const [reactionModal, setReactionModal] = useState(false)
  const [reactionTarget, setReactionTarget] = useState('')

  // 댓글 공감
  const [commentReactionModal, setCommentReactionModal] = useState(false)
  const [commentReactionTarget, setCommentReactionTarget] = useState('')

  const [reportFetchOptions, setReportFetchOptions] = useState({
    page: 1,
    amount: 10,
    search: ''
  })

  const [formData, setFormData] = useState({
    comment: '',
    targetUserId: null, // 테스터 아이디,
    feedCommentId: ''
  })
  const [targetComment, setTargetComment] = useState([])

  const [status, setStatus] = useState({
    isError: false,
    color: 'danger',
    errorMsg: '피드 조회 중 문제가 발생했습니다.'
  })

  const location = useLocation()
  const idInfo = location.state?.idInfo ? location.state.idInfo : ''
  const pageInfo = location.state?.pageInfo ? location.state.pageInfo : 1
  const lastPageInfo = location.state?.lastPageInfo
    ? location.state.lastPageInfo
    : 1
  const searchInfo = location.state?.searchInfo ? location.state.searchInfo : ''
  const isFeedDetail = location.state?.isFeedDetail ? true : false
  const filteringInfo = location.state?.filteringInfo
    ? location.state.filteringInfo
    : ''

  useEffect(() => {
    // ;(async () => {
    //   setFetching(true)
    //   const { users, max, opcode } = await getTesters({
    //     page: 1,
    //     amount: 9999,
    //     search: ''
    //   })
    //   if (opcode === 0) {
    //     if (users.length > 0) {
    //       setTesterData(users)
    //     }
    //   }
    //   setFetching(false)
    // })()
  }, [])

  useEffect(() => {
    getFeedDetail(feedId)
    getTesterList()
    getUserList({
      page: 1,
      amount: 9999,
      search: ''
    })

    // ;(async () => {
    //   setFetching(true)
    //   const usersResponse = await getUsers({
    //     page: 1,
    //     amount: 9999,
    //     search: ''
    //   })
    //   if (usersResponse.opcode === 0) {
    //     setUsersData(usersResponse.users)
    //   }
    //   const { opcode, feed } = await getFeed(feedId)
    //   if (opcode === 0) {
    //     setFeedData(feed)
    //   } else {
    //     history.push('/feeds')
    //   }
    //   setFetching(false)
    // })()
    // ;(async () => {
    //   setFetching(true)
    //   const response = await declareFeedList({
    //     page: 0,
    //     amount: 9999,
    //     search: ''
    //   })
    //   if (response.opcode === 0) {
    //     const filterData = response.feedAdminDeclarations.filter(ele => {
    //       return ele.feedId === feedId
    //     })
    //     setDeclareData(filterData)
    //   }
    //   setFetching(false)
    // })()
    // }, [feedId])
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_FEED_DETAIL':
        setFeedData(feedDetail)
        getUserDetail(feedDetail.userId)
        break
      case 'SUCCESS_GET_USER_DETAIL':
        setUserData(userDetail)

        // const filterData = declareList.feedAdminDeclarations.filter(ele => {
        //   return ele.feedId === feedId
        // })
        // setDeclareData(filterData)
        break
      case 'SUCCESS_GET_TESTER_LIST':
        const filterTester = testerList.sort((a, b) => {
          let x = addOrderPrefix(a.username)
          let y = addOrderPrefix(b.username)

          if (x < y) {
            return -1
          }
          if (x > y) {
            return 1
          }

          return 0
        })

        setTesterData(filterTester)
        break
      case 'FAILED_GET_FEED_DETAIL':
        setStatus({ color: 'danger', isError: true, errorMsg: params.message })
        break
      case 'FAILED_GET_USER_DETAIL':
        setStatus({ color: 'danger', isError: true, errorMsg: params.message })
        break
      case 'SUCCESS_GET_USER_LIST':
        setAllUserData(userList.users)
        break
      case 'SUCCESS_SET_FEED_COMMENT':
        setFormData({
          comment: '',
          targetUserId: null,
          feedCommentId: ''
        })
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '댓글이 등록되었습니다'
        })
        getFeedDetail(feedId)
        break
      case 'SUCCESS_REMOVE_FEED_COMMENT':
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '댓글이 삭제되었습니다'
        })
        setTargetComment([])
        getFeedDetail(feedId)
        break
      case 'SUCCESS_SET_FEED_REACTION':
        setReactionTarget('')
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '공감이 추가되었습니다'
        })
        getFeedDetail(feedId)
        break
      case 'SUCCESS_SET_FEED_COMMENT_REACTION':
        setCommentReactionTarget('')
        setTargetComment([])
        setStatus({
          color: 'success',
          isError: true,
          errorMsg: '댓글에 공감이 추가되었습니다'
        })
        getFeedDetail(feedId)
        break

      default:
        break
    }
  }, [state])

  const onSaveComment = e => {
    e.preventDefault()

    const data = {
      comment: formData.comment,
      feedCommentId: targetComment.feedCommentId,
      targetUserId: formData.targetUserId
    }

    setCreateModal(false)
    setFeedComment(feedId, data, targetComment)
  }

  // const onSaveComment = async event => {
  //   event.preventDefault()

  //   const data = {
  //     comment: formData.comment,
  //     feedCommentId: targetComment.feedCommentId,
  //     targetUserId: formData.targetUserId
  //   }

  //   const result = await createComment(
  //     feedId,
  //     data,
  //     targetComment
  //     // targetComment.feedCommentId
  //   )

  //   if (result.opcode === 0) {
  //     setFetching(true)
  //     setFormData({
  //       comment: '',
  //       targetUserId: null,
  //       feedCommentId: ''
  //     })
  //     setTargetComment([])
  //     setCreateModal(false)
  //     const { opcode, feed } = await getFeed(feedId)

  //     if (opcode === 0) {
  //       setFeedData(feed)
  //     } else {
  //       history.push('/feeds')
  //     }
  //     setFetching(false)
  //   } else {
  //     return setError(result.message)
  //   }
  // }

  const handleCommentInputs = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onChangeTester = e => {
    setFormData({ ...formData, targetUserId: e.target.value })
  }

  const handleDelete = e => {
    e.preventDefault()

    removeFeedComment(feedId, targetComment)
  }

  const handleFeedReaction = (e, type) => {
    e.preventDefault()

    let checkType = 10

    const selectedReaction = feedData.feedReaction.filter(
      f =>
        f.userId === (reactionTarget === '' ? userData.user.id : reactionTarget)
    )

    if (selectedReaction.length > 0) {
      if (selectedReaction[0].reaction === type) {
        checkType = null
      } else {
        checkType = type
      }
    }

    const data = {
      reaction: checkType === 10 ? type : checkType,
      targetUserId: reactionTarget === '' ? userData.user.id : reactionTarget
    }

    setReactionModal(false)
    setFeedReaction(feedId, data)
  }

  const handleFeedCommentReaction = (e, type) => {
    e.preventDefault()

    let checkType = 10
    let filterData = []

    // const selectedReaction = feedData.feedComment.feedCommentReaction.filter(
    //   f =>
    //     f.userId ===
    //     (commentReactionTarget === ''
    //       ? userData.user.id
    //       : commentReactionTarget)
    // )
    const selectComment = feedData.feedComment.filter(
      f => f.id === targetComment
    )

    if (selectComment[0].feedCommentReaction.length > 0) {
      filterData = selectComment[0].feedCommentReaction.filter(
        f =>
          f.userId ===
          (commentReactionTarget === ''
            ? userData.user.id
            : commentReactionTarget)
      )
    }

    if (filterData.length > 0) {
      if (filterData[0].reaction === type) {
        checkType = null
      } else {
        checkType = type
      }
    }

    const data = {
      reaction: checkType === 10 ? type : checkType,
      targetUserId:
        commentReactionTarget === '' ? userData.user.id : commentReactionTarget
    }

    setCommentReactionModal(false)

    setFeedCommentReaction(feedId, targetComment, data)
  }

  const onChangeReactionTester = e => {
    setReactionTarget(e.target.value)
  }

  const onChangeCommentReactionTester = e => {
    setCommentReactionTarget(e.target.value)
  }

  const handleCommunity = feedId => {
    const urlEncrypted = CryptoJS.AES.encrypt(
      JSON.stringify(feedId),
      'a37b5knl2nv46l36nk9m34lqmo0sac'
    ).toString()
    const deleteSlash = urlEncrypted.replace(/\//g, 'y9gy9g')

    window.open(
      `https://console.paywork.io/community/post/${deleteSlash}`,
      '_blank'
    )
  }

  // console.log('$$ feed detail', userData)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Link to={'/feeds'}>
            <Breadcrumbs title={`<  ${userData.user?.username || ''}`} />
          </Link> */}
          <FeedBreadcrumb
            title={`<  ${feedData.feedBoard?.title || ''}`}
            idInfo={idInfo}
            pageInfo={pageInfo}
            lastPageInfo={lastPageInfo}
            searchInfo={searchInfo}
            isFeedDetail={isFeedDetail}
            filteringInfo={filteringInfo}
          />

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1')
                      }}
                    >
                      <i className="bx bx-detail d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">상세 정보</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        setActiveTab('2')
                      }}
                    >
                      <i className="bx bxs-message-detail d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">댓글 정보</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        setActiveTab('3')
                      }}
                    >
                      <i className="bx bxs-report d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">공감 정보</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => {
                        setActiveTab('4')
                      }}
                    >
                      <i className="bx bxs-calendar-exclamation d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">신고 정보</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        {isFetching ? (
                          <Indicator />
                        ) : (
                          <div
                            // style={{ minHeight: '850px' }}
                            style={{ minHeight: '1000px' }}
                          >
                            <Form
                            // onSubmit={handleUpdate}
                            >
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    연결
                                    {feedData?.feedStatus === 0 ? (
                                      <i
                                        className="mdi mdi-link"
                                        style={{
                                          cursor: 'pointer',
                                          marginLeft: '10px'
                                        }}
                                        onClick={() =>
                                          handleCommunity(feedData.id)
                                        }
                                      />
                                    ) : (
                                      <i
                                        className="mdi mdi-link-off"
                                        style={{
                                          cursor: 'pointer',
                                          marginLeft: '10px'
                                        }}
                                        onClick={() =>
                                          setStatus({
                                            ...status,
                                            color: 'danger',
                                            errorMsg: '삭제된 게시물입니다',
                                            isError: true
                                          })
                                        }
                                      />
                                    )}
                                  </FormGroup>
                                </Col>

                                <Col lg="12">
                                  <FormGroup>
                                    <Label>프로필</Label>
                                    <div className="mt-4 mt-md-0">
                                      {feedData.feedUserInfoPrint
                                        ?.userProfileImage ? (
                                        <img
                                          className="img-thumbnail rounded-circle avatar-lg"
                                          src={`https://s3.ap-northeast-2.amazonaws.com/media.paywork.io/${feedData.feedUserInfoPrint.userProfileImage.path}/${feedData.feedUserInfoPrint.userProfileImage.filename}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="avatar-lg">
                                          <span
                                            className="avatar-title rounded-circle"
                                            style={{ fontSize: '2.5rem' }}
                                          >
                                            {feedData.feedUserInfoPrint?.nickname.charAt(
                                              0
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>이름</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      value={userData.user?.username}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>성별</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="gender"
                                      value={
                                        userData.user &&
                                        genderState(userData.user.gender)
                                      }
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>생년월일</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="gender"
                                      value={
                                        userData.user && userData.user.birth
                                          ? userData.user.birth.replace(
                                              /(\d{4})(\d{2})(\d{2})/g,
                                              '$1-$2-$3'
                                            )
                                          : '미입력'
                                      }
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>직업</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="nickname"
                                      value={
                                        userData.user &&
                                        userData.user.userFeed.job
                                          ? userData.user.userFeed.job
                                          : '미입력'
                                      }
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>닉네임</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="nickname"
                                      value={
                                        feedData.feedUserInfoPrint &&
                                        feedData?.feedUserInfoPrint.nickname
                                      }
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>전화번호</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="nickname"
                                      value={userData.user?.phone}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>이메일</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="nickname"
                                      value={userData.user?.email}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>삭제</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="nickname"
                                      value={
                                        feedData?.feedStatus === 0 ? 'X' : 'O'
                                      }
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>제목</Label>
                                    <Input
                                      disabled
                                      className="form-control disabled"
                                      value={
                                        feedData.feedBoard &&
                                        feedData?.feedBoard.title
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>카테고리</Label>
                                    <Input
                                      disabled
                                      className="form-control disabled"
                                      value={
                                        feedData?.feedCategory &&
                                        categoryState(
                                          feedData?.feedCategory[0]
                                            .feedCategoryTempletId
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <Label>등록일 </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="phone"
                                      value={
                                        feedData.createdAt &&
                                        feedData?.createdAt.slice(0, 10)
                                      }
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label>내용</Label>
                                    <Input
                                      type="textarea"
                                      value={
                                        feedData.feedBoard &&
                                        feedData?.feedBoard.content
                                      }
                                      style={{ minHeight: '300px' }}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row></Row>
                              <Row>
                                <Col lg="12">
                                  <Label>이미지</Label>
                                  {feedData.feedBoard?.feedBoardImage?.length >
                                  0 ? (
                                    <AttachmentSlider
                                      data={feedData.feedBoard?.feedBoardImage}
                                    />
                                  ) : (
                                    <Input
                                      readOnly
                                      className="form-control"
                                      type="input"
                                      rows={11}
                                      name="description"
                                      value={'등록된 사진이 없습니다'}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </TabPane>
                      <TabPane tabId="2">
                        <FeedComments
                          feedData={feedData}
                          usersData={allUserData}
                          testerData={testerData}
                          formData={formData}
                          error={error}
                          createModal={createModal}
                          onSaveComment={onSaveComment}
                          onChangeCreateModal={() =>
                            setCreateModal(!createModal)
                          }
                          handleInputs={handleCommentInputs}
                          onChangeTester={onChangeTester}
                          setTargetComment={setTargetComment}
                          targetComment={targetComment}
                          handleDelete={handleDelete}
                          setDeleteModal={setDeleteModal}
                          deleteModal={deleteModal}
                          commentReactionModal={commentReactionModal}
                          onChangeCommentReactionModal={() =>
                            setCommentReactionModal(!commentReactionModal)
                          }
                          onChangeCommentReactionTester={
                            onChangeCommentReactionTester
                          }
                          handleFeedCommentReaction={handleFeedCommentReaction}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <FeedReaction
                          feedData={feedData}
                          // usersData={userData}
                          usersData={allUserData}
                          testerData={testerData}
                          reactionModal={reactionModal}
                          onChangeTester={onChangeTester}
                          onChangeReactionModal={() =>
                            setReactionModal(!reactionModal)
                          }
                          handleFeedReaction={handleFeedReaction}
                          setTargetComment={setTargetComment}
                          targetComment={targetComment}
                          onChangeReactionTester={onChangeReactionTester}
                        />
                      </TabPane>
                      <TabPane tabId="4">
                        <FeedReports
                          feedData={feedData}
                          usersData={allUserData}
                          // usersData={userData}
                          declareData={declareData}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  feedDetail: state.appState.data.feedDetail,
  userDetail: state.appState.data.userDetail,
  declareList: state.appState.data.declareList,
  testerList: state.appState.data.testerList,
  userList: state.appState.data.userList
})
const mapDispatchToProps = dispatch => ({
  getFeedDetail: id => dispatch(getFeedDetail(id)),
  getUserDetail: id => dispatch(getUserDetail(id)),
  getTesterList: id => dispatch(getTesterList()),
  getUserList: options => dispatch(getUserList(options)),
  setFeedComment: (feedId, commentData, commentId) =>
    dispatch(setFeedComment(feedId, commentData, commentId)),
  removeFeedComment: (feedId, feedCommentId) =>
    dispatch(removeFeedComment(feedId, feedCommentId)),
  setFeedReaction: (feedId, reactionData) =>
    dispatch(setFeedReaction(feedId, reactionData)),
  setFeedCommentReaction: (feedId, feedCommentId, reactionData) =>
    dispatch(setFeedCommentReaction(feedId, feedCommentId, reactionData))
  // declareFeedList: options => dispatch(declareFeedList(options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedDetail)
