import {
  put,
  call,
  takeLatest,
  takeEvery,
  select
} from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'
import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_RECEIPT_DETAIL,
  GET_RECEIPT_LIST,
  SET_RECEIPT_IS_BUSINESSMAN
} from '../reducers/receipt'
import { getWorkDocumentHtml } from '../sagas/workOrder'

export function* getReceiptList(actions) {
  const {
    page,
    amount,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate
  } = actions

  yield put(setAppState('START_GET_RECEIPT_LIST', null, true))

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_RECEIPT_LIST', null, false))
  }

  try {
    const params = {
      page: page,
      amount: amount,
      search: search,
      workDocumentType: 'Receipt',
      filterBoClientType: clientType,
      filterTotalPriceStartEqualSign: 'More',
      filterTotalPriceStartValue: startMoney,
      filterStartDate: startDate,
      filterEndDate: endDate
    }
    if (endMoney > 0) {
      params.filterTotalPriceEndEqualSign = 'Less'
      params.filterTotalPriceEndValue = endMoney
    }
    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.GET_WORK_DOCUMENT,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('receiptList', response.data))
      yield put(setAppState('SUCCESS_GET_RECEIPT_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_RECEIPT_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_RECEIPT_LIST', e, false))
  }
}

export function* getReceiptDetail(actions) {
  const { id } = actions
  yield put(setAppState('START_GET_RECEIPT_DETAIL', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_RECEIPT_DETAIL', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.RECEIPT}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      const newData = {
        ...response.data.workOrderForQuantity
        // type: 'Receipt'
      }

      yield call(getWorkDocumentHtml, { data: newData })

      yield put(
        saveAppData('receiptDetail', response.data.workOrderForQuantity)
      )

      yield put(setAppState('SUCCESS_GET_RECEIPT_DETAIL', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_RECEIPT_DETAIL', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_RECEIPT_DETAIL', e, false))
  }
}

export function* setReceiptIsBusinessman(actions) {
  const { id, isBusinessman } = actions
  yield put(setAppState('START_SET_RECEIPT_IS_BUSINESSMAN', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_RECEIPT_IS_BUSINESSMAN', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.RECEIPT}/clientIsBusinessman/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        clientIsBusinessman: isBusinessman
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState('SUCCESS_SET_RECEIPT_IS_BUSINESSMAN', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_SET_RECEIPT_IS_BUSINESSMAN', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_RECEIPT_IS_BUSINESSMAN', e, false))
  }
}

const tradingStatementSaga = [
  takeLatest(GET_RECEIPT_LIST, getReceiptList),
  takeLatest(GET_RECEIPT_DETAIL, getReceiptDetail),
  takeLatest(SET_RECEIPT_IS_BUSINESSMAN, setReceiptIsBusinessman)
]

export default tradingStatementSaga
