import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { backoffice } from '../../../../global/constants'
import CommonPie from '../../../../components/Common/Pie'

export default function DocumentPatternTaxDashboard() {
  const [docType, setDocType] = useState('견적서')
  const onChangeDocType = useCallback(e => {
    setDocType(e.target.value)
  }, [])

  const [data, setData] = useState(null)
  const callAPI = useCallback(async () => {
    const doc =
      docType === '견적서'
        ? 'Estimate'
        : docType === '거래명세서'
        ? 'TradingStatement'
        : docType === '영수증'
        ? 'Receipt'
        : docType === '청구서'
        ? 'Bill'
        : ''
    try {
      const url = `${backoffice.TAX_INVOICE_RATIO_DASHBOARD}?type=${doc}`
      const res = await axios.get(url)
      setData(res.data)
    } catch (e) {
      throw e
    }
  }, [docType])

  const docTypes = ['견적서', '거래명세서', '영수증', '청구서']
  const labels = ['청구', '영수', '발행안함']

  useEffect(() => {
    callAPI()
  }, [docType])

  return (
    <Wrapper>
      <p>세금계산서 비율</p>
      <SelectWrapper>
        <Select value={docType} onChange={onChangeDocType}>
          {docTypes.map(i => (
            <Option key={i} value={i}>
              {i}
            </Option>
          ))}
        </Select>
      </SelectWrapper>
      {data && (
        <CommonPie
          type={'donut'}
          labels={labels}
          data={[data.claim, data.receipt, data.none]}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const SelectWrapper = styled.div`
  margin: 5px 0;
  & > p {
    margin-bottom: 0;
  }
`

const Select = styled.select`
  margin: 0 10px;
`

const Option = styled.option``
