import { put, call, takeLatest, all } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import {
  DASHBOARD,
  GET_GRAPH_DASHBOARD,
  GET_USER_LOGIN_DASHBOARD,
  GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD,
  GET_BUSINESSMAN_PERCENT_DASHBOARD,
  GET_PLATFORM_LOGIN_DASHBOARD,
  GET_REVISIT_USER_DASHBOARD,
  GET_REVISIT_MONTH_USER_DASHBOARD,
  GET_USER_RETENTION,
  GET_USER_COHORT,
  GET_RETENTION,
  GET_CLIENT_IS_BUSINESS_MAN,
  GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN,
  GET_PAYMENT_PERCENT_DASHBOARD,
  GET_USER_RATIO_DASHBOARD,
  GET_NEW_USER_TO_PAY_DASHBOARD,
  GET_TRIAL_TO_PAY_DASHBOARD,
  GET_AVG_WORK_DOCUMENT_DASHBOARD,
  GET_USER_AVG_WORK_DOCUMENT_DASHBOARD,
  GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD,
  GET_TAX_INVOICE_RATIO_DASHBOARD,
  GET_SALES_DASHBOARD,
  GET_RATE_PLAN_PRICE_DASHBOARD,
  GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD,
  GET_ACC_GRAPH_DASHBOARD
} from '../reducers/dashboard'

export function* getDashboard() {
  yield put(setAppState('START_GET_DASHBOARD', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: constants.backoffice.DASHBOARD,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield all([
        // call(getPaymentPercentDashboard),
        call(getPaymentPercentCard),
        call(getNewMemberToPayCard),
        call(getNewMemberToTrialCard),
        call(getTrialToPayCard),
        call(getDocumentShareCard),
        call(getDocumentCreateCard),
        // call(getRetention),
        call(getRetentionUserCard),
        call(getWorkDocumentCreateUserDashboard),
        call(getFreeUserActiveRatio),
        call(getPaymentMethodRatio),
        call(getClientIsBusinessman),
        call(getTradingStatementClientIsBusinessman)
      ])

      yield put(saveAppData('dashboard', response.data))
      yield put(setAppState('SUCCESS_GET_DASHBOARD', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_DASHBOARD', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_DASHBOARD', e))
  }
}

export function* getGraphDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_GRAPH_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_GRAPH_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_GRAPH_DASHBOARD}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type === 'year' ? 'year' : 'month',
        year: options.year,
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('graphDashboard', response.data.dashBoard))
      yield put(
        setAppState('SUCCESS_GET_GRAPH_DASHBOARD', response.data, false)
      )
    } else {
      yield put(setAppState('FAILED_GET_GRAPH_DASHBOARD', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_GRAPH_DASHBOARD', e))
  }
}

export function* getUserLoginDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_USER_LOGIN_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_USER_LOGIN_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_USER_LOGIN_DASHBOARD}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type === 'year' ? 'year' : 'month',
        year: options.year,
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('loginUserDashboard', response.data.dashBoard))
      yield put(
        setAppState('SUCCESS_GET_USER_LOGIN_DASHBOARD', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_USER_LOGIN_DASHBOARD', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_USER_LOGIN_DASHBOARD', e))
  }
}

export function* getWorkOrderQuantityShareDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(
    setAppState('START_GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD', null, true)
  )
  if (!accessToken) {
    yield put(
      setAppState('FAILED_GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_WORK_ORDER_QUANTITY_SHARE}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type === 'year' ? 'year' : 'month',
        year: options.year,
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        saveAppData('workOrderQuantityShareDashboard', response.data.dashBoard)
      )
      yield put(
        setAppState(
          'SUCCESS_GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD', e))
  }
}

export function* getBusinessmanPercentDashboard() {
  yield put(setAppState('START_GET_BUSINESSMAN_PERCENT_DASHBOARD', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(
      setAppState('FAILED_GET_BUSINESSMAN_PERCENT_DASHBOARD', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: constants.backoffice.GET_BUSINESSMAN_PERCENT_DASHBOARD,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('businessmanPercentDashboard', response.data))
      yield put(
        setAppState(
          'SUCCESS_GET_BUSINESSMAN_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_BUSINESSMAN_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_BUSINESSMAN_PERCENT_DASHBOARD', e))
  }
}

export function* getPlatformLoginDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_PLATFORM_LOGIN_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_PLATFORM_LOGIN_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_PLATFORM_LOGIN_DASHBOARD}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type === 'year' ? 'year' : 'month',
        year: options.year,
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('platformLogin', response.data.dashBoard))
      yield put(
        setAppState(
          'SUCCESS_GET_PLATFORM_LOGIN_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState('FAILED_GET_PLATFORM_LOGIN_DASHBOARD', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PLATFORM_LOGIN_DASHBOARD', e))
  }
}

export function* getRevisitUserDashboard(actions) {
  const { time } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_REVISIT_USER_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_REVISIT_USER_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      // url: constants.backoffice.REVISIT_DAY_USER_LIST,
      url: constants.backoffice.REVISIT_DAY_USER_LIST,

      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: 0,
        amount: 99,
        search: '',
        getTime: time
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('revisitListDashboard', response.data.userRevisits))

      yield put(
        setAppState('SUCCESS_GET_REVISIT_USER_DASHBOARD', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_REVISIT_USER_DASHBOARD', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_REVISIT_USER_DASHBOARD', e))
  }
}

export function* getRevisitMonthUserDashboard(actions) {
  const { time } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_REVISIT_USER_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_REVISIT_USER_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      // url: constants.backoffice.REVISIT_DAY_USER_LIST,
      url: constants.backoffice.REVISIT_MONTH_USER_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: 0,
        amount: 99,
        search: '',
        getTime: time
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('revisitListDashboard', response.data.userRevisits))

      yield put(
        setAppState('SUCCESS_GET_REVISIT_USER_DASHBOARD', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_REVISIT_USER_DASHBOARD', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_REVISIT_USER_DASHBOARD', e))
  }
}

export function* getRetention(actions) {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  // yield put(setAppState('START_GET_RETENTION', null, true))
  // if (!accessToken) {
  //   yield put(setAppState('FAILED_GET_RETENTION', null, false))
  // }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.USER_RETENTION}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('retentionInfo', response.data.retentionInfo))
      // yield put(setAppState('SUCCESS_GET_RETENTION', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_RETENTION', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_RETENTION', e))
  }
}

export function* getUserCohort(actions) {
  const { year, week } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_COHORT', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_COHORT', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.DASHBOARD}/cohort/nonSubLogin`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        year: year,
        week: week
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('cohort', response.data))
      yield put(setAppState('SUCCESS_GET_COHORT', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_COHORT', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_COHORT', e))
  }
}

export function* getUserRetention(actions) {
  const { retentionType, year } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_USER_RETENTION', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_USER_RETENTION', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.USER_RETENTION}/dashboard`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: retentionType,
        year: year
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('retentionList', response.data.retentionInfos))
      yield put(setAppState('SUCCESS_GET_USER_RETENTION', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_USER_RETENTION', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_USER_RETENTION', e))
  }
}

export function* getClientIsBusinessman(actions) {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_CLIENT_IS_BUSINESS_MAN', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_CLIENT_IS_BUSINESS_MAN', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_CLIENT_IS_BUSINESS_MAN}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    // '사업자', '개인', '공기관', '협단체'

    if (response.data.opcode === 0) {
      let clientIsBusinessmanData = [
        response.data.businessman,
        response.data.individual,
        response.data.publicInstitutions,
        response.data.association
      ]

      yield put(saveAppData('clientIsBusinessmanData', clientIsBusinessmanData))
      yield put(
        setAppState('SUCCESS_GET_CLIENT_IS_BUSINESS_MAN', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_CLIENT_IS_BUSINESS_MAN', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_CLIENT_IS_BUSINESS_MAN', e))
  }
}

export function* getTradingStatementClientIsBusinessman(actions) {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(
    setAppState(
      'START_GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN',
      null,
      true
    )
  )
  if (!accessToken) {
    yield put(
      setAppState(
        'FAILED_GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN',
        null,
        false
      )
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      let clientIsBusinessmanData = [
        response.data.businessman,
        response.data.individual,
        response.data.publicInstitutions,
        response.data.association
      ]
      yield put(
        saveAppData(
          'tradingStatementClientIsBusinessmanData',
          clientIsBusinessmanData
        )
      )
      yield put(
        setAppState(
          'SUCCESS_GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(
      setAppState('FAILED_GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN', e)
    )
  }
}

export function* getPaymentPercentDashboard() {
  yield put(setAppState('START_GET_PAYMENT_PERCENT_DASHBOARD', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: `${constants.backoffice.PAYMENT_PERCENT}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    // console.log('$$ paymentPercentDashboard', response)

    if (response.data.opcode === 0) {
      yield put(saveAppData('paymentPercentDashboard', response.data.dashBoard))
    } else {
      yield put(
        setAppState(
          'FAILED_GET_PAYMENT_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', e))
  }
}

function* getPaymentPercentCard() {
  const accessToken = yield call([localStorage, 'getItem'], 'token')

  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: `${constants.backoffice.PAYMENT_PERCENT}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('paymentPercentCard', response.data.dashBoard))
    } else {
      yield put(
        setAppState(
          'FAILED_GET_PAYMENT_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', e))
  }
}

function* getNewMemberToPayCard() {
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: `${constants.backoffice.NEW_MEMBER_TO_PAY}/card`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('memberToPayCard', response.data.dashBoard))
    } else {
      yield put(
        setAppState(
          'FAILED_GET_PAYMENT_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', e))
  }
}

function* getNewMemberToTrialCard() {
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: `${constants.backoffice.NEW_MEMBER_TO_TRIAL}/card`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('memberToTrialCard', response.data.dashBoard))
    } else {
      yield put(
        setAppState(
          'FAILED_GET_PAYMENT_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', e))
  }
}

function* getTrialToPayCard() {
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: `${constants.backoffice.TRIAL_TO_PAY}/card`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('trialToPayCard', response.data.dashBoard))
    } else {
      yield put(
        setAppState(
          'FAILED_GET_PAYMENT_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', e))
  }
}

function* getDocumentShareCard() {
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: `${constants.backoffice.DOCUMENT_SHARE}/card`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('documentShareCard', response.data.dashBoard))
    } else {
      yield put(
        setAppState(
          'FAILED_GET_PAYMENT_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', e))
  }
}

function* getDocumentCreateCard() {
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: `${constants.backoffice.DOCUMENT_CREATE}/card`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('documentCreateCard', response.data.dashBoard))
    } else {
      yield put(
        setAppState(
          'FAILED_GET_PAYMENT_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', e))
  }
}

function* getRetentionUserCard() {
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  try {
    const response = yield call(axios, {
      method: 'GET',
      validateStatus: () => true,
      url: `${constants.backoffice.WEEK_ACTIVE_USER}/card`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('weekActiveUserCard', response.data.dashBoard))
    } else {
      yield put(
        setAppState(
          'FAILED_GET_PAYMENT_PERCENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_PERCENT_DASHBOARD', e))
  }
}

export function* getUserRatioDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_USER_RATIO_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_USER_RATIO_DASHBOARD', null, false))
  }

  // console.log('$$ getUserRatioDashboard', options)

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.USER_RATIO}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type,
        year: options.year,
        // month: 10
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('userRatioGraph', response.data.dashBoard))
      yield put(
        setAppState('SUCCESS_GET_USER_RATIO_DASHBOARD', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_USER_RATIO_DASHBOARD', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_USER_RATIO_DASHBOARD', e))
  }
}

export function* getNewUserToPayDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_NEW_USER_TO_PAY_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_NEW_USER_TO_PAY_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.NEW_MEMBER_TO_PAY}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type,
        year: options.year,
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('newUserToPayGraph', response.data.dashBoard))
      yield put(
        setAppState(
          'SUCCESS_GET_NEW_USER_TO_PAY_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_NEW_USER_TO_PAY_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_NEW_USER_TO_PAY_DASHBOARD', e))
  }
}

export function* getTrialToPayDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_TRIAL_TO_PAY_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_TRIAL_TO_PAY_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.TRIAL_TO_PAY}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type,
        year: options.year,
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('trialToPayGraph', response.data.dashBoard))
      yield put(
        setAppState('SUCCESS_GET_TRIAL_TO_PAY_DASHBOARD', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_TRIAL_TO_PAY_DASHBOARD', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_TRIAL_TO_PAY_DASHBOARD', e))
  }
}

export function* getAvgWorkDocumentDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_AVG_WORK_DOCUMENT_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(
      setAppState('FAILED_GET_AVG_WORK_DOCUMENT_DASHBOARD', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.AVG_WORK_DOCUMENT}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type,
        year: options.year,
        month: options.month,
        workDocumentType: options.workDocumentType
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('avgWorkDocumentGraph', response.data.dashBoard))
      yield put(
        setAppState(
          'SUCCESS_GET_AVG_WORK_DOCUMENT_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_AVG_WORK_DOCUMENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_AVG_WORK_DOCUMENT_DASHBOARD', e))
  }
}

export function* getUserAvgWorkDocumentDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_REDUX_SAGA', null, true))
  if (!accessToken) {
    yield put(
      setAppState('FAILED_GET_USER_AVG_WORK_DOCUMENT_DASHBOARD', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.USER_AVG_WORK_DOCUMENT}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type,
        year: options.year,
        month: options.month,
        workDocumentType: options.workDocumentType
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        saveAppData('userAvgWorkDocumentGraph', response.data.dashBoard)
      )
      yield put(
        setAppState(
          'SUCCESS_GET_USER_AVG_WORK_DOCUMENT_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_USER_AVG_WORK_DOCUMENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_AVG_WORK_DOCUMENT_DASHBOARD', e))
  }
}

export function* getWorkDocumentCreateUserDashboard(actions) {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(
    setAppState('START_GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD', null, true)
  )
  if (!accessToken) {
    yield put(
      setAppState('FAILED_GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.CREATE_USER_WORK_DOCUMENT}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('workDocumentCreateUserCount', response.data.count))
      yield put(
        setAppState(
          'SUCCESS_GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_AVG_WORK_DOCUMENT_DASHBOARD', e))
  }
}

export function* getTaxInvoiceRatioDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_TAX_INVOICE_RATIO_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(
      setAppState('FAILED_GET_TAX_INVOICE_RATIO_DASHBOARD', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.TAX_INVOICE_RATIO_DASHBOARD}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('taxInvoiceRatioData', response.data))
      yield put(
        setAppState(
          'SUCCESS_GET_TAX_INVOICE_RATIO_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_TAX_INVOICE_RATIO_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_TAX_INVOICE_RATIO_DASHBOARD', e))
  }
}

export function* getFreeUserActiveRatio() {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_REDUX_SAGA', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_FREE_USER_ACTIVE_RATIO', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.FREE_USER_ACTIVE_RATIO}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(
        saveAppData('freeUserActiveRatioDashboard', response.data.dashBoard)
      )
      yield put(
        setAppState(
          'SUCCESS_GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState('FAILED_GET_FREE_USER_ACTIVE_RATIO', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_FREE_USER_ACTIVE_RATIO', e))
  }
}

export function* getSalesDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_REDUX_SAGA', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_SALES_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.SALES}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type,
        year: options.year,
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('salesDashboard', response.data.dashBoard))
      yield put(
        setAppState('SUCCESS_GET_SALES_DASHBOARD', response.data, false)
      )
    } else {
      yield put(setAppState('FAILED_GET_SALES_DASHBOARD', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_SALES_DASHBOARD', e))
  }
}

export function* getRatePlanPriceDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_REDUX_SAGA', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_RATE_PLAN_PRICE_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.RATE_PLAN_REVENUE}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: 'year',
        year: options.year,
        month: options.month
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('ratePlanPriceDashboard', response.data.dashBoard))
      yield put(
        setAppState(
          'SUCCESS_GET_RATE_PLAN_PRICE_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_RATE_PLAN_PRICE_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_RATE_PLAN_PRICE_DASHBOARD', e))
  }
}

export function* getPaymentMethodRatio() {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_REDUX_SAGA', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_PAYMENT_RATIO_RATIO', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.PAYMENT_METHOD_RATIO}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: 'TradingStatement'
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('paymentMethodRatioDashboard', response.data))
      yield put(
        setAppState('SUCCESS_GET_PAYMENT_RATIO_RATIO', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_PAYMENT_RATIO_RATIO', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PAYMENT_RATIO_RATIO', e))
  }
}

export function* getActiveUserWorkDocumentDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(
    setAppState('START_GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD', null, true)
  )
  if (!accessToken) {
    yield put(
      setAppState('FAILED_GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.ACTIVE_USER_WORK_DOCUMENT}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type,
        year: options.year,
        month: options.month,
        workDocumentType: options.workDocumentType
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        saveAppData('activeUserWorkDocumentGraph', response.data.dashBoard)
      )
      yield put(
        setAppState(
          'SUCCESS_GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD', e))
  }
}

export function* getAccGraphDashboard(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_ACC_GRAPH_DASHBOARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ACC_GRAPH_DASHBOARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_ACC_GRAPH_DASHBOARD}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type === 'year' ? 'year' : 'month',
        year: options.year,
        month: options.month,
        workDocumentType: options.workDocumentType
      }
    })

    if (response.data.opcode === 0) {
      yield call(getAccUserGraphDashboard, { options })

      yield put(saveAppData('graphAccDashboard', response.data.dashBoard))
      yield put(
        setAppState('SUCCESS_GET_ACC_GRAPH_DASHBOARD', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_ACC_GRAPH_DASHBOARD', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ACC_GRAPH_DASHBOARD', e))
  }
}

function* getAccUserGraphDashboard(opt) {
  const { options } = opt

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_ACC_USER_GRAPH_DASHBOARD}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        type: options.type === 'year' ? 'year' : 'month',
        year: options.year,
        month: options.month,
        workDocumentType: options.workDocumentType
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('graphAccUserDashboard', response.data.dashBoard))
    } else {
      yield put(
        setAppState('FAILED_GET_ACC_GRAPH_DASHBOARD', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ACC_GRAPH_DASHBOARD', e))
  }
}

const dashboardSagas = [
  takeLatest(DASHBOARD, getDashboard),
  takeLatest(GET_GRAPH_DASHBOARD, getGraphDashboard),
  takeLatest(GET_USER_LOGIN_DASHBOARD, getUserLoginDashboard),
  takeLatest(
    GET_WORK_ORDER_QUANTITY_SHARE_DASHBOARD,
    getWorkOrderQuantityShareDashboard
  ),
  takeLatest(GET_BUSINESSMAN_PERCENT_DASHBOARD, getBusinessmanPercentDashboard),
  takeLatest(GET_PLATFORM_LOGIN_DASHBOARD, getPlatformLoginDashboard),
  takeLatest(GET_REVISIT_USER_DASHBOARD, getRevisitUserDashboard),
  takeLatest(GET_REVISIT_MONTH_USER_DASHBOARD, getRevisitMonthUserDashboard),
  takeLatest(GET_RETENTION, getRetention),
  takeLatest(GET_USER_RETENTION, getUserRetention),
  takeLatest(GET_USER_COHORT, getUserCohort),
  takeLatest(GET_CLIENT_IS_BUSINESS_MAN, getClientIsBusinessman),
  takeLatest(
    GET_TRADING_STATEMENT_CLIENT_IS_BUSINESS_MAN,
    getTradingStatementClientIsBusinessman
  ),
  takeLatest(GET_PAYMENT_PERCENT_DASHBOARD, getPaymentPercentDashboard),
  takeLatest(GET_USER_RATIO_DASHBOARD, getUserRatioDashboard),
  takeLatest(GET_NEW_USER_TO_PAY_DASHBOARD, getNewUserToPayDashboard),
  takeLatest(GET_TRIAL_TO_PAY_DASHBOARD, getTrialToPayDashboard),
  takeLatest(GET_AVG_WORK_DOCUMENT_DASHBOARD, getAvgWorkDocumentDashboard),
  takeLatest(
    GET_USER_AVG_WORK_DOCUMENT_DASHBOARD,
    getUserAvgWorkDocumentDashboard
  ),
  takeLatest(
    GET_WORK_DOCUMENT_CREATE_USER_DASHBOARD,
    getWorkDocumentCreateUserDashboard
  ),
  takeLatest(GET_TAX_INVOICE_RATIO_DASHBOARD, getTaxInvoiceRatioDashboard),
  takeLatest(GET_SALES_DASHBOARD, getSalesDashboard),
  takeLatest(GET_RATE_PLAN_PRICE_DASHBOARD, getRatePlanPriceDashboard),
  takeLatest(
    GET_ACTIVE_USER_WORK_DOCUMENT_DASHBOARD,
    getActiveUserWorkDocumentDashboard
  ),
  takeLatest(GET_ACC_GRAPH_DASHBOARD, getAccGraphDashboard)
]

export default dashboardSagas
