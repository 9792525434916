export const dateOptions = [
  { value: 'all', label: '전체' },
  { value: '1-week', label: '일주일' },
  { value: '1-month', label: '1개월' },
  { value: '2-month', label: '2개월' },
  { value: '3-month', label: '3개월' },
  { value: '6-month', label: '6개월' },
  { value: '1-year', label: '1년' }
]
export const noticeOptions = [
  { value: 'AK', label: '승인요청' },
  { value: 'HI', label: '보고승인' },
  { value: 'CA', label: '알림' },
  { value: 'NV', label: '지연' }
]
export const detailOptions = [
  { value: 'AK1', label: '새 알림1' },
  { value: 'AK2', label: '새 알림2' },
  { value: 'AK3', label: '새 알림3' },
  { value: 'AK4', label: '새 알림4' }
]
export const countOptions = [
  { value: '전체', label: '전체' },
  { value: '보낸 견적서', label: '보낸 견적서' },
  { value: '받은 견적서', label: '받은 견적서' },
  { value: '나만의 일정', label: '나만의 일정' }
]

export const getDateRange = selectedRange => {
  const today = new Date()
  const day = today.getDay()
  const month = today.getMonth()
  const year = today.getFullYear()
  switch (selectedRange) {
    case '1-week':
      const startNow = new Date()
      const endNow = new Date()
      return {
        start: new Date(startNow.setDate(startNow.getDate() - day)),
        end: new Date(endNow.setDate(endNow.getDate() - day + 6))
      }
    case '1-month':
      return {
        start: new Date(year, month, 1).getTime(),
        end: new Date(year, month + 1, 0).getTime()
      }
    case '2-month':
      return {
        start: new Date(year, month - 1, 1).getTime(),
        end: new Date(year, month + 1, 0).getTime()
      }
    case '3-month':
      return {
        start: new Date(year, month - 2, 1).getTime(),
        end: new Date(year, month + 1, 0).getTime()
      }
    case '6-month':
      return {
        start: new Date(year, month - 5, 1).getTime(),
        end: new Date(year, month + 1, 0).getTime()
      }
    case '1-year':
      return {
        start: new Date(year, month - 11, 1).getTime(),
        end: new Date(year, month + 11, 0).getTime()
      }

    default:
      return {
        start: 0,
        end: Infinity
      }
  }
}

export const getDeadDate = (
  createdAt,
  deadline = 0,
  deadlineUnit = undefined
) => {
  if (deadlineUnit === null || deadlineUnit === undefined) return

  const from = new Date(createdAt)
  const date = from.getDate()
  const month = from.getMonth()
  const year = from.getFullYear()

  switch (parseInt(deadline)) {
    // 주: 1
    case 1:
      return from.setDate(date + 7 * deadline)
    // 개월: 2
    case 2:
      return from.setMonth(month + deadline)
    // 년: 3
    case 3:
      return from.setFullYear(year + deadline)
    // 일: 0
    default:
      return from.setDate(date + deadline)
  }
}

export const getUnserializedData = (type, code) => {
  if (code === null || code === undefined) return

  switch (type) {
    case 'MemberType':
      const obj1 = {
        0: '클라이언트',
        1: '작업자'
      }
      return obj1[code]
    case 'ProjectStatus':
      const obj2 = {
        0: '정상',
        1: '승인 대기 중',
        2: '보고 대기 중',
        3: '리뷰 대기 중'
      }
      return obj2[code]
    case 'ApproveAttachmentType':
      const obj3 = {
        0: '알 수 없음',
        1: '이미지'
      }
      return obj3[code]
    case 'ApproveStatus':
      const obj4 = {
        0: '응답 대기 중',
        1: '성공',
        2: '실패',
        3: '취소'
      }
      return obj4[code]
  }
}

export const setStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const getStorage = key => {
  const data = JSON.parse(window.localStorage.getItem(key) || '[]')

  return data.length ? data : null
}

export const sortingData = type => {
  const sorting = type.sort((a, b) => {
    let x = new Date(a.createdAt).getTime()
    let y = new Date(b.createdAt).getTime()

    if (x > y) {
      return -1
    } else {
      if (x < y) {
        return 1
      }
      return 0
    }
  })

  return sorting
}

export const addOrderPrefix = s => {
  let code = s.toLowerCase().charCodeAt(0)
  let prefix

  // 한글 AC00—D7AF
  if (0xac00 <= code && code <= 0xd7af) prefix = '1'
  // 한글 자모 3130—318F
  else if (0x3130 <= code && code <= 0x318f) prefix = '2'
  // 영어 소문자 0061-007A
  else if (0x61 <= code && code <= 0x7a) prefix = '3'
  // 그외
  else prefix = '9'

  return prefix + s
}

export const Week_Type_Repeat = [
  '월요일',
  '화요일',
  '수요일',
  '목요일',
  '금요일',
  '토요일',
  '일요일',
  '월요일',
  '화요일',
  '수요일',
  '목요일',
  '금요일',
  '토요일',
  '일요일'
]

export const Month_Type_Repeat = [
  '1월',
  '2월',
  '3월',
  '4월',
  '5월',
  '6월',
  '7월',
  '8월',
  '9월',
  '10월',
  '11월',
  '12월',
  '1월',
  '2월',
  '3월',
  '4월',
  '5월',
  '6월',
  '7월',
  '8월',
  '9월',
  '10월',
  '11월',
  '12월'
]
