import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Row
} from 'reactstrap'
import React, { useState } from 'react'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
// Form Editor
import { Editor } from 'react-draft-wysiwyg'
import { Link } from 'react-router-dom'

const NoticeRegister = props => {
  const [noticeContent, setNoticeContent] = useState({
    author_name: '손지인',
    author_email: 'start@paywork.io',
    title: '',
    overview: '타이틀',
    content: 'Lorem Ipsum is simply dummy text of the printing'
  })

  const { author_name, author_email, title, overview, content } = noticeContent

  const onChange = e => {
    setNoticeContent({ ...noticeContent, [e.target.name]: e.target.value })
  }
  const onSubmit = async e => {
    e.preventDefault()
  }

  return (
    <React.Fragment>
      <Form>
        <div className="page-content">
          <Container fluid={true}>
            <Row className="mb-0">
              <Col sm="8">
                {/* Render Breadcrumbs */}
                <Breadcrumbs
                  title="게시글 등록하기"
                  breadcrumbItem="Board Register"
                />
              </Col>
              <Col sm="4">
                <div className="text-sm-right">
                  <Link to={'/'}>
                    <Button
                      type="button"
                      color="secondary"
                      className="w-md btn waves-effect waves-light mb-2 mr-2"
                    >
                      <i className="mdi"></i> 임시저장
                    </Button>
                  </Link>
                  <Button
                    onSubmit={onSubmit}
                    type="button"
                    color="primary"
                    className="w-md btn waves-effect waves-light mb-2"
                  >
                    <i className="mdi"></i> 등록하기
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>
                      <input
                        className="form-control"
                        type="text"
                        name="title"
                        placeholder="제목을 입력하세요"
                        value={noticeContent.title}
                        onChange={onChange}
                      />
                    </CardTitle>

                    <Editor
                      // onChange={onChange}
                      // name="overview"
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default NoticeRegister
