import React, { useState, useMemo } from 'react'

// tools
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const PaginatorTemp = ({
  currentPage = 1,
  pageSize = 10,
  total = 0,
  onChange
}) => {
  const [start, setStart] = useState(1)
  const [end, setEnd] = useState(11)

  const pageRange = Array.from(
    {
      length: total >= 10 ? end - start : total
    },
    (v, i) => i + start
  )

  const closeIndicatorIn5Seconds = () => {
    setTimeout(() => {
      this.setState({
        showLoader: false
      })
    }, 5000)
  }

  const handlePaginator = (e, changePage) => {
    e.preventDefault()
    if (!changePage) {
      const selectedPage = parseInt(e.target.innerText)
      return onChange(selectedPage, pageSize)
    }
    return onChange(changePage, pageSize)
  }

  return total > 0 ? (
    <Pagination
      className="pagination pagination-rounded justify-content-center mb-2"
      style={{ position: 'absolute', bottom: '10px' }}
    >
      <PaginationItem disabled={Boolean(end === 11) || Boolean(total < 11)}>
        <PaginationLink
          previous
          // onClick={e => handlePaginator(e, currentPage - 1)}
          onClick={e => {
            if (start - 10 < 0) {
              setStart(1)
              setEnd(11)
            }
            if (start - 10 >= 0) {
              setStart(start - 10)
              // setEnd(end - 10)
              setEnd(start)
            }
            handlePaginator(e, currentPage - 10 < 0 ? 1 : currentPage - 10)
          }}
        />
      </PaginationItem>
      {Array.isArray(pageRange) &&
        pageRange.map(page => (
          <PaginationItem active={currentPage === page} key={page}>
            <PaginationLink onClick={e => handlePaginator(e)}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}

      {/* <PaginationItem disabled={Boolean(currentPage === total)}> */}
      <PaginationItem
        disabled={Boolean(end - 1 === total || Boolean(total < 11))}
      >
        <PaginationLink
          next
          onClick={e => {
            if (end + 10 < total) {
              setStart(start + 10)
              setEnd(end + 10)
            }
            if (end + 10 >= total) {
              setStart(start + 10)
              setEnd(total + 1)
            }
            handlePaginator(
              e,
              currentPage + 10 >= total ? total : currentPage + 10
            )
            // handlePaginator(e, currentPage + 1)
          }}
        />
      </PaginationItem>
    </Pagination>
  ) : (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '20vh' }}
    >
      {/* 데이터가 없습니다 */}
      {/* <Indicator height={'30vh'} /> */}
    </div>
  )
}

export default PaginatorTemp
