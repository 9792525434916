import React, { Fragment, useEffect, useState, useCallback } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import CryptoJS from 'crypto-js'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Link, withRouter, useLocation } from 'react-router-dom'
import Paginator from '../../components/Common/Paginator'
import PaginatorTemp from '../../components/Common/PaginatorTemp'
import PaginatorProject from '../../components/Common/PaginatorProject'

// redux
import { getFeedList, removeFeed } from '../../reducers/feed'

// utils
import { sortingData } from '../../helpers/utils'
import { categoryState } from '../../constant/utils'

export const FeedList = ({
  history,
  state,
  params,
  feedList,
  getFeedList,
  removeFeed
}) => {
  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '피드 조회 중 문제가 발생했습니다.',
    color: 'danger'
  })

  const [selectedBoxes, setSelectedBoxes] = useState([])

  const [declareData, setDeclareData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)

  const [originData, setOriginData] = useState({ list: [], max: 0 })
  const [feedData, setFeedData] = useState({ list: [], max: 0 })
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    // amount: 10,
    amount: 9999,
    search: '',
    filtering: 'all'
  })

  // pagination
  const location = useLocation()
  const feedPage = location.state?.feedPage ? location.state.feedPage : 1
  const feedLastPage = location.state?.feedLastPage
    ? location.state.feedLastPage
    : 1
  const feedSearch = location.state?.feedSearch ? location.state.feedSearch : ''
  const feedCheck = location.state?.feedCheck ? true : false
  const feedFiltering = location.state?.feedFiltering
    ? location.state.feedFiltering
    : ''
  const [start, setStart] = useState(1)
  const [end, setEnd] = useState(11)
  const [isFilter, setIsFilter] = useState(false)
  const [filterTitle, setFilterTitle] = useState('')

  useEffect(() => {
    // 상세 페이지에서 이동

    if (feedCheck) {
      setFetchOptions({
        ...fetchOptions,
        page: feedPage,
        amount: 9999,
        search: feedSearch,
        filtering: feedFiltering
      })
      getFeedList({ page: 1, amount: 9999, search: '' })

      if (feedPage > 10) {
        setStart(Math.floor(feedPage / 10) * 10 + 1)
        setEnd(
          Math.floor(feedPage / 10) * 10 + 10 <= feedLastPage
            ? Math.floor(feedPage / 10) * 10 + 11
            : feedLastPage + 1
        )
      }
    } else {
      getFeedList(fetchOptions)
    }

    // getFeedList(fetchOptions)
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_FEED_LIST':
        if (feedList.feeds) {
          const filterData = feedList?.feeds.filter(
            f =>
              (f.user?.username &&
                f.user?.username.includes(fetchOptions.search)) ||
              (f.feedBoard?.title &&
                f.feedBoard?.title.includes(fetchOptions.search)) ||
              (f.user?.phone &&
                f.user?.phone
                  .replace(/-/g, '')
                  .includes(fetchOptions.search.replace(/-/g, '')))
          )

          let finalData = []

          if (fetchOptions.filtering === 'all') {
            finalData = filterData
          } else if (fetchOptions.filtering === 'realUser') {
            finalData = filterData.filter(
              f => f.user?.isTestAccount === false && f.user?.admin === false
            )
            setFilterTitle('피드(실회원)')
          } else if (fetchOptions.filtering === 'tester') {
            finalData = filterData.filter(f => f.user?.isTestAccount === true)
            setFilterTitle('피드(테스터)')
          } else if (fetchOptions.filtering === 'admin') {
            finalData = filterData.filter(f => f.user?.admin === true)
            setFilterTitle('피드(관리자)')
          } else {
            finalData = filterData.filter(
              f =>
                f.feedCategory[0].feedCategoryTempletId ===
                fetchOptions.filtering
            )
            setFilterTitle('피드')
          }

          setOriginData({
            list: finalData,
            max: finalData.length
          })

          setFeedData({
            list: finalData.slice(
              (fetchOptions.page - 1) * 10,
              fetchOptions.page * 10
            ),
            max: finalData.length
          })
        }

        break
      case 'FAILED_GET_FEED_LIST':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        break
      case 'SUCCESS_REMOVE_FEED':
        setSelectedBoxes([])
        setStatus({
          ...status,
          color: 'success',
          isError: true,
          errorMsg: '피드 게시물이 삭제되었습니다'
        })
        getFeedList(fetchOptions)
        break
      case 'FAILED_REMOVE_FEED':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        break
      default:
        break
    }
  }, [state])

  // useEffect(() => {
  //   const filterData = feedList?.feeds.filter(
  //     f =>
  //       f.user?.username.includes(fetchOptions.search) ||
  //       f.feedBoard?.title.includes(fetchOptions.search) ||
  //       f.user?.phone
  //         .replace(/-/g, '')
  //         .includes(fetchOptions.search.replace(/-/g, ''))
  //   )

  //   if (filterData) {
  //     setFeedData({
  //       ...feedData,
  //       list: filterData.slice(
  //         (fetchOptions.page - 1) * 10,
  //         fetchOptions.page * 10
  //       ),
  //       max: filterData.length
  //     })
  //   }
  // }, [fetchOptions])

  useEffect(() => {
    const filterData = feedList?.feeds.filter(
      f =>
        (f.user?.username && f.user?.username.includes(fetchOptions.search)) ||
        (f.feedBoard?.title &&
          f.feedBoard?.title.includes(fetchOptions.search)) ||
        (f.user?.phone &&
          f.user?.phone
            .replace(/-/g, '')
            .includes(fetchOptions.search.replace(/-/g, '')))
    )

    if (filterData) {
      let finalData = []

      if (fetchOptions.filtering === 'all') {
        finalData = filterData
      } else if (fetchOptions.filtering === 'realUser') {
        finalData = filterData.filter(
          f => f.user?.isTestAccount === false && f.user?.admin === false
        )
        setFilterTitle('피드(실회원)')
      } else if (fetchOptions.filtering === 'tester') {
        finalData = filterData.filter(f => f.user?.isTestAccount === true)
        setFilterTitle('피드(테스터)')
      } else if (fetchOptions.filtering === 'admin') {
        finalData = filterData.filter(f => f.user?.admin === true)
        setFilterTitle('피드(관리자)')
      } else {
        finalData = filterData.filter(
          f =>
            f.feedCategory[0].feedCategoryTempletId === fetchOptions.filtering
        )
      }
      setFilterTitle('피드')

      setFeedData({
        ...feedData,
        list: finalData.slice(
          (fetchOptions.page - 1) * 10,
          fetchOptions.page * 10
        ),
        max: finalData.length
      })
    }
  }, [fetchOptions])

  // useEffect(() => {
  //   const filterData = originData.list.filter(
  //     f =>
  //       f.user?.username.includes(fetchOptions.search) ||
  //       f.user?.phone
  //         .replace(/-/g, '')
  //         .includes(fetchOptions.search.replace(/-/g, '')) ||
  //       f.feedBoard?.title.includes(fetchOptions.search)
  //   )

  //   setFeedData({
  //     ...feedData,
  //     list: filterData.slice(
  //       (fetchOptions.page - 1) * 10,
  //       fetchOptions.page * 10
  //     ),
  //     max: filterData.length
  //   })
  // }, [fetchOptions])

  const handlePagination = (page, pageSize) => {
    if (fetchOptions.page !== page) {
      return setFetchOptions({
        ...fetchOptions,
        page
      })
    }

    return
  }

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    setFetchOptions({ ...fetchOptions, search: inputValue })
    setStart(1)
    setEnd(11)

    // debounceInput(inputValue)
  }

  const debounceInput = useCallback()
  // debounce(input => {
  //   setFetchOptions({ ...fetchOptions, search: input })
  //   setStart(1)
  //   setEnd(11)
  // }, 750),
  // []

  const handleDelete = () => {
    setDeleteModal(false)
    removeFeed(selectedBoxes)
  }

  const handleCommunity = feedId => {
    const urlEncrypted = CryptoJS.AES.encrypt(
      JSON.stringify(feedId),
      'a37b5knl2nv46l36nk9m34lqmo0sac'
    ).toString()
    const deleteSlash = urlEncrypted.replace(/\//g, 'y9gy9g')

    window.open(
      `https://console.paywork.io/community/post/${deleteSlash}`,
      '_blank'
    )
  }

  const handlerFilter = input => {
    if (feedList?.feeds.length === 0) return
    else {
      let filterData = feedList?.feeds.filter(
        f =>
          f.user?.username.includes(fetchOptions.search) ||
          f.feedBoard?.title.includes(fetchOptions.search) ||
          f.user?.phone
            .replace(/-/g, '')
            .includes(fetchOptions.search.replace(/-/g, ''))
      )

      if (input === 0) {
        if (filterData) {
          setFetchOptions({
            ...fetchOptions,
            page: 1,
            filtering: 'all'
          })
        }
      }
      if (input === 1) {
        setFetchOptions({
          ...fetchOptions,
          page: 1,
          filtering: 'c623262b-0c32-4721-be00-b1fac7fe4502'
        })
      }
      if (input === 2) {
        if (filterData) {
          setFetchOptions({
            ...fetchOptions,
            page: 1,
            filtering: '76c371d9-f410-4ebb-81f4-032284a7b191'
          })
        }
      }
      if (input === 3) {
        if (filterData) {
          setFetchOptions({
            ...fetchOptions,
            page: 1,
            filtering: '0ded9cec-dbd9-4ea4-ad18-6561ecbfaad4'
          })
        }
      }
      if (input === 4) {
        if (filterData) {
          setFetchOptions({
            ...fetchOptions,
            page: 1,
            filtering: '245241e7-cbce-4108-bb82-b571d8654fa4'
          })
        }
      }
      if (input === 5) {
        if (filterData) {
          setFetchOptions({
            ...fetchOptions,
            page: 1,
            filtering: '0876d320-66bb-48a9-b9ec-23a3bb15f142'
          })
        }
      }
      if (input === 6) {
        if (filterData) {
          setFetchOptions({
            ...fetchOptions,
            page: 1,
            filtering: 'realUser'
          })
        }
      }
      if (input === 7) {
        if (filterData) {
          setFetchOptions({
            ...fetchOptions,
            page: 1,
            filtering: 'tester'
          })
        }
      }
      if (input === 8) {
        if (filterData) {
          setFetchOptions({
            ...fetchOptions,
            page: 1,
            filtering: 'admin'
          })
        }
      }
    }
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`${filterTitle} 정보`}
            breadcrumbItem="Feeds List"
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            value={fetchOptions.search}
                            onChange={handleSearch}
                            placeholder="이름/전화번호/제목"
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>

                    <Col sm="8">
                      <div
                        className="text-sm-right"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Dropdown
                          isOpen={isFilter}
                          toggle={() => setIsFilter(!isFilter)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-light"
                            style={{ marginRight: '10px' }}
                          >
                            <i className="mdi mdi-filter mr-1" />
                            {/* {toggleTitle} */}
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu dropdown-menu-right">
                            <DropdownItem onClick={() => handlerFilter(0)}>
                              전체
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(1)}>
                              자유게시판
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(2)}>
                              업무 QnA
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(3)}>
                              꿀팁저장소
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(4)}>
                              견적 비교
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(5)}>
                              함께 일해요
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(6)}>
                              실회원 글
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(7)}>
                              테스터 글
                            </DropdownItem>
                            <DropdownItem onClick={() => handlerFilter(8)}>
                              관리자 글
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>

                        <Link to={'/feed-register'}>
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          >
                            <i className="mdi mdi-plus mr-1"></i> 등록하기
                          </Button>
                        </Link>
                        <Button
                          type="button"
                          color="danger"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          onClick={() => setDeleteModal(!deleteModal)}
                          disabled={selectedBoxes.length === 0}
                        >
                          <i className="mdi mdi-minus mr-1"></i> 삭제하기
                        </Button>

                        {/* <Button
                          type="button"
                          color="secondary"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          onClick={filterDelete}
                        >
                          <i className="mdi mdi-filter mr-1" /> {toggleTitle}
                        </Button> */}
                      </div>
                    </Col>
                  </Row>

                  <Modal
                    centered
                    scrollable
                    isOpen={deleteModal}
                    toggle={() => setDeleteModal(!deleteModal)}
                  >
                    <ModalHeader
                      toggle={() => setDeleteModal(!deleteModal)}
                      style={{
                        borderBottom: '2px solid #2a3042'
                      }}
                    >
                      <div>피드 삭제</div>
                    </ModalHeader>
                    <ModalBody
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        height: '150px',
                        fontSize: '18px'
                      }}
                    >{`${selectedBoxes.length}개의 피드를 삭제하시겠습니까?`}</ModalBody>
                    <ModalFooter
                      onClick={handleDelete}
                      style={{
                        cursor: 'pointer',
                        justifyContent: 'center',
                        borderTop: '2px solid #e2e2e2',
                        fontWeight: 'bold',
                        fontSize: '16px'
                      }}
                    >
                      삭제하기
                    </ModalFooter>
                  </Modal>

                  <Alert
                    color={status.color}
                    isOpen={status.isError}
                    toggle={() =>
                      setStatus(prevState => ({
                        ...prevState,
                        isError: false
                      }))
                    }
                  >
                    {status.errorMsg}
                  </Alert>

                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '4%' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length === feedData.list.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(feedData.list) &&
                                      setSelectedBoxes(
                                        feedData.list.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            게시일
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            이름
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            전화번호
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            닉네임
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            직업
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            카테고리
                          </th>
                          <th scope="col" style={{ width: '25%' }}>
                            제목
                          </th>
                          <th scope="col" style={{ width: '4%' }}>
                            삭제
                          </th>
                          <th scope="col" style={{ width: '4%' }}>
                            공감
                          </th>
                          <th scope="col" style={{ width: '4%' }}>
                            신고
                          </th>
                          <th scope="col" style={{ width: '4%' }}>
                            이동
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(feedData.list) &&
                          feedData.list.slice().map((feed, idx) => (
                            <>
                              <tr key={'_feed_' + idx}>
                                <td style={{ height: '65px' }}>
                                  <div
                                    className="custom-control custom-checkbox"
                                    style={{ zIndex: 0 }}
                                  >
                                    <Input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={feed.id}
                                      checked={selectedBoxes.includes(feed.id)}
                                      onChange={e =>
                                        e.target.checked
                                          ? setSelectedBoxes([
                                              ...selectedBoxes,
                                              feed.id
                                            ])
                                          : setSelectedBoxes(
                                              selectedBoxes.filter(
                                                b => b !== feed.id
                                              )
                                            )
                                      }
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor={feed.id}
                                    >
                                      &nbsp;
                                    </Label>
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    history.push({
                                      pathname: `/feed-detail/${feed.feedBoard?.feedId}`,
                                      state: {
                                        idInfo: feed.feedBoard?.feedId,
                                        pageInfo: fetchOptions.page,
                                        lastPageInfo: Math.ceil(
                                          feedData.max / 10
                                        ),
                                        searchInfo: fetchOptions.search,
                                        isFeedDetail: true,
                                        filteringInfo: fetchOptions.filtering
                                      }
                                    })
                                  }}
                                  style={{
                                    color: '#556EE6',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {/* <Link
                                    to={`/feed-detail/${feed.feedBoard?.feedId}`}
                                  > */}
                                  {feed.createdAt &&
                                    feed.createdAt.slice(0, 10)}
                                  {/* </Link> */}
                                </td>
                                <td>
                                  <Link to={`/user-detail/${feed.user?.id}`}>
                                    {feed.user?.username
                                      ? feed.user?.username
                                      : ''}
                                  </Link>
                                </td>

                                <td>
                                  {feed.user?.phone ? feed.user?.phone : ''}
                                </td>
                                <td>{feed.feedUserInfoPrint?.nickname}</td>
                                <td>{feed?.feedUserInfoPrint.job}</td>
                                <td>
                                  {feed?.feedCategory[0]
                                    .feedCategoryTempletId &&
                                    categoryState(
                                      feed?.feedCategory[0]
                                        .feedCategoryTempletId
                                    )}
                                </td>
                                <td
                                  style={{
                                    wordBreak: 'break-all',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  {feed.feedBoard?.title}
                                </td>
                                <td>{feed.feedStatus === 0 ? 'X' : 'O'}</td>
                                <td>{`${feed.feedReaction.length}개`}</td>
                                <td>
                                  {declareData.length > 0 &&
                                    `${
                                      declareData.filter(
                                        ele => ele.feed.id === feed.id
                                      ).length
                                    }건`}
                                </td>
                                <td>
                                  {feed.feedStatus === 0 && (
                                    <i
                                      className="mdi mdi-link"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => handleCommunity(feed.id)}
                                    />
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                    <PaginatorProject
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(feedData.max / 10)}
                      onChange={handlePagination}
                      start={start}
                      setStart={setStart}
                      end={end}
                      setEnd={setEnd}
                    />
                    {/* <PaginatorTemp
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(feedData.max / 10)}
                      onChange={handlePagination}
                    /> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  feedList: state.appState.data.feedList
})

const mapDispatchToProps = dispatch => ({
  removeFeed: ids => dispatch(removeFeed(ids)),
  getFeedList: options => dispatch(getFeedList(options))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedList)
)
