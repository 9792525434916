import {
  put,
  call,
  takeLatest,
  takeEvery,
  select
} from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_WORK_ORDER_LIST,
  GET_WORK_ORDER_DETAIL,
  SET_CLIENT_IS_BUSINESSMAN,
  GET_WORK_DOCUMENT_HTML
} from '../reducers/workOrder'

export function* getWorkOrderList(actions) {
  const {
    page,
    amount,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate
  } = actions
  yield put(setAppState('START_GET_WORK_ORDER_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_WORK_ORDER_LIST', null, false))
  }

  try {
    const params = {
      page: page,
      amount: amount,
      search: search,
      workDocumentType: 'Estimate',
      filterBoClientType: clientType,
      filterTotalPriceStartEqualSign: 'More',
      filterTotalPriceStartValue: startMoney,
      filterStartDate: startDate,
      filterEndDate: endDate
    }
    if (endMoney > 0) {
      params.filterTotalPriceEndEqualSign = 'Less'
      params.filterTotalPriceEndValue = endMoney
    }

    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.GET_WORK_DOCUMENT,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('workOrderList', response.data))
      yield put(
        setAppState('SUCCESS_GET_WORK_ORDER_LIST', response.data, false)
      )
    } else {
      yield put(setAppState('FAILED_GET_WORK_ORDER_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_WORK_ORDER_LIST', e, false))
  }
}

export function* getWorkOrderDetail(actions) {
  const { id, isPrint } = actions
  yield put(setAppState('START_GET_WORK_ORDER_DETAIL', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_WORK_ORDER_DETAIL', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.WORK_ORDER_DETAIL}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('workOrderDetail', response.data))

      const newData = {
        ...response.data.workOrderForQuantity
        // type: 'Receipt'
      }

      yield call(getWorkDocumentHtml, { data: newData })
      // if (isPrint) {
      //   const imageUrl =
      //     response.data?.workOrderForQuantity?.workOrderForQuantityLogo
      //       ?.attachmentId &&
      //     `https://s3.ap-northeast-2.amazonaws.com/media.paywork.io/${response.data?.workOrderForQuantity?.workOrderForQuantityLogo?.attachment?.path}/${response.data?.workOrderForQuantity?.workOrderForQuantityLogo?.attachment?.filename}`

      //   if (imageUrl) {
      //     const img = new Image()
      //     img.src = imageUrl

      //     img.onload = () => {
      //       if (img.width > img.height) {
      //         // 가로형 로고

      //         if (img.width > 200) {
      //           let imageWidth = 200
      //           let imageHeight = img.height * (200 / img.width)

      //           generatePDFDocument(
      //             response.data.workOrderForQuantity,
      //             response.data.workOrderForQuantity.user,
      //             imageWidth,
      //             imageHeight
      //           )
      //         }

      //         generatePDFDocument(
      //           response.data.workOrderForQuantity,
      //           response.data.workOrderForQuantity.user,
      //           200,
      //           80
      //         )
      //       } else {
      //         // 세로형 로고
      //         if (img.height > 200) {
      //           let imageWidth = 200
      //           let imageHeight = img.width * (200 / img.height)

      //           generatePDFDocument(
      //             response.data.workOrderForQuantity,
      //             response.data.workOrderForQuantity.user,
      //             imageWidth,
      //             imageHeight
      //           )
      //         }

      //         generatePDFDocument(
      //           response.data.workOrderForQuantity,
      //           response.data.workOrderForQuantity.user,
      //           200,
      //           80
      //         )
      //       }
      //     }
      //   } else {
      //     yield call(
      //       generatePDFDocument,
      //       response.data.workOrderForQuantity,
      //       response.data.workOrderForQuantity.user,
      //       0,
      //       0
      //     )
      //   }
      // }
      yield put(
        setAppState('SUCCESS_GET_WORK_ORDER_DETAIL', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_WORK_ORDER_DETAIL', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_WORK_ORDER_DETAIL', e, false))
  }
}

export function* setClientIsBusinessman(actions) {
  const { id, isBusinessman } = actions
  yield put(setAppState('START_SET_CLIENT_IS_BUSINESSMAN', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_CLIENT_IS_BUSINESSMAN', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      // url: `${constants.backoffice.BUSINESSMAN_WORK_DOCUMENT}/${id}`,
      url: `${constants.backoffice.CLIENT_TYPE_WORK_DOCUMENT}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        userId: id
        // ratePlanId: ratePlanId
      },
      data: {
        boClientType: isBusinessman
        // boClientIsBusinessman: isBusinessman
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('workOrderList', response.data))
      yield put(
        setAppState('SUCCESS_SET_CLIENT_IS_BUSINESSMAN', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_SET_CLIENT_IS_BUSINESSMAN', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_CLIENT_IS_BUSINESSMAN', e, false))
  }
}

export function* getWorkDocumentHtml(actions) {
  const { data } = actions
  yield put(setAppState('START_REDUX_SAGA', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_WORK_DOCUMENT_HTML', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.WORK_DOCUMENT_HTML}/${data.id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      responseType: 'arraybuffer',
      // data: {
      //   workOrderForQuantity: data
      // }
    })

    if (response.status === 200) {
      // let slice1 = response.data.split('</head>')[1]
      // let slice2 = slice1.split('</html>')[0]

      // let htmlResult = slice2.replace(/body/gi, 'div')

      // console.log('$$ htmlResult', htmlResult)

      // yield put(saveAppData('workDocumentHTML', htmlResult))
      yield put(saveAppData('workDocumentHTML', response.data))
      yield put(
        setAppState('SUCCESS_GET_WORK_DOCUMENT_HTML', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_WORK_DOCUMENT_HTML', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_WORK_DOCUMENT_HTML', e, false))
  }
}

const workOrderSagas = [
  takeLatest(GET_WORK_ORDER_LIST, getWorkOrderList),
  takeEvery(GET_WORK_ORDER_DETAIL, getWorkOrderDetail),
  takeEvery(SET_CLIENT_IS_BUSINESSMAN, setClientIsBusinessman),
  takeLatest(GET_WORK_DOCUMENT_HTML, getWorkDocumentHtml)
]

export default workOrderSagas
