import 'react-datepicker/dist/react-datepicker.css'

import { CardBody, Container, Input, Label, Row, Table, Col } from 'reactstrap'
import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import debounce from 'lodash.debounce'
import moment from 'moment'
import 'moment/locale/ko'

import Indicator from '../../../components/Common/Indicator'
import Paginator from '../../../components/Common/Paginator'

// utils
import { sortingData } from '../../../helpers/utils'

const UserLogin = ({ loginData }) => {
  const userId = window.location.pathname.split('/')[2]
  const [userFeedData, setUserFeedData] = useState([])
  const [renderData, setRenderData] = useState({
    data: [],
    max: []
  })
  const [isFetching, setIsFetching] = useState(false)
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    amount: 10
  })
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10,
    search: ''
  })

  const handlePagination = (page, pageSize) => {
    if (pageOptions.page !== page) {
      return setPageOptions(prevState => ({
        ...prevState,
        page
      }))
    }
    return
  }

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    debounceInput(inputValue)
  }

  const debounceInput = useCallback(
    debounce(input => {
      setSearchKeyword(input)
    }, 750),
    []
  )

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)

      if (loginData) {
        const sliceData = sortingData(loginData).slice(0, 10)

        setRenderData({ data: sliceData, max: loginData.length })
      }
      setIsFetching(false)
    })()
  }, [loginData])

  // useEffect(() => {
  //   ;(async () => {
  //     setIsFetching(true)

  //     if (feedData.userFeedNotice) {
  //       const sliceData = sortingData(feedData.userFeedNotice).slice(0, 10)

  //       setRenderData({ data: sliceData, max: feedData.userFeedNotice.length })
  //       setUserFeedData(feedData.userFeedNotice)
  //     }
  //     setIsFetching(false)
  //   })()
  // }, [feedId])

  // useEffect(() => {
  //   const filteredData = userFeedData.filter(feedData => {
  //     return (
  //       feedData.noticeTargetUsername.includes(searchKeyword) ||
  //       feedData.feedTitle.includes(searchKeyword) ||
  //       feedData.createdAt.slice(0, 10).includes(searchKeyword)
  //     )
  //   })

  //   setRenderData({ data: filteredData.slice(0, 10), max: filteredData.length })
  // }, [searchKeyword])

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)
      if (Array.isArray(loginData)) {
        const sliceData = loginData.slice(
          10 * (pageOptions.page - 1),
          10 * (pageOptions.page - 1) + 10
        )

        if (sliceData) {
          setRenderData({ data: sliceData, max: loginData.length })
        }
      }
      setIsFetching(false)
    })()
  }, [pageOptions])

  const platformTypeState = state => {
    let string = ''

    switch (state) {
      case 'App':
        string = 'APP'
        break

      case 'PC':
        string = 'PC'
        break

      case 'Mobile':
        string = 'Mobile'
        break

      default:
        string = 'WEB'
        break
    }

    return string
  }

  return (
    <Container fluid>
      <Row className="mb-4 d-flex justify-content-between">
        <div className="col-xl col-sm-6">
          <Label>
            <h3>로그인 총{renderData.max ? renderData.max : '0건'}회</h3>
          </Label>
        </div>
      </Row>
      <Row>
        <CardBody>
          <div className="table-responsive" style={{ minHeight: '600px' }}>
            <Table className="table table-centered table-nowrap">
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ width: '15%' }}>
                    일자
                  </th>
                  <th scope="col" style={{ width: '15%' }}>
                    ip
                  </th>
                  <th scope="col" style={{ width: '5%' }}>
                    구분
                  </th>
                  <th scope="col" style={{ width: '65%' }}>
                    플랫폼
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(renderData.data) &&
                  renderData.data.slice().map((login, idx) => (
                    <tr key={'_login_' + idx}>
                      <td>
                        {login.createdAt &&
                          moment(login.createdAt).format('YYYY-MM-DD-HH:mm')}
                      </td>
                      <td>{login.ip}</td>
                      <td>{platformTypeState(login.platformType)}</td>
                      <td>{login.platform}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <Paginator
            currentPage={pageOptions.page}
            pageSize={pageOptions.amount}
            total={renderData && Math.ceil(renderData.max / pageOptions.amount)}
            onChange={handlePagination}
          />
        </CardBody>
      </Row>
    </Container>
  )
}

export default UserLogin
