import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

import React, { Fragment, useEffect, useState, useCallback } from 'react'
import debounce from 'lodash.debounce'
import {
  getLMFMoney,
  getLMFService,
  getLMFCertificate,
  getLMFMoneyVote
} from '../../api/lmf'
import { connect } from 'react-redux'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import Indicator from '../../components/Common/Indicator'
import Paginator from '../../components/Common/Paginator'

const VoteList = ['신용카드 결제 기능', '무통장입금 결제 기능', '캘린더 기능']

const LMFList = ({ history }) => {
  const [moneyData, setMoneyData] = useState([])
  const [serviceData, setServiceData] = useState([])
  const [certificateData, setCertificateData] = useState([])
  const [originData, setOriginData] = useState([])
  const [renderData, setRenderData] = useState({ max: '' })
  const [applicants, setApplicants] = useState([])
  const [data, setData] = useState([])
  const [voteData, setVoteData] = useState([])
  const [isFetching, setFetching] = useState(true)
  const [deleteModal, setDeleteModal] = useState(false)
  const [fetchOptions, setFetchOptions] = useState({
    page: 0,
    amount: 10
  })
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    amount: 10
  })
  const [page, setPage] = useState({
    current: 1
  })
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [error, setError] = useState('')
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [toggleTitle, setToggleTitle] = useState('전체')
  const [title, setTitle] = useState('전체')
  const [searchKeyword, setSearchKeyword] = useState('')

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    debounceInput(inputValue)
  }

  const debounceInput = useCallback(
    debounce(input => {
      setSearchKeyword(input)
    }, 750),
    []
  )

  const handlePagination = (page, pageSize) => {
    if (page.current !== page) {
      return setPage({ ...page, current: page })
    }
    return
  }

  const sortData = type => {
    const sorting = type.sort((a, b) => {
      let x = new Date(a.createdAt).getTime()
      let y = new Date(b.createdAt).getTime()

      if (x < y) {
        return 1
      }
      if (x > y) {
        return -1
      }
      return 0
    })

    return sorting
  }

  useEffect(() => {
    if (toggleTitle === '전체') {
      ;(async () => {
        setFetching(true)
        let combineData = []
        let moneyArr = []

        const moneyResponse = await getLMFMoney({ page: 0, amount: 99999 })
        if (moneyResponse.opcode === 0) {
          for (let i = 0; i < moneyResponse.LMFMoneyGivers.length; i++) {
            moneyResponse.LMFMoneyGivers[i].type = '돈 주는 사람'
            moneyArr.push(moneyResponse.LMFMoneyGivers)
          }

          setMoneyData(...moneyArr)
          combineData.push(...moneyResponse.LMFMoneyGivers)
        }

        const serviceResponse = await getLMFService({ page: 0, amount: 99999 })
        let serviceArr = []
        if (serviceResponse.opcode === 0) {
          for (let i = 0; i < serviceResponse.LMFServices.length; i++) {
            serviceResponse.LMFServices[i].type = '용역 플랫폼'
            serviceArr.push(serviceResponse.LMFServices)
          }

          setServiceData(...serviceArr)
          combineData.push(...serviceResponse.LMFServices)
        }

        const certificateResponse = await getLMFCertificate({
          page: 0,
          amount: 99999
        })
        let certificateArr = []

        if (certificateResponse.opcode === 0) {
          for (
            let i = 0;
            i < certificateResponse.LMFTerminationCertifications.length;
            i++
          ) {
            certificateArr.push(
              certificateResponse.LMFTerminationCertifications
            )
            certificateResponse.LMFTerminationCertifications[i].type =
              '해촉 증명서'
          }

          setCertificateData(...certificateArr)
          combineData.push(...certificateResponse.LMFTerminationCertifications)
        }

        const sortingTest = sortData(combineData)
        const sortLMF = sortData([combineData])
        // const sliceLMF = sortLMF[0].slice(0).slice(0, 10)
        const sliceLMF = sortingTest.slice(0).slice(0, 10)

        setRenderData({
          ...renderData,
          lmf: sliceLMF,
          max: sortLMF[0].length
        })
        setApplicants({
          ...applicants,
          lmf: sortLMF[0],
          max: sortLMF[0].length
        })
        setOriginData({
          lmf: sortLMF[0],
          max: sortLMF[0].length
        })
        setFetching(false)
      })()
    }

    if (toggleTitle === '돈') {
      setFetching(true)
      const sortingTest = sortData(moneyData)
      const sortLMF = sortData([moneyData])
      const sliceLMF = sortingTest.slice(0).slice(0, 10)

      setPage({ current: 1 })
      setRenderData({
        lmf: sliceLMF,
        max: sortLMF[0].length
      })
      setApplicants({
        lmf: sortLMF[0],
        max: sortLMF[0].length
      })
      setOriginData({
        lmf: sortLMF[0],
        max: sortLMF[0].length
      })
      setFetching(false)
    }

    if (toggleTitle === '용역') {
      setFetching(true)
      const sortingTest = sortData(serviceData)
      const sortLMF = sortData([serviceData])
      const sliceLMF = sortingTest.slice(0).slice(0, 10)

      setPage({ current: 1 })
      setRenderData({
        lmf: sliceLMF,
        max: sortLMF[0].length
      })
      setApplicants({
        lmf: sortLMF[0],
        max: sortLMF[0].length
      })
      setOriginData({
        lmf: sortLMF[0],
        max: sortLMF[0].length
      })
      setFetching(false)
    }

    if (toggleTitle === '해촉') {
      setFetching(true)
      const sortLMF = sortData([certificateData])
      const sliceLMF = sortLMF[0].slice(0).slice(0, 10)

      setPage({ current: 1 })
      setRenderData({
        lmf: sliceLMF,
        max: sortLMF[0].length
      })
      setApplicants({
        lmf: sortLMF[0],
        max: sortLMF[0].length
      })
      setOriginData({
        lmf: sortLMF[0],
        max: sortLMF[0].length
      })
      setFetching(false)
    }

    if (toggleTitle === '질문') {
      ;(async () => {
        setFetching(true)
        const { opcode, LMFMoneyGiverChecks } = await getLMFMoneyVote({
          page: 0,
          amount: 99999
        })

        let vote = [
          { function: VoteList[0], yes: 0, no: 0 },
          { function: VoteList[1], yes: 0, no: 0 },
          { function: VoteList[2], yes: 0, no: 0 }
        ]

        if (opcode === 0) {
          for (let i = 0; i < LMFMoneyGiverChecks.length; i++) {
            if (LMFMoneyGiverChecks[i].type === 0) {
              if (LMFMoneyGiverChecks[i].confirm) {
                vote[0].yes += 1
              } else {
                vote[0].no += 1
              }
            }
            if (LMFMoneyGiverChecks[i].type === 1) {
              if (LMFMoneyGiverChecks[i].confirm) {
                vote[1].yes += 1
              } else {
                vote[1].no += 1
              }
            }
            if (LMFMoneyGiverChecks[i].type === 2) {
              if (LMFMoneyGiverChecks[i].confirm) {
                vote[2].yes += 1
              } else {
                vote[2].no += 1
              }
            }
          }
        }

        setVoteData(vote)
        setFetching(false)
      })()
    }
  }, [toggleTitle])

  useEffect(() => {
    setFetching(true)

    if (Array.isArray(applicants.lmf)) {
      const sliceData = applicants.lmf.slice(
        10 * (page.current - 1),
        10 * (page.current - 1) + 10
      )

      if (sliceData) {
        setRenderData({ ...renderData, lmf: sliceData })
      }
    }

    setFetching(false)
  }, [page.current])

  const toggleSettings = () => {
    setSettingsMenu(!settingsMenu)
  }

  const handlerFilter = input => {
    let tempTitle = null

    if (input === 0) {
      tempTitle = '전체'
      setTitle('전체')
    }
    if (input === 1) {
      tempTitle = '돈'
      setTitle('돈 주는 사람 - 목록')
    }
    if (input === 2) {
      tempTitle = '용역'
      setTitle('용역 플랫폼')
    }
    if (input === 3) {
      tempTitle = '해촉'
      setTitle('해촉 증명서')
    }
    if (input === 4) {
      tempTitle = '질문'
      setTitle('돈 주는 사람 - 질문')
    }

    setToggleTitle(tempTitle)
  }

  useEffect(() => {
    ;(function () {
      setFetching(true)

      if (Array.isArray(originData.lmf)) {
        const filterData = originData.lmf.filter(
          ele =>
            (ele.email && ele.email.includes(searchKeyword)) ||
            (ele.type && ele.type.includes(searchKeyword))
        )

        setPage({ current: 1 })
        setRenderData({
          lmf: filterData.slice(0, 10),
          max: filterData.length
        })
        setApplicants({
          lmf: filterData,
          max: filterData.length
        })
      }
      setFetching(false)
    })()
  }, [searchKeyword])

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`LMF ${title} ${
              toggleTitle !== '질문' ? renderData.max : ` `
            }`}
            breadcrumbItem="LMFList"
          />

          <Alert isOpen={error} color={'danger'}>
            {error}
          </Alert>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    {/* <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Label style={{ fontSize: '20px' }}>
                            {title}{' '}
                            {toggleTitle !== '질문' && `${renderData.max}개`}
                          </Label>
                        </div>
                      </div>
                    </Col> */}
                    <Col sm="4">
                      {/* <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          {toggleTitle !== '질문' && (
                            <>
                              <Input
                                type="text"
                                className="form-control"
                                onChange={handleSearch}
                                placeHolder={'이메일/종류'}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </>
                          )}
                        </div>
                      </div> */}
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-right">
                        <Dropdown isOpen={settingsMenu} toggle={toggleSettings}>
                          <DropdownToggle
                            tag="button"
                            className="btn btn-light"
                          >
                            <i className="mdi mdi-filter mr-1" />
                            {toggleTitle}
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu dropdown-menu-right">
                            <DropdownItem
                              href="#"
                              onClick={() => handlerFilter(0)}
                            >
                              전체
                            </DropdownItem>
                            <DropdownItem
                              href="#"
                              onClick={() => handlerFilter(1)}
                            >
                              돈 주는 사람 - 목록
                            </DropdownItem>
                            <DropdownItem
                              href="#"
                              onClick={() => handlerFilter(4)}
                            >
                              돈 주는 사람 - 질문
                            </DropdownItem>
                            <DropdownItem
                              href="#"
                              onClick={() => handlerFilter(2)}
                            >
                              용역 플랫폼
                            </DropdownItem>
                            <DropdownItem
                              href="#"
                              onClick={() => handlerFilter(3)}
                            >
                              해촉 증명서
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>

                    <Modal
                      centered
                      scrollable
                      isOpen={deleteModal}
                      toggle={() => {
                        setDeleteModal(!deleteModal)
                        setSelectedBoxes([])
                      }}
                    >
                      <ModalHeader
                        toggle={() => {
                          setDeleteModal(!deleteModal)
                          setSelectedBoxes([])
                        }}
                        style={{
                          borderBottom: '2px solid #2a3042'
                        }}
                      >
                        <div>회원 삭제</div>
                      </ModalHeader>
                      <ModalBody
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: '150px',
                          flexDirection: 'column'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '15px',
                            fontWeight: 'bold',
                            marginBottom: '8px'
                          }}
                        >{`${selectedBoxes.length}명의 테스터를 삭제하시겠습니까?`}</div>
                        <div>{`테스터가 권한이 삭제됩니다`}</div>
                      </ModalBody>
                      <ModalFooter
                        onClick={() => setDeleteModal(!deleteModal)}
                        style={{
                          justifyContent: 'center',
                          borderTop: '2px solid #e2e2e2'
                        }}
                      >
                        {/* <Button color="danger" outline onClick={handleDelete}>
                          삭제하기
                        </Button> */}
                      </ModalFooter>
                    </Modal>
                  </Row>
                  {isFetching ? (
                    <Indicator height={'30vh'} />
                  ) : toggleTitle !== '질문' ? (
                    <div
                      className="table-responsive"
                      style={{ minHeight: '800px' }}
                    >
                      <Table className="table-centered table-nowrap table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" style={{ width: '30%' }}>
                              신청일
                            </th>
                            <th scope="col" style={{ width: '40%' }}>
                              이메일
                            </th>
                            <th scope="col" style={{ width: '30%' }}>
                              종류
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(renderData.lmf) &&
                            renderData.lmf.map((applicant, idx) => (
                              <tr key={'_applicant_' + idx}>
                                <td>{applicant.createdAt.slice(0, 10)}</td>
                                <td>{applicant && applicant.email}</td>
                                <td>{applicant.type}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Paginator
                        currentPage={page.current}
                        pageSize={10}
                        total={Math.ceil(renderData.max / 10)}
                        onChange={handlePagination}
                      />
                    </div>
                  ) : (
                    <div
                      className="table-responsive"
                      style={{ minHeight: '800px' }}
                    >
                      <Table className="table-centered table-nowrap table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" style={{ width: '50%' }}>
                              기능
                            </th>
                            <th scope="col" style={{ width: '25%' }}>
                              네
                            </th>
                            <th scope="col" style={{ width: '25%' }}>
                              아니오
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(voteData) &&
                            voteData.slice().map((vote, idx) => (
                              <tr key={'_vote_' + idx}>
                                <td>{vote.function}</td>
                                <td>{vote.yes}</td>
                                <td>{vote.no}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data
})
const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LMFList)
