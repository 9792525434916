import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/ko'
import Select from 'react-select'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import { getBillList, setBillIsBusinessman } from '../../reducers/bill'
import { setClientIsBusinessman } from '../../reducers/workOrder'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

import BillList from './BillList'
import LinkPaginator from '../../components/Common/LinkPaginator'

import * as INFO from '../../global/projectInfo'
import PushOff from '../../assets/images/push_off.png'
import PushOn from '../../assets/images/push_on.png'

const Bill = ({ state, getBillList, billList, setClientIsBusinessman }) => {
  const location = useLocation()
  const history = useHistory()

  const params = new URLSearchParams(location.search)

  const [page, setPage] = useState(
    params.get('page') ? Number(params.get('page')) : 0
  )
  const [clientType, setClientType] = useState(params.get('clientType') ?? '')
  const [search, setSearch] = useState(params.get('search') ?? '')
  const [startMoney, setStartMoney] = useState(
    params.get('startMoney') ? Number(params.get('startMoney')) : 0
  )
  const [endMoney, setEndMoney] = useState(
    params.get('endMoney') ? Number(params.get('endMoney')) : 0
  )
  const [startDate, setStartDate] = useState(params.get('startDate') ?? '')
  const [endDate, setEndDate] = useState(params.get('endDate') ?? '')

  const [totalListCount, setTotalListCount] = useState(0)
  const [billData, setBillData] = useState([])

  const [clientDropDownOpen, setClientDropDownOpen] = useState(false)

  const [breadcrumbTitle, setBreadcrumbTitle] = useState('')

  const fetchingAmount = 10
  const isFetchingRef = useRef(false)

  const handleUrl = changingClientType => {
    //clientType은 드롭다운 클릭이어서 별도로 뺌.
    const editedParams = new URLSearchParams()
    editedParams.append(
      'clientType',
      changingClientType === ''
        ? ''
        : changingClientType
        ? changingClientType
        : clientType
    )
    editedParams.append('page', page)
    editedParams.append('search', search)
    editedParams.append('startMoney', startMoney)
    editedParams.append('endMoney', endMoney)
    editedParams.append('startDate', startDate)
    editedParams.append('endDate', endDate)
    window.location.replace(`?${editedParams.toString()}`)
  }

  const handleEnter = e => {
    if (e.key === 'Enter') {
      if (!isFetchingRef.current) {
        isFetchingRef.current = true
        handleUrl()
      }
    }
  }

  const handleClickClientType = changingClientType => {
    handleUrl(changingClientType)
  }

  const handleDateSearch = () => {
    handleUrl()
  }

  const handleExcelDownload = () => {
    // let csvFiltering = []
    // let workOrderListData = []
    // workOrderListData = workOrderList.workOrderForQuantitys.filter(
    //   f => !adminIds.includes(f.creatorUserId)
    // )
    // for (let i = 0; i < workOrderListData.length; i++) {
    //   csvFiltering.push({
    //     startedAt: moment(workOrderListData[i].startedAt).format(
    //       'YYYY-MM-DD-HH:mm'
    //     ),
    //     formCode:
    //       workOrderListData[i].formCode === '' ||
    //       workOrderListData[i].formCode === INFO.FORM_CODE.KIM_WO_KEU
    //         ? '김워크'
    //         : '최진중',
    //     projectState: workOrderProjectState(workOrderListData[i].projectState),
    //     businessman: workOrderListData[i].businessman ? '사업자' : '개인',
    //     creatorEmail: workOrderListData[i].creatorEmail ?? '',
    //     clientName: workOrderListData[i].clientName,
    //     totalPrice: workOrderListData[i].totalPrice
    //   })
    // }
    // setCsvData(csvFiltering)
  }

  useEffect(() => {
    getBillList(
      page,
      fetchingAmount,
      search,
      clientType,
      startMoney,
      endMoney,
      startDate,
      endDate
    )
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_BILL_LIST':
        setBillData(billList.workOrderForQuantitys)
        setTotalListCount(billList.count)
        break

      case 'SUCCESS_SET_BILL_IS_BUSINESSMAN':
      case 'SUCCESS_SET_CLIENT_IS_BUSINESSMAN':
        // handleUrl()
        break

      default:
        break
    }
  }, [state])

  // 차트 제목 hook
  useEffect(() => {
    setBreadcrumbTitle(`청구서 (${totalListCount? totalListCount.toLocaleString() : '-'}건)`)
  }, [totalListCount])

  const handleChangeBusiness = (flag, id) => {
    setClientIsBusinessman(id, flag.value)
  }

  const handleClientName = name => {
    if (name === 'Individual') {
      return '개인'
    } else if (name === 'Businessman') {
      return '사업자'
    } else if (name === 'PublicInstitutions') {
      return '공공기관'
    } else if (name === 'Association') {
      return '협/단체'
    } else if (name === 'Total' || !name) {
      return '전체'
    } else {
      return '키값 오류입니다.'
    }
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={breadcrumbTitle}
            breadcrumbItem="Bill List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            placeholder={'회원명/고객명/전화번호'}
                            onKeyUp={handleEnter}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>

                    <Col sm="8">
                      <div
                        className="text-sm-right"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <InputGroup>
                          <InputTitle>금액</InputTitle>
                          <input
                            type="number"
                            value={startMoney}
                            onChange={e => setStartMoney(e.target.value)}
                            onKeyUp={handleEnter}
                            placeholder={'0원이상'}
                          />
                          <span>이상 / </span>
                          <input
                            type="number"
                            value={endMoney}
                            onChange={e => setEndMoney(e.target.value)}
                            onKeyUp={handleEnter}
                            placeholder={'0원이하'}
                          />
                          <span>이하</span>
                        </InputGroup>
                        <InputGroup>
                          <InputTitle>날짜</InputTitle>
                          <input
                            type="date"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                          />
                          <span> / </span>
                          <input
                            type="date"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                          />
                          <button onClick={handleDateSearch}>날짜검색</button>
                        </InputGroup>
                        <Dropdown
                          isOpen={clientDropDownOpen}
                          toggle={() =>
                            setClientDropDownOpen(!clientDropDownOpen)
                          }
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-light"
                            style={{ marginRight: '10px' }}
                          >
                            {handleClientName(clientType)}
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu dropdown-menu-right">
                            <DropdownItem
                              onClick={() => handleClickClientType('')}
                            >
                              {handleClientName('Total')}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleClickClientType('Individual')
                              }
                            >
                              {handleClientName('Individual')}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleClickClientType('Businessman')
                              }
                            >
                              {handleClientName('Businessman')}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleClickClientType('PublicInstitutions')
                              }
                            >
                              {handleClientName('PublicInstitutions')}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleClickClientType('Association')
                              }
                            >
                              {handleClientName('Association')}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: '10%' }}>
                            생성일
                          </th>
                          <th scope="col" style={{ width: '6%' }}>
                            상태
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            이메일
                          </th>
                          <th scope="col" style={{ width: '6%' }}>
                            구분
                          </th>
                          <th scope="col" style={{ width: '11%' }}>
                            고객유형
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            회원명
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            고객명
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            금액
                          </th>
                          <th scope="col" style={{ width: '6%' }}>
                            제목
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(billData) &&
                          billData.map((project, idx) => (
                            <tr key={'_Bill_' + idx}>
                              <BillList
                                history={history}
                                project={project}
                                billPage={page}
                                billSearch={search}
                                handleChangeBusiness={handleChangeBusiness}
                              />
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <LinkPaginator
                      searchParams={{
                        currentPage: page,
                        search: search,
                        clientType: clientType,
                        startMoney: startMoney,
                        endMoney: endMoney,
                        startDate: startDate,
                        endDate: endDate
                      }}
                      visibleCount={fetchingAmount}
                      totalCount={Math.ceil(totalListCount / fetchingAmount)}
                      urlPrefix={'/bill'}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  billList: state.appState.data.billList
})
const mapDispatchToProps = dispatch => ({
  getBillList: (
    page,
    amount,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate
  ) =>
    dispatch(
      getBillList(
        page,
        amount,
        search,
        clientType,
        startMoney,
        endMoney,
        startDate,
        endDate
      )
    ),
  setBillIsBusinessman: (id, isBusinessman) =>
    dispatch(setBillIsBusinessman(id, isBusinessman)),
  setClientIsBusinessman: (id, isBusinessman) =>
    dispatch(setClientIsBusinessman(id, isBusinessman))
})

export default connect(mapStateToProps, mapDispatchToProps)(Bill)

const InputGroup = styled.div`
  padding-top: 6px;
  * > span {
    margin: 0 5px;
  }
  margin: 0 10px;
`
const InputTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
`
