import React, { useEffect, useState } from 'react'

// globals
import colors from '../../../global/colors'
import * as INFO from '../../../global/projectInfo'

// redux
import { connect } from 'react-redux'
import { getTradingStatementDetail } from '../../../reducers/tradingStatement'

// tools
import styled from 'styled-components'

// time
import moment from 'moment'
import 'moment/locale/ko'

const CashReceiptPreview = ({ cashReceiptDetailView }) => {
  const [image, setImage] = useState();

  const onCreateImgUrl = (data) => {
    try{
      const bufferToBase64 = Buffer.from(data, 'binary').toString('base64');
      setImage(bufferToBase64);
    }
    catch(e){
      console.log(e)
    }
    
  };

  useEffect(()=>{
    onCreateImgUrl(cashReceiptDetailView)
  }, [cashReceiptDetailView]);

  return (
    <div
      style={{
        marginTop: 70,
        width: '100%',
        padding: '20px 20px 100px',
        backgroundColor: '#fff'
      }}
    >
      {cashReceiptDetailView && (
        <img style={{ width: "70%", margin: "auto", display: "block" }} src={`data:image/png;base64,${image}`} />
      )}
    </div>
  )
}
const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  cashReceiptDetailView: state.appState.data.cashReceiptDetailView
})

const mapDispatchToProps = dispatch => ({
  getTradingStatementDetail: id => dispatch(getTradingStatementDetail(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(CashReceiptPreview)