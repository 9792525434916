export const GET_RECEIPT_LIST = 'reducers/receipt/GET_RECEIPT_LIST'
export const GET_RECEIPT_DETAIL = 'reducers/receipt/GET_RECEIPT_DETAIL'
export const SET_RECEIPT_IS_BUSINESSMAN =
  'reducers/receipt/SET_RECEIPT_IS_BUSINESSMAN'

export const getReceiptList = (
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
) => ({
  type: GET_RECEIPT_LIST,
  page,
  amount,
  search,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
})

export const getReceiptDetail = id => ({
  type: GET_RECEIPT_DETAIL,
  id
})

export const setReceiptIsBusinessman = (id, isBusinessman) => ({
  type: SET_RECEIPT_IS_BUSINESSMAN,
  id,
  isBusinessman
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
