import * as dateFns from 'date-fns'
import moment from 'moment'

export const wauGoals2023 = [
  425, 600, 1500, 5000, 10000, 23000, 40000, 60000, 80000, 100000, 120000,
  150000
]

export const msuGoals2023 = [
  12000, 15000, 30000, 50000, 100000, 150000, 300000, 350000, 400000, 420000,
  470000, 500000
]

/** [2021, ..., 현재년도] */
export const years = Array.from({ length: moment().year() - 2020 }, (_, i) => i + 2021);

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const weeks = () => Array.from({ length: 53 }, (_, i) => i)

export const daysInMonth = (year, month) => {
  const daysLength = dateFns.getDaysInMonth(new Date(parseInt(year), parseInt(month)-1))
  return Array.from({ length: daysLength }, (_, i) => i + 1)
}
