import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert
} from 'reactstrap'
import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Indicator from '../../components/Common/Indicator'
import Paginator from '../../components/Common/Paginator'
import PaginatorTemp from '../../components/Common/PaginatorTemp'

// redux
import { getFeedCategoryList, removeCategoryList } from '../../reducers/feed'

const tags = [
  {
    id: 1,
    nickname: '사과',
    requestTag: '잡담',
    createdAt: '2021-11-23',
    process: false
  },
  {
    id: 2,
    nickname: '사이다',
    requestTag: '동료 찾기',
    createdAt: '2021-11-24',
    process: false
  },
  {
    id: 3,
    nickname: '꿀',
    requestTag: '번개',
    createdAt: '2021-11-25',
    process: false
  }
]

const categoryGroup = [
  {
    label: '업무',
    options: [
      { label: '업무 일상', value: 'DailyTask' },
      { label: '노하우 꿀팁', value: 'Tip' },
      { label: '협업 찾기', value: 'Find' }
    ]
  },
  {
    label: '자유',
    options: [{ label: '자유 질문', value: 'Question' }]
  }
]

const CategoryList = ({
  history,
  state,
  params,
  feedCategoryList,
  getFeedCategoryList,
  removeCategoryList
}) => {
  const [tagsData, setTagsData] = useState([])
  const [isFetching, setFetching] = useState(true)
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10,
    search: ''
  })
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [selectOpt, setSelectOpt] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [status, setStatus] = useState({
    color: 'danger',
    isError: false,
    errorMsg: '카테고리 조회 중 문제가 발생했습니다.'
  })
  const [originData, setOriginData] = useState({ list: [], max: 0 })
  const [categoryData, setCategoryData] = useState({
    list: [],
    max: 0
  })

  useEffect(() => {
    getFeedCategoryList()
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_FEED_CATEGORY_LIST':
        const filterCategory = feedCategoryList.feedCategoryTemplets
          .filter(f => f.enabled === true)
          .sort(function (a, b) {
            var date_a = new Date(a.createdAt)
            var date_b = new Date(b.createdAt)
            if (date_a > date_b) return -1
            if (date_a < date_b) return 1
            return 0
          })
        setOriginData({ list: filterCategory, max: filterCategory.length })
        setCategoryData({
          list: filterCategory.slice(
            (fetchOptions.page - 1) * 10,
            fetchOptions.page * 10
          ),
          max: filterCategory.length
        })
        break
      case 'FAILED_SUCCESS_GET_FEED_CATEGORY_LIST':
        setStatus({
          ...status,
          isError: true,
          color: 'danger',
          errorMsg: params.message
        })
        break
      case 'SUCCESS_REMOVE_CATEGORY':
        getFeedCategoryList()
        setStatus({
          ...status,
          color: 'success',
          isError: true,
          errorMsg: '카테고리가 삭제되었습니다'
        })
        setSelectedBoxes([])
        break
      case 'FAILED_REMOVE_CATEGORY':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        setSelectedBoxes([])
        break

      default:
        break
    }
  }, [state])

  useEffect(() => {
    const filterData = originData.list.filter(
      f => f.name && f.name.includes(fetchOptions.search)
    )

    setCategoryData({
      ...categoryData,
      list: filterData.slice(
        (fetchOptions.page - 1) * 10,
        fetchOptions.page * 10
      ),
      max: filterData.length
    })
  }, [fetchOptions])

  const handlePagination = (page, pageSize) => {
    if (fetchOptions.page !== page) {
      return setFetchOptions({
        ...fetchOptions,
        page
      })
    }

    return
  }

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    debounceInput(inputValue)
  }

  const debounceInput = useCallback(
    debounce(input => {
      setFetchOptions({ ...fetchOptions, search: input })
    }, 750),
    []
  )

  const handleDeleteCategory = () => {
    if (selectedBoxes.length < 1) return

    setDeleteModal(false)
    removeCategoryList(selectedBoxes)
    // const check = await deleteCategory(selectedBoxes)
    // for (let i = 0; i < check.length; i++) {
    //   if (check[i].data.opcode !== 0) {
    //     return setError(check[i].data.message)
    //   } else {
    //     if (check[check.length - 1].data.opcode === 0) {
    //       return window.location.reload()
    //     }
    //   }
    // }
  }

  // const handleDelete = async () => {
  //   console.log('selected', selectedBoxes)

  //   if (selectedBoxes.length < 1) return
  //   const check = await deleteCategory(selectedBoxes)
  //   for (let i = 0; i < check.length; i++) {
  //     if (check[i].data.opcode !== 0) {
  //       return setError(check[i].data.message)
  //     } else {
  //       if (check[check.length - 1].data.opcode === 0) {
  //         return window.location.reload()
  //       }
  //     }
  //   }
  // }

  // const handlePagination = (page, pageSize) => {
  //   console.log({ page, pageSize })
  //   if (fetchOptions.page !== page) {
  //     return setFetchOptions(prevState => ({
  //       ...prevState,
  //       page
  //     }))
  //   }
  //   return
  // }

  // useEffect(() => {
  //   ;(async () => {
  //     setFetching(true)
  //     const { opcode, feedCategoryTemplets, message } = await getCategoryList()

  //     if (opcode === 0) {
  //       const enabledList = feedCategoryTemplets
  //         .filter(ele => ele.enabled === true)
  //         .sort(function (a, b) {
  //           var date_a = new Date(a.createdAt)
  //           var date_b = new Date(b.createdAt)
  //           if (date_a > date_b) return -1
  //           if (date_a < date_b) return 1
  //           return 0
  //         })

  //       setCategoryList(enabledList)
  //     } else {
  //       setError(message)
  //     }

  //     setFetching(false)
  //   })()
  // }, [fetchOptions])

  // const handleSelect = options => {
  //   setSelectOpt(options)
  // }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="카테고리 목록" breadcrumbItem="Category List" />

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    {/* <Col sm="4">
                      <div className="search-box mr-2 mb-2">
                        <div className="position-relative">
                          <Select
                            isMulti={true}
                            defaultValue={selectOpt}
                            onChange={handleSelect}
                            options={list}
                          />
                        </div>
                      </div>
                    </Col> */}

                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            onChange={handleSearch}
                            placeholder="카테고리명"
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>

                    <Col sm="8">
                      <div className="text-sm-right">
                        <Link to={'/category-register'}>
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          >
                            <i className="mdi mdi-plus mr-1"></i> 추가하기
                          </Button>
                        </Link>
                        <Button
                          type="button"
                          color="danger"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          onClick={() => setDeleteModal(!deleteModal)}
                          disabled={selectedBoxes.length === 0}
                        >
                          <i className="mdi mdi-minus mr-1"></i> 삭제하기
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Modal
                    centered
                    scrollable
                    isOpen={deleteModal}
                    toggle={() => setDeleteModal(!deleteModal)}
                  >
                    <ModalHeader
                      toggle={() => setDeleteModal(!deleteModal)}
                      style={{
                        borderBottom: '2px solid #2a3042'
                      }}
                    >
                      <div>삭제</div>
                    </ModalHeader>
                    <ModalBody
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        height: '150px',
                        fontSize: '18px'
                      }}
                    >{`${
                      Array.isArray(selectedBoxes) && selectedBoxes.length > 0
                        ? selectedBoxes.length
                        : '0'
                    }개의 카테고리를 삭제 하시겠습니까?`}</ModalBody>
                    <ModalFooter
                      onClick={handleDeleteCategory}
                      style={{
                        cursor: 'pointer',
                        justifyContent: 'center',
                        borderTop: '2px solid #e2e2e2',
                        fontWeight: 'bold',
                        fontSize: '16px'
                      }}
                    >
                      삭제하기
                    </ModalFooter>
                  </Modal>
                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table
                      className="table-centered table-nowrap table-hover"
                      // style={{ minHeight: '500px' }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '20px' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length ===
                                    categoryData.list.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(categoryData.list) &&
                                      setSelectedBoxes(
                                        categoryData.list.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          <th scope="col">등록일</th>
                          <th scope="col">카테고리명</th>
                          <th scope="col">고유 ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(categoryData.list) &&
                          categoryData.list.length > 0 &&
                          categoryData.list.map((category, idx) => (
                            <tr key={'_category_' + idx}>
                              <td style={{ height: '65px' }}>
                                <div
                                  className="custom-control custom-checkbox"
                                  style={{ zIndex: 0 }}
                                >
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={category.id}
                                    checked={selectedBoxes.includes(
                                      category.id
                                    )}
                                    onChange={e =>
                                      e.target.checked
                                        ? setSelectedBoxes([
                                            ...selectedBoxes,
                                            category.id
                                          ])
                                        : setSelectedBoxes(
                                            selectedBoxes.filter(
                                              b => b !== category.id
                                            )
                                          )
                                    }
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor={category.id}
                                  >
                                    &nbsp;
                                  </Label>
                                </div>
                              </td>
                              <td>
                                {category.createdAt &&
                                  category.createdAt.slice(0, 10)}
                              </td>
                              <td>{category.name}</td>
                              <td>{category.id}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <PaginatorTemp
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(categoryData.max / 10)}
                      onChange={handlePagination}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  feedCategoryList: state.appState.data.feedCategoryList
})

const mapDispatchToProps = dispatch => ({
  getFeedCategoryList: () => dispatch(getFeedCategoryList()),
  removeCategoryList: ids => dispatch(removeCategoryList(ids))
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)
