import { ErrorRes } from '@payworkteam/server-product-types';
import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../global/constants';

/** extends ServerError */
export interface ServerError {
	response?: AxiosResponse<ErrorRes>;
}

/** response handler 추가 */
export const instance = axios.create({
	baseURL: `${API_URL}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

instance.interceptors.request.use(
	async config => {
		/** get a tokens */
		const token = localStorage.getItem('token') || '';
		
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	},
);


instance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response.data;
  	},
	(error: ServerError) => {
		console.log("$$ AxiosError => ", error);
		const data = error?.response?.data;

		/** type guard */
		if (!data) {
			return Promise.reject(error);
		}
		
		return data;
  	},
);