import React, { useEffect, useState } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  Table,
  Label,
  Media,
  Container
} from 'reactstrap'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import styled from 'styled-components'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'

// temp
import { notices } from '../../constant/noticeDummy'

//Import Image
import avatar2 from '../../assets/images/users/avatar-2.jpg'
import img3 from '../../assets/images/small/img-3.jpg'
import img4 from '../../assets/images/small/img-4.jpg'
import user1 from '../../assets/images/users/ic_name_a.png'
import eventImg from '../../assets/images/event/ta_event.png'

const NoticeDetail = props => {
  const noticeId = window.location.pathname.split('/')[2]
  const [status, setStatus] = useState({
    isLoading: false,
    isError: false,
    errorMsg: '공지사항 조회 중 문제가 발생했습니다.'
  })
  const [renderData, setRenderData] = useState({
    data: [],
    max: 0
  })

  useEffect(() => {
    ;(async () => {
      setStatus({ ...status, isLoading: true })
      const noticeResponse = await notices

      if (noticeResponse) {
        const sortingNotice = noticeResponse.filter(ele => {
          return ele.id === Number(noticeId)
        })

        setRenderData({
          data: sortingNotice,
          max: sortingNotice.length
        })
        setStatus({ ...status, isLoading: false })
      } else {
        setStatus({
          ...status,
          isLoading: false,
          isError: true
        })
      }
    })()
  }, [])

  // console.log('$$ render detail', renderData.data[0])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={`${renderData.data[0] && renderData.data[0].title}`}
            breadcrumbItem="Read Notice"
          />

          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Media className="mb-4">
                    <img
                      className="d-flex mr-3 rounded-circle avatar-sm"
                      src={user1}
                      alt=""
                    />
                    <Media body>
                      <h5 className="font-size-14 mt-1">
                        {renderData.data[0] && renderData.data[0].writer}
                      </h5>
                      <small className="text-muted">
                        {renderData.data[0] && renderData.data[0].createdAt}
                      </small>
                    </Media>
                  </Media>
                  {renderData.data[0] && renderData.data[0].id === 1 && (
                    <>
                      <p>안녕하세요 페이워크 웹 PC버전이 출시되었습니다.</p>
                      <p> 서비스 기능에는</p>
                      <h5> ∙ 견적서 생성, 공유 </h5>
                      <h5> ∙ 알림톡 발송, 시간대 설정 </h5>
                      <h5> ∙ 거래처 주소록 관리 </h5>
                      <p>기능이 추가되었습니다.</p>
                      <p>
                        서비스 오픈 기념으로 2개월 간 9,900원에 이용하실 수
                        있도록 이용권 할인 이벤트를 진행하고 있습니다.
                      </p>
                      <p>
                        기존 유료 고객도 10/1~11/30 2개월간은 9,900원으로 요금이
                        과금됩니다! :-)
                      </p>
                    </>
                  )}

                  {renderData.data[0] && renderData.data[0].id === 2 && (
                    <>
                      <h5>구매상품: 13980 재택근무 (협업 tool)</h5>
                      <p />
                      <p />
                      <p>
                        금액 400만원 상품을 구매하신 비대면 바우처 수요기업 회원
                        대상으로 안내드립니다.
                      </p>
                      <p />
                      <p>
                        이용기간을 기존 12개월에서 24개월로 연장하여 이용하실 수
                        있도록 아래 내용을 읽으신 후 약관에 동의, 제출하기를
                        누르시면 온라인 서명으로 인정되어 자동 동의연장 계약으로
                        간주합니다.
                      </p>
                      <p />
                      <p />
                      <p>별도 문의시 서면 계약서로도 보내드립니다.</p>
                      <p>이메일: start@paywork.io</p>

                      <hr />
                      <p>ㅁ 위 내용을 인지 및 동의하였습니다.</p>
                      <p />
                      <p />
                      <p />

                      <h6>
                        <button>제출하기</button>
                      </h6>
                    </>
                  )}

                  {renderData.data[0] && renderData.data[0].id === 3 && (
                    <>
                      <img
                        className="card-img-top img-fluid"
                        src={eventImg}
                        alt="skote"
                        style={{ width: '600px' }}
                      />
                    </>
                  )}
                  {/* <h4 className="mt-0 font-size-16">This Week's Top Stories</h4>

                  <p>Dear Lorem Ipsum,</p>
                  <p>
                    Praesent dui ex, dapibus eget mauris ut, finibus vestibulum
                    enim. Quisque arcu leo, facilisis in fringilla id, luctus in
                    tortor. Nunc vestibulum est quis orci varius viverra.
                    Curabitur dictum volutpat massa vulputate molestie. In at
                    felis ac velit maximus convallis.
                  </p>
                  <p>
                    Sed elementum turpis eu lorem interdum, sed porttitor eros
                    commodo. Nam eu venenatis tortor, id lacinia diam. Sed
                    aliquam in dui et porta. Sed bibendum orci non tincidunt
                    ultrices. Vivamus fringilla, mi lacinia dapibus condimentum,
                    ipsum urna lacinia lacus, vel tincidunt mi nibh sit amet
                    lorem.
                  </p>
                  <p>Sincerly,</p>
                  <hr /> */}
                  <Row></Row>
                  <Link
                    to="/notice"
                    className="btn btn-secondary waves-effect mt-4"
                  >
                    <i className="mdi mdi-reply"></i>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NoticeDetail
