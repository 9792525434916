import apiClient from "./index";

const basePath = "/backoffice/review";

export const getReviews = async (options = { page: 1, amount: 10 }) => {
  try {
    const { data } = await apiClient().get(`${basePath}`, {
      params: options,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error.message;
  }
};

export const getReview = async (id, options = { page: 1, amount: 10 }) => {
  try {
    const { data } = await apiClient().get(`${basePath}/${id}`, {
      params: options,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error.message;
  }
};

export const getProjectReviews = async (
  projectId,
  options = { page: 1, amount: 10 }
) => {
  try {
    const { data } = await apiClient().get(`${basePath}/project/${projectId}`, {
      params: options,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error.message;
  }
};

export const getUserReviews = async (
  userId,
  options = { page: 1, amount: 10 }
) => {
  try {
    const { data } = await apiClient().get(`${basePath}/user/${userId}`, {
      params: options,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error.message;
  }
};
