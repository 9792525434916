import axios from 'axios'

const basePath = '/LMF'

const apiHost = 'https://api-event.paywork.io'

const apiClient = () => {
  const accessToken = localStorage.getItem('token') || ''

  return axios.create({
    baseURL: apiHost,
    validateStatus: () => true,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

// 돈 주는 사람
export const getLMFMoney = async options => {
  try {
    const { data } = await apiClient().post(
      // `${basePath}/moneyGiver/check/admin/0fc4fcc7-41b3-4d6b-b039-77778c8f6852/?page=${options.page}&amount=${options.amount}`,
      `${basePath}/moneyGiver/admin/6fc4fcc7-11b3-4d6b-b039-77778c8f6852/?page=${options.page}&amount=${options.amount}`,

      {
        // params: options,
        password: 'paywork&admin!!'
      }
    )

    return data
  } catch (error) {
    console.error(error)
    return error.message
  }
}

// 돈 주는 사람 투표
export const getLMFMoneyVote = async options => {
  try {
    const { data } = await apiClient().post(
      // `${basePath}/moneyGiver/check/admin/0fc4fcc7-41b3-4d6b-b039-77778c8f6852`,
      `${basePath}/moneyGiver/check/admin/0fc4fcc7-41b3-4d6b-b039-77778c8f6852/?page=${options.page}&amount=${options.amount}`,
      {
        password: 'paywork&admin!!'
      }
    )

    return data
  } catch (error) {
    console.error(error)
    return error.message
  }
}

// 용역
export const getLMFService = async options => {
  try {
    const { data } = await apiClient().post(
      `${basePath}/service/admin/34c4fcc7-11b3-4d4b-b039-36338c8f6852/?page=${options.page}&amount=${options.amount}`,

      {
        // params: options,
        password: 'paywork&admin!!'
      }
    )

    return data
  } catch (error) {
    console.error(error)
    return error.message
  }
}

// 해촉 증명서
export const getLMFCertificate = async options => {
  try {
    const { data } = await apiClient().post(
      `${basePath}/terminationCertification/admin/54c4fcc7-11b3-4d6b-b039-36338c8f6852/?page=${options.page}&amount=${options.amount}`,

      {
        // params: options,
        password: 'paywork&admin!!'
      }
    )

    return data
  } catch (error) {
    console.error(error)
    return error.message
  }
}
