import { useState, useCallback, useEffect, SelectHTMLAttributes, OptionHTMLAttributes } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import ReactApexChart from 'react-apexcharts'
import { backofficeBaseUrl } from '../../../../../global/constants'
import { months, years } from '../../../../../constant/chart'
import { defaultOptions } from '../../../../../helpers/chart'
import moment from 'moment'
import CommonTable from '../../../../../components/Common/Table'

interface Props {
  userType: number;
  dateType: string;
}

/** 유료 회원 라벨 */
const ratePlanLabels = ['신규 유입율', '이탈자 수', '결제실패 이탈자 수', '전체 유입율(%)', '전체 이탈율(%)'];

/** 일반 회원 라벨 */
const bounceLabels = ['신규 유입율', '탈퇴자 수', '전체 유입율(%)', '전체 이탈율(%)'];

const apiUrl = `${backofficeBaseUrl}/dashBoard/inflowAndBounceRate`;

export default function InflowAndBounceBoard({
  userType,
  dateType
}: Props) {
  const [year, setYear] = useState(moment().year().toString());
  const [month, setMonth] = useState((moment().month() + 1).toString());
  const [week, setWeek] = useState('1');
  const [date, setDate] = useState('1');
  const [openTable, setOpenTable] = useState(false);

  const onChangeYear = useCallback(e => {
    setYear(e.target.value)
  }, []);

  const onChangeMonth = useCallback(e => {
    setMonth(e.target.value)
  }, []);
  
  const [range, setRange] = useState('4')

  const [data, setData] = useState<Array<{
    date: string;
    totalCount: number;
    inflowRateCount: number;
    bounceRateCount: number;
    bounceRatePaymentErrorCount?: number;
    inflowRatePercent: number;
    bounceRatePercent: number;
  }>>([]);

  const callAPI = useCallback(async () => {
    try {
      if (dateType === 'year' && !year && !month) {
        alert('년,월 입력 오류')
        return
      } else if (dateType === 'month' && !year && !month && !date) {
        alert('년,월,일 입력 오류')
        return
      } else if (dateType === 'week' && !year && !month) {
        alert('년,월 입력 오류')
        return
      }

      const res = await axios.get(apiUrl, {
        params: {
          type: dateType,
          year,
          month,
          whereType: (userType === 0) ? "Plan" : "Normal",
        }
      });
      
      setData(res.data.dashBoard);
    } catch (e) {
      throw e
    }
  }, [year, month, week, date, range, userType, dateType]);

  useEffect(() => {
    callAPI()
  }, [year, month, week, date, range, userType, dateType]);

  /** functions */
  /** 대시보드 그래프 렌더링 */
  const seriesRender = () => {
    switch (userType) {
      /** 유료 */
      case 0: {
        return [
          {
            name: ratePlanLabels[0],
            type: 'bar',
            data: data?.map((i) => {
              return {
                x: `${i.date}`,
                y: `${i.inflowRateCount}명`
              }
            })
          },
          {
            name: ratePlanLabels[1],
            type: 'bar',
            data: data.map((i) => {
              return {
                x: `${i.date}`,
                y: `${i.bounceRateCount}명`
              }
            })
          },
          {
            name: ratePlanLabels[2],
            type: 'bar',
            data: data.map(i => {
              return {
                x: `${i.date}`,
                y: `${i.bounceRatePaymentErrorCount}명`
              }
            })
          },
          {
            name: ratePlanLabels[3],
            type: 'line',
            data: data.map(i => {
              return {
                x: `${i.date}`,
                y: `${i.inflowRatePercent}`
              }
            })
          },
          {
            name: ratePlanLabels[4],
            type: 'line',
            data: data.map(i => {
              return {
                x: `${i.date}`,
                y: `${i.bounceRatePercent}`
              }
            })
          }
        ];
      }

      /** 일반 */
      case 1: {
        return [
          {
            name: bounceLabels[0],
            type: 'bar',
            data: data?.map((i) => {
              return {
                x: `${i.date}`,
                y: `${i.inflowRateCount}명`
              }
            })
          },
          {
            name: bounceLabels[1],
            type: 'bar',
            data: data.map((i) => {
              return {
                x: `${i.date}`,
                y: `${i.bounceRateCount}명`
              }
            })
          },
          {
            name: bounceLabels[2],
            type: 'line',
            data: data.map(i => {
              return {
                x: `${i.date}`,
                y: `${i.inflowRatePercent}`
              }
            })
          },
          {
            name: bounceLabels[3],
            type: 'line',
            data: data.map(i => {
              return {
                x: `${i.date}`,
                y: `${i.bounceRatePercent}`
              }
            })
          }
        ];
      }

      default: {
        return [{
          
        }];
      }
    }
  }
  
  return (
    <Wrapper>
      <DateWrapper>
        <p>날짜</p>
        <Select value={year} onChange={onChangeYear}>
          {years.map(i => (
            <Option key={i} value={i}>
              {i}년
            </Option>
          ))}
        </Select>

        {/* 일별일때 활성화 */}
        {dateType === 'month' && (
            <Select value={month} onChange={onChangeMonth}>
              {months.map(i => (
                <Option key={i} value={i}>
                  {i}월
                </Option>
              ))}
            </Select>
        )}
      </DateWrapper>

      <ChartWrapper>
        {data && (
          <>
            <ReactApexChart
              key={'retention-monthly'}
              height={300}
              options={{
                ...defaultOptions,
                plotOptions: {
                  bar: {
                    // horizontal: true
                  }
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: userType === 0 ? [3, 4] : [2, 3]
                },
                yaxis: [
                  {
                    show: false,
                    seriesName: 'Bar Series'
                  },
                  {
                    show: false,
                    opposite: true,
                    min: 0,
                    max: 100,
                    seriesName: 'Line Series'
                  },
                ],
              }}
              series={seriesRender()}
            />
          </>
        )}
      </ChartWrapper>

      <TableWrapper>
          <TableToggleButtonWrapper>
            <TableToggleButton onClick={() => setOpenTable(openTable ? false : true)}>
              {openTable ? '테이블 닫기' : '테이블 열기'}
            </TableToggleButton>
          </TableToggleButtonWrapper>
          {openTable && (
            <CommonTable
              datas={userType === 0 ? [
                data?.map((i) => i.totalCount),
                data?.map((i) => i.inflowRateCount),
                data?.map((i) => i.bounceRateCount),
                data?.map((i) => i.bounceRatePaymentErrorCount),
                data?.map((i) => i.inflowRatePercent),
                data?.map((i) => i.bounceRatePercent)
              ]: [
                data?.map((i) => i.totalCount),
                data?.map((i) => i.inflowRateCount),
                data?.map((i) => i.bounceRateCount),
                data?.map((i) => i.inflowRatePercent),
                data?.map((i) => i.bounceRatePercent)
              ]}
              colHeaders={userType === 0 ? ["누적 유료회원 수", ...ratePlanLabels] : ["누적 이용자 수", ...bounceLabels]}
              rowNames={data.map(j => `${j.date}`)}
            />
          )}
        </TableWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 420px;
`

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  & > p {
    margin-bottom: 0;
  }
`

const RangeName = styled.span``

const RangeInput = styled.input`
  width: 40px;
  text-align: center;
`

const RangeDate = styled.span``

const Select = styled.select<SelectHTMLAttributes<HTMLSelectElement>>`
  margin: 0 10px;
`

const Option = styled.option<OptionHTMLAttributes<HTMLOptionElement>>``

const ChartWrapper = styled.div``

const TableWrapper = styled.div``
const TableToggleButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const TableToggleButton = styled.button<OptionHTMLAttributes<HTMLButtonElement>>``
