export const defaultOptions = {
  grid: {
    show: true
  },
  row: {
    colors: ['#f3f3f3', 'transparent'],
    opacity: 0.5
  },
  dataLabels: { enabled: false }
}

export const xaxisOption = xAxisArray => {
  return {
    categories: xAxisArray
  }
}
