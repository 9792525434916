import React from 'react'

// tool
import { PulseLoader } from 'halogenium'

// component
import styled from 'styled-components'

// Redux
import { connect } from 'react-redux'

import colors from '../../global/colors'

class IndicatorAdmin extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.isLoading) {
      return (
        <IndicatorContainer>
          <IndicatorContent>
            <PulseLoader color="#346DFF" size="16px" margin="0 16px 0 0" />
          </IndicatorContent>
        </IndicatorContainer>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  isLoading: state.appState.data.isLoading
  //   isLoading: state.appState.isLoading
})
export default connect(mapStateToProps, null)(IndicatorAdmin)

const IndicatorContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.mainG};
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`
const IndicatorContent = styled.div`
  padding-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`
