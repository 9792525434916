import React, { ComponentType, FC } from "react";
import { Route, Redirect, withRouter, RouteComponentProps } from "react-router-dom";

interface AuthmiddlewareProps extends RouteComponentProps {
  component: ComponentType<any>;
  layout: ComponentType<any>;
}

const Authmiddleware: FC<AuthmiddlewareProps> = ({ component: Component, layout: Layout }) => (
  <Route
    exact={true}
    render={(props) => {
      // here you can apply condition
      if (!localStorage.getItem("token")) {
        return (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default withRouter(Authmiddleware);
