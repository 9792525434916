import React, { useState, useEffect } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Indicator from '../../components/Common/Indicator'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// redux
import { getCategoryList, createCategory } from '../../api/feed'
import { createFeedCategory, getFeedCategoryList } from '../../reducers/feed'

const CategoryRegister = ({
  history,
  state,
  params,
  createFeedCategory,
  feedCategoryList,
  getFeedCategoryList
}) => {
  const [formData, setFormData] = useState({
    name: ''
  })
  const [status, setStatus] = useState({
    color: 'danger',
    isError: false,
    errorMsg: '카테고리 추가 중 문제가 발생했습니다'
  })
  const [list, setList] = useState([])

  useEffect(() => {
    getFeedCategoryList()
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_FEED_CATEGORY_LIST':
        const filterCategory = feedCategoryList.feedCategoryTemplets
          .filter(f => f.enabled === true)
          .sort(function (a, b) {
            var date_a = new Date(a.createdAt)
            var date_b = new Date(b.createdAt)
            if (date_a > date_b) return -1
            if (date_a < date_b) return 1
            return 0
          })

        let tempArr = []
        for (let i = 0; i < filterCategory.length; i++) {
          if (filterCategory[i].enabled) {
            tempArr.push(`  ${filterCategory[i].name}`)
          }
        }
        setList(tempArr)

        break
      case 'SUCCESS_CREATE_FEED_CATEGORY':
        setFormData({ name: '' })
        setStatus({
          ...status,
          color: 'success',
          isError: true,
          errorMsg: '카테고리가 추가되었습니다'
        })
        getFeedCategoryList()
        break
      case 'FAILED_SUCCESS_GET_FEED_CATEGORY_LIST':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        break
      case 'FAILED_CREATE_FEED_CATEGORY':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        break
      default:
        break
    }
  }, [state])

  const handleCreateCategory = e => {
    e.preventDefault()

    createFeedCategory(formData.name)
  }

  const handleInputs = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleChange = options => {

    return setFormData(prevState => ({
      ...prevState,
      tags: Array.isArray(options) && options.map(option => option.value)
    }))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ height: '80vh' }}>
          <Link to={'/category'}>
            <Breadcrumbs
              title="< 카테고리 추가하기"
              breadcrumbItem="Add Category"
            />
          </Link>

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleCreateCategory}>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>카테고리명</Label>
                          <Input
                            required
                            name="name"
                            type="text"
                            className="form-control"
                            value={formData.name}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>등록된 카테고리명 목록</Label>
                          <Input
                            required
                            name="name"
                            type="text"
                            className="form-control"
                            value={list}
                            disabled
                          ></Input>
                        </FormGroup>
                      </Col>

                      <Col sm="12">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block mr-1 waves-effect waves-light"
                        >
                          등록하기
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  feedCategoryList: state.appState.data.feedCategoryList
})

const mapDispatchToProps = dispatch => ({
  getFeedCategoryList: () => dispatch(getFeedCategoryList()),
  createFeedCategory: name => dispatch(createFeedCategory(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryRegister)
