import React, { Fragment, useEffect, useState, useCallback } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup
} from 'reactstrap'
import { NativeSelect } from '@material-ui/core'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import CryptoJS from 'crypto-js'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Link, withRouter, useLocation } from 'react-router-dom'
import PaginatorProject from '../../components/Common/PaginatorProject'

// redux
import { getFeedList, removeFeed } from '../../reducers/feed'
import {
  getRouletteItemList,
  createRouletteItem,
  deleteRouletteItem,
  updateRouletteItem
} from '../../reducers/event'

// utils
import { sortingData } from '../../helpers/utils'
import { categoryState } from '../../constant/utils'

export const EventItemList = ({
  history,
  state,
  params,
  feedList,
  getFeedList,
  removeFeed,
  getRouletteItemList,
  rouletteItemList,
  createRouletteItem,
  updateRouletteItem
}) => {
  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '피드 조회 중 문제가 발생했습니다.',
    color: 'danger'
  })

  const [selectedBoxes, setSelectedBoxes] = useState([])

  const [rouletteItemListData, setRouletteItemListData] = useState([])
  const [originData, setOriginData] = useState({ list: [], max: 0 })

  const [declareData, setDeclareData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)

  const [formData, setFormData] = useState({
    item: '',
    quantity: 0,
    numberOfCaseToStart: 0,
    numberOfCaseToEnd: 0
  })

  const [feedData, setFeedData] = useState({ list: [], max: 0 })
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    // amount: 10,
    amount: 9999,
    search: '',
    filtering: 'all'
  })

  // pagination
  const location = useLocation()
  const feedPage = location.state?.feedPage ? location.state.feedPage : 1
  const feedLastPage = location.state?.feedLastPage
    ? location.state.feedLastPage
    : 1
  const feedSearch = location.state?.feedSearch ? location.state.feedSearch : ''
  const feedCheck = location.state?.feedCheck ? true : false
  const feedFiltering = location.state?.feedFiltering
    ? location.state.feedFiltering
    : ''
  const [start, setStart] = useState(1)
  const [end, setEnd] = useState(11)
  const [isFilter, setIsFilter] = useState(false)
  const [filterTitle, setFilterTitle] = useState('')

  useEffect(() => {
    getRouletteItemList()

    // 상세 페이지에서 이동

    if (feedCheck) {
      setFetchOptions({
        ...fetchOptions,
        page: feedPage,
        amount: 9999,
        search: feedSearch,
        filtering: feedFiltering
      })
      getFeedList({ page: 1, amount: 9999, search: '' })

      if (feedPage > 10) {
        setStart(Math.floor(feedPage / 10) * 10 + 1)
        setEnd(
          Math.floor(feedPage / 10) * 10 + 10 <= feedLastPage
            ? Math.floor(feedPage / 10) * 10 + 11
            : feedLastPage + 1
        )
      }
    } else {
      getFeedList(fetchOptions)
    }

    // getFeedList(fetchOptions)
  }, [])

  useEffect(() => {

    switch (state) {
      case 'SUCCESS_GET_FEED_LIST':
        break
      case 'SUCCESS_GET_ROULETTE_ITEM_LIST':
        setRouletteItemListData(rouletteItemList)
        break
      case 'FAILED_CREATE_ROULETTE_ITEM':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        break

      default:
        break
    }
  }, [state])

  useEffect(() => {
    // const filterData = feedList?.feeds.filter(
    //   f =>
    //     f.user?.username.includes(fetchOptions.search) ||
    //     f.feedBoard?.title.includes(fetchOptions.search) ||
    //     f.user?.phone
    //       .replace(/-/g, '')
    //       .includes(fetchOptions.search.replace(/-/g, ''))
    // )
    // if (filterData) {
    //   let finalData = []
    //   if (fetchOptions.filtering === 'all') {
    //     finalData = filterData
    //   } else if (fetchOptions.filtering === 'realUser') {
    //     finalData = filterData.filter(
    //       f => f.user?.isTestAccount === false && f.user?.admin === false
    //     )
    //     setFilterTitle('피드(실회원)')
    //   } else if (fetchOptions.filtering === 'tester') {
    //     finalData = filterData.filter(f => f.user?.isTestAccount === true)
    //     setFilterTitle('피드(테스터)')
    //   } else if (fetchOptions.filtering === 'admin') {
    //     finalData = filterData.filter(f => f.user?.admin === true)
    //     setFilterTitle('피드(관리자)')
    //   } else {
    //     finalData = filterData.filter(
    //       f =>
    //         f.feedCategory[0].feedCategoryTempletId === fetchOptions.filtering
    //     )
    //   }
    //   setFilterTitle('피드')
    //   setFeedData({
    //     ...feedData,
    //     list: finalData.slice(
    //       (fetchOptions.page - 1) * 10,
    //       fetchOptions.page * 10
    //     ),
    //     max: finalData.length
    //   })
    // }
  }, [fetchOptions])

  // useEffect(() => {
  //   const filterData = originData.list.filter(
  //     f =>
  //       f.user?.username.includes(fetchOptions.search) ||
  //       f.user?.phone
  //         .replace(/-/g, '')
  //         .includes(fetchOptions.search.replace(/-/g, '')) ||
  //       f.feedBoard?.title.includes(fetchOptions.search)
  //   )

  //   setFeedData({
  //     ...feedData,
  //     list: filterData.slice(
  //       (fetchOptions.page - 1) * 10,
  //       fetchOptions.page * 10
  //     ),
  //     max: filterData.length
  //   })
  // }, [fetchOptions])

  const handlePagination = (page, pageSize) => {
    if (fetchOptions.page !== page) {
      return setFetchOptions({
        ...fetchOptions,
        page
      })
    }

    return
  }

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    setFetchOptions({ ...fetchOptions, search: inputValue })
    setStart(1)
    setEnd(11)
  }

  const handleDelete = () => {
    setDeleteModal(false)
    setStatus({
      ...status,
      color: 'danger',
      isError: true,
      errorMsg: '현재 삭제 기능은 사용할 수 없습니다'
    })
    setSelectedBoxes([])
    // deleteRouletteItem(selectedBoxes)
  }

  const handlerFilter = input => {
    // if (feedList?.feeds.length === 0) return
    // else {
    //   let filterData = feedList?.feeds.filter(
    //     f =>
    //       f.user?.username.includes(fetchOptions.search) ||
    //       f.feedBoard?.title.includes(fetchOptions.search) ||
    //       f.user?.phone
    //         .replace(/-/g, '')
    //         .includes(fetchOptions.search.replace(/-/g, ''))
    //   )
    //   if (input === 0) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'all'
    //       })
    //     }
    //   }
    //   if (input === 1) {
    //     setFetchOptions({
    //       ...fetchOptions,
    //       page: 1,
    //       filtering: 'c623262b-0c32-4721-be00-b1fac7fe4502'
    //     })
    //   }
    //   if (input === 2) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: '76c371d9-f410-4ebb-81f4-032284a7b191'
    //       })
    //     }
    //   }
    //   if (input === 3) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: '0ded9cec-dbd9-4ea4-ad18-6561ecbfaad4'
    //       })
    //     }
    //   }
    //   if (input === 4) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: '245241e7-cbce-4108-bb82-b571d8654fa4'
    //       })
    //     }
    //   }
    //   if (input === 5) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: '0876d320-66bb-48a9-b9ec-23a3bb15f142'
    //       })
    //     }
    //   }
    //   if (input === 6) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'realUser'
    //       })
    //     }
    //   }
    //   if (input === 7) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'tester'
    //       })
    //     }
    //   }
    //   if (input === 8) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'admin'
    //       })
    //     }
    //   }
    // }
  }

  const itemFilter = name => {
    if (!name) return '꽝'

    switch (name) {
      case 'ChupaChups':
        return '츄파춥스'
      case 'XCrew':
        return '엑스크루'
      case 'Starbucks':
        return '스타벅스 아메리카노 T'
      case 'Chicken':
        return 'BBQ치킨 + 콜라 1.25L'
      case 'BananaMilk':
        return '바나나우유'
      default:
        return ''
    }
  }

  const handleCreateInputs = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleQuantityInputs = e => {
    let input = e.target.value

    if (Number(input) < 0) {
      return
    }

    if (e.target.value.length > 1) {
      if (e.target.value[0] === '0' && e.target.value[1] !== '.') {
        input = input.substring(1, input.length)
      }
    }

    setFormData({ ...formData, [e.target.name]: input })
  }

  const handleNumberInputs = e => {
    let input = e.target.value

    if (Number(input) < 0) {
      return
    }

    if (e.target.value.length > 1) {
      if (e.target.value[0] === '0' && e.target.value[1] !== '.') {
        input = input.substring(1, input.length)
      }
    }

    if (Number(input) > 100) {
      setFormData({ ...formData, [e.target.name]: '100' })
    } else {
      setFormData({ ...formData, [e.target.name]: input })
    }
  }

  const onSaveItem = e => {
    e.preventDefault()

    const filteringData = {
      item: formData.item,
      quantity: Number(formData.quantity),
      numberOfCaseToStart: Number(formData.numberOfCaseToStart),
      numberOfCaseToEnd: Number(formData.numberOfCaseToEnd)
    }

    setCreateModal(false)

    // console.log('$$ update item', filteringData)

    // createRouletteItem(filteringData)
  }

  const onSaveFormData = target => {
    setFormData({
      item: target.item,
      notationName: target.notationName,
      numberOfCase: target.numberOfCase,
      numberOfCaseToEnd: target.numberOfCaseToEnd,
      numberOfCaseToStart: target.numberOfCaseToStart,
      quantity: target.quantity,
      targetId: target.id,
      randEnabled: target.randEnabled
    })
  }

  const updateItem = e => {
    e.preventDefault()

    const convertEnum = {
      Chicken: 0,
      XCrew: 1,
      Starbucks: 2,
      BananaMilk: 3,
      ChupaChups: 4
    }

    const filterData = {
      item: formData.item,
      notationName: formData.notationName,
      quantity: Number(formData.quantity),
      numberOfCaseToStart: Number(formData.numberOfCaseToStart),
      numberOfCaseToEnd: Number(formData.numberOfCaseToEnd),
      targetId: formData.targetId,
      // randEnabled: formData.item === 'ChupaChups' ? false : formData.randEnabled
      randEnabled: formData.randEnabled
    }

    // console.log('$$ updateItem', filterData)

    updateRouletteItem(filterData)
  }

  // console.log('$$ roulette item', formData)

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`${filterTitle} 룰렛 상품`}
            breadcrumbItem="Feeds List"
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      {/* <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            value={fetchOptions.search}
                            onChange={handleSearch}
                            placeholder="이름/전화번호/제목"
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div> */}
                    </Col>

                    <Col sm="8">
                      <div
                        className="text-sm-right"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          // onClick={() => setCreateModal(true)}
                          onClick={() =>
                            setStatus({
                              ...status,
                              color: 'danger',
                              isError: true,
                              errorMsg: '현재 추가 기능은 사용할 수 없습니다'
                            })
                          }
                        >
                          <i className="mdi mdi-plus mr-1"></i> 추가
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                          onClick={() => setDeleteModal(!deleteModal)}
                          disabled={selectedBoxes.length === 0}
                        >
                          <i className="mdi mdi-minus mr-1"></i> 삭제
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Modal
                    centered
                    scrollable
                    isOpen={deleteModal}
                    toggle={() => setDeleteModal(!deleteModal)}
                  >
                    <ModalHeader
                      toggle={() => setDeleteModal(!deleteModal)}
                      style={{
                        borderBottom: '2px solid #2a3042'
                      }}
                    >
                      <div>상품 삭제</div>
                    </ModalHeader>
                    <ModalBody
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        height: '150px',
                        fontSize: '18px'
                      }}
                    >{`${selectedBoxes.length}개의 상품을 삭제하시겠습니까?`}</ModalBody>
                    <ModalFooter
                      onClick={handleDelete}
                      style={{
                        cursor: 'pointer',
                        justifyContent: 'center',
                        borderTop: '2px solid #e2e2e2',
                        fontWeight: 'bold',
                        fontSize: '16px'
                      }}
                    >
                      삭제
                    </ModalFooter>
                  </Modal>

                  <Modal
                    centered
                    scrollable
                    isOpen={createModal}
                    toggle={() => setCreateModal(!createModal)}
                  >
                    <ModalHeader
                      toggle={() => setCreateModal(!createModal)}
                      style={{
                        borderBottom: '2px solid #2a3042'
                      }}
                    >
                      <div>상품 추가</div>
                    </ModalHeader>
                    <ModalBody
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        minHeight: '400px',
                        flexDirection: 'column'
                      }}
                    >
                      <Form onSubmit={onSaveItem}>
                        <Row>
                          <Col sm="12">
                            <FormGroup>
                              <Label>상품</Label>
                              <Input
                                required
                                name="item"
                                type="text"
                                className="form-control"
                                placeholder="상품을 입력해주세요"
                                value={formData.item}
                                onChange={handleCreateInputs}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="12">
                            <FormGroup>
                              <Label>수량</Label>
                              <Input
                                required
                                name="quantity"
                                type="number"
                                className="form-control"
                                placeholder="수량을 입력해주세요"
                                value={formData.quantity}
                                onChange={handleQuantityInputs}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="12">
                            <FormGroup>
                              <Label>확률 시작 범위</Label>
                              <Input
                                required
                                name="numberOfCaseToStart"
                                type="number"
                                className="form-control"
                                placeholder="확률 시작 범위를 입력해주세요"
                                value={formData.numberOfCaseToStart}
                                onChange={handleNumberInputs}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="12">
                            <FormGroup>
                              <Label>확률 마지막 범위</Label>
                              <Input
                                required
                                name="numberOfCaseToEnd"
                                type="number"
                                className="form-control"
                                placeholder="확률 마지막 범위를 입력해주세요"
                                value={formData.numberOfCaseToEnd}
                                onChange={handleNumberInputs}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="12">
                            <Button
                              type="submit"
                              color="primary"
                              className="btn-block mr-1 waves-effect waves-light"
                            >
                              등록
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                  <Modal
                    centered
                    scrollable
                    isOpen={updateModal}
                    toggle={() => setUpdateModal(!updateModal)}
                  >
                    <ModalHeader
                      toggle={() => setUpdateModal(!updateModal)}
                      style={{
                        borderBottom: '2px solid #2a3042'
                      }}
                    >
                      <div>상품 수정</div>
                    </ModalHeader>
                    <ModalBody
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        minHeight: '400px',
                        flexDirection: 'column'
                      }}
                    >
                      <Form
                        onSubmit={e => {
                          updateItem(e)
                          setUpdateModal(false)
                        }}
                      >
                        <Row>
                          <Col sm="12">
                            <FormGroup>
                              <Label>상품</Label>
                              <Input
                                required
                                name="item"
                                type="text"
                                className="form-control"
                                placeholder="상품을 입력해주세요"
                                value={formData.notationName}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="10">
                            <FormGroup>
                              <Label>수량</Label>
                              <Input
                                required
                                name="quantity"
                                type="number"
                                className="form-control"
                                placeholder="수량을 입력해주세요"
                                value={formData.quantity}
                                onChange={handleQuantityInputs}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="2">
                            <FormGroup>
                              <Label>당첨 제외</Label>
                              <div
                                className="custom-control custom-checkbox"
                                style={{
                                  zIndex: 0,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={formData.randEnabled === false}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor={formData.randEnabled}
                                  onClick={() =>
                                    setFormData({
                                      ...formData,
                                      randEnabled: !formData.randEnabled
                                    })
                                  }
                                >
                                  &nbsp;
                                </Label>
                              </div>
                            </FormGroup>
                          </Col>

                          <Col sm="6">
                            <FormGroup>
                              <Label>확률 시작 범위</Label>
                              <Input
                                required
                                name="numberOfCaseToStart"
                                type="number"
                                className="form-control"
                                placeholder="확률 시작 범위를 입력해주세요"
                                value={formData.numberOfCaseToStart}
                                onChange={handleNumberInputs}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="6">
                            <FormGroup>
                              <Label>확률 마지막 범위</Label>
                              <Input
                                required
                                name="numberOfCaseToEnd"
                                type="number"
                                className="form-control"
                                placeholder="확률 마지막 범위를 입력해주세요"
                                value={formData.numberOfCaseToEnd}
                                onChange={handleNumberInputs}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="12">
                            <Button
                              type="submit"
                              color="primary"
                              className="btn-block mr-1 waves-effect waves-light"
                            >
                              수정
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                  <Alert
                    color={status.color}
                    isOpen={status.isError}
                    toggle={() =>
                      setStatus(prevState => ({
                        ...prevState,
                        isError: false
                      }))
                    }
                  >
                    {status.errorMsg}
                  </Alert>

                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: '4%' }}>
                            <div
                              className="custom-control custom-checkbox"
                              style={{ zIndex: 0 }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="all-check"
                                checked={
                                  selectedBoxes.length !== 0 &&
                                  selectedBoxes.length ===
                                    rouletteItemListData.length
                                }
                                onChange={e =>
                                  e.target.checked
                                    ? Array.isArray(rouletteItemListData) &&
                                      setSelectedBoxes(
                                        rouletteItemListData.map(u => u.id)
                                      )
                                    : setSelectedBoxes([])
                                }
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="all-check"
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </th>
                          <th scope="col" style={{ width: '25%' }}>
                            상품명
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            아이템명
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            수량
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            확률
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            확률 시작 범위
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            확률 마지막 범위
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            당첨 제외
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(rouletteItemListData) &&
                          rouletteItemListData.slice().map((ele, idx) => (
                            <>
                              <tr key={'_item_' + idx}>
                                <td style={{ height: '65px' }}>
                                  <div
                                    className="custom-control custom-checkbox"
                                    style={{ zIndex: 0 }}
                                  >
                                    <Input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={ele.id}
                                      checked={selectedBoxes.includes(ele.id)}
                                      onChange={e =>
                                        e.target.checked
                                          ? setSelectedBoxes([
                                              ...selectedBoxes,
                                              ele.id
                                            ])
                                          : setSelectedBoxes(
                                              selectedBoxes.filter(
                                                b => b !== ele.id
                                              )
                                            )
                                      }
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor={ele.id}
                                    >
                                      &nbsp;
                                    </Label>
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    onSaveFormData(ele)
                                    setUpdateModal(true)
                                  }}
                                >
                                  {/* {itemFilter(ele.item)} */}
                                  {ele.notationName}
                                </td>
                                <td
                                  onClick={() => {
                                    onSaveFormData(ele)
                                    setUpdateModal(true)
                                  }}
                                >
                                  {ele.item}
                                </td>
                                <td
                                  onClick={() => {
                                    onSaveFormData(ele)
                                    setUpdateModal(true)
                                  }}
                                >
                                  {ele.quantity}
                                </td>
                                <td
                                  onClick={() => {
                                    onSaveFormData(ele)
                                    setUpdateModal(true)
                                  }}
                                >
                                  {ele.numberOfCase}%
                                </td>
                                <td
                                  onClick={() => {
                                    onSaveFormData(ele)
                                    setUpdateModal(true)
                                  }}
                                >
                                  {ele.numberOfCaseToStart}%
                                </td>
                                <td
                                  onClick={() => {
                                    onSaveFormData(ele)
                                    setUpdateModal(true)
                                  }}
                                >
                                  {ele.numberOfCaseToEnd}%
                                </td>
                                <td
                                  onClick={() => {
                                    onSaveFormData(ele)
                                    setUpdateModal(true)
                                  }}
                                >
                                  {/* {ele.item === 'ChupaChups'
                                    ? 'X'
                                    : ele.randEnabled
                                    ? 'X'
                                    : 'O'} */}
                                  {ele.randEnabled ? 'X' : 'O'}
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                    <PaginatorProject
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(feedData.max / 10)}
                      onChange={handlePagination}
                      start={start}
                      setStart={setStart}
                      end={end}
                      setEnd={setEnd}
                    />
                    {/* <PaginatorTemp
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(feedData.max / 10)}
                      onChange={handlePagination}
                    /> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  feedList: state.appState.data.feedList,
  rouletteItemList: state.appState.data.rouletteItemList
})

const mapDispatchToProps = dispatch => ({
  removeFeed: ids => dispatch(removeFeed(ids)),
  getFeedList: options => dispatch(getFeedList(options)),
  getRouletteItemList: () => dispatch(getRouletteItemList()),
  createRouletteItem: items => dispatch(createRouletteItem(items)),
  deleteRouletteItem: id => dispatch(deleteRouletteItem(id)),
  updateRouletteItem: data => dispatch(updateRouletteItem(data))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventItemList)
)
