import React, { Fragment, useEffect, useState, useCallback } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import 'moment/locale/ko'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Indicator from '../../components/Common/Indicator'
// import Paginator from '../../components/Common/Paginator'
import UserPaginator from './UserPaginator'
import PaginatorUser from '../../components/Common/PaginatorUser'

// redux
import { getUsers, deleteUser } from '../../api/users'
import { getRevisitUserList } from '../../reducers/users'

// utils
import { sortingData } from '../../helpers/utils'
import { genderState } from '../../constant/utils'

// temp
import { usersDummy, usersMainDummy } from '../../constant/usersDummy'
import PaginatorProject from '../../components/Common/PaginatorProject'

// table
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { voucherData } from '../../constant/dummy'

const RevistList = ({ params, state, getRevisitUserList, revisitList }) => {
  const [originData, setOriginData] = useState({ data: [], max: 0 })
  const [renderData, setRenderData] = useState({ data: [], max: 0 })
  const [page, setPage] = useState(1)
  const [pageRange, setPageRange] = useState([])

  // page
  const [start, setStart] = useState(1)
  const [end, setEnd] = useState(11)

  const [searchKeyword, setSearchKeyword] = useState('')

  // redux
  const [usersData, setUsersData] = useState({ list: [], max: 0 })
  const [csvData, setCsvData] = useState([])
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 9999,
    search: '',
    filtering: 'all',
    getTime: 3
  })
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '회원 조회 중 문제가 발생했습니다',
    color: 'danger'
  })
  const [deleteModal, setDeleteModal] = useState(false)

  // pagination
  const location = useLocation()
  const history = useHistory()
  const userPage = location.state?.userPage ? location.state.userPage : 1
  const userLastPage = location.state?.userLastPage
    ? location.state.userLastPage
    : 1
  const userSearch = location.state?.userSearch ? location.state.userSearch : ''
  const userCheck = location.state?.userCheck ? true : false
  const userFiltering = location.state?.userFiltering
    ? location.state.userFiltering
    : ''

  const [isFilter, setIsFilter] = useState(false)
  const [filterTitle, setFilterTitle] = useState('재방문')

  useEffect(() => {
    // temp
    // getRevisitUserList(fetchOptions)

    // 상세 페이지에서 이동
    if (userCheck) {
      // setFetchOptions(
      //   {
      //     page: userPage,
      //     amount: 99999,
      //     search: userSearch,
      //     filtering: userFiltering
      //   },
      //   () => {
      //     getUserList({ page: 1, amount: 99999, search: '' })
      //   }
      // )
      // if (userPage > 10) {
      //   setStart(Math.floor(userPage / 10) * 10 + 1)
      //   setEnd(
      //     Math.floor(userPage / 10) * 10 + 10 <= userLastPage
      //       ? Math.floor(userPage / 10) * 10 + 11
      //       : userLastPage + 1
      //   )
      // }
    } else {
      getRevisitUserList(fetchOptions)
    }
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_REVISIT_USER_LIST':

        setOriginData({
          list: revisitList,
          max: revisitList.length
        })

        setUsersData({
          list: revisitList.slice(
            (fetchOptions.page - 1) * 10,
            fetchOptions.page * 10
          ),
          max: revisitList.length
        })

        // if (userList.users) {
        //   const filterData = userList?.users.filter(
        //     f =>
        //       (f.username && f.username.includes(fetchOptions.search)) ||
        //       (f.id && f.id.includes(fetchOptions.search)) ||
        //       (f.email && f.email.includes(fetchOptions.search)) ||
        //       (f.phone &&
        //         f.phone
        //           .replace(/-/g, '')
        //           .includes(fetchOptions.search.replace(/-/g, '')))
        //   )

        //   let finalData = []

        //   if (fetchOptions.filtering === 'all') {
        //     finalData = filterData
        //     setFilterTitle('전체')
        //   } else if (fetchOptions.filtering === 'workOrderForQuantityCount') {
        //     finalData = filterData.sort(
        //       (a, b) =>
        //         b.workOrderForQuantityCount - a.workOrderForQuantityCount
        //     )
        //     setFilterTitle('견적서 생성순')
        //   } else if (fetchOptions.filtering === 'realUser') {
        //     finalData = filterData.filter(f => f.admin === false)
        //     setFilterTitle('실회원')
        //   } else if (fetchOptions.filtering === 'admin') {
        //     finalData = filterData.filter(f => f.admin === true)
        //     setFilterTitle('관리자')
        //   } else if (fetchOptions.filtering === 'tester') {
        //     finalData = filterData.filter(f => f.isTestAccount === true)
        //     setFilterTitle('테스터')
        //   } else if (fetchOptions.filtering === 'isService') {
        //     finalData = filterData.filter(f => f.setServicePush === true)
        //     setFilterTitle('서비스 푸시 동의')
        //   } else if (fetchOptions.filtering === 'isMarketing') {
        //     finalData = filterData.filter(f => f.setMarketingPush === true)
        //     setFilterTitle('마케팅 정보 제공 동의')
        //   } else if (fetchOptions.filtering === 'signupAt') {
        //     finalData = sortingSignup(filterData)
        //     setFilterTitle('가입일순')
        //   } else if (fetchOptions.filtering === 'loginAt') {
        //     finalData = sortingLogin(filterData)
        //     setFilterTitle('최근 로그인순')
        //   } else if (fetchOptions.filtering === 'voucher') {
        //     let filterVoucher = []
        //     for (let i = 0; i < voucherData.length; i++) {
        //       let temp = filterData.filter(f => f.id === voucherData[i].id)[0]
        //       if (temp) {
        //         filterVoucher.push(temp)
        //       }
        //     }
        //     finalData = filterVoucher
        //     setFilterTitle('비대면바우처')
        //   } else if (fetchOptions.filtering === 'userLoginInfoCount') {
        //     finalData = filterData.sort(
        //       (a, b) => b.userLoginInfoCount - a.userLoginInfoCount
        //     )
        //     setFilterTitle('로그인 횟수순')
        //   }

        //   console.log('$$ finalData', finalData)

        //   setOriginData({
        //     list: finalData,
        //     max: finalData.length
        //   })

        //   setUsersData({
        //     list: finalData.slice(
        //       (fetchOptions.page - 1) * 10,
        //       fetchOptions.page * 10
        //     ),
        //     max: finalData.length
        //   })
        // }

        break

      default:
        break
    }
  }, [state])

  // useEffect(() => {
  //   getUserList(fetchOptions)
  // }, [fetchOptions])

  useEffect(() => {
    // const filterData = userList?.users.filter(
    //   f =>
    //     (f.username && f.username.includes(fetchOptions.search)) ||
    //     (f.id && f.id.includes(fetchOptions.search)) ||
    //     (f.email && f.email.includes(fetchOptions.search)) ||
    //     (f.phone &&
    //       f.phone
    //         .replace(/-/g, '')
    //         .includes(fetchOptions.search.replace(/-/g, '')))
    // )
    // // console.log('$$ finalData', filterData)
    // if (filterData) {
    //   let finalData = []
    //   if (fetchOptions.filtering === 'all') {
    //     finalData = filterData
    //     setFilterTitle('전체')
    //   } else if (fetchOptions.filtering === 'workOrderForQuantityCount') {
    //     finalData = filterData.sort(
    //       (a, b) => b.workOrderForQuantityCount - a.workOrderForQuantityCount
    //     )
    //     setFilterTitle('견적서 생성순')
    //   } else if (fetchOptions.filtering === 'realUser') {
    //     finalData = filterData.filter(
    //       f => f.admin === false && f.isTestAccount === false
    //     )
    //     setFilterTitle('실회원')
    //   } else if (fetchOptions.filtering === 'admin') {
    //     finalData = filterData.filter(f => f.admin === true)
    //     setFilterTitle('관리자')
    //   } else if (fetchOptions.filtering === 'tester') {
    //     finalData = filterData.filter(f => f.isTestAccount === true)
    //     setFilterTitle('테스터')
    //   } else if (fetchOptions.filtering === 'isService') {
    //     finalData = filterData.filter(f => f.setServicePush === true)
    //     setFilterTitle('서비스 푸시 동의')
    //   } else if (fetchOptions.filtering === 'isMarketing') {
    //     finalData = filterData.filter(f => f.setMarketingPush === true)
    //     setFilterTitle('마케팅 정보 제공 동의')
    //   } else if (fetchOptions.filtering === 'signupAt') {
    //     finalData = sortingSignup(filterData)
    //     setFilterTitle('가입일순')
    //   } else if (fetchOptions.filtering === 'loginAt') {
    //     finalData = sortingLogin(filterData)
    //     setFilterTitle('최근 로그인순')
    //   } else if (fetchOptions.filtering === 'voucher') {
    //     let filterVoucher = []
    //     for (let i = 0; i < voucherData.length; i++) {
    //       let temp = filterData.filter(f => f.id === voucherData[i].id)[0]
    //       if (temp) {
    //         filterVoucher.push(temp)
    //       }
    //     }
    //     finalData = filterVoucher
    //     setFilterTitle('비대면바우처')
    //   } else if (fetchOptions.filtering === 'userLoginInfoCount') {
    //     finalData = filterData.sort(
    //       (a, b) => b.userLoginInfoCount - a.userLoginInfoCount
    //     )
    //     setFilterTitle('로그인 횟수순')
    //   }
    //   setUsersData({
    //     ...usersData,
    //     list: finalData.slice(
    //       (fetchOptions.page - 1) * 10,
    //       fetchOptions.page * 10
    //     ),
    //     max: finalData.length
    //   })
    // }
  }, [fetchOptions])

  const handlePagination = (page, pageSize) => {
    if (fetchOptions.page !== page) {
      return setFetchOptions({
        ...fetchOptions,
        page
      })
    }

    return
  }

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    setFetchOptions({ ...fetchOptions, search: inputValue, page: 1 })
    setStart(1)
    setEnd(11)
  }

  const debounceInput = useCallback()
  // debounce(input => {
  //   setFetchOptions({ ...fetchOptions, search: input })
  //   setStart(1)
  //   setEnd(11)
  // }, 750),
  // []

  const sortingSignup = type => {
    const sorting = type.sort((a, b) => {
      let x = new Date(a.signupAt).getTime()
      let y = new Date(b.signupAt).getTime()

      if (x > y) {
        return 1
      } else {
        if (x < y) {
          return -1
        }
        return 0
      }
    })

    return sorting
  }

  const sortingLogin = type => {
    const sorting = type.sort((a, b) => {
      let x = new Date(a.loginAt).getTime()
      let y = new Date(b.loginAt).getTime()

      if (x > y) {
        return -1
      } else {
        if (x < y) {
          return 1
        }
        return 0
      }
    })

    return sorting
  }

  const handlerFilter = input => {
    // if (userList?.users.length === 0) return
    // else {
    //   let filterData = userList?.users.filter(
    //     f =>
    //       (f.username && f.username.includes(fetchOptions.search)) ||
    //       (f.id && f.id.includes(fetchOptions.search)) ||
    //       (f.phone &&
    //         f.phone
    //           .replace(/-/g, '')
    //           .includes(fetchOptions.search.replace(/-/g, '')))
    //   )
    //   if (input === 0) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'all'
    //       })
    //     }
    //   }
    //   if (input === 1) {
    //     setFetchOptions({
    //       ...fetchOptions,
    //       page: 1,
    //       filtering: 'workOrderForQuantityCount'
    //     })
    //   }
    //   if (input === 2) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'realUser'
    //       })
    //     }
    //   }
    //   if (input === 3) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'admin'
    //       })
    //     }
    //   }
    //   if (input === 4) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'tester'
    //       })
    //     }
    //   }
    //   if (input === 5) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'isService'
    //       })
    //     }
    //   }
    //   if (input === 6) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'isMarketing'
    //       })
    //     }
    //   }
    //   if (input === 7) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'signupAt'
    //       })
    //     }
    //   }
    //   if (input === 8) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'loginAt'
    //       })
    //     }
    //   }
    //   if (input === 9) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'voucher'
    //       })
    //     }
    //   }
    //   if (input === 10) {
    //     if (filterData) {
    //       setFetchOptions({
    //         ...fetchOptions,
    //         page: 1,
    //         filtering: 'userLoginInfoCount'
    //       })
    //     }
    //   }
    //   getAllUserList()
    // }
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={filterTitle} breadcrumbItem="Revisit List" />

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="8"></Col>
                  </Row>

                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: '50%' }}>
                            ID
                          </th>
                          <th scope="col" style={{ width: '25%' }}>
                            이전 방문
                          </th>
                          <th scope="col" style={{ width: '25%' }}>
                            최근 방문
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(usersData.list) &&
                          usersData.list.slice().map((user, idx) => (
                            <tr key={'_user_' + idx}>
                              <td
                                style={{
                                  color: '#556EE6',
                                  cursor: 'pointer',
                                  height: '65px'
                                }}
                              >
                                <Link to={`/user-detail/${user.id}`}>
                                  {user.id}
                                </Link>
                              </td>
                              <td>
                                {user.previousLoginedAt &&
                                  moment(user.previousLoginedAt).format(
                                    'YYYY-MM-DD-HH:mm'
                                  )}
                              </td>
                              <td>
                                {user.lastLoginedAt &&
                                  moment(user.lastLoginedAt).format(
                                    'YYYY-MM-DD-HH:mm'
                                  )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <PaginatorProject
                      currentPage={fetchOptions.page}
                      pageSize={10}
                      total={Math.ceil(usersData.max / 10)}
                      onChange={handlePagination}
                      start={start}
                      setStart={setStart}
                      end={end}
                      setEnd={setEnd}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  revisitList: state.appState.data.revisitList
})
const mapDispatchToProps = dispatch => ({
  getRevisitUserList: options => dispatch(getRevisitUserList(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(RevistList)
