import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import InflowAndBounceBoard from './board';

export default function InFlowAndBounce() {
  const [userType, setUserType] = useState(0);  // 0:해지율 1:탈퇴율
  const [type, setType] = useState('year')
  
  const onClickButton = useCallback((type: string) => {
    setType(type)
  }, [])

  const onHandleUserType = (userType: number) => {
    setUserType(userType);
  }

  return (
    <Wrapper>
      <h2>유입율 / 이탈율</h2>
      
      {/* 이탈율 설명 */}
      {userType === 0 ? (
        // 유료
        <p>
          유료 유입/이탈율의 판별 기준은 트라이얼을 제외한 당월/당일에 유료가입 혹은 해지를 한 유저를 대상으로 합니다.<br />
          전체 유입율 = ((당월/일 전체 누적 유료 회원 수) / (당월/일 유료가입 수)) * 100<br />
          전체 이탈율 = ((당월/일 전체 누적 유료 회원 수) / ((당월/일 유료해지 수) + (당월/일 결제실패 수))) * 100
        </p>
      ): (
        // 일반
        <p>
          일반 유입/이탈율의 판별 기준은 관리자를 제외한 당월/당일에 가입 혹은 탈퇴를 한 유저를 대상으로 합니다.<br />
          전체 유입율 = ((당월/일 전체 누적 이용 회원 수) / (당월/일 일반가입 수)) * 100<br />
          전체 이탈율 = ((당월/일 전체 누적 이용 회원 수) / (당월/일 탈퇴 수)) * 100
        </p>
      )}

      <span style={{display:'flex', flexDirection: 'row', flex:1, justifyContent:'space-between'}}>
        <LeftButtonWrapper>
          <Button
            color={userType === 0 ? 'primary' : 'secondary'}
            active={userType === 0}
            onClick={() => onHandleUserType(0)}
          >
            유료
          </Button>
          <Button
            color={userType === 1 ? 'primary' : 'secondary'}
            active={userType === 1}
            onClick={() => onHandleUserType(1)}
          >
            일반
          </Button>
        </LeftButtonWrapper>

        <ButtonWrapper>
          <Button
            color={type === 'year' ? 'primary' : 'secondary'}
            active={type === 'year'}
            onClick={() => onClickButton('year')}
          >
            Monthly
          </Button>
          <Button
            color={type === 'week' ? 'primary' : 'secondary'}
            active={type === 'week'}
            onClick={() => onClickButton('week')}
          >
            Weekly
          </Button>
          <Button
            color={type === 'month' ? 'primary' : 'secondary'}
            active={type === 'month'}
            onClick={() => onClickButton('month')}
          >
            Daily
          </Button>
        </ButtonWrapper>
      </span>

      <ChartWrapper>
        <InflowAndBounceBoard userType={userType} dateType={type} />
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const LeftButtonWrapper = styled.span`
  align-items: center;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`
const ButtonWrapper = styled.span`
  align-items: center;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`

const ChartWrapper = styled.div``
