import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import styled from 'styled-components'

export default function LinkPaginator({
  searchParams,
  visibleCount,
  totalCount = 0,
  urlPrefix
}) {
  const {
    currentPage,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate,
    paymentStatus,
    userRequestBusinessmanState,
    certFilter,
    type
  } = searchParams
 
  const params = new URLSearchParams()

  /** 검색 URL 상속 */
  if(search) params.append('search', search)
  if(clientType) params.append('clientType', clientType)
  if(startMoney) params.append('startMoney', startMoney)
  if(endMoney) params.append('endMoney', endMoney)
  if(startDate)params.append('startDate', startDate)
  if(endDate) params.append('endDate', endDate)
  if(paymentStatus) params.append('paymentStatus', paymentStatus)
  if (userRequestBusinessmanState) params.append('userRequestBusinessmanState', userRequestBusinessmanState)
  if (certFilter) params.append('certFilter', certFilter)
  if (type) params.append('type', type)
  
  const searchParamsWithoutPage = `&${params.toString()}`

  const isLeftDisabled = Boolean(totalCount <= visibleCount || currentPage <= visibleCount/2)
  // 전체 페이지가 10보다 같거나 작은경우, 현재 페이지가 마지막 구간에 있는경우, 현재 페이지가 전체 페이지보다 같거나 큰경우
  const isRightDisabled = Boolean(totalCount <= visibleCount || totalCount <= currentPage + visibleCount/2 || totalCount <= currentPage + 1)

  const calculatePagination = (totalPages, currentPage, visiblePages) => {
    const halfVisiblePages = Math.floor(visiblePages / 2) // 중앙에 위치한 페이지 수
    let startPage, endPage

    // 전체 페이지가 한번에 보여줄 페이지 수 보다 작은 경우
    if (totalPages <= visiblePages) {
      startPage = 0
      endPage = totalPages
    }
    // 현재 페이지가 중앙에 위치한 페이지 수보다 작은 경우
    else if (currentPage <= halfVisiblePages) {
      startPage = 0
      endPage = visiblePages
    }
    // 현재 페이지가 중앙에 위치한 페이지 수보다 큰 경우
    else if (currentPage + halfVisiblePages >= totalPages) {
      startPage = totalPages - visiblePages + 1
      endPage = totalPages
    }
    // 그 외의 경우
    else {
      startPage = currentPage - halfVisiblePages
      endPage = currentPage + halfVisiblePages
    }

    const pages = []
    // 0부터 시작이어서 <endPage
    for (let i = startPage; i < endPage; i++) {
      pages.push(i)
    }
    return pages
  }

  const getPageURL = (n = 0) => `${urlPrefix}?page=${n}${searchParamsWithoutPage}`

  if (totalCount === 0)
    return (
      <Wrapper>
        <Pagination>
          <PaginationItem disabled={true}>
            <PaginationLink
              first
              href={getPageURL()}
            />
          </PaginationItem>
        </Pagination>
      </Wrapper>
    )
  return (
    <Wrapper>
      <Pagination>
        <PaginationItem disabled={isLeftDisabled} >
          <PaginationLink
            first
            href={getPageURL()}
          />
        </PaginationItem>
        <PaginationItem disabled={isLeftDisabled} >
          <PaginationLink
            previous
            href={getPageURL(Math.max(0, currentPage-visibleCount))}
          />
        </PaginationItem>
        {calculatePagination(totalCount, currentPage, visibleCount).map(
          (item, index) => (
            <PaginationItem key={index} active={item === currentPage}>
              <PaginationLink
                href={getPageURL(item)} >
                {item+1}
              </PaginationLink>
            </PaginationItem>
          )
        )}
        <PaginationItem disabled={isRightDisabled}>
          <PaginationLink
            next
            href={getPageURL(Math.min(currentPage+visibleCount, totalCount - 1))}
          />
        </PaginationItem>
        <PaginationItem disabled={isRightDisabled} >
          <PaginationLink
            last
            href={getPageURL(totalCount - 1)}
          />
        </PaginationItem>
      </Pagination>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
