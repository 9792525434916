export const GET_TAXINVOICE_LIST = 'reducers/taxInvoice/GET_TAXINVOICE_LIST'
export const GET_TAXINVOICE_DETAIL = 'reducers/taxInvoice/GET_TAXINVOICE_DETAIL'
export const SET_TAXINVOICE_IS_BUSINESSMAN = 'reducers/taxInvoice/SET_TAXINVOICE_IS_BUSINESSMAN'
export const GET_TAXINVOICE_VIEW = 'reducers/taxInvoice/GET_TAXINVOICE_VIEW'

export const getTaxInvoiceList = (
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
) => ({
  type: GET_TAXINVOICE_LIST,
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
})

export const getTaxInvoiceDetail = id => ({
  type: GET_TAXINVOICE_DETAIL,
  id
})

export const setTaxInvoiceIsBusinessman = (id, isBusinessman) => ({
  type: SET_TAXINVOICE_IS_BUSINESSMAN,
  id,
  isBusinessman
})


export const getTaxInvoiceDetailView = id => ({
  type: GET_TAXINVOICE_VIEW,
  id
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
