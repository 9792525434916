// import 'react-datepicker/dist/react-datepicker.css'
import { Card, CardBody, Col, Container, Label, Row, Table } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { getDateRange } from '../../../helpers/utils'

import Indicator from '../../../components/Common/Indicator'
import Paginator from '../../../components/Common/Paginator'

const reportList = [
  '욕설 / 인신공격',
  '개인정보 노출',
  '음란성 / 선정성',
  '같은 내용 반복(도배)',
  '영리 목적 / 홍보성',
  '기타'
]

const FeedReports = ({ feedData, usersData, declareData }) => {
  const feedId = window.location.pathname.split('/')[2]
  const [isFetching, setFetching] = useState(false)
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10,
    search: ''
  })
  const [searchQuery, setSearchQuery] = useState({
    project: '',
    user: '',
    date: '',
    range: {
      label: '전체',
      value: 'all',
      start: 0,
      end: Infinity
    }
  })
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    amount: 10
  })
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)

  const handlePagination = (page, pageSize) => {
    if (pageOptions.page !== page) {
      return setPageOptions(prevState => ({
        ...prevState,
        page
      }))
    }
    return
  }

  const handleInput = e => {
    e.persist()
    return setSearchQuery(prevState => ({
      ...prevState,
      [e.target?.name]: e.target?.value
    }))
  }

  const handleChange = option => {
    const { start, end } = getDateRange(option.value)
    return setSearchQuery(prevState => ({
      ...prevState,
      range: {
        label: option.label,
        value: option.value,
        start: start,
        end: end
      }
    }))
  }

  const handleDelete = async () => {
    if (selectedBoxes.length < 1) return

    // const { opcode, results } = await deleteUsers(selectedBoxes);

    // if (opcode === 0) {
    //   console.log(results);
    //   history.push("/feeds");
    // }
    alert('삭제 완료')
    setDeleteModal(false)
  }

  return (
    <Container fluid>
      <Row>
        <div className="col-xl col-sm-6">
          <Label>
            <h3>신고 {declareData.length > 0 ? declareData.length : 0}건</h3>
          </Label>
        </div>
        <Col lg="12">
          <Card>
            <CardBody>
              {isFetching ? (
                <Indicator height={'30vh'} />
              ) : (
                <div
                  className="table-responsive"
                  style={{ minHeight: '850px' }}
                >
                  <Table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center">신고일</th>
                        <th className="text-center">유형</th>
                        <th className="text-center">내용</th>
                        <th className="text-center">이름</th>
                        <th className="text-center">전화번호</th>
                        <th className="text-center">처리</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(declareData) &&
                        declareData
                          .slice(
                            (pageOptions.page - 1) * 10,
                            pageOptions.amount * pageOptions.page
                          )
                          .reverse()
                          .map((declare, idx) => {
                            return (
                              <tr key={idx + 1}>
                                <td
                                  className="text-center"
                                  style={{ height: '65px' }}
                                >
                                  {declare.createdAt &&
                                    declare.createdAt.slice(0, 10)}
                                </td>
                                <td className="text-center">
                                  {reportList[declare.type]}
                                </td>
                                <td className="text-center">
                                  {declare.content}
                                </td>

                                <td className="text-center">
                                  {usersData.length > 0 &&
                                    usersData.filter(
                                      ele => ele.id === declare.userId
                                    )[0].username}
                                </td>
                                <td className="text-center">
                                  {usersData.length > 0 &&
                                    usersData.filter(
                                      ele => ele.id === declare.userId
                                    )[0].phone}
                                </td>
                                <td className="text-center">X</td>
                              </tr>
                            )
                          })}
                    </tbody>
                  </Table>
                  <Paginator
                    currentPage={fetchOptions.page}
                    pageSize={fetchOptions.amount}
                    total={declareData && Math.ceil(declareData.length / 10)}
                    onChange={handlePagination}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default FeedReports
