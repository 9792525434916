export const GET_TESTER_LIST = 'reducers/admin/GET_TESTER_LIST'

export const getTesterList = () => ({
  type: GET_TESTER_LIST
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
