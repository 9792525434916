import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// redux
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// component
import WorkDocumentPreview from '../../WorkDocumentPreview'
import ProjectQuantityBreadcrumb from '../../Users/UserDetail/UserBreadcrumbs'

// tools
import { Nav, NavItem, NavLink, Container } from 'reactstrap'

// time
import classNames from 'classnames'
import ReceiptInfo from './ReceiptInfo'
import { getReceiptDetail } from '../../../reducers/receipt'

const ReceiptDetail = ({ state, receiptDetail, getReceiptDetail }) => {
  const location = useLocation()
  const history = useHistory()

  const pageInfo = location.state?.pageInfo ?? 1
  const searchInfo = location.state?.searchInfo ?? ''
  const filteringInfo = location.state?.filteringInfo ?? ''
  const startInfo = location.state?.startInfo ?? ''
  const endInfo = location.state?.endInfo ?? ''
  const prevInfo = location.state?.prevInfo ?? ''

  const [menu, setMenu] = useState(0)
  const [renderData, setRenderData] = useState(null)
  const projectId = window.location.pathname.split('/')[2]

  useEffect(() => {
    getReceiptDetail(projectId)
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_RECEIPT_DETAIL':
        setRenderData(receiptDetail)
        break
      default:
        break
    }
  }, [state])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ backgroundColor: 'white' }}>
          {/* <ProjectQuantityBreadcrumb
            title={`< 뒤로가기`}
            pageInfo={pageInfo}
            searchInfo={searchInfo}
            filteringInfo={filteringInfo}
            startInfo={startInfo}
            endInfo={endInfo}
            prevInfo={prevInfo}
          /> */}

          <Nav
            tabs
            className="nav-tabs-custom nav-justified"
            style={{
              marginBottom: 50
            }}
          >
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classNames({
                  active: menu === 0
                })}
                onClick={() => setMenu(0)}
              >
                <span className="d-block d-sm-none">
                  <i className="bx bx-calendar-event"></i>
                </span>
                <span className="d-none d-sm-block" style={{ fontSize: 15 }}>
                  미리보기
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classNames({
                  active: menu === 1
                })}
                onClick={() => setMenu(1)}
              >
                <span className="d-block d-sm-none">
                  <i className="bx bx-calendar-alt"></i>
                </span>
                <span className="d-none d-sm-block" style={{ fontSize: 15 }}>
                  상세정보
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          {renderData &&
            (menu === 0 ? <WorkDocumentPreview workDocumentId={projectId} /> : <ReceiptInfo />)}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  receiptDetail: state.appState.data.receiptDetail
})
const mapDispatchToProps = dispatch => ({
  getReceiptDetail: id => dispatch(getReceiptDetail(id))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReceiptDetail)
)
