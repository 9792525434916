import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import DAU from './DAU'
import WAU from './WAU'
import MAU from './MAU'

export default function NorthStarAuMain() {
  const [type, setType] = useState('WAU')
  const onClickButton = useCallback(type => {
    setType(type)
  }, [])

  return (
    <Wrapper>
      <h2>Doc AU</h2>
      <p>
        [Doc Active User] 1번이라도 문서를 조회, 생성, 수정, 복제, 공유한
        회원입니다.
      </p>
      <ButtonWrapper>
        <Button
          color={type === 'MAU' ? 'primary' : 'secondary'}
          active={type === 'MAU'}
          onClick={() => onClickButton('MAU')}
        >
          MAU
        </Button>
        <Button
          color={type === 'WAU' ? 'primary' : 'secondary'}
          active={type === 'WAU'}
          onClick={() => onClickButton('WAU')}
        >
          WAU
        </Button>
        <Button
          color={type === 'DAU' ? 'primary' : 'secondary'}
          active={type === 'DAU'}
          onClick={() => onClickButton('DAU')}
        >
          DAU
        </Button>
      </ButtonWrapper>
      <ChartWrapper>
        {type === 'DAU' ? (
          <DAU />
        ) : type === 'WAU' ? (
          <WAU />
        ) : type === 'MAU' ? (
          <MAU />
        ) : (
          <></>
        )}
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`

const ChartWrapper = styled.div``
