export const PROJECT_METHOD_PAYMENT = {
  CASH: 'Cash',
  CARD: 'Card'
}
export const PROJECT_TAX_INVOICE = {
  CLAIM: 'Claim',
  RECEIPT: 'Receipt',
  NULL: null
}

export const DATE_TYPE = {
  QUOTE_DATE: 'QuoteDate',
  CONSTRUCTION_DATE: 'ConstructionDate',
  DELEVERY_DATE: 'DeliveryDate',
  SHIPPING_DATE: 'ShippingDate',
  DIRECT: 'DirectInput',
  TRANSACTION_DATE: 'TransactionDate'
}

export const TAX_TYPE = {
  VAT_INCLUDED: 'VATIncluded',
  VAT_NOT_INCLUDED: 'VATNotIncluded',
  NO_VAT: 'NoVAT',
  WITH_HOLDING_TAX_INCLUDE: 'WithholdingTaxIncluded',
  WITH_HOLDING_TAX_NOT_INCLUDE: 'WithholdingTaxNotIncluded'
}

export const FORM_CODE = {
  KIM_WO_KEU: 'KIM_WO_KEU',
  CHOI_JIN_JOONG: 'CHOI_JIN_JOONG'
}

export const RATE_PLAN_TYPE = {
  Lite: '혼자서 일해요',
  Standard: '여러 사람과 함께 일해요'
}

export const PROJECT_TYPE = {
  Estimate: '견적서',
  TradingStatement: '거래명세서',
  Receipt: '영수증',
  Bill: '청구서',
  OrderSheet: '발주서',
}

export const PROJECT_URL = {
  Estimate: '/work-order-for-quantity-detail/',
  TradingStatement: '/trading-statement-detail/',
  Receipt: '/receipt-detail/',
  Bill: '/bill-detail/',
  OrderSheet: '/order-sheet-detail/',
}

export const CLIENT_TYPE = {
  Individual: { label: '개인', value: 'Individual' },
  Businessman: { label: '사업자', value: 'Businessman' },
  PublicInstitutions: { label: '공기관', value: 'PublicInstitutions' },
  Association: { label: '협단체', value: 'Association' }
}
