export const GET_PROFILE = 'reducers/feed/GET_PROFILE'

export const getProfile = () => ({
  type: GET_PROFILE
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
