export const GET_CASHRECEIPT_LIST = 'reducers/cashReceipt/GET_CASHRECEIPT_LIST'
export const GET_CASHRECEIPT_DETAIL = 'reducers/cashReceipt/GET_CASHRECEIPT_DETAIL'
export const SET_CASHRECEIPT_IS_BUSINESSMAN = 'reducers/cashReceipt/SET_CASHRECEIPT_IS_BUSINESSMAN'
export const GET_DOCUMENT_VIEW = 'reducers/cashReceipt/GET_DOCUMENT_VIEW'

export const getCashReceiptList = (
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
) => ({
  type: GET_CASHRECEIPT_LIST,
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
})

export const getCashReceiptDetail = id => ({
  type: GET_CASHRECEIPT_DETAIL,
  id
})

export const setCashReceiptIsBusinessman = (id, isBusinessman) => ({
  type: SET_CASHRECEIPT_IS_BUSINESSMAN,
  id,
  isBusinessman
})

export const getDocumentView = (id) => ({
  type: GET_DOCUMENT_VIEW,
  id
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
