import { Container, Form, Label, Row, Table } from "reactstrap";
import { useState } from "react";
import { useQuery } from "react-query";
import { getUserWorkDocumentListV3 } from "../../../../api/users";
import { Opcode } from "@payworkteam/server-product-types";
import moment from "moment";
import { PROJECT_TYPE, PROJECT_URL } from "../../../../global/projectInfo";
import Paginator from "../../../../components/Common/Paginator";
import { Link } from "react-router-dom";

interface Props {

}

/** 유저 상세 거래 문서 목록 */
export default function UserDetailWorkDocuments({
}: Props) {
	/** app status */
	const [page, setPage] = useState(1);
	const [amount, setAmount] = useState(10);
	const userId = window.location.pathname.split('/')[2];
	
	/** querys */
	/** 유저 거래문서 리스트 호출 */
	const {
		data: userWorkDocumentList,
	} = useQuery(["getUserWorkDocumentList", userId, page, amount], async () => {
		if (!userId) {
			return;
		}

		const {
			opcode,
			message,
			workDocuments,
			count
		} = await getUserWorkDocumentListV3(userId, {
			page: page - 1,
			amount,
		});

		/** exception */
		if (opcode !== Opcode.SUCCESS) {
			console.error("$$ getUserWorkDocumentListV3 error => ", message);
			return;
		}
		
		return {
			workDocuments,
			count
		}
	});
	
	return (
		<Container fluid>
			<Form>
				{/* title */}
				<Row className="mb-4 d-flex justify-content-between">
					<Label>
						<h3>
							전체 {userWorkDocumentList?.count || 0}건
						</h3>
					</Label>
				</Row>
			</Form>

			<Row>
				<Table class="table table-centered table-nowrap">
					<thead className="thead-light">
						<tr>
							<th className="text-center">제목</th>
							<th className="text-center">문서</th>
							<th className="text-center">고객명</th>
							<th className="text-center">금액</th>
							<th className="text-center">생성일</th>
						</tr>
					</thead>
					<tbody>
						{userWorkDocumentList?.workDocuments.map((workDocument, index) => {
							const {
								id,
								projectName,
								type,
								clientName,
								totalPrice,
								createdAt,
							} = workDocument;
							
							const detailLink = `${PROJECT_URL[type]}${id}`;
							
							return (
								<tr key={index}>
									<td className="text-center" style={{ color: "#4E62DC", cursor: "pointer" }}>
										<Link to={detailLink}>
											{projectName || "무제"}
										</Link>
									</td>
									<td className="text-center">{PROJECT_TYPE[type]}</td>
									<td className="text-center">{clientName}</td>
									<td className="text-center">{totalPrice}</td>
									<td className="text-center">{moment(createdAt).format("YYYY-MM-DD")}</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
			</Row>

			{/* pagination */}
			<Paginator
				currentPage={page}
				pageSize={amount}
				total={Math.ceil((userWorkDocumentList?.count || 0) / amount)}
				onChange={(page: number) => setPage(page)}
			/>
		</Container>
	)
}