import React, { useState, useEffect } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { connect } from 'react-redux'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import Select from 'react-select'
import { Link } from 'react-router-dom'

// redux
import { createUser } from '../../../reducers/users'

const categoryGroup = [
  {
    label: '테스트 계정 설정 ',
    options: [
      { label: 'O', value: 'isTestEnabled' },
      { label: 'X', value: 'isTestDisabled' }
    ]
  }
]

const UserRegister = ({ history, state, params, createUser }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    isTestAccount: false
  })

  const [status, setStatus] = useState({
    isError: false,
    errorMsg: '회원 등록 중 문제가 발생했습니다',
    color: 'danger'
  })

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_CREATE_USER':
        setFormData({
          username: '',
          email: '',
          phone: '',
          isTestAccount: false
        })
        setStatus({
          ...status,
          color: 'success',
          isError: true,
          errorMsg: '회원이 등록되었습니다'
        })
        break
      case 'FAILED_CREATE_USER':
        setStatus({
          ...status,
          color: 'danger',
          isError: true,
          errorMsg: params.message
        })
        break
      default:
        break
    }
  }, [state])

  const handleInputs = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleChange = options => {
    let newEmail = ''

    if (formData.email.includes('@') && options.value === 'isTestEnabled') {
      newEmail = formData.email.split('@')
      newEmail[1] = '@paywork.test.kr'
      newEmail = newEmail.join('')

      setFormData({
        ...formData,
        email: newEmail
      })
    }

    return setFormData(prevState => ({
      ...prevState,
      isTestAccount: options.value === 'isTestEnabled' ? true : false
    }))
  }

  const onKeyUpPhone = e => {
    e.persist()

    const keyCode = e.keyCode
    const inputValue = e.key

    if (keyCode === 46 || keyCode === 8) {
      onChangePhone(inputValue, 1)
    }
    if (keyCode >= 48 && keyCode <= 57) {
      onChangePhone(inputValue, 2)
    }
  }

  const onChangePhone = (input, checkNum) => {
    const { phone } = formData
    let newPhone = ''

    if (checkNum === 1) {
      if (phone.length > 0) newPhone = phone.replace(/.$/, '')
    }
    if (checkNum === 2) {
      if (phone.length === 0) newPhone = input
      if (phone.length === 1) newPhone = phone + input
      if (phone.length === 2) newPhone = phone + input + '-'
      if (phone.length > 2 && phone.length < 7) newPhone = phone + input
      if (phone.length === 7) newPhone = phone + input + '-'
      if (phone.length > 7 && phone.length < 13) newPhone = phone + input
    }

    setFormData({ ...formData, phone: newPhone })
  }

  const onSubmit = async event => {
    event.preventDefault()

    createUser(formData)

    // const result = await createUser(formData)

    // if (result.opcode === 0) {
    //   history.push('/users')
    // } else {
    //   return setError(result.message)
    // }
    // setFetching(false)
    // return setFormData({
    //   username: '',
    //   email: '',
    //   phone: ''
    // })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ height: '80vh' }}>
          <Link to={'/users'}>
            <Breadcrumbs title="< 회원 등록하기" breadcrumbItem="Add Product" />
          </Link>

          <Alert
            color={status.color}
            isOpen={status.isError}
            toggle={() =>
              setStatus(prevState => ({
                ...prevState,
                isError: false
              }))
            }
          >
            {status.errorMsg}
          </Alert>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>회원명</Label>
                          <Input
                            required
                            name="username"
                            type="text"
                            className="form-control"
                            // placeholder={alphaKoreanPlaholder}
                            // pattern={alphaKoreanPattern}
                            placeholder="회원명을 입력해주세요"
                            value={formData.username}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>휴대폰 번호</Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="번호를 입력해주세요"
                            type="text"
                            maxLength="13"
                            onKeyUp={onKeyUpPhone}
                            value={formData.phone}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>이메일 </Label>
                          <Input
                            required
                            name="email"
                            type="email"
                            placeholder="이메일을 입력해주세요"
                            className="form-control"
                            value={formData.email}
                            onChange={handleInputs}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>테스트 계정</Label>
                          <Select
                            isMulti={false}
                            defaultValue={formData.isTestAccount}
                            onChange={handleChange}
                            options={categoryGroup}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block mr-1 waves-effect waves-light"
                        >
                          등록하기
                        </Button>
                      </Col>
                    </Row>
                    {/*<Button type="submit" color="secondary" className="waves-effect">Cancel</Button>*/}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data
})
const mapDispatchToProps = dispatch => ({
  createUser: newUser => dispatch(createUser(newUser))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserRegister)
