import React, { useState, useEffect, useCallback } from 'react'

// tools
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  Table,
  Label
} from 'reactstrap'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import styled from 'styled-components'

// components
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Indicator from '../../components/Common/Indicator'
import Paginator from '../../components/Common/Paginator'

// temp
import { notices } from '../../constant/noticeDummy'

const Notice = () => {
  const [status, setStatus] = useState({
    isLoading: false,
    isError: false,
    errorMsg: '공지사항 조회 중 문제가 발생했습니다.'
  })
  const [page, setPage] = useState(1)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [originData, setOriginData] = useState({ data: [], max: 0 })
  const [renderData, setRenderData] = useState({
    data: [],
    max: 0
  })
  const [selectedBoxes, setSelectedBoxes] = useState([])

  useEffect(() => {
    ;(async () => {
      setStatus({ ...status, isLoading: true })
      const noticeResponse = await notices

      if (noticeResponse) {
        const sortingUsers = noticeResponse
        const sliceUsers = sortingUsers.slice(0).slice(0, 10)

        setOriginData({ data: sortingUsers, max: sortingUsers.length })
        setRenderData({ data: sliceUsers, max: sortingUsers.length })
        setStatus({ ...status, isLoading: false })
      } else {
        setStatus({
          ...status,
          isLoading: false,
          isError: true
        })
      }
    })()
  }, [])

  useEffect(() => {
    setStatus({ ...status, isLoading: true })

    if (Array.isArray(originData.data)) {
      const sliceData = originData.data.slice(
        10 * (page - 1),
        10 * (page - 1) + 10
      )

      if (sliceData) {
        setRenderData({ ...renderData, data: sliceData })
      }
    }

    setStatus({ ...status, isLoading: false })
  }, [page])

  useEffect(() => {
    ;(function () {
      setStatus({ ...status, isLoading: true })

      if (Array.isArray(originData.data)) {
        const filterData = originData.data.filter(
          f =>
            f.title.includes(searchKeyword) ||
            f.writer.replace(/-/g, '').includes(searchKeyword.replace(/-/g, ''))
        )

        setPage(1)
        setRenderData({
          data: filterData.slice(0, 10),
          max: filterData.length
        })
      }
      setStatus({ ...status, isLoading: false })
    })()
  }, [searchKeyword])

  const handleSearch = e => {
    e.persist()
    const inputValue = e.target.value

    debounceInput(inputValue)
  }

  const debounceInput = useCallback(
    debounce(input => {
      setSearchKeyword(input)
    }, 750),
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col sm="6">
              <Breadcrumbs title="공지사항" breadcrumbItem="목록" />
            </Col>
            <Col sm="6">
              <Link to={'/notice-register'}>
                <div className="text-sm-right">
                  <Button
                    type="button"
                    color="primary"
                    className="btn waves-effect waves-light mb-2"
                  >
                    <i className="mdi"></i> 등록하기
                  </Button>
                </div>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            className="form-control"
                            placeholder="제목 또는 작성자"
                            onChange={handleSearch}
                            style={{ minWidth: '200px', fontSize: '16px' }}
                          />
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ fontSize: '18px' }}
                          ></i>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Alert
                    color="danger"
                    isOpen={status.isError}
                    toggle={() =>
                      setStatus(prevState => ({
                        ...prevState,
                        isError: false
                      }))
                    }
                  >
                    {status.errorMsg}
                  </Alert>

                  {status.isLoading ? (
                    <Indicator height={'30vh'} />
                  ) : (
                    <div
                      className="table-responsive"
                      style={{ minHeight: '922px' }}
                    >
                      <Table className="table-centered table-nowrap">
                        <thead className="thead-light">
                          <tr>
                            <th>
                              {/* <div className="custom-control custom-checkbox">
                                <Input type="checkbox" className="custom-control-input" />
                                <Label className="custom-control-label">&nbsp;</Label>
                              </div> */}
                            </th>
                            <th style={{ fontSize: '20px' }}>No.</th>
                            <th style={{ fontSize: '20px' }}>작성일자</th>
                            <th style={{ fontSize: '20px' }}>제목</th>
                            <th style={{ fontSize: '20px' }}>작성자</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(renderData.data) &&
                            renderData.data
                              .slice(0)
                              .reverse()
                              .map((user, idx) => (
                                <UsersTR
                                  key={user.id}
                                  delCheck={user.isDelete}
                                  style={{
                                    background: user.isTemp && 'lightgrey'
                                  }}
                                >
                                  <td style={{ height: '85px' }}>
                                    <div className="custom-control custom-checkbox">
                                      <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`_user_checkbox_${user.id}`}
                                        checked={selectedBoxes.includes(
                                          user.id
                                        )}
                                        onChange={e =>
                                          e.target.checked
                                            ? setSelectedBoxes([
                                                ...selectedBoxes,
                                                user.id
                                              ])
                                            : setSelectedBoxes(
                                                selectedBoxes.filter(
                                                  b => b !== user.id
                                                )
                                              )
                                        }
                                      />
                                      <Label
                                        className="custom-control-label"
                                        htmlFor={`_user_checkbox_${user.id}`}
                                      >
                                        &nbsp;
                                      </Label>
                                    </div>
                                  </td>
                                  <td style={{ fontSize: '18px' }}>
                                    <p className="mb-0">{user.id}</p>
                                  </td>
                                  <td style={{ fontSize: '18px' }}>
                                    <p className="mb-0">{user.createdAt}</p>
                                  </td>
                                  <td style={{ fontSize: '18px' }}>
                                    <Link to={`/notice-detail/${user.id}`}>
                                      {user.title}
                                    </Link>
                                  </td>
                                  <td style={{ fontSize: '18px' }}>
                                    {user.writer}
                                  </td>
                                </UsersTR>
                              ))}
                        </tbody>
                      </Table>
                    </div>
                    // <div
                    //   className="table-responsive"
                    //   style={{ minHeight: '922px' }}
                    // >
                    //   <Table className="table mb-0">
                    //     <thead className="thead-light">
                    //       <tr>
                    //         <th>No.</th>
                    //         <th>작성일자</th>
                    //         <th>제목</th>
                    //         <th>작성자</th>
                    //       </tr>
                    //     </thead>
                    //     <tbody>
                    //       <tr>
                    //         <th scope="row">4</th>
                    //         <td>2020.10.05</td>
                    //         <td>어민슬로프 사용가능합니다</td>
                    //         <td>서울보트</td>
                    //       </tr>
                    //       <tr>
                    //         <th scope="row">3</th>
                    //         <td>2020.10.02</td>
                    //         <td>
                    //           갑자기 해상에서 엔진시동이 안걸린다면 월요일
                    //           오픈합니다 론칭가능해요
                    //         </td>
                    //         <td>서울보트</td>
                    //       </tr>
                    //       <tr>
                    //         <th scope="row">2</th>
                    //         <td>2020.09.30</td>
                    //         <td>추석연휴 사무실 오픈 합니다</td>
                    //         <td>서울보트</td>
                    //       </tr>
                    //       <tr>
                    //         <th scope="row">1</th>
                    //         <td>2020.09.30</td>
                    //         <td>홈페이지 오픈 안내</td>
                    //         <td>서울보트</td>
                    //       </tr>
                    //     </tbody>
                    //   </Table>
                    // </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Notice

const UsersTR = styled.tr`
  text-decoration: ${props => props.delCheck && 'line-through'};
  height: 20px;
`
