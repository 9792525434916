import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { persistStore } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'

import appStateReducer from './appState'
import layoutReducer from './Layout/layout'

// auth
import accountReducer from './account'

// user
import usersReducer from './users'
import adminReducer from './admin'
import testerReducer from './tester'

// dashboard
import dashboardReducer from './dashboard'

// feed
import feedReducer from './feed'

import forgetReducer from './Auth/forgetpwd'
import loginReducer from './Auth/login'
import profileReducer from './Auth/profile'

import workOrderReducer from './workOrder'
import authReducer from './auth'

import ServerReducer from './server'

// event
import eventReducer from './event'

// TradingStatement
import TradingStatementReducer from './tradingStatement'

// receipt
import ReceiptReducer from './receipt'

// bill
import BillReducer from './bill'

import CashReceiptReducer from './cashReceipt'

import OrderSheetReducer from './orderSheet'

import TaxInvoiceReducer from './taxInvoice'

// import accountsReducer from './account_back'

// payment
import PaymentReducer from './payment'

import rootSaga from '../sagas'

// ratePlan
import RatePlanReducer from "./ratePlan";

const reducers = {
  appState: appStateReducer,
  Layout: layoutReducer,

  account: accountReducer,

  ForgetPassword: forgetReducer,
  Login: loginReducer,
  Profile: profileReducer,
  Dashboard: dashboardReducer,
  Admin: adminReducer,
  Tester: testerReducer,
  Feed: feedReducer,
  Users: usersReducer,
  WorkOrder: workOrderReducer,
  Auth: authReducer,
  Event: eventReducer,
  TradingStatement: TradingStatementReducer,
  Server: ServerReducer,
  Receipt: ReceiptReducer,
  Bill: BillReducer,
  CashReceipt: CashReceiptReducer,
  OrderSheet: OrderSheetReducer,
  TaxInvoice: TaxInvoiceReducer,
  Payment: PaymentReducer,
  RatePlan: RatePlanReducer

  // Accounts: accountsReducer
}

const reducer = combineReducers({
  ...reducers
})

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['appState'],
  blacklist: []
}

let middleware = []
let enhancers = []
let sagaMiddleware = createSagaMiddleware()
middleware.push(sagaMiddleware)
enhancers.push(applyMiddleware(...middleware))

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedReducer = persistReducer(persistConfig, reducer)
const store = createStore(
  persistedReducer,
  composeWithDevTools(compose(...enhancers))
)

// const store = createStore(
//   persistReducer(persistConfig, reducer),
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// )

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }

// export { store, reducers }
