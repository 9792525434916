import React, { Fragment } from 'react';

export default function Test() {
	return (
		<Fragment>
			<div className="page-content">
				tsx file test
			</div>
		</Fragment>
	)
}