import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// redux
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// component
import TaxInvoicePreview from './TaxInvoicePreview'

// tools
import { Nav, NavItem, NavLink, Container } from 'reactstrap'

// time
import classNames from 'classnames'
import { getTaxInvoiceDetail } from '../../../reducers/taxInvoice'

const TaxInvoiceDetail = ({ state, taxInvoiceDetail, getTaxInvoiceDetail }) => {
  const [menu, setMenu] = useState(0)
  const [renderData, setRenderData] = useState(null)
  const projectId = window.location.pathname.split('/')[2]

  useEffect(() => {
    getTaxInvoiceDetail(projectId)
  }, [projectId])
  
  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_TAXINVOICE_DETAIL':
        setRenderData(taxInvoiceDetail)
        break;
      
      case 'FAILED_GET_TAXINVOICE_DETAIL':
        break;
        
      default:
        break;
    }
  }, [state])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ backgroundColor: 'white' }}>
          {/* <ProjectQuantityBreadcrumb
            title={`< 뒤로가기`}
            pageInfo={pageInfo}
            searchInfo={searchInfo}
            filteringInfo={filteringInfo}
            startInfo={startInfo}
            endInfo={endInfo}
            prevInfo={prevInfo}
          /> */}

          <Nav
            tabs
            className="nav-tabs-custom nav-justified"
            style={{
              marginBottom: 50
            }}
          >
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classNames({
                  active: menu === 0
                })}
                onClick={() => setMenu(0)}
              >
                <span className="d-block d-sm-none">
                  <i className="bx bx-calendar-event"></i>
                </span>
                <span className="d-none d-sm-block" style={{ fontSize: 15 }}>
                  미리보기
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          {renderData && (<TaxInvoicePreview />)}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  taxInvoiceDetail: state.appState.data.taxInvoiceDetail
})

const mapDispatchToProps = dispatch => ({
  getTaxInvoiceDetail: id => dispatch(getTaxInvoiceDetail(id))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaxInvoiceDetail)
)
