export const GET_WORK_ORDER_LIST = 'reducers/workOrder/GET_WORK_ORDER_LIST'
export const GET_WORK_ORDER_DETAIL = 'reducers/workOrder/GET_WORK_ORDER_DETAIL'
export const SET_CLIENT_IS_BUSINESSMAN =
  'reducers/workOrder/SET_CLIENT_IS_BUSINESSMAN'
export const GET_WORK_DOCUMENT_HTML =
  'reducers/workOrder/GET_WORK_DOCUMENT_HTML'

export const getWorkOrderList = (
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
) => ({
  type: GET_WORK_ORDER_LIST,
  page,
  amount,
  search,
  clientType,
  startMoney,
  endMoney,
  startDate,
  endDate
})

export const getWorkOrderDetail = (id, isPrint) => ({
  type: GET_WORK_ORDER_DETAIL,
  id,
  isPrint
})

export const setClientIsBusinessman = (id, isBusinessman) => ({
  type: SET_CLIENT_IS_BUSINESSMAN,
  id,
  isBusinessman
})

export const getWorkDocumentHtml = data => ({
  type: GET_WORK_DOCUMENT_HTML,
  data
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
