import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { backoffice } from '../../../../global/constants'
import CommonPie from '../../../../components/Common/Pie'

export default function UserPatternBusinessRateDashboard() {
  const [data, setData] = useState(null)
  const callAPI = useCallback(async () => {
    try {
      const url = `${backoffice.GET_BUSINESSMAN_PERCENT_DASHBOARD}`
      const res = await axios.get(url)
      setData(res.data)
    } catch (e) {
      throw e
    }
  }, [])

  const labels = ['개인', '사업자']

  useEffect(() => {
    callAPI()
  }, [])

  return (
    <Wrapper>
      <p>가입자 비율</p>
      {data && (
        <CommonPie
          type={'donut'}
          labels={labels}
          data={[data.nonBusinessmanPercent, data.businessmanPercent]}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
