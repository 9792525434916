import { HTMLAttributes, useState } from "react";
import { useQuery } from "react-query";
import { Container, Form, Label, Row, Table } from "reactstrap";
import { Opcode } from "@payworkteam/server-product-types";
import styled from "styled-components";
import Paginator from "../../../../components/Common/Paginator";
import moment from "moment";
import { getUserBusinessmanList } from "../../../../api/users";

interface Props {

}

/** 유저 상세 사업자 목록 */
export default function UserDetailBusinessmans({
	
}: Props) {
	/** app states */
	const [page, setPage] = useState(1);
	const [amount, setAmount] = useState(10);
	const userId = window.location.pathname.split('/')[2];
	
	/** querys */
	/** 유저 사업자 리스트 호출 */
	const {
		data: userPopbills,
	} = useQuery(["getUserBusinessmanList", page, amount, userId], async () => {
		if (!userId) {
			return;
		}

		const {
			opcode,
			message,
			businessmans,
			count
		} = await getUserBusinessmanList(userId, {
			page: page - 1,
			amount,
		});

		/** exception */
		if (opcode !== Opcode.SUCCESS) {
			console.error("$$ getBusinessmans error => ", message);
			return;
		}

		return {
			businessmans,
			count
		}
	});
	
	return (
		<Container fluid>
			<Form>
				{/* title */}
				<Row className="mb-4 d-flex justify-content-between">
					<Label>
						<h3>
							전체 {userPopbills?.count || 0}건
						</h3>
					</Label>
				</Row>
			</Form>

			<Row>
				<Table class="table table-centered table-nowrap">
					<thead className="thead-light">
						<tr>
							<ListHeader>사업장등록번호</ListHeader>
							<ListHeader>사업장명</ListHeader>
							<ListHeader>대표자명</ListHeader>
							<ListHeader>업종</ListHeader>
							<ListHeader>업태</ListHeader>
							<ListHeader>등록일</ListHeader>
						</tr>
					</thead>
					<tbody>
						{userPopbills?.businessmans.map((businessmans, index) => {
							const {
								companyRegistrationNumber,
								companyName,
								representative,
								sectors,
								businessCondition,
								createdAt,
								popbillId
							} = businessmans;
							
							const detailLink = `/business-detail/${popbillId}`;
							
							return (
								<tr key={index}>
									{popbillId ? (
										<ListData style={{ color: "#4E62DC", cursor: "pointer" }} onClick={() => {
											window.location.href = detailLink;
										}}>
											{companyRegistrationNumber || "없음"}
										</ListData>
									): (
										<ListData>{companyRegistrationNumber || "없음"}</ListData>
									)}
									<ListData>{companyName}</ListData>
									<ListData>{representative}</ListData>
									<ListData>{sectors}</ListData>
									<ListData>{businessCondition}</ListData>
									<ListData>{moment(createdAt).format("YYYY-MM-DD")}</ListData>
								</tr>
							)
						})}
					</tbody>
				</Table>
			</Row>

			{/* pagination */}
			<Paginator
				currentPage={page}
				pageSize={amount}
				total={Math.ceil((userPopbills?.count || 0) / amount)}
				onChange={(page: number) => setPage(page)}
			/>
		</Container>
	)
}


/** 테이블 리스트 헤더 */
const ListHeader = styled.th`
	text-align: center;
	vertical-align: middle;
`;

/** 테이블 리스트 데이터 */
const ListData = styled.td<HTMLAttributes<HTMLTableCellElement>>`
	text-align: center;
	max-width: 40px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;