import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logoutUser } from '../../reducers/Auth/login'
import { logout } from '../../reducers/account'

const Logout = props => {
  useEffect(() => {
    // props.logoutUser(props.history);
    props.logout()
    props.history.push('/signin')
  })

  return <></>
}

export default withRouter(connect(null, { logoutUser, logout })(Logout))
