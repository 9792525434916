const publicStyle = {
	position: "block",
	width: "30px", /* 사이즈 */
	height: "30px", /* 사이즈 */
	"border-top": "5px solid #000", /* 선 두께 */
	"border-right": "5px solid #000", /* 선 두께 */
	opacity: "0.4", /* 투명도 */
}

/** 캐러셀 다음 화살표 */
export const SilderNextArrow = (props) => {
	const { className, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...publicStyle,
				transform: "rotate(45deg)", /* 각도 */
			}}
			onClick={onClick}
		>
			<style>
				{`
					.slick-next:before {
						display: none;
					}
				`}
			</style>
		</div>
	)
};
  
/** 캐러셀 이전 화살표 */
export const SilderPrevArrow = (props) => {
	const { className, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...publicStyle,
				transform: "rotate(225deg)", /* 각도 */
			}}
			onClick={onClick}
		>
			<style>
				{`
					.slick-prev:before {
						display: none;
					}
				`}
			</style>
		</div>
	)
};