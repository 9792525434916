export const GET_SWAGGER_LIST = 'reducers/server/GET_SWAGGER_LIST'

export const getSwaggerList = () => ({
  type: GET_SWAGGER_LIST,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
