import { Col, Row } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import React from 'react'

const FeedBreadcrumb = ({
  title,
  idInfo,
  pageInfo,
  lastPageInfo,
  searchInfo,
  isFeedDetail,
  filteringInfo
}) => {
  const history = useHistory()

  return (
    <Row>
      <Col xs="12">
        <div
          className="page-title-box d-flex align-items-center justify-content-between"
          style={{ cursor: 'pointer' }}
        >
          {isFeedDetail ? (
            <div
              onClick={() => {
                history.push({
                  pathname: '/feeds',
                  state: {
                    feedId: idInfo,
                    feedPage: pageInfo,
                    feedLastPage: lastPageInfo,
                    feedSearch: searchInfo,
                    feedCheck: isFeedDetail ? true : false,
                    feedFiltering: filteringInfo
                  }
                })
              }}
            >
              <h2 className="mb-0 font-size-22">{title}</h2>
            </div>
          ) : (
            <div onClick={() => history.goBack()}>
              <h2 className="mb-0 font-size-22">{title}</h2>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default FeedBreadcrumb
