import React, { useEffect, useState } from 'react'

// tools
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// api
import { getDashboard } from '../../reducers/dashboard'
import { login } from '../../reducers/account'
import { initialize } from '../../reducers/appState'

// images
import profile from '../../assets/images/logo/logo_img_b.png'

const Login = ({
  history,
  appState,
  state,
  params,
  getDashboard,
  login,
  initialize
}) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })
  const { email, password } = formData
  const [status, setStatus] = useState({
    color: 'danger',
    isError: false,
    errorMsg: ''
  })

  const onChange = e => {
    setStatus({ ...status, isError: false })
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    switch (state) {
      case "SUCCESS_LOGIN": {
        window.location.href = "/dashboard";
        break;
      }
      
      case "FAILED_LOGIN": {
        setStatus({ ...status, isError: true, errorMsg: params.message });
        break;
      }
    }
  }, [state])

  useEffect(() => {
    initialize()
  }, [])

  const onSubmit = () => {
    login(email, password)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">로그인</h5>
                        <p>페이워크 관리자 로그인 페이지</p>
                      </div>
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}
                      >
                        <img
                          src={profile}
                          width="150"
                          height="50"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Alert
                      color={status.color}
                      isOpen={status.isError}
                      toggle={() => setStatus({ ...status, isError: false })}
                    >
                      {status.errorMsg}
                    </Alert>

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={onSubmit}
                    >
                      <div className="form-group">
                        <AvField
                          name="email"
                          value={email}
                          className="form-control"
                          placeholder="이메일 주소를 입력해주세요"
                          type="email"
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          value={password}
                          type="password"
                          required
                          placeholder="비밀번호를 입력해주세요"
                          onChange={onChange}
                        />
                      </div>

                      <div className="custom-checkbox">
                        {/* <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          자동 저장
                        </label> */}
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          로그인
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i> 비밀번호 변경
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} 페이워크 어드민</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  error: state.Login.error,
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params
})

const mapDispatchToProps = dispatch => ({
  getDashboard: () => dispatch(getDashboard()),
  login: (email, password) => dispatch(login(email, password)),
  initialize: () => dispatch(initialize())
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
    // { loginUser, apiError, getDashboard }
  )(Login)
)
