import React, { useEffect, useState } from 'react'

// tool
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Input,
  Row,
  Table
} from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

// component
import Breadcrumbs from '../../components/Common/Breadcrumb'
import PaginatorProject from '../../components/Common/PaginatorProject'

// utils
import { Badge_Type } from '../../constant/utils'

// temp
import { voucherData } from '../../constant/dummy'

const VoucherList = () => {
  const history = useHistory()
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    errorMsg: '바우처 고객 조회 중 문제가 발생했습니다.'
  })
  const [isToggle, setIsToggle] = useState(false)
  const [start, setStart] = useState(1)
  const [end, setEnd] = useState(11)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [filtering, setFiltering] = useState('all')
  
  const [filterTitle, setFilterTitle] = useState('전체')
  const [voucherListData, setVoucherListData] = useState([])

  const [breadcrumbTitle, setBreadcrumbTitle] = useState('')

  // 필터, 쿼리 변경시 리스트 업데이트
  useEffect(() => {
    let filterData = voucherData.filter(
      f => 
      f.username.includes(search) 
      || f.phone.replace(/-/g, '').includes(search)
    )

    if (filtering === 'agree') {
      filterData = filterData.filter(f => f.isAgree)
    } else if (filtering === 'disagree') {
      filterData = filterData.filter(f => !f.isAgree)
    }

    setVoucherListData(filterData)
  }, [filtering, search])

  // 차트 제목 hook
  useEffect(() =>{
    setBreadcrumbTitle(`비대면바우처 ${filterTitle} (${voucherListData? voucherListData.length.toLocaleString() : '-'}명)`)
  }, [filterTitle, voucherListData])

  const handlePagination = (page, pageSize) => {
    setPage(page)
  }

  const handleSearch = search => {
    setSearch(search)
  }

  const handlerFilter = input => {
      if (input === 0) {
        setFiltering('all')
        setFilterTitle('전체')
      }
      if (input === 1) {
        setFiltering('agree')
        setFilterTitle('동의')
      }
      if (input === 2) {
        setFiltering('disagree')
        setFilterTitle('비동의')
      }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={breadcrumbTitle}
            breadcrumbItem="Voucher"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            className="form-control"
                            placeholder="이름/전화번호"
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>

                    <Col sm="8">
                      <div className="text-sm-right">
                        <Dropdown
                          isOpen={isToggle}
                          toggle={() => setIsToggle(!isToggle)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-light"
                          >
                            <i className="mdi mdi-filter mr-1" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu dropdown-menu-right">
                            <DropdownItem
                              href=""
                              onClick={() => handlerFilter(1)}
                            >
                              동의
                            </DropdownItem>
                            <DropdownItem
                              href=""
                              onClick={() => handlerFilter(2)}
                            >
                              비동의
                            </DropdownItem>
                            <DropdownItem
                              href=""
                              onClick={() => handlerFilter(0)}
                            >
                              전체
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>

                  <Alert
                    color="danger"
                    isOpen={status.isError}
                    toggle={() =>
                      setStatus(prevState => ({
                        ...prevState,
                        isError: false
                      }))
                    }
                  >
                    {status.errorMsg}
                  </Alert>

                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: '10%' }}>
                            이름
                          </th>
                          <th scope="col" style={{ width: '20%' }}>
                            전화번호
                          </th>
                          <th scope="col" style={{ width: '55%' }}>
                            내용
                          </th>
                          <th scope="col" style={{ width: '5%' }}>
                            동의
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            동의완료
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {voucherListData
                        .slice((page - 1) * 10 , (page-1) * 10 + 10)
                        .map((user, idx) => (
                            <UsersTR key={user.id}>
                              <td
                                onClick={() => {
                                  history.push({
                                    pathname: `/user-detail/${user.id}`,
                                    // state: {
                                    //   idInfo: user.id,
                                    //   pageInfo: fetchOptions.page,
                                    //   lastPageInfo: Math.ceil(
                                    //     usersData.max / 10
                                    //   ),
                                    //   searchInfo: fetchOptions.search,
                                    //   isUserDetail: true,
                                    //   filteringInfo: fetchOptions.filtering,
                                    //   prevPage: 'voucher'
                                    // }
                                  })
                                }}
                                style={{
                                  color: '#556EE6',
                                  cursor: 'pointer',
                                  height: '65px'
                                }}
                              >
                                {user.username}
                              </td>
                              <td>
                                <p className="mb-0">
                                  {user.phone ? user.phone : ''}
                                </p>
                              </td>
                              <td>
                                <p className="mb-0">
                                  {user.isAgree && user.agreeContent}
                                </p>
                              </td>
                              <td>
                                <span
                                  className={`badge ${
                                    Badge_Type[user.isAgree ? 1 : 2]
                                  } badge-pill`}
                                >
                                  {user.isAgree ? 'O' : 'X'}
                                </span>
                              </td>
                              <td>
                                <p className="mb-0">
                                  {user.isAgree && user.agreeAt}
                                </p>
                              </td>
                              {/* <td>
                                {user.signupAt && user.signupAt.slice(0, 10)}
                              </td> */}
                            </UsersTR>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <PaginatorProject
                    currentPage={page}
                    pageSize={10}
                    total={Math.ceil(voucherData.length / 10)}
                    onChange={handlePagination}
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VoucherList

const UsersTR = styled.tr`
  text-decoration: ${props => props.delCheck && 'line-through'};
  height: 20px;
`
