import React from 'react'
import { Table } from 'reactstrap'

export default function CommonTable({ datas, colHeaders, rowNames }) {
  const colCount = datas.length
  const rowCount = rowNames ? rowNames.length : datas[0].length

  const renderRows = () => {
    const countArr = Array.from({ length: rowCount }, (_, i) => i)
    return countArr.map((i, index) => renderRow(index))
  }

  const renderRow = rowIndex => (
    <tr key={rowIndex}>
      {renderRowName(rowIndex)}
      {renderRowDatas(rowIndex)}
    </tr>
  )
  const renderRowName = rowIndex =>
    rowNames ? (
      <th key={rowIndex}>{rowNames[rowIndex]}</th>
    ) : (
      <th key={rowIndex}>{rowIndex + 1}</th>
    )

  const renderRowDatas = rowIndex => {
    const a = datas.map(i => i[rowIndex])
    return a.map((j, jIndex) => <td key={jIndex}>{j}</td>)
  }

  return (
    <Table hover>
      <thead>
        <tr>
          <th>#</th>
          {colHeaders ? (
            colHeaders.map((i, index) => <th key={index}>{i}</th>)
          ) : (
            <th>data</th>
          )}
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </Table>
  )
}

// 예시 데이터 형태
// #    |  WAU로그인   |  WAU문서생성
// 1주차 |   100      |  1000
// 2주차 |   1500     |  3000
// ...  |   ...      |  ...

// colHeaders: ['WAU로그인', 'WAU문서생성' ] 없을시 data만
// rowNames: ['1주차', ....'52주차'] 없을 시 숫자로
//  datas: [
// [100, 1500, ...], 항목1 //WAU 로그인
// [1000, 3000, ...], 항목2 //WAU 문서생성
// ]
