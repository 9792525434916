import { IPostDocumentPaymentViewSettlementStatementReq, Opcode, ViewOutputSizeType, ViewOutputType } from "@payworkteam/server-product-types";
import { instance } from "../baseInstance";
import { AxiosError } from "axios";

/** 전체 정산내역서 리스트 */
export const getDocumentPaymentSettlementStatementsV3 = async (params: {
	page: number;
	amount: number;
	type?: string;
	search?: string;
  }) => {
	  const data: {
	  opcode: Opcode;
	  message: string;
	  settlementStatements: Array<{
		userId: string;
		username: string;
		email: string;
		date: string;
		count: number;
		clientName?: string;
			  clientCompanyName?: string;
			  clientCompanyRegistrationNumber?: string;
			  amt: number;
			  depositAmt: number;
			  nicePayFeeSupplyAmount: number;
			  nicePayFeeVat: number;
			  nicePayFeeTotal: number;
			  payworkFeeSupplyAmount: number;
			  payworkFeeVat: number;
			  payworkFeeTotal: number;
	  }>;
	  count: number;
	  totalDepositAmt: number;
	} = await instance.get(`/documentPayment/settlementStatements`, {
		params
	  });
	
	  return data;
  }

/** 정산내역서 출력 */
export const getDocumentPaymentViewSettlementStatementV3 = async (payload: IPostDocumentPaymentViewSettlementStatementReq, params: {
	output?: ViewOutputType;
	outputSize?: ViewOutputSizeType;
	viewPortScale?: string | null;
}) => {
	const data: string | AxiosError = await instance.post(`/documentPayment/view/settlementStatement`, payload, {
	  params
	});
  
	return data;
}