import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import MetisMenu from 'metismenujs'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getSwaggerList } from '../../reducers/server'

const SidebarContent = props => {
  useEffect(() => {
    var pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu('#side-menu')
      var matchingMenuItem = null
      var ul = document.getElementById('side-menu')
      var items = ul.getElementsByTagName('a')

      for (var i = 0; i < items.length; ++i) {
        if (items[i].pathname === pathName) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show')

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add('mm-active')
          }
        }
      }
      return false
    }
    return false
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{'페이워크 관리자'} </li>

          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="mdi mdi-monitor-dashboard"></i>
              <span>{'대시보드'}</span>
            </Link>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-face"></i>
              <span>{'유저 관리'}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/users">{'전체'}</Link>
              </li>
              <li>
                <Link to="/users-rate-plan">{'요금제 관련 유저 목록'}</Link>
              </li>
              <li>
                <Link to="/voucher">{'비대면바우처'}</Link>
              </li>
              <li>
                <Link to="/B2B">{'B2B'}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-briefcase"></i>
              <span>{'사업자 관리'}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/business">{'사업자 목록'}</Link>
              </li>
              <li>
                <Link to="/businessApproval">{'승인 요청'}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-calendar-month"></i>
              <span>{'문서 관리'}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/work-order-for-quantity">{'견적서'}</Link>
              </li>
              <li>
                <Link to="/trading-statement">{'거래명세서'}</Link>
              </li>
              <li>
                <Link to="/receipt">{'영수증'}</Link>
              </li>
              <li>
                <Link to="/bill">{'청구서'}</Link>
              </li>
              <li>
                <Link to="/orderSheet">{'발주서'}</Link>
              </li>
              <li>
                <Link to="/cashReceipt">{'현금영수증'}</Link>
              </li>
              <li>
                <Link to="/taxInvoice">{'세금계산서'}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-alert-circle"></i>
              <span>{'운영 기능'}</span>
            </Link>

            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/notice">{'공지사항'}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-cash-usd"></i>
              <span>{'정산 관리'}</span>
            </Link>

            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/documentPayment">{'결제내역'}</Link>
              </li>
              <li>
                <Link to="/settlementStatements">{'월별 정산내역서'}</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  swaggerList: state.appState.data.swaggerList
})
const mapDispatchToProps = dispatch => ({
  getSwaggerList: () => dispatch(getSwaggerList())
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarContent)
)
