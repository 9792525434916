import { useState, useCallback, useEffect, SelectHTMLAttributes, OptionHTMLAttributes } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import * as dateFns from 'date-fns';
import ReactApexChart from 'react-apexcharts';
import { backoffice } from '../../../../../global/constants';
import CommonTable from '../../../../../components/Common/Table';
import { defaultOptions } from '../../../../../helpers/chart';
import moment from 'moment';
import { months, years } from '../../../../../constant/chart';

interface Props {
  dateType: string;
}

const apiUrl = `${backoffice.GET_DASHBOARD_RATEPLANSUBSCRIPTIONSTATUS}`;

/** 요금제 라벨 */
const ratePlanLabels = ['전체 구독 수', '초급 요금제 구독 수', '중급 요금제 구독 수'];

export default function PlanGraphMonthly({
  dateType
}: Props) {
  const [year, setYear] = useState(moment().year().toString());
  const [month, setMonth] = useState((moment().month() + 1).toString());
  const [week, setWeek] = useState('1');
  const [date, setDate] = useState('1');
  const [openTable, setOpenTable] = useState(false);

  const onChangeYear = useCallback(e => {
    setYear(e.target.value)
  }, []);

  const onChangeMonth = useCallback(e => {
    setMonth(e.target.value)
  }, []);

  const [data, setData] = useState<Array<{
    date: string;
    standardCount: number;
    advancedCount: number;
  }>>([]);

  useEffect(() => {
    callAPI()
  }, [year, month, week, date, dateType]);

  const callAPI = async () => {
    try {
      if (dateType === 'year' && !year && !month) {
        alert('년,월 입력 오류')
        return
      } else if (dateType === 'month' && !year && !month && !date) {
        alert('년,월,일 입력 오류')
        return
      } else if (dateType === 'week' && !year && !month) {
        alert('년,월 입력 오류')
        return
      }

      const res = await axios.get(apiUrl, {
        params: {
          type: dateType,
          year,
          month,
        }
      });
      
      setData(res?.data?.dashBoard || []);
    } catch (e) {
      console.log("PlanGraphMonthly error => ", e);
      throw e;
    }
  }

  /** functions */
  /** 대시보드 그래프 렌더링 */
  const seriesRender = () => {
    if (!data || data.length === 0) {
      return [];
    }
    
    /** line이 앞에있으면 에러터지고 난리남 */
    return [
      {
        name: ratePlanLabels[1],
        type: 'bar',
        data: data?.map(i => {
          return {
            x: i?.date,
            y: i?.standardCount || 0
          }
        })
      },
      {
        name: ratePlanLabels[2],
        type: 'bar',
        data: data?.map(i => {
          return {
            x: i?.date,
            y: i?.advancedCount || 0
          }
        })
      },
      {
        name: ratePlanLabels[0],
        type: 'line',
        data: data?.map((i) => {
          return {
            x: i?.date,
            y: i?.standardCount + i?.advancedCount || 0
          }
        })
      },
    ];
  }

  return (
    <Wrapper>
      <DateWrapper>
        <p>날짜</p>
        <Select value={year} onChange={onChangeYear}>
          {years.map(i => (
            <Option key={i} value={i}>
              {i}년
            </Option>
          ))}
        </Select>

        {/* 일별일때 활성화 */}
        {dateType === 'month' && (
            <Select value={month} onChange={onChangeMonth}>
              {months.map(i => (
                <Option key={i} value={i}>
                  {i}월
                </Option>
              ))}
            </Select>
        )}
      </DateWrapper>

      <ChartWrapper>
        {data && (
          <>
            <ReactApexChart
              key={'retention-monthly'}
              height={300}
              options={{
                ...defaultOptions,
                plotOptions: {
                  bar: {
                    // horizontal: true
                  }
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                yaxis: [
                  {
                    show: false,
                    seriesName: 'Bar Series'
                  },
                  {
                    show: false,
                    opposite: true,
                    seriesName: 'Line Series'
                  },
                ],
              }}
              series={seriesRender()}
            />
          </>
        )}
      </ChartWrapper>

      <TableWrapper>
          <TableToggleButtonWrapper>
            <TableToggleButton onClick={() => setOpenTable(openTable ? false : true)}>
              {openTable ? '테이블 닫기' : '테이블 열기'}
            </TableToggleButton>
          </TableToggleButtonWrapper>
          {openTable && (
            <CommonTable
              datas={[
                data?.map((i) => i.standardCount + i.advancedCount),
                data?.map((i) => i.standardCount),
                data?.map((i) => i.advancedCount),
              ]}
              colHeaders={ratePlanLabels}
              rowNames={data.map(j => `${j.date}`)}
            />
          )}
      </TableWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 420px;
`

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  & > p {
    margin-bottom: 0;
  }
`

const Select = styled.select<SelectHTMLAttributes<HTMLSelectElement>>`
  margin: 0 10px;
`

const Option = styled.option<OptionHTMLAttributes<HTMLOptionElement>>``

const ChartWrapper = styled.div``

const TableWrapper = styled.div``
const TableToggleButtonWrapper = styled.div`
  text-align: right;
`;

const TableToggleButton = styled.button<OptionHTMLAttributes<HTMLButtonElement>>``;