const colorSchemas = {
  mainG: '#28272f',
  mainB: '#346DFF',

  mainSubG: '#3B3A41',

  G1: '#535259',
  G2: '#68686D',
  G3: '#949397',
  G4: '#BFBEC1',
  G5: '#D4D4D5',
  G6: '#E9E9EA',
  G7: '#F3F3F3',
  G8: '#F9F9F9',
  B1: '#1F50FF',
  B2: '#346DFF',
  B3: '#4D7FFF',
  B4: '#699CFF',
  B5: '#85B6FF',
  B6: '#9BC3FF',
  B7: '#BED8FF',
  B8: '#E5EFFF',
  B9: '#F3F8FF',
  Secondary1: '#FFB342',
  Secondary2: '#5E2EE9',
  Secondary3: '#FF476C',
  Secondary4: '#06F07F',
  Secondary5: '#0085FF',

  //legacy
  PRIMARY: 'rgb(35, 35, 80)',
  paleGrey: 'rgb(240, 240, 245)',
  veryLightPink: 'rgb(204, 204, 204)',
  purple: '#4b50be',
  darkBlue: '#282882',
  skyBlueLight: '#e1ebfa',
  cloud: '#cdd7f0',
  semiBlue: '#7887d2',
  mint: '#13cba2',
  yellow: '#ffbe4b',
  red: '#ff5849',
  pink: '#dc96fa',
  black: 'rgb(17, 17, 17)',

  gray900: 'rgb(34, 34, 34)',
  gray800: 'rgb(51, 51, 51)',
  gray700: 'rgb(102, 102, 102)',
  gray600: 'rgb(133, 133, 133)',
  gray500: 'rgb(170, 170, 170)',
  gray400: 'rgb(204, 204, 204)',
  gray300: 'rgb(230, 230, 230)',
  gray200: 'rgb(242, 242, 242)',
  gray100: 'rgb(248, 248, 248)',
  white: 'rgb(255, 255, 255)',

  successBlue: 'rgb(125, 215, 205)',
  successBlueDark: 'rgb(59,189,174)',
  pendingYellow: '#f9c00c',
  pendingYellowDark: '#e4b725',
  notiRed: 'rgb(255, 87, 87)',
  notiRedDark: 'rgb(255, 87, 87)',

  profileBlue: '#024873',
  profileGreen: '#0FA697',
  profileYellow: '#F2C53D',
  profileBrown: '#F2C53D',
  profilePink: '#F25270',
  profileRed: '#D92B2B',
  profilePurple: '#51548C',
  profileOrange: '#F28705',
  profileSky: '#1EB7D9',
  profileGray: '#6D7BA6',
  profileMint: '#00B992',
  profilePurple2: 'rgb(97, 103, 239)',
  profilePink2: 'rgb(231, 132, 131)',
  profileGreen2: 'rgb(91, 180, 159)'
}

export default colorSchemas
