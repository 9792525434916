import { Opcode } from '@payworkteam/server-product-types';
import apiClient from '.'
import { instance } from './baseInstance';
import { AttachmentModel, PopBillModel, UserRequestBusinessmanModel } from '@payworkteam/server-product-entity';

const businessmanPath = `/businessman`;
const businessmanModifyRequestPath = `${businessmanPath}/request/modify`;

/** 사업장 목록 (팝빌) */
export const getBusinessmans = async (params: {
  page?: number;
  amount?: number;
  search?: string;
  certFilter?: string;
}) => {
  const data: {
    opcode: Opcode;
    message: string;
    popbills: Array<PopBillModel>;
    count: number;
  } = await instance.get(`${businessmanPath}/list`, {
    params
  });
  
  return data;
}

/** 사업장 수정 */
export const patchBusinessman = async ({
  popbillId,
  body
}: {
  popbillId: string;
  body: {
    companyName: string;
    representative: string;
    companyAddress: string;
    sectors: string;
    businessCondition: string;
  }
}) => {
  try {
    const url = `${businessmanPath}/modify/${popbillId}`;
    const { data } = await apiClient().patch(url, body);

    return data
  } catch (error: any) {
    console.error(error);
    return error.message;
  }
}

/**
 * 사업자 수정 요청 리스트
 * @param {*} options
 * @returns
 */
export const getBusinessmanRequestList = async (
  options: {
    page: number;
    amount: number;
    search: string;
    requestType: string;
    state: string;
  } = {
    page: 0,
    amount: 10,
    search: '',
    requestType: 'Modify',
    state: 'Waiting'
  }
) => {
  try {
    const { data } = await apiClient().get(`${businessmanModifyRequestPath}/list`, {
      params: options
    })

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

/** 사업장 수정 요청 상세 */
export const getBusinessmanRequestDetail = async (userRequestBusinessmanId: string) => {
  const data: {
    opcode: Opcode;
    message: string;
    userRequestBusinessman: UserRequestBusinessmanModel;
    businessman: {
      companyRegistrationNumber: string;
			companyName: string;
			representative: string;
			companyAddress: string;
			sectors: string;
			businessCondition: string;
			isBlindCompanyAddress: boolean;
			isExistence: boolean;
      businessRegistrationAttachmentId: string;
      businessRegistrationAttachment: AttachmentModel;
    }
  } = await instance.get(`${businessmanModifyRequestPath}/get/${userRequestBusinessmanId}`);

  return data;
}

/**
 * 사업자 수정 요청 반려
 * @param {*} param0
 * @returns
 */
export const patchBusinessmanRequestModifyReject = async (userRequestBusinessmanId: string) => {
  try {
    const { data } = await apiClient().patch(
      `${businessmanModifyRequestPath}/reject/${userRequestBusinessmanId}`
    )

    return data
  } catch (error: any) {
    console.error(error)
    return error.message
  }
}

/** 사업장 수정 요청 컨펌 */
export const postBusinessmanRequestModifyConfirm = async ({
  userRequestBusinessmanId,
  companyName,
  representative,
  companyAddress,
  sectors,
  businessCondition
}: {
  userRequestBusinessmanId: string;
  companyName: string;
  representative: string;
  companyAddress: string;
  sectors: string;
  businessCondition: string;
}) => {
  try {
    const { data } = await apiClient().post(
      `${businessmanModifyRequestPath}/confirm/${userRequestBusinessmanId}`,
      {
        companyName,
        representative,
        companyAddress,
        sectors,
        businessCondition
      }
    )

    return data
  } catch (error: any) {
    console.error(error)
    return error.message;
  }
}