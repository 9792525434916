import * as bankImage from '../assets/images/banks'

export default [
  {
    name: '국민은행',
    code: '004',
    image: bankImage.kb,
    color: '#554E44'
  },
  {
    name: '기업은행',
    code: '003',
    image: bankImage.ibk,
    color: '#04438F'
  },
  {
    name: 'NH농협',
    code: '011',
    image: bankImage.nh,
    color: '#239C49'
  },
  {
    name: '신한은행',
    code: '088',
    image: bankImage.shinhan,
    color: '#033C80'
  },
  {
    name: '한국씨티은행',
    code: '027',
    image: bankImage.citi,
    color: '#004187'
  },
  {
    name: 'SC은행',
    code: '023',
    image: bankImage.sc,
    color: '#2C3A87'
  },
  {
    name: '카카오뱅크',
    code: '090',
    image: bankImage.kakao,
    color: '#FEB42D'
  },
  {
    name: '우리은행',
    code: '020',
    image: bankImage.woori,
    color: '#0067AC'
  },
  {
    name: '하나은행',
    code: '081',
    image: bankImage.hana,
    color: '#008C8C'
  },
  {
    name: '경남은행',
    code: '039',
    image: bankImage.bnk,
    color: '#D01C23'
  },
  {
    name: '광주은행',
    code: '034',
    image: bankImage.gwangju,
    color: '#193181'
  },
  {
    name: '전북은행',
    code: '037',
    image: bankImage.gwangju,
    color: '#193181'
  },
  {
    name: '대구은행',
    code: '031',
    image: bankImage.daegu,
    color: '#004282'
  },
  {
    name: '부산은행',
    code: '032',
    image: bankImage.bnk,
    color: '#D01C23'
  },
  {
    name: '수협은행',
    code: '007',
    image: bankImage.suhup,
    color: '#033C80'
  },
  {
    name: 'KDB산업은행',
    code: '002',
    image: bankImage.kdb,
    color: '#012F72'
  },
  {
    name: '우체국',
    code: '071',
    image: bankImage.postoffice,
    color: '#E72410'
  },
  {
    name: '제주은행',
    code: '035',
    image: bankImage.shinhan,
    color: '#033C80'
  },
  {
    name: '새마을금고',
    code: '045',
    image: bankImage.saemaeul,
    color: '#0071BC'
  },
  {
    name: '신협',
    code: '048',
    image: bankImage.shinhup,
    color: '#C3C4C4'
  },
  {
    name: '미래에셋대우',
    code: '0238',
    image: bankImage.miraeasset,
    color: '#F58220'
  },
  {
    name: 'SBI저축은행',
    code: '103',
    image: bankImage.sbi,
    color: '#005EA1'
  },
  {
    name: '케이뱅크',
    code: '089',
    image: bankImage.k,
    color: '#005EA1'
  },
  {
    name: '토스뱅크',
    code: '092',
    image: bankImage.toss,
    color: '#005EA1'
  }
]
