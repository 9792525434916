import { put, call, takeLatest } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import { GET_SWAGGER_LIST } from '../reducers/server'

export function* getSwaggerList() {
  yield put(setAppState('START_GET_SWAGGER_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_SWAGGER_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.SWAGGER_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('swaggerList', response.data.serverVersions))
      yield put(setAppState('SUCCESS_GET_SWAGGER_LIStT', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_SWAGGER_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_SWAGGER_LIST', e, false))
  }
}

const serverSaga = [takeLatest(GET_SWAGGER_LIST, getSwaggerList)]

export default serverSaga
