import React from 'react'
import ReactApexChart from 'react-apexcharts'
import styled from 'styled-components'

export default function CommonPie({ type, labels, data }) {
  // type : 'pie' ,'donut'
  const options = {
    labels: labels,
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
      offsetY: -10
    },
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      }
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240
          },
          legend: {
            show: true,
            formatter: (val, opts) => val
          }
        }
      }
    ]
  }

  return (
    <Wrapper>
      {data && (
        <ReactApexChart
          options={options}
          series={data}
          type={type ? type : 'pie'}
          height="380"
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
