import { all, put, call, takeLatest } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import {
  CREATE_USER,
  GET_USER_DETAIL,
  GET_ALL_USER_LIST,
  GET_USER_LIST,
  GET_REVISIT_USER_LIST,
  REMOVE_USER,
  COMPLETE_REMOVE_USER,
  TEST_USER_LIST,
  TOGGLE_TEST_USER,
  UPDATE_USER,
  GET_USER_PAY_APP_DETAIL,
  SET_ADMIN,
  SET_NICKNAME,
  SET_PROFILE_IMAGE,
  GET_CERTIFICATION,
  GET_USER_PAYMENT_HISTORY,
  SET_USER_PAYMENT,
  UPDATE_USER_PAYMENT,
  REMOVE_USER_PAYMENT,
  SET_ADMIN_CATEGORY,
  GET_ADMIN_CATEGORY_GROUP_BY,
  REMOVE_RATE_PLAN_USER,
  SET_RATE_PLAN_USER,
  GET_RATE_PLAN_LIST,
  SET_USER_PASSWORD,
  SET_USER_KAKAOCREDITREWARD,
  SET_USER_EMAILREWARD,
  SET_USER_TAXINVOICEREWARD
} from '../reducers/users'

// voucher
import { tempUsers } from '../constant/dummy'
import { sortingUsers } from '../constant/utils'

export function* getUserList(actions) {
  const { page, amount, search, filtering } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_USER_LIST', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_USER_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url:
        filtering === 'real' ? `${constants.backoffice.USER_LIST}/realAccount`
        : filtering === 'tester' ? `${constants.backoffice.USER_LIST}/testAccount`
        : filtering === 'admin' ? `${constants.backoffice.USER_LIST}/adminAccount`
        : filtering === 'workOrderForQuantityCount' ? `${constants.backoffice.USER_LIST}/workOrderForQuantity/created/most`
        : filtering === 'isService' ? `${constants.backoffice.USER_LIST}/setServicePush`
        : filtering === 'isMarketing' ? `${constants.backoffice.USER_LIST}/setMarketingPush`
        : `${constants.backoffice.USER_LIST}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: page - 1,
        amount: amount,
        search: search
        // isHistory: true // 이용했던 사람 // false: 현재 이용
      }
    })

    console.log("response => ", response)
    if (response.data.opcode === 0) {
      if (filtering === 'workOrderForQuantityCount') {
        yield put(saveAppData('totalCount', response.data.count))
      } else if (filtering === 'isService' || filtering === 'isMarketing') {
        yield put(saveAppData('totalCount', response.data.totalCount))
      } else {
        yield put(saveAppData('totalCount', response.data.totalCount))
      }
      yield put(saveAppData('userList', response.data.users))

      // yield put(saveAppData('userList', response.data))
      yield put(setAppState('SUCCESS_GET_USER_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_USER_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_USER_LIST', e))
  }
}

export function* getRevisitUserList(actions) {
  const { options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_REVISIT_USER_LIST', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_REVISIT_USER_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.REVISIT_DAY_USER_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: 1,
        amount: 99999,
        search: '',
        getTime: options.getTime
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('revisitList', response.data.userRevisits))

      yield put(
        setAppState('SUCCESS_GET_REVISIT_USER_LIST', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_REVISIT_USER_LIST', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_REVISIT_USER_LIST', e))
  }
}

export function* getAllUserList() {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_ALL_USER_LIST', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ALL_USER_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.USER_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: 0,
        amount: 99999,
        search: ''
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('allUserList', response.data.users))
      yield put(setAppState('SUCCESS_GET_ALL_USER_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_ALL_USER_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ALL_USER_LIST', e))
  }
}

export function* getUserDetail(actions) {
  const { id } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_USER_DETAIL', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_USER_DETAIL', null, false))
  }
  saveAppData('userDetail', [])

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_USER_DETAIL}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
      // params: { id }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('userDetail', response.data))
      yield put(setAppState('SUCCESS_GET_USER_DETAIL', response.data))
    } else {
      yield put(setAppState('FAILED_GET_USER_DETAIL', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_USER_DETAIL', e))
  }
}

export function* createUser(actions) {
  const { newUser } = actions
  yield put(setAppState('START_GET_CREATE_USER', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_CREATE_USER', null, false))
  }

  const { username, email, phone, isTestAccount } = newUser

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: constants.backoffice.CREATE_USER,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        username,
        email,
        phone,
        isTestAccount
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_CREATE_USER', response.data, false))
    } else {
      yield put(setAppState('FAILED_CREATE_USER', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_CREATE_USER', e, false))
  }
}

export function* removeUser(actions) {
  const { ids } = actions
  yield put(setAppState('START_GET_REMOVE_USER', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_REMOVE_USER', null, false))
  }

  try {
    const response = yield all(
      ids.map(id => {
        return call(axios, {
          method: 'GET',
          validateStatus: () => true,
          url: `${constants.backoffice.TOGGLE_USER}/${id}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
      })
    )

    for (let i = 0; i < response.length; i++) {
      if (response[i].data.opcode !== 0) {
        yield put(setAppState('FAILED_REMOVE_USER', null, false))
      }
    }
    yield put(setAppState('SUCCESS_REMOVE_USER', response, false))
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_REMOVE_USER', e, false))
  }
}

export function* completeRemoveUser(actions) {
  const { ids } = actions
  yield put(setAppState('START_COMPLETE_REMOVE_USER', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_COMPLETE_REMOVE_USER', null, false))
  }

  try {
    const response = yield all(
      ids.map(id => {
        return call(axios, {
          method: 'DELETE',
          validateStatus: () => true,
          url: `${constants.backoffice.COMPLETE_REMOVE_USER}/${id}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
      })
    )

    for (let i = 0; i < response.length; i++) {
      if (response[i].data.opcode !== 0) {
        yield put(setAppState('FAILED_COMPLETE_REMOVE_USER', null, false))
      }
    }
    yield put(setAppState('SUCCESS_COMPLETE_REMOVE_USER', response, false))
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_COMPLETE_REMOVE_USER', e, false))
  }
}

export function* updateUser(actions) {
  const { id, updateInfo } = actions
  yield put(setAppState('START_SUCCESS_UPDATE_USER', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_SUCCESS_UPDATE_USER', null, false))
  }

  const { username, email, phone } = updateInfo

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.UPDATE_USER}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      // params: { id },
      data: {
        username,
        email,
        phone
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_UPDATE_USER', response.data))
    } else {
      yield put(setAppState('FAILED_UPDATE_USER', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_UPDATE_USER', e))
  }
}

export function* testUserList(actions) {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.TEST_USER_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_TEST_USER_LIST', response.data))
    } else {
      yield put(setAppState('FAILED_TEST_USER_LIST', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_TEST_USER_LIST', e))
  }
}

export function* toggleTestUser(actions) {
  const { id } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.TOGGLE_TEST_USER}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_TOGGLE_TEST_USER', response.data))
    } else {
      yield put(setAppState('FAILED_TOGGLE_TEST_USER', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_TOGGLE_TEST_USER', e))
  }
}

export function* getUserPayAppDetail(actions) {
  const { id } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_USER_PAY_APP_DETAIL', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_USER_PAY_APP_DETAIL', null, false))
  }

  saveAppData('userPayApp', [])

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_USER_PAY_APP_DETAIL}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
      // params: { id }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('userPayApp', response.data.payApp))
      yield put(setAppState('SUCCESS_GET_USER_PAY_APP_DETAIL', response.data))
    } else {
      yield put(setAppState('FAILED_GET_USER_PAY_APP_DETAIL', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_USER_PAY_APP_DETAIL', e))
  }
}

export function* setAdmin(actions) {
  const { id } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.SET_ADMIN}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_ADMIN', response.data, false))
    } else {
      yield put(setAppState('FAILED_SET_ADMIN', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_ADMIN', e, false))
  }
}

export function* setNickname(actions) {
  const { id } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_NICK_NAME', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_NICK_NAME', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.SET_NICKNAME}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_NICKNAME', response.data, false))
    } else {
      yield put(setAppState('FAILED_SET_NICKNAME', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_NICKNAME', e, false))
  }
}

export function* setProfileImage(actions) {
  const { targetId, image } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_PROFILE_IMAGE', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_PROFILE_IMAGE', null, false))
  }

  const formData = new FormData()
  formData.append('image', image)

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.SET_PROFILE_IMAGE}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        targetUserId: targetId
      },
      data: formData
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_PROFILE_IMAGE', null, false))
    } else {
      yield put(setAppState('FAILED_PROFILE_IMAGE', null, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_PROFILE_IMAGE', e, false))
  }
}

export function* getCertification(actions) {
  const { phone } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_CERTIFICATION', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_CERTIFICATION', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.SET_CERTIFICATION}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: 0,
        amount: 99999,
        search: ''
      }
      // data: {
      //   phone
      // }
    })

    if (response.data.opcode === 0) {
      let targetId = ''

      for (let i = 0; i < response.data.verifys.length; i++) {
        if (response.data.verifys[i].phone === phone) {
          targetId = response.data.verifys[i].id
          break
        }
      }

      if (response.data.verifys.length > 0) {
        const deleteResponse = yield call(
          setCertification,
          // response.data.verifys[0].id
          { id: targetId }
        )

        if (deleteResponse.data.opcode === 0) {
          yield put(
            setAppState('SUCCESS_SET_CERTIFICATION', response.data, false)
          )
        }
      } else {
        yield put(setAppState('FAILED_SET_CERTIFICATION', response.data, false))
      }
    } else {
      yield put(setAppState('FAILED_SET_CERTIFICATION', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_CERTIFICATION', e, false))
  }
}

function* setCertification(actions) {
  const { id } = actions

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  // try {
  const response = yield call(axios, {
    method: 'POST',
    url: `${constants.backoffice.SET_CERTIFICATION}/clear`,
    validateStatus: () => true,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      phone: id
    }
  })

  return response

  //   if (response.data.opcode === 0) {
  //     yield put(setAppState('SUCCESS_SET_CERTIFICATION', response.data, false))
  //   } else {
  //     yield put(setAppState('FAILED_SET_CERTIFICATION', response.data, false))
  //   }
  // } catch (e) {
  //   console.log(e)
  //   yield put(setAppState('FAILED_SET_CERTIFICATION', e, false))
  // }
}

export function* getUserPaymentHistory(actions) {
  const { id, options } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_USER_PAYMENT_HISTORY', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_USER_PAYMENT_HISTORY', null, false))
  }

  saveAppData('paymentHistory', [])

  // temp
  let checker = false
  for (let i = 0; i < tempUsers.length; i++) {
    if (tempUsers[i].id === id) {
      checker = true
    }
  }

  if (checker) {
    return yield put(
      setAppState('FAILED_GET_USER_PAYMENT_HISTORY', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.GET_USER_PAYMENT_HISTORY}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        page: options.page - 1,
        amount: options.amount,
        search: options.search
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('paymentHistory', response.data))
      yield put(
        setAppState('SUCCESS_GET_USER_PAYMENT_HISTORY', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_USER_PAYMENT_HISTORY', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_USER_PAYMENT_HISTORY', e))
  }
}

export function* setUserPayment(actions) {
  const { id, paymentData } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_USER_PAYMENT', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_USER_PAYMENT', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.SET_USER_PAYMENT}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        type: paymentData.type,
        category: paymentData.category,
        paymentPrice: paymentData.paymentPrice,
        rechargePrice: paymentData.rechargePrice,
        paymentedAt: paymentData.paymentedAt
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_USER_PAYMENT', response.data, false))
    } else {
      yield put(setAppState('FAILED_SET_USER_PAYMENT', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_USER_PAYMENT', e, false))
  }
}

export function* updateUserPayment(actions) {
  const { id, paymentData } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_UPDATE_USER_PAYMENT', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_UPDATE_USER_PAYMENT', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.UPDATE_USER_PAYMENT}/${id}/${paymentData.targetId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        type: paymentData.type,
        category: paymentData.category,
        paymentPrice: paymentData.paymentPrice,
        rechargePrice: paymentData.rechargePrice,
        paymentedAt: paymentData.paymentedAt
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState('SUCCESS_UPDATE_USER_PAYMENT', response.data, false)
      )
    } else {
      yield put(setAppState('FAILED_UPDATE_USER_PAYMENT', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_UPDATE_USER_PAYMENT', e, false))
  }
}

export function* removeUserPayment(actions) {
  const { id, paymentIds } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_REMOVE_USER_PAYMENT', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_REMOVE_USER_PAYMENT', null, false))
  }

  try {
    const response = yield all(
      paymentIds.map(paymentId => {
        return call(axios, {
          method: 'DELETE',
          validateStatus: () => true,
          url: `${constants.backoffice.UPDATE_USER_PAYMENT}/${id}/${paymentId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
      })
    )

    for (let i = 0; i < response.length; i++) {
      if (response[i].data.opcode !== 0) {
        yield put(setAppState('FAILED_REMOVE_USER_PAYMENT', null, false))
      }
    }
    yield put(setAppState('SUCCESS_REMOVE_USER_PAYMENT', response, false))
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_REMOVE_USER_PAYMENT', e, false))
  }
}

export function* setAdminCategory(actions) {
  const { id, category } = actions
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_ADMIN_CATEGORY', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_ADMIN_CATEGORY', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.ADMIN_CATEGORY}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        category: category
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_ADMIN_CATEGORY', response.data, false))
    } else {
      yield put(setAppState('FAILED_SET_ADMIN_CATEGORY', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_ADMIN_CATEGORY', e, false))
  }
}

export function* getAdminCategoryGroupBy(actions) {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_ADMIN_CATEGORY_GROUP_BY', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_ADMIN_CATEGORY_GROUP_BY', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.ADMIN_CATEGORY}/groupby`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('adminCategory', response.data.raw))
      yield put(
        setAppState('SUCCESS_GET_ADMIN_CATEGORY_GROUP_BY', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_ADMIN_CATEGORY_GROUP_BY', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_ADMIN_CATEGORY_GROUP_BY', e, false))
  }
}

export function* removeRatePlanUser(actions) {
  const { userId } = actions

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_REMOVE_RATE_PLAN_USER', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_REMOVE_RATE_PLAN_USER', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'DELETE',
      url: `${constants.backoffice.RATE_PLAN_USER}/${userId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState('SUCCESS_REMOVE_RATE_PLAN_USER', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_REMOVE_RATE_PLAN_USER', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_REMOVE_RATE_PLAN_USER', e, false))
  }
}

export function* setRatePlanUser(actions) {
  const { userId, ratePlanId, date } = actions

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_RATE_PLAN_USER', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_RATE_PLAN_USER', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.RATE_PLAN_USER}/${userId}/${ratePlanId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        expiryDate: date
      }
    })

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_RATE_PLAN_USER', response.data, false))
    }
    
    else {
      yield put(setAppData('errorInfo', response.data))
      yield put(setAppState('FAILED_SET_RATE_PLAN_USER', response.data, false))
    }
  } catch (e) {
    console.log("FAILED_SET_RATE_PLAN_USER =>", e)
    yield put(setAppState('FAILED_SET_RATE_PLAN_USER', e, false))
  }
}

export function* getRatePlanList() {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_GET_RATE_PLAN_LIST', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_RATE_PLAN_LIST', null, false))
  }
  
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.ratePlanUrl}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      let ratePlanObj = { lite: '', standard: '' }
      ratePlanObj.lite = response.data.ratePlans.filter(
        f => f.type === 'Lite'
      )[0].id
      ratePlanObj.standard = response.data.ratePlans.filter(
        f => f.type === 'Standard'
      )[0].id
      
      yield put(saveAppData('ratePlanList', ratePlanObj))
      yield put(setAppState('SUCCESS_GET_RATE_PLAN_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_RATE_PLAN_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_RATE_PLAN_LIST', e, false))
  }
}

/** 유저 패스워드 변경 */
export function* setUserPassword(props) {
  const {
    userId,
    password
  } = props;

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_USER_PASSWORD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_USER_PASSWORD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.SET_USER_PASSWORD}/${userId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        password
      }
    });

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_USER_PASSWORD', response.data, false))
      alert("비밀번호가 변경되었습니다.")
      
      return;
    } else {
      yield put(setAppState('FAILED_SET_USER_PASSWORD', response.data, false))
    }
  } catch(err) {
    console.log(err);
    yield put(setAppState('FAILED_SET_USER_PASSWORD', err, false))
  }

  alert("비밀번호 변경 중 문제가 발생했습니다.")
}

/** 유저 카카오톡 리워드 반영 */
export function* setUserKakaoCreditReward(props) {
  const {
    userId,
    coin
  } = props;

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_USER_KAKAOCREDITREWARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_USER_KAKAOCREDITREWARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'PATCH',
      url: `${constants.backoffice.SET_USER_KAKAOCREDITREWARD}/${userId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        coin
      }
    });

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_USER_KAKAOCREDITREWARD', response.data, false))
      alert("카카오톡 리워드가 변경되었습니다.")
      
      return;
    } else {
      yield put(setAppState('FAILED_SET_USER_KAKAOCREDITREWARD', response.data, false))
    }
  } catch(err) {
    console.log(err);
    yield put(setAppState('FAILED_SET_USER_KAKAOCREDITREWARD', err, false))
  }

  alert("카카오톡 리워드 변경 중 문제가 발생했습니다.")
}

/** 유저 이메일 리워드 반영 */
export function* setUserEmailReward(props) {
  const {
    userId,
    coin
  } = props;

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_USER_EMAILREWARD', null, true))

  if (!accessToken) {
    yield put(setAppState('FAILED_SET_USER_EMAILREWARD', null, false))
  }
  
  try {
    const response = yield call(axios, {
      method: 'PATCH',
      url: `${constants.backoffice.SET_USER_EMAILREWARD}/${userId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        coin
      }
    });

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_USER_EMAILREWARD', response.data, false))
      alert("이메일 리워드가 변경되었습니다.")
      
      return;
    } else {
      yield put(setAppState('FAILED_SET_USER_EMAILREWARD', response.data, false))
    }
  } catch(err) {
    console.log(err);
    yield put(setAppState('FAILED_SET_USER_EMAILREWARD', err, false))
  }

  alert("이메일 리워드 변경 중 문제가 발생했습니다.");
}

/** 유저 세금계산서 리워드 반영 */
export function* setUserTaxInvoiceReward(props) {
  const {
    userId,
    coin
  } = props;

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  yield put(setAppState('START_SET_USER_TAXINVOICEREWARD', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_USER_TAXINVOICEREWARD', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'PATCH',
      url: `${constants.backoffice.SET_USER_TAXINVOICEREWARD}/${userId}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        coin
      }
    });

    if (response.data.opcode === 0) {
      yield put(setAppState('SUCCESS_SET_USER_TAXINVOICEREWARD', response.data, false))
      alert("세금계산서 리워드가 변경되었습니다.")
      
      return;
    } else {
      yield put(setAppState('FAILED_SET_USER_TAXINVOICEREWARD', response.data, false))
    }
  } catch(err) {
    console.log(err);
    yield put(setAppState('FAILED_SET_USER_TAXINVOICEREWARD', err, false))
  }

  alert("세금계산서 리워드 변경 중 문제가 발생했습니다.")
}

const usersSagas = [
  takeLatest(GET_USER_LIST, getUserList),
  takeLatest(GET_REVISIT_USER_LIST, getRevisitUserList),
  takeLatest(GET_ALL_USER_LIST, getAllUserList),
  takeLatest(GET_USER_DETAIL, getUserDetail),
  takeLatest(CREATE_USER, createUser),
  takeLatest(REMOVE_USER, removeUser),
  takeLatest(COMPLETE_REMOVE_USER, completeRemoveUser),
  takeLatest(UPDATE_USER, updateUser),
  takeLatest(TEST_USER_LIST, testUserList),
  takeLatest(TOGGLE_TEST_USER, toggleTestUser),
  takeLatest(GET_USER_PAY_APP_DETAIL, getUserPayAppDetail),
  takeLatest(SET_ADMIN, setAdmin),
  takeLatest(SET_NICKNAME, setNickname),
  takeLatest(SET_PROFILE_IMAGE, setProfileImage),
  takeLatest(GET_CERTIFICATION, getCertification),
  takeLatest(GET_USER_PAYMENT_HISTORY, getUserPaymentHistory),
  takeLatest(SET_USER_PAYMENT, setUserPayment),
  takeLatest(UPDATE_USER_PAYMENT, updateUserPayment),
  takeLatest(REMOVE_USER_PAYMENT, removeUserPayment),
  takeLatest(SET_ADMIN_CATEGORY, setAdminCategory),
  takeLatest(GET_ADMIN_CATEGORY_GROUP_BY, getAdminCategoryGroupBy),
  takeLatest(REMOVE_RATE_PLAN_USER, removeRatePlanUser),
  takeLatest(SET_RATE_PLAN_USER, setRatePlanUser),
  takeLatest(GET_RATE_PLAN_LIST, getRatePlanList),
  takeLatest(SET_USER_PASSWORD, setUserPassword),
  takeLatest(SET_USER_KAKAOCREDITREWARD, setUserKakaoCreditReward),
  takeLatest(SET_USER_EMAILREWARD, setUserEmailReward),
  takeLatest(SET_USER_TAXINVOICEREWARD, setUserTaxInvoiceReward),
]

export default usersSagas
