import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import DLU from './DLU'
import WLU from './WLU'
import MLU from './MLU'

export default function NorthStarLu() {
  const [type, setType] = useState('WLU')
  const onClickButton = useCallback(type => {
    setType(type)
  }, [])

  return (
    <Wrapper>
      <h2>LU 로그인 회원</h2>
      <p>[Login User] 이번주에 접속한 회원입니다.</p>
      <ButtonWrapper>
        <Button
          color={type === 'MLU' ? 'primary' : 'secondary'}
          active={type === 'MLU'}
          onClick={() => onClickButton('MLU')}
        >
          MLU
        </Button>
        <Button
          color={type === 'WLU' ? 'primary' : 'secondary'}
          active={type === 'WLU'}
          onClick={() => onClickButton('WLU')}
        >
          WLU
        </Button>
        <Button
          color={type === 'DLU' ? 'primary' : 'secondary'}
          active={type === 'DLU'}
          onClick={() => onClickButton('DLU')}
        >
          DLU
        </Button>
      </ButtonWrapper>
      <ChartWrapper>
        {type === 'DLU' ? (
          <DLU />
        ) : type === 'WLU' ? (
          <WLU />
        ) : type === 'MLU' ? (
          <MLU />
        ) : (
          <></>
        )}
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`

const ChartWrapper = styled.div``
