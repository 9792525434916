import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import DocumentCountDashboard from './Count'
import DocumentGraphDashboard from './Graph'
import DocumentPatternDashboard from './Pattern'
import Slider, { Settings } from 'react-slick';
import { SilderNextArrow, SilderPrevArrow } from "../../../components/Common/SilderArrow";

/** 캐러셀 디자인 추가 */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Dispatch } from 'redux'

const DocumentDashboard = ({}) => {
    /** 캐러셀 옵션 */
    const SliderOption: Settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: "ondemand",
      nextArrow: <SilderNextArrow />,
      prevArrow: <SilderPrevArrow />
    };
  
  return (
    <Wrapper>
      <Slider {...SliderOption}>
        <DocumentGraphDashboard />
        <DocumentCountDashboard />
        <DocumentPatternDashboard />
      </Slider>
    </Wrapper>
  )
}

const mapStateToProps = (state: any) => ({
  appState: state,
  state: state.appState.state,
  params: state.appState.params,
  dashboard: state.appState.data.dashboard,
  paymentPercentDashboard: state.appState.data.paymentPercentDashboard,
  memberToPayCard: state.appState.data.memberToPayCard,
  memberToTrialCard: state.appState.data.memberToTrialCard,
  trialToPayCard: state.appState.data.trialToPayCard,
  documentShareCard: state.appState.data.documentShareCard,
  documentCreateCard: state.appState.data.documentCreateCard,
  weekActiveUserCard: state.appState.data.weekActiveUserCard,
  paymentPercentCard: state.appState.data.paymentPercentCard,
  userRatioGraph: state.appState.data.userRatioGraph,
  newUserToPayGraph: state.appState.data.newUserToPayGraph,
  trialToPayGraph: state.appState.data.trialToPayGraph,
  freeUserActiveRatioDashboard:
    state.appState.data.freeUserActiveRatioDashboard,
  paymentMethodRatioDashboard: state.appState.data.paymentMethodRatioDashboard,

  ///
  profile: state.appState.data.profile,
  graphDashboard: state.appState.data.graphDashboard,
  loginUserDashboard: state.appState.data.loginUserDashboard,
  workOrderQuantityShareDashboard:
    state.appState.data.workOrderQuantityShareDashboard,
  businessmanPercentDashboard: state.appState.data.businessmanPercentDashboard,
  platformLogin: state.appState.data.platformLogin,
  revisitListDashboard: state.appState.data.revisitListDashboard,
  retentionList: state.appState.data.retentionList,
  cohort: state.appState.data.cohort,
  retentionInfo: state.appState.data.retentionInfo,
  adminCategory: state.appState.data.adminCategory,
  clientIsBusinessmanData: state.appState.data.clientIsBusinessmanData,
  tradingStatementClientIsBusinessmanData:
    state.appState.data.tradingStatementClientIsBusinessmanData,
  avgWorkDocumentGraph: state.appState.data.avgWorkDocumentGraph,
  userAvgWorkDocumentGraph: state.appState.data.userAvgWorkDocumentGraph,
  workDocumentCreateUserCount: state.appState.data.workDocumentCreateUserCount,
  taxInvoiceRatioData: state.appState.data.taxInvoiceRatioData,
  salesDashboard: state.appState.data.salesDashboard,
  ratePlanPriceDashboard: state.appState.data.ratePlanPriceDashboard,
  activeUserWorkDocumentGraph: state.appState.data.activeUserWorkDocumentGraph,
  graphAccDashboard: state.appState.data.graphAccDashboard,
  graphAccUserDashboard: state.appState.data.graphAccUserDashboard
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // getDashboard: () => dispatch(getDashboard()),
  // getProfile: () => dispatch(getProfile()),
  // getGraphDashboard: options => dispatch(getGraphDashboard(options)),
  // getUserLoginDashboard: options => dispatch(getUserLoginDashboard(options)),
  // getWorkOrderQuantityShareDashboard: options =>
  //   dispatch(getWorkOrderQuantityShareDashboard(options)),
  // getBusinessmanPercentDashboard: () =>
  //   dispatch(getBusinessmanPercentDashboard()),
  // getPlatformLoginDashboard: options =>
  //   dispatch(getPlatformLoginDashboard(options)),
  // getUserRetention: (retentionType, year) =>
  //   dispatch(getUserRetention(retentionType, year)),
  // getUserCohort: (year, week) => dispatch(getUserCohort(year, week)),
  // getRetention: () => dispatch(getRetention()),
  // getAdminCategoryGroupBy: () => dispatch(getAdminCategoryGroupBy()),
  // getClientIsBusinessman: () => dispatch(getClientIsBusinessman()),
  // getTradingStatementClientIsBusinessman: () =>
  //   dispatch(getTradingStatementClientIsBusinessman()),
  // getPaymentPercentDashboard: () => dispatch(getPaymentPercentDashboard()),
  // getUserRatioDashboard: options => dispatch(getUserRatioDashboard(options)),
  // getNewUserToPayDashboard: options =>
  //   dispatch(getNewUserToPayDashboard(options)),
  // getTrialToPayDashboard: options => dispatch(getTrialToPayDashboard(options)),
  // getAvgWorkDocumentDashboard: options =>
  //   dispatch(getAvgWorkDocumentDashboard(options)),
  // getUserAvgWorkDocumentDashboard: options =>
  //   dispatch(getUserAvgWorkDocumentDashboard(options)),
  // getTaxInvoiceRatioDashboard: options =>
  //   dispatch(getTaxInvoiceRatioDashboard(options)),
  // getSalesDashboard: options => dispatch(getSalesDashboard(options)),
  // getRatePlanPriceDashboard: options =>
  //   dispatch(getRatePlanPriceDashboard(options)),
  // getActiveUserWorkDocumentDashboard: options =>
  //   dispatch(getActiveUserWorkDocumentDashboard(options)),
  // getAccGraphDashboard: options => dispatch(getAccGraphDashboard(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDashboard)

const Wrapper = styled.div`
  @media (min-width: 992px) {
    margin-left: 20px;
    margin-right: 20px;
    padding: 100px 20px 80px 20px;
  }
  margin-left: 20px;
  margin-right: 20px;
  padding: 100px 100px 80px 100px;
`
