export const GET_USER_LIST = 'reducers/users/GET_USER_LIST'
export const GET_ALL_USER_LIST = 'reducers/users/GET_ALL_USER_LIST'
export const GET_REVISIT_USER_LIST = 'reducers/users/GET_REVISIT_USER_LIST'
export const GET_USER_DETAIL = 'reducers/users/GET_USER_DETAIL'
export const CREATE_USER = 'reducers/users/CREATE_USER'
export const REMOVE_USER = 'reducers/users/REMOVE_USER'
export const COMPLETE_REMOVE_USER = 'reducers/users/COMPLETE_REMOVE_USER'
export const UPDATE_USER = 'reducers/users/UPDATE_USER'

export const TEST_USER_LIST = 'reducers/users/TEST_USER_LIST'
export const TOGGLE_TEST_USER = 'reducers/users/TOGGLE_TEST_USER'

export const GET_USER_PAY_APP_DETAIL = 'reducers/users/GET_USER_PAY_APP_DETAIL'
export const SET_ADMIN = 'reducers/users/SET_ADMIN'
export const SET_NICKNAME = 'reducers/users/SET_NICKNAME'
export const SET_PROFILE_IMAGE = 'reducers/users/SET_PROFILE_IMAGE'
export const GET_CERTIFICATION = 'reducers/users/GET_CERTIFICATION'

export const GET_USER_PAYMENT_HISTORY =
  'reducers/users/GET_USER_PAYMENT_HISTORY'
export const SET_USER_PAYMENT = 'reducers/users/SET_USER_PAYMENT'
export const UPDATE_USER_PAYMENT = 'reducers/users/UPDATE_USER_PAYMENT'
export const REMOVE_USER_PAYMENT = 'reducers/users/REMOVE_USER_PAYMENT'

export const SET_ADMIN_CATEGORY = 'reducers/users/SET_ADMIN_CATEGORY'
export const GET_ADMIN_CATEGORY_GROUP_BY =
  'reducers/user/GET_ADMIN_CATEGORY_GROUP_BY'

export const REMOVE_RATE_PLAN_USER = 'reducers/users/REMOVE_RATE_PLAN_USER'
export const SET_RATE_PLAN_USER = 'reducers/users/SET_RATE_PLAN_USER'
export const GET_RATE_PLAN_LIST = 'reducers/users/GET_RATE_PLAN_LIST'

export const SET_USER_PASSWORD = 'reducers/users/SET_USER_PASSWORD';

export const SET_USER_KAKAOCREDITREWARD = 'reducers/users/SET_USER_KAKAOCREDITREWARD';
export const SET_USER_EMAILREWARD = 'reducers/users/SET_USER_EMAILREWARD';
export const SET_USER_TAXINVOICEREWARD = 'reducers/users/SET_USER_TAXINVOICEREWARD';

export const getUserList = (page, amount, search, filtering) => ({
  type: GET_USER_LIST,
  page,
  amount,
  search,
  filtering
})

export const getAllUserList = () => ({
  type: GET_ALL_USER_LIST
})

export const getRevisitUserList = options => ({
  type: GET_REVISIT_USER_LIST,
  options
})

export const getUserDetail = id => ({
  type: GET_USER_DETAIL,
  id
})
export const createUser = newUser => ({
  type: CREATE_USER,
  newUser
})

export const removeUser = ids => ({
  type: REMOVE_USER,
  ids
})

export const completeRemoveUser = ids => ({
  type: COMPLETE_REMOVE_USER,
  ids
})

export const updateUser = (id, updateInfo) => ({
  type: UPDATE_USER,
  id,
  updateInfo
})

export const testUserList = () => ({
  type: TEST_USER_LIST
})

export const toggleTestUser = id => ({
  type: TOGGLE_TEST_USER,
  id
})

export const getUserPayAppDetail = id => ({
  type: GET_USER_PAY_APP_DETAIL,
  id
})

export const setAdmin = id => ({
  type: SET_ADMIN,
  id
})

export const setNickname = id => ({
  type: SET_NICKNAME,
  id
})

export const setProfileImage = (targetId, image) => ({
  type: SET_PROFILE_IMAGE,
  targetId,
  image
})

export const getCertification = phone => ({
  type: GET_CERTIFICATION,
  phone
})

export const getUserPaymentHistory = (
  id,
  options = { page: 1, amount: 10, search: '' }
) => ({
  type: GET_USER_PAYMENT_HISTORY,
  id,
  options
})

export const setUserPayment = (id, paymentData) => ({
  type: SET_USER_PAYMENT,
  id,
  paymentData
})

export const updateUserPayment = (id, paymentData) => ({
  type: UPDATE_USER_PAYMENT,
  id,
  paymentData
})

export const removeUserPayment = (id, paymentIds) => ({
  type: REMOVE_USER_PAYMENT,
  id,
  paymentIds
})

export const setAdminCategory = (id, category) => ({
  type: SET_ADMIN_CATEGORY,
  id,
  category
})

export const getAdminCategoryGroupBy = () => ({
  type: GET_ADMIN_CATEGORY_GROUP_BY
})

export const removeRatePlanUser = userId => ({
  type: REMOVE_RATE_PLAN_USER,
  userId
})

export const setRatePlanUser = (userId, ratePlanId, date) => ({
  type: SET_RATE_PLAN_USER,
  userId,
  ratePlanId,
  date
})

export const getRatePlanList = () => ({
  type: GET_RATE_PLAN_LIST
})

/** 패스워드 변경 */
export const setUserPassword = (props) => ({
  type: SET_USER_PASSWORD,
  ...props
})

/** 카카오톡 리워드 반영 */
export const setUserKakaoCreditReward = (props) => ({
  type: SET_USER_KAKAOCREDITREWARD,
  ...props
})

/** 이메일 리워드 반영 */
export const setUserEmailCreditReward = (props) => ({
  type: SET_USER_EMAILREWARD,
  ...props
})

/** 세금계산서 리워드 반영 */
export const setUserTaxInvoiceCreditReward = (props) => ({
  type: SET_USER_TAXINVOICEREWARD,
  ...props
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
