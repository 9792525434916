import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import * as dateFns from 'date-fns'

import { backoffice } from '../../../../global/constants'
import CommonNorthStarCard from '../../../../components/Common/Card'
// import UserCard from './User'

export default function Cards() {
  const [data, setData] = useState(null)
  const [transaction, setTransaction] = useState()
  const [apiTime, setApiTime] = useState('')
  const [conversion, setConversion] = useState()

  /** data view switch */
  const [dataView, setDataView] = useState(false);

  const callAPI = useCallback(async () => {
    try {
      const url = `${backoffice.DASHBOARD}`
      const res = await axios.get(url)
      setApiTime(dateFns.format(new Date(), 'yyyy-MM-dd HH:mm:ss'))
      setData(res.data)
    } catch (e) {
      throw e
    }
  }, [])

  const callConversionAPI = useCallback(async () => {
    try {
      const url = `${backoffice.DASHBOARD}/card/userDocumentCreationConversionRate`
      const res = await axios.get(`${url}`)
      setConversion({
        totalUser: res.data.totalUser,
        conversionUser: res.data.conversionUser,
        conversionRate: res.data.conversionRate
      })
    } catch (e) {
      throw e
    }
  }, [])

  const callbackFunction = () => {
    callAPI()
  }

  useEffect(() => {
    callAPI()
    callConversionAPI()
  }, [])

  return (
    <Wrapper>
      <h2>핵심 수치</h2>
      <TimeBlock>핵심수치 확인 시간: {apiTime}</TimeBlock>
      <p>카드 클릭하면 핵심수치 전체 새로고침(유저 / 문서별도)</p>
      
      <CardWrapper>
        {/* <UserCard /> */}
        <CommonNorthStarCard
          title={'누적 회원수'}
          data={
            data
              ? `
            ${data.total.totalUserCount.toLocaleString()}명
            `
              : '로딩중'
          }
          iconType={'human'}
          onClick={callbackFunction}
          cardIndex={1}
        />
        <CommonNorthStarCard
          title={'견적서'}
          data={
            data
              ? `
            ${data.workOrderForQuantity.totalCount.toLocaleString()}건
            `
              : '로딩중'
          }
          iconType={'document'}
          onClick={callbackFunction}
          cardIndex={2}
        />
        <CommonNorthStarCard
          title={'거래명세서'}
          data={
            data
              ? `
            ${data.tradingStatement.totalCount.toLocaleString()}건
            `
              : '로딩중'
          }
          iconType={'document'}
          onClick={callbackFunction}
          cardIndex={3}
        />
        <CommonNorthStarCard
          title={'영수증'}
          data={
            data
              ? `
            ${data.receipt.totalCount.toLocaleString()}건
            `
              : '로딩중'
          }
          iconType={'document'}
          onClick={callbackFunction}
          cardIndex={4}
        />
        <CommonNorthStarCard
          title={'청구서'}
          data={
            data
              ? `
            ${data.bill.totalCount.toLocaleString()}건
            `
              : '로딩중'
          }
          iconType={'document'}
          onClick={callbackFunction}
          cardIndex={5}
        />
        <CommonNorthStarCard
          title={'발주서'}
          data={
            data
              ? `
            ${data.orderSheet.totalCount.toLocaleString()}건
            `
              : '로딩중'
          }
          iconType={'document'}
          onClick={callbackFunction}
          cardIndex={6}
        />
        <CommonNorthStarCard
          title={'현금영수증'}
          data={
            data
              ? `
            ${data.cashReceipt.totalCount.toLocaleString()}건
            `
              : '로딩중'
          }
          iconType={'document'}
          onClick={callbackFunction}
          cardIndex={7}
        />
        <CommonNorthStarCard
          title={'세금계산서'}
          data={
            data
              ? `
            ${data.taxInvoice.totalCount.toLocaleString()}건
            `
              : '로딩중'
          }
          iconType={'document'}
          onClick={callbackFunction}
          cardIndex={8}
        />
        <CommonNorthStarCard
          title={'전체 평균 거래금액'}
          data={`${transaction?.all.price.toLocaleString() ?? '~~~'}원`}
          onClick={callbackFunction}
          cardIndex={9}
        />
        {/* <CommonNorthStarCard
          title={'유료 이용자 평균 거래금액'}
          data={`${transaction?.plan.price.toLocaleString() ?? '~~~'}원`}
          onClick={callbackFunction}
          cardIndex={10}
        />
        <CommonNorthStarCard
          title={'무료 이용자 평균 거래금액'}
          data={`${transaction?.free.price.toLocaleString() ?? '~~~'}원`}
          onClick={callbackFunction}
          cardIndex={11}
        /> */}
        <CommonNorthStarCard
          title={'전체 유저 문서작성 전환율'}
          iconType={'document'}
          data={`${conversion?.conversionRate ?? '~~~'}%`}
          onClick={callbackFunction}
          cardIndex={10}
        />
      </CardWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding: 8px;
`

const TimeBlock = styled.div`
  position: absolute;
  right: 8px;
  top: 20px;
`

const CardWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-areas: 
	"card1 card2 card3 card4"
  "card5 card6 card7 card8"
  "card9 card9 card10 card10"
  "card11 card11 card12 card12"
`