import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UserOtherSizeType } from "@payworkteam/server-product-types";

import moment from 'moment';
import {
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Table,
	Row,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	Dropdown
} from "reactstrap";

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import LinkPaginator from '../../../components/Common/LinkPaginator';
import { getUserRatePlanUsers } from '../../../api/users';

/** 속성 정의 */
/** 필터 타입 */
const interfaceType = {
	RatePlanCurrentUse: "현재 유료 회원",
	RatePlanAccumulate: "누적 유료 회원",
	RatePlanWaitCancel: "유료 해지 예약 회원",
	RatePlanCancelled: "유료 해지 회원",
	TrialPlanCurrentUse: "현재 체험판 이용 회원",
	TrialPlanAccumulate: "누적 체험판 이용 회원",
	// TrialPlanChange: "체험판 유료 전환 회원",
	TrialPlanCancelled: "체험판 해지 회원"
}

/** 메인 타이틀 부가설명 */
const subTitle = {
	RatePlanCurrentUse: "현재 유료 기능을 이용중인 회원들입니다.",
	RatePlanAccumulate: "지금까지 유료 기능을 사용해본 회원들입니다.",
	RatePlanWaitCancel: "유료 기능에 대해 해지 예정인 회원들입니다.",
	RatePlanCancelled: "유료 기능을 해지한 회원들입니다.",
	TrialPlanCurrentUse: "현재 체험판을 이용중인 회원들입니다.",
	TrialPlanAccumulate: "지금까지 체험판을 사용해본 회원들입니다.",
	TrialPlanChange: "체험판을 유료로 전환한 회원들입니다.",
	TrialPlanCancelled: "체험판을 유료로 전환하지 않고 해지한 회원들입니다."
}

/** 정렬일 헤더 */
const orderByDateHeader = {
	RatePlanCurrentUse: "유료 가입일",
	RatePlanAccumulate: "유료 가입일",
	RatePlanWaitCancel: "유료 해지 예약일",
	RatePlanCancelled: "유료 해지일",
	TrialPlanCurrentUse: "체험판 가입일",
	TrialPlanAccumulate: "체험판 가입일",
	TrialPlanChange: "체험판 유료 전환일",
	TrialPlanCancelled: "체험판 해지일"
}

/** 사업체 규모 */
const businessScale = {
	[UserOtherSizeType.Individual]: "개인",
	[UserOtherSizeType.Less5]: "5인미만",
	[UserOtherSizeType.More5]: "5인이상"
}

/** 요굼제 관련 유저 리스트 */
const RatePlanUserList = () => {
	const location = useLocation();

	/** 현재 주소 추가 경로 */
	const params = new URLSearchParams(location.search);

	/** 상태 목록 */
	const [page, setPage] = useState(Number(params.get('page')) || 0);
	const [amount, setAmount] = useState(10);
	const [search, setSearch] = useState(params.get('search') || '');
	const [type, setType] = useState(params.get('type') || Object.keys(interfaceType)[0]);
	const [isTypeToggle, setIsTypeToggle] = useState(false);
	const [users, setUsers] = useState([]);
	const [totalCount, setTotalCount] = useState(0);

	/** 호출 함수 정의 */
	/** 리스트 호출 */
	const callRatePlanUsers = async ({
		page,
		amount,
		search,
		type
	}) => {
		await getUserRatePlanUsers({
			query: {
				page,
				amount,
				search,
				type
			}
		})
		.then((res) => {
			const {
				users,
				count: totalCount
			} = res;
			
			if (res.opcode === 0) {
				/** 리스트 state 업데이트 */
				setUsers(users);
				
				/** 20240228 엑스트라 요청 (메뉴 별 유료회원 뻥튀기) */
				let count = totalCount;
				// switch(type) {
				// 	case Object.keys(interfaceType).find(key => interfaceType[key] === interfaceType.RatePlanCurrentUse): {
				// 		count += 900;
				// 		break;
				// 	}

				// 	case Object.keys(interfaceType).find(key => interfaceType[key] === interfaceType.RatePlanAccumulate): {
				// 		count += 700;
				// 		break;
				// 	}

				// 	case Object.keys(interfaceType).find(key => interfaceType[key] === interfaceType.TrialPlanAccumulate): {
				// 		count += 400;
				// 		break;
				// 	}
				// }

				/** 총 개수 state 업데이트 */
				setTotalCount(count);
			}
		})
		.catch((err) => {
			console.error(err);
		});
	}

	/** 타입 토글 핸들러 */
	const handlerFilter = (key, value) => {
		setType(key);
		setIsTypeToggle(false);
		
		window.location.href = `/users-rate-plan?search=${search}&type=${key}`;
	}

	/** 훅 */
	/** 첫 로딩 */
	useEffect(() => {
		/** 리스트 호출 */
		callRatePlanUsers({
			page,
			amount,
			search,
			type
		});
	}, []);
	
	return (
		<Fragment>
			<div className="page-content">
				<Container fluid>
					<SubTitle>{subTitle[type]}</SubTitle>
					<Breadcrumbs title={`${interfaceType[type]}(${totalCount}명)`} breadcrumbItems="RatePlanUserList" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									{/* 상단 검색 메뉴 */}
									<Row className="mb-2">
										{/* 검색 Input */}
										<Col sm="4">
										<div className = "search-box mr-2 mb-2 d-inline-block">
												<div className = "position-releative">
													<Input
														type = "text"
														className = "form-control"
														placeholder = "회원명/전화번호/이메일"
														value = {search}
														onChange = {(e) => setSearch(e.target.value)}
														onKeyUp = {(e) => {
															if (e.key === 'Enter') {
																window.location.href = `/users-rate-plan?search=${search}&type=${type}`;
															}
														}}
													/>
													<i className="bx bx-search-alt search-icon" style = {{ left: "23px" }}></i>
												</div>
											</div>
										</Col>

										{/* 검색 필터 */}
										<Col sm="8">
											<div className="text-sm-right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Dropdown isOpen={isTypeToggle} toggle={() => setIsTypeToggle(!isTypeToggle)}>
												<DropdownToggle
													tag="button"
													className="btn btn-light"
													style={{ marginRight: '10px' }}
												>
													<i className="mdi mdi-filter mr-1" />
												</DropdownToggle>
												<DropdownMenu className="dropdown-menu dropdown-menu-right">
													{/* 필터 개체 생성 */}
													{Object.entries(interfaceType).map((res, index) => {
														const key = res[0];
														const value = res[1];
														
														return (
															<DropdownItem key={index} onClick={() => handlerFilter(key, value)}>{value}</DropdownItem>
														)
													})}
												</DropdownMenu>
												</Dropdown>
											</div>
										</Col>

										{/* 유저 테이블 */}
										<ListDiv className="table-reponsive">
											<Table className = "table-centered table-nowrap table-hover">
												<thead className = "thead-light">
													<tr>
														{/* 리스트 올 체킹 체크박스 인풋 */}
														<th style = {{ width: "3%" }}>
															<div className = "custom-control custom-checkbox" style = {{ zIndex: 0 }}>
																<Input type = "checkbox" className = "custom-control-input" id = "allCheck" />
																<Label className = "custom-control-label" htmlFor = "allCheck">&nbsp;</Label>
															</div>
														</th>

														{/* 테이블 헤더 */}
														<ListHeader scope = "col" style = {{ width: "10%" }}>유저명</ListHeader>
														<ListHeader scope = "col" style = {{ width: "20%" }}>이메일</ListHeader>
														<ListHeader scope = "col" style = {{ width: "10%" }}>전화번호</ListHeader>
														<ListHeader scope = "col" style = {{ width: "10%" }}>사업체 규모</ListHeader>
														<ListHeader scope = "col" style = {{ width: "10%" }}>사업체 비고</ListHeader>
														<ListHeader scope = "col" style = {{ width: "20%" }}>회원가입일</ListHeader>
														<ListHeader scope = "col" style = {{ width: "20%" }}>{orderByDateHeader[type]}</ListHeader>
													</tr>
												</thead>

												{/* 유저 목록 */}
												<tbody>
													{users && users.map((user, index) => {
														return (
															<tr key={index}>
																{/* 리스트 올 체킹 체크박스 인풋 */}
																<td style = {{ height: "65px" }}>
																	<div className = "custom-control custom-checkbox" style = {{ zIndex: 0 }}>
																		<Input type = "checkbox" className = "custom-control-input" id = {`check${index}`} />
																		<Label className = "custom-control-label" htmlFor = {`check${index}`}>&nbsp;</Label>
																	</div>
																</td>

																{/* 유저 목록 */}
																{/* 유저명 */}
																<ListData>{user.username}</ListData>
																{/* 이메일 */}
																<ListData
																	onClick={() => {
																		window.location.href = `/user-detail/${user.id}`;
																	}}
																	style={{ color: "#556EE6", cursor: 'pointer' }}
																>{user.email}</ListData>
																{/* 전화번호 */}
																<ListData>{user.phone}</ListData>
																{/* 사업체 규모 */}
																<ListData>{businessScale[user.otherInfoSize]}</ListData>
																{/* 사업체 비고 */}
																<ListData>{user.otherInfoDescript}</ListData>
																{/* 회원가입일 */}
																<ListData>{moment(user.signupAt).format("YYYY-MM-DD HH:mm")}</ListData>
																{/* 정렬일자 */}
																<ListData>{moment(user.orderByDate).format("YYYY-MM-DD HH:mm")}</ListData>
															</tr>
														)
													})}
												</tbody>
											</Table>
											
											{/* 페이지 네비게이션 */}
											<LinkPaginator
												searchParams = {{
													currentPage: page,
													search,
													type
												}}
												visibleCount = {amount}
												totalCount = {Math.ceil(totalCount / amount)}
												urlPrefix = {"/users-rate-plan"}
											/>
										</ListDiv>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment>
	)
}

const mapStateToProps = (state) => ({
	appState: state.appState,
	state: state.appState.state,
	params: state.appState.params,
	data: state.appState.data
});

/** style components */
/** sub title */
const SubTitle = styled.span`
	font-size: 13px;
	color: #6C757D;
	user-select: none;
`;

/** 테이블 리스트 Div */
const ListDiv = styled.div`
	min-height: 800px;
	width: 100%;
`;

/** 테이블 리스트 헤더 */
const ListHeader = styled.th`
	text-align: center;
`;

/** 테이블 리스트 데이터 */
const ListData = styled.td`
	text-align: center;
	max-width: 40px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export default connect(mapStateToProps)(RatePlanUserList);