import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ko';
import { withRouter } from 'react-router-dom';
import { TaxInvoiceStatusType } from "@payworkteam/server-product-types";

const TaxInvoiceList = ({
  history,
  project,
  taxInvoicePage,
  taxInvoiceSearch,
  handleChangeBusiness
}) => {
  const {
    invoicerCompanyName,
    invoiceeCompanyName,
    statusType,
    issueType,
    purposeType,
    taxType,
    totalAmount,
    createdAt
  } = project;

  /** taxInvoice State Format */
  const statusTypeSubString =
  (statusType === TaxInvoiceStatusType.Issued) ? '발행완료' :
  (statusType === TaxInvoiceStatusType.Completed) ? '신고완료' :
  (statusType === TaxInvoiceStatusType.Cancel) ? '발행취소' :
  (statusType === TaxInvoiceStatusType.Failed) ? '발행실패' :
  '알수없음';

  return (
    <>
      {/* 생성일 */}
      <td
        onClick={() => {
          history.push({
            pathname: `/taxInvoice-detail/${project.id}`,
            state: {
              pageInfo: taxInvoicePage,
              searchInfo: taxInvoiceSearch,
              prevInfo: 'taxInvoice'
            }
          })
        }}
        style={{
          color: '#556EE6',
          cursor: 'pointer'
        }}
      >
        {createdAt &&
          moment(createdAt).format('YYYY-MM-DD-HH:mm')}
      </td>

      {/* 공급자 상호명 */}
      <td>{invoicerCompanyName}</td>

      {/* 공급받는자 상호명 */}
      <td>{invoiceeCompanyName}</td>
      
      {/* 상태 */}
      <td>{statusTypeSubString}</td>

      {/* 빌행 타입 */}
      <td>{issueType}</td>

      {/* 영수/청구 타입 */}
      <td>{purposeType}</td>

      {/* 과세 유형 */}
      <td>{taxType}</td>

      {/* 금액 */}
      <td>{`${totalAmount?.toLocaleString() ?? '0'}원`}</td>
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data
})

const mapDispatchToProps = dispatch => ({
  // getTradingStatementDetail: id => dispatch(getTradingStatementDetail(id))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaxInvoiceList)
)
