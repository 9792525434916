import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'

import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu
} from '../../reducers/Layout/layout'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

import logo from '../../assets/images/logo.svg'
import logoDark from '../../assets/images/logo-dark.png'
import logoLightPng from '../../assets/images/logo_new_white.png'
import logoLightPng2 from '../../assets/images/logo_new_white2.png'

const Header = props => {
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const inDashboard = window.location.pathname.includes('dashboard')
  const currentUrlPath = window.location.pathname

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu)
    if (props.leftSideBarType === 'default') {
      props.changeSidebarType('condensed', isMobile)
    } else if (props.leftSideBarType === 'condensed') {
      props.changeSidebarType('default', isMobile)
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="10" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="30" />
                </span>
              </Link>
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightPng2} alt="Paywork" height="23" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="30" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          <Nav tabs className={!inDashboard ? 'hide_navbar' : ''}>
            <NavItem>
              <NavLink
                href="/dashboard"
                active={currentUrlPath === '/dashboard'}
              >
                북극성
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/dashboard/user"
                active={currentUrlPath === '/dashboard/user'}
              >
                사용자
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/dashboard/document"
                active={currentUrlPath === '/dashboard/document'}
              >
                거래문서
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/dashboard/revenue"
                active={currentUrlPath === '/dashboard/revenue'}
              >
                매출
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                href="/dashboard/pattern"
                active={currentUrlPath === '/dashboard/pattern'}
              >
                유형분석
              </NavLink>
            </NavItem> */}
            {/* <Link to="/dashboard">북극성</Link> */}
            {/* <Link to="/dashboard/au">AU</Link> */}
            {/* <Link to="/dashboard/user">회원</Link> */}
            {/* <Link to="/dashboard/document">거래문서</Link> */}
            {/* <Link to="/dashboard/revenue">매출</Link> */}
            {/* <Link to="/dashboard/pattern">패턴</Link> */}
          </Nav>
          <div className="d-flex dropdown-right">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className={
                  search
                    ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show'
                    : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}
const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
})(Header)
