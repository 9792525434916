export const GET_DOCUMENT_PAYMENT_LIST =
  'reducers/payment/GET_DOCUMENT_PAYMENT_LIST'
export const GET_DOCUMENT_PAYMENT_BALANCE =
  'reducers/payment/GET_DOCUMENT_PAYMENT_BALANCE'
export const DEPOSIT_DOCUMENT_PAYMENT_BALANCE =
  'reducers/payment/DEPOSIT_DOCUMENT_PAYMENT_BALANCE'
export const DELETE_DOCUMENT_PAYMENT =
  'reducers/payment/DELETE_DOCUMENT_PAYMENT'

export const getDocumentPaymentList = (page, amount, paymentStatus) => ({
  type: GET_DOCUMENT_PAYMENT_LIST,
  page,
  amount,
  paymentStatus
  // search,
  // filtering
})

export const getDocumentPaymentBalance = () => ({
  type: GET_DOCUMENT_PAYMENT_BALANCE
})

export const depositDocumentPaymentBalance = (paymentId, amt, amtDate) => ({
  type: DEPOSIT_DOCUMENT_PAYMENT_BALANCE,
  paymentId,
  amt,
  amtDate
})

export const deleteDocumentPayment = (paymentId) => ({
  type: DELETE_DOCUMENT_PAYMENT,
  paymentId
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
