import 'moment/locale/ko';
import { useQuery } from 'react-query';
import { ViewOutputType } from '@payworkteam/server-product-types';
import { getWorkDocumentViewV3 } from '../../api/workDocument';
import styled from 'styled-components';

interface Props {
  workDocumentId: string;
}

const WorkDocumentPreview = ({ workDocumentId }: Props) => {
  /** 문서 뷰 가져오기 */
  const {
    data: workDocumentView
  } = useQuery(["workDocumentView", workDocumentId], async() => {
    /** workDocumentId가 없으면 뷰 거부 */
    if (!workDocumentId) {
      return;
    }

    /** 기본 사이즈 */
    const data = await getWorkDocumentViewV3(workDocumentId, {
      output: ViewOutputType.PDF_BASE64,
    });
    
    /** exception */
    if (typeof data !== "string") {
      console.log("$$ workDocumentView error => ", data?.message);
      return;
    }

    return data;
  });
  
  return (
    <Main>
      {/* 문서 뷰어 */}
      {workDocumentView &&
        <iframe
			style={{ width: "100%", height: "90vh", margin: "auto", display: "block" }}
			src={`data:application/pdf;base64,${workDocumentView}#toolbar=0`}
		/>
      }
    </Main>
  )
}

const Main = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default WorkDocumentPreview;