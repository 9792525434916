// import 'react-datepicker/dist/react-datepicker.css'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input
} from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { NativeSelect } from '@material-ui/core'
import styled from 'styled-components'

import Indicator from '../../../components/Common/Indicator'
import Paginator from '../../../components/Common/Paginator'

import Emoji1 from '../assets/images/emoji1_30.png'
import Emoji2 from '../assets/images/emoji2_30.png'
import Emoji3 from '../assets/images/emoji3_30.png'
import Emoji4 from '../assets/images/emoji4_30.png'
import Emoji5 from '../assets/images/emoji5_30.png'

const emojiList = [Emoji1, Emoji2, Emoji3, Emoji4, Emoji5]

const FeedReports = ({
  feedData,
  usersData,
  testerData,
  reactionModal,
  onChangeReactionModal,
  onChangeTester,
  handleFeedReaction,
  onChangeReactionTester
}) => {
  const feedId = window.location.pathname.split('/')[2]
  const [reportsData, setReportsData] = useState([])
  const [isFetching, setFetching] = useState(false)
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    amount: 10,
    search: ''
  })
  const [searchQuery, setSearchQuery] = useState({
    project: '',
    user: '',
    date: '',
    range: {
      label: '전체',
      value: 'all',
      start: 0,
      end: Infinity
    }
  })
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    amount: 10
  })
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)

  const handlePagination = (page, pageSize) => {
    if (pageOptions.page !== page) {
      return setPageOptions(prevState => ({
        ...prevState,
        page
      }))
    }
    return
  }

  const handleInput = e => {
    e.persist()
    return setSearchQuery(prevState => ({
      ...prevState,
      [e.target?.name]: e.target?.value
    }))
  }

  useEffect(() => {
    ;(async () => {
      setFetching(true)
      setReportsData(feedData)
      setFetching(false)
    })()
  }, [feedData])

  return (
    <Container fluid>
      <Row>
        <div className="col-xl col-sm-6">
          <Label>
            <h3>
              공감 {reportsData.feedReaction && reportsData.feedReaction.length}
              개
            </h3>
          </Label>
        </div>

        <Col sm="8">
          <div className="text-sm-right">
            <Button
              type="button"
              color="success"
              className="btn-rounded waves-effect waves-light mb-2 mr-2"
              onClick={onChangeReactionModal}
            >
              <i className="mdi mdi-emoticon-happy-outline"></i> 공감
            </Button>
          </div>
        </Col>

        <Modal
          centered
          scrollable
          isOpen={reactionModal}
          toggle={onChangeReactionModal}
        >
          <ModalHeader
            toggle={onChangeReactionModal}
            style={{
              borderBottom: '2px solid #2a3042'
            }}
          >
            <div>공감 추가</div>
          </ModalHeader>
          <ModalBody
            className="d-flex align-items-center justify-content-center"
            style={{
              minHeight: '400px',
              flexDirection: 'column'
            }}
          >
            <Form
            // onSubmit={onSaveComment}
            >
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label>테스트 계정</Label>
                    <NativeSelect
                      className="form-control"
                      name="boatSale"
                      onChange={e => onChangeReactionTester(e)}
                      disableUnderline
                    >
                      {testerData.map(ele => (
                        <option value={ele.id}>{ele.username}</option>
                      ))}
                    </NativeSelect>
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <Label>공감</Label>
                    <ReactionWrapper>
                      {emojiList.map((emotion, i) => {
                        return (
                          <img
                            src={emotion}
                            alt=""
                            style={{
                              width: '24px',
                              height: '24px',
                              cursor: 'pointer'
                            }}
                            onClick={e => handleFeedReaction(e, i)}
                          />
                        )
                      })}
                    </ReactionWrapper>
                  </FormGroup>
                </Col>

                {/* <Col sm="12">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-block mr-1 waves-effect waves-light"
                  >
                    등록하기
                  </Button>
                </Col> */}
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        <Col lg="12">
          <Card>
            <CardBody>
              <div className="table-responsive" style={{ minHeight: '850px' }}>
                <Table className="table table-centered table-nowrap">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">공감일</th>
                      <th className="text-center">내용</th>
                      <th className="text-center">이름</th>
                      <th className="text-center">전화번호</th>
                      <th className="text-center">닉네임</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(reportsData.feedReaction) &&
                      reportsData.feedReaction
                        .slice(
                          (pageOptions.page - 1) * 10,
                          pageOptions.amount * pageOptions.page
                        )
                        .reverse()
                        .map((reaction, idx) => {
                          return (
                            <tr key={idx + 1}>
                              <td
                                className="text-center"
                                style={{ height: '65px' }}
                              >
                                {reaction.createdAt &&
                                  reaction.createdAt.slice(0, 10)}
                              </td>
                              <td className="text-center">
                                <img
                                  src={emojiList[reaction.reaction]}
                                  style={{
                                    width: '24px',
                                    height: '24px'
                                  }}
                                />
                              </td>

                              <td className="text-center">
                                {Array.isArray(usersData) &&
                                  usersData.length > 0 &&
                                  usersData.filter(
                                    ele => ele.id === reaction.userId
                                  )[0].username}
                              </td>
                              <td className="text-center">
                                {Array.isArray(usersData) &&
                                  usersData.length > 0 &&
                                  usersData.filter(
                                    ele => ele.id === reaction.userId
                                  )[0].phone}
                              </td>
                              <td className="text-center">
                                {reaction.feedUserInfoPrint.nickname &&
                                  reaction.feedUserInfoPrint.nickname}
                              </td>
                            </tr>
                          )
                        })}
                  </tbody>
                </Table>
                <Paginator
                  currentPage={fetchOptions.page}
                  pageSize={fetchOptions.amount}
                  total={
                    reportsData.feedReaction &&
                    Math.ceil(reportsData.feedReaction.length / 10)
                  }
                  onChange={handlePagination}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default FeedReports

const ReactionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`
