import React, { useEffect, useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Button } from 'reactstrap'
import CommonPie from '../../../../components/Common/Pie'
import CommonTable from '../../../../components/Common/Table'
import { backoffice, backofficeBaseUrl } from '../../../../global/constants'

export default function AgeGroup() {
  const [data, setData] = useState(null)
  const [planData, setPlanData] = useState(null)
  const [freeData, setFreeData] = useState(null)

  const callAPI = useCallback(async () => {
    try {
      const url = `${backofficeBaseUrl}/dashBoard/card/ageGroup`
      const res = await axios.get(url)
      setData(res.data.groups)
    } catch (e) {
      throw e
    }
  }, [])

  const callPlanAPI = useCallback(async () => {
    try {
      const url = `${backofficeBaseUrl}/dashBoard/card/ageGroup?isPlan=true`
      const res = await axios.get(url)
      setPlanData(res.data.groups)
    } catch (e) {
      throw e
    }
  }, [])

  const callFreeAPI = useCallback(async () => {
    try {
      const url = `${backofficeBaseUrl}/dashBoard/card/ageGroup?isPlan=false`
      const res = await axios.get(url)
      setFreeData(res.data.groups)
    } catch (e) {
      throw e
    }
  }, [])

  const labels = useMemo(() => {
    return data?.map(item => item.ageGroup) ?? []
  }, [data])

  const [openTable, setOpenTable] = useState(false)

  useEffect(() => {
    callAPI()
    callPlanAPI()
    callFreeAPI()
  }, [])

  return (
    <Wrapper>
      <h2>연령대 그룹 유형</h2>
      <p>연령대 별 사용 비중</p>

      <ChartWrapper>
        {data && (
          <div>
            <p>전체</p>
            <CommonPie
              type={'donut'}
              labels={labels}
              data={data?.map(item => item.count) ?? []}
            />
          </div>
        )}
        {planData && (
          <div>
            <p>유료</p>
            <CommonPie
              type={'donut'}
              labels={labels}
              data={planData?.map(item => item.count) ?? []}
            />
          </div>
        )}
        {freeData && (
          <div>
            <p>무료</p>
            <CommonPie
              type={'donut'}
              labels={labels}
              data={freeData?.map(item => item.count) ?? []}
            />
          </div>
        )}
      </ChartWrapper>
      {/* <TableWrapper>
            <TableToggleButtonWrapper>
              <TableToggleButton
                onClick={() => setOpenTable(openTable ? false : true)}
              >
                {openTable ? '테이블 닫기' : '테이블 열기'}
              </TableToggleButton>
            </TableToggleButtonWrapper>
            {openTable && (
              <CommonTable
                colHeaders={[...labels, 'total']}
                datas={[
                  [data.liteCount],
                  [data.standardCount],
                  [data.signupCount - data.liteCount - data.standardCount],
                  [data.signupCount]
                ]}
              />
            )}
          </TableWrapper> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 50px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`
const ChartWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const TableWrapper = styled.div``
const TableToggleButtonWrapper = styled.div`
  text-align: right;
`

const TableToggleButton = styled.button``
