import axios from 'axios';
import { backofficeBaseUrl } from "../global/constants";

/** 부분적으로 사용중인 Global Axios (외엔 constants에서 라우터만 관리하고 페이지에서 다이렉트로 axios 모듈 쓰는 듯) */
const apiClient = () => {
	/** get a tokens */
	const accessToken = localStorage.getItem('token') || ''
	const Authorization = `Bearer ${accessToken}`;
	// const Authorization = `Bearer 2sONVSg1thYkd8zC1Uuf6UKgZB8HtJFeZYXI2Hgcmex8yVMpWbXoUdI2RVygid4XcDCe22V7nLiAx81eB+AdnRpwEOUVDPFg44xW+NN6GdSgG4HvuwcB3opa/3EslgU=` // 개발용

	return axios.create({
		baseURL: backofficeBaseUrl,
		validateStatus: () => true,
		// withCredentials: true,
		headers: {
			Authorization
		}
	});
}

export default apiClient;