import React, { HTMLAttributes, useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import styled from "styled-components";
import { getBusinessmanRequestDetail, patchBusinessmanRequestModifyReject, postBusinessmanRequestModifyConfirm } from "../../api/businessman";
import { UserRequestBusinessmanState } from "@payworkteam/server-product-types";
import { AttachmentModel, UserRequestBusinessmanModel } from "@payworkteam/server-product-entity";
import { saveAs } from 'file-saver';
import moment from "moment";

export default function BusinessApprovalDetail() {
	/** app states */
	const businessApprovalId = window.location.pathname.split('/')[2];
	const [businessData, setBusinessData] = useState<{
		companyRegistrationNumber: string;
		companyName: string;
		representative: string;
		companyAddress: string;
		sectors: string;
		businessCondition: string;
		isBlindCompanyAddress: boolean;
		isExistence: boolean;
		businessRegistrationAttachmentId: string;
		businessRegistrationAttachment: AttachmentModel;
	}>();
	const [userData, setUserData] = useState<UserRequestBusinessmanModel>();

	/** image path */
	const [currentImagePath, setCurrentImagePath] = useState('');
	const [desiredImagePath, setDesiredImagePath] = useState('');

	/** imageType */
	const [currentImageType, setCurrentImageType] = useState('png');
	const [desiredImageType, setDesiredImageType] = useState('png');

	/** data state */
	const [companyName, setCompanyName] = useState('');
	const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState('');
	const [representative, setRepresentative] = useState('');
	const [companyAddress, setCompanyAddress] = useState('');
	const [sectors, setSectorsValue] = useState('');
	const [businessCondition, setBusinessCondition] = useState('');
	
	/** hooks */
	/** init */
	useEffect(() => {
		/** call businessRequest data */
		onInit();
	}, []);

	/** 사업자 정보 init */
	useEffect(() => {
		if (!businessData) {
			return;
		}

		setCompanyName(businessData?.companyName);
		setCompanyRegistrationNumber(businessData?.companyRegistrationNumber);
		setRepresentative(businessData?.representative);
		setCompanyAddress(businessData?.companyAddress);
		setSectorsValue(businessData?.sectors);
		setBusinessCondition(businessData?.businessCondition);
	}, [businessData]);

	/** functions */
	/** init */
	const onInit = async () => {
		const businessRequestDetail = await getBusinessmanRequestDetail(businessApprovalId)
		.catch((err) => {
		  console.log(`$$ getBusinessmanRequestDetail error => `, err);
		  return;
		});
	
		if (!businessRequestDetail) {
		  return;
		}
		
		const currentData = businessRequestDetail?.businessman;
		const desiredData = businessRequestDetail?.userRequestBusinessman;
		
		setBusinessData(currentData);
		setUserData(desiredData);
	
		/** set image path */
		const s3 = `https://s3.ap-northeast-2.amazonaws.com/media.paywork.io`;
		setCurrentImagePath(`${s3}/${currentData?.businessRegistrationAttachment?.path}/${currentData?.businessRegistrationAttachment?.filename}`);
		setDesiredImagePath(`${s3}/${desiredData?.businessRegistrationAttachment?.path}/${desiredData?.businessRegistrationAttachment?.filename}`);
	
		/** image type */
		const currentTypeIsPdf = currentData?.businessRegistrationAttachment?.fileType.includes("pdf");
		if (currentTypeIsPdf) {
		  setCurrentImageType('pdf');
		}
	
		const desiredTypeIsPdf = desiredData?.businessRegistrationAttachment?.fileType.includes("pdf");
		if (desiredTypeIsPdf) {
		  setDesiredImageType('pdf');
		}
	}
	
	/** file open */
	const fileOpen = (path: string, type: string) => {
		saveAs(path, `paywork_business.${type}`);
	}

	/** file download (CORS떠서 동작안함) */
	const fileDownload = async (path: string, type: string) => {
		console.log("download")
		const response = await fetch(path)
		.catch((err) => {
			console.log(`$$ fileDownload error => `, err);
			return;
		});

		if (!response) {
			return;
		}

		const blob = await response.blob();

		saveAs(blob, `paywork_business.${type}`);
	}

	/** 반려 */
	const reject = async () => {
		/** 의사 검증 */
		const confirm = window.confirm('정말로 승인 요청을 반려하시겠습니까?');

		if (!confirm) {
			return;
		}
		
		try {
			await patchBusinessmanRequestModifyReject(businessApprovalId)
			.then((res) => {
				if (res.opcode === 0) {
					window.location.reload();
				}
			})
		} catch (e) {
			console.log(e)
		}

		/** 리프레쉬 */
		window.location.reload();
	}
	
	/** 승인 */
	const approve = async () => {
		/** 의사 검증 */
		const confirm = window.confirm('위 내용대로 사업자 정보를 수정하시겠습니까?');

		if (!confirm) {
			return;
		}
		
		try {
			await postBusinessmanRequestModifyConfirm({
				userRequestBusinessmanId: businessApprovalId,
				companyName,
				representative,
				companyAddress,
				sectors,
				businessCondition
			})
			.then((res) => {
				if (res.opcode === 0) {
					window.location.reload();
				}
			})
		} catch (e) {
			console.log(e)
		}

		/** 리프레쉬 */
		window.location.reload();
	}
	
	return (
		<React.Fragment>
			<Container fluid>
				<Main>
					<Box>
						{/* 처리 상태 */}
						<Label>
							<StateLabel state={userData?.state} />
						</Label>

						{/* 상호명 타이틀 + 수정 요청일 */}
						<Title>
							<TitleText>{businessData?.companyName ?? "상호명 찾을 수 없음"}</TitleText>
							<TitleDate>수정 요청일: {moment(userData?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</TitleDate>
						</Title>

						{/* 현재 사업자 */}
						<Field>
							<FieldLegend>현재 등록되어 있는 사업자</FieldLegend>

							{/* 내부 영역 */}
							<FieldBox>
								{/* 사업자 이미지 영역 */}
								<FieldBoxImageArea>
									{/* 이미지 */}
									<FieldBoxImageAreaImage>
										{currentImagePath ? (
											<img
												className="img-thumbnail avatar-lg"
												alt=""
												src={currentImagePath}
												onClick={() => fileOpen(currentImagePath, currentImageType)}
												style={{ cursor: "pointer", width: "100%", height: "auto" }}
											/>
										) : (
											<></>	
										)}
									</FieldBoxImageAreaImage>

									{/* 이미지 다운로드 버튼 */}
									<FieldBoxImageAreaButton onClick={() => fileOpen(currentImagePath, currentImageType)}>
										{currentImagePath ? (
											<i className="fas fa-download" />
										) : (
											<></>
										)}
									</FieldBoxImageAreaButton>
								</FieldBoxImageArea>
		
								{/* 정보 영역 */}
								<FieldBoxContent>
									{/* 상호명 */}
									<FieldBoxContentForm>
										<Label>상호명</Label>
										<Input
											type="textarea"
											className="form-control"
											value={businessData?.companyName}
											disabled={true}
										/>
									</FieldBoxContentForm>

									{/* 사업자 등록번호 */}
									<FieldBoxContentForm>
										<Label>사업자 등록번호</Label>
										<Input
											type="textarea"
											className="form-control"
											value={businessData?.companyRegistrationNumber}
											disabled={true}
										/>
									</FieldBoxContentForm>

									{/* 대표자명 */}
									<FieldBoxContentForm>
										<Label>대표자명</Label>
										<Input
											type="textarea"
											className="form-control"
											value={businessData?.representative}
											disabled={true}
										/>
									</FieldBoxContentForm>

									{/* 사업장 소재지 */}
									<FieldBoxContentForm>
										<Label>사업장 소재지</Label>
										<Input
											type="textarea"
											className="form-control"
											value={businessData?.companyAddress}
											disabled={true}
										/>
									</FieldBoxContentForm>
									
									{/* 업태업종이 데이터상에서 다 뒤바껴있어서 일단 임시대응 */}
									{/* 업태 */}
									<FieldBoxContentForm>
										<Label>업태</Label>
										<Input
											type="textarea"
											className="form-control"
											value={businessData?.businessCondition}
											disabled={true}
										/>
									</FieldBoxContentForm>

									{/* 종목 */}
									<FieldBoxContentForm>
										<Label>종목</Label>
										<Input
											type="textarea"
											className="form-control"
											value={businessData?.sectors}
											disabled={true}
										/>
									</FieldBoxContentForm>
								</FieldBoxContent>
							</FieldBox>
						</Field>

						{/* 수정 요청 사업자 */}
						<Field>
							<FieldLegend>변경 요청한 사업자</FieldLegend>

							{/* 내부 영역 */}
							<FieldBox>
								{/* 사업자 이미지 영역 */}
								<FieldBoxImageArea>
									{/* 이미지 */}
									<FieldBoxImageAreaImage>
										{desiredImagePath ? (
											<img
												className="img-thumbnail avatar-lg"
												alt=""
												src={desiredImagePath}
												onClick={() => fileOpen(desiredImagePath, desiredImageType)}
												style={{ cursor: "pointer", width: "100%", height: "auto" }}
											/>
										) : (
											<></>	
										)}
									</FieldBoxImageAreaImage>

									{/* 이미지 다운로드 버튼 */}
									<FieldBoxImageAreaButton onClick={() => fileOpen(desiredImagePath, desiredImageType)}>
										{desiredImagePath ? (
											<i className="fas fa-download" />
										) : (
											<></>
										)}
									</FieldBoxImageAreaButton>
								</FieldBoxImageArea>
		
								{/* 정보 영역 */}
								<FieldBoxContent>
									{/* 상호명 */}
									<FieldBoxContentForm>
										<Label>상호명</Label>
										<Input
											type="textarea"
											className="form-control"
											value={companyName}
											onChange={(e) => setCompanyName(e.target.value)}
											disabled={userData?.state !== UserRequestBusinessmanState.Waiting}
										/>
									</FieldBoxContentForm>

									{/* 사업자 등록번호 */}
									<FieldBoxContentForm>
										<Label>사업자 등록번호</Label>
										<Input
											type="textarea"
											className="form-control"
											value={companyRegistrationNumber}
											onChange={(e) => setCompanyRegistrationNumber(e.target.value)}
											disabled={userData?.state !== UserRequestBusinessmanState.Waiting}
										/>
									</FieldBoxContentForm>

									{/* 대표자명 */}
									<FieldBoxContentForm>
										<Label>대표자명</Label>
										<Input
											type="textarea"
											className="form-control"
											value={representative}
											onChange={(e) => setRepresentative(e.target.value)}
											disabled={userData?.state !== UserRequestBusinessmanState.Waiting}
										/>
									</FieldBoxContentForm>

									{/* 사업장 소재지 */}
									<FieldBoxContentForm>
										<Label>사업장 소재지</Label>
										<Input
											type="textarea"
											className="form-control"
											value={companyAddress}
											onChange={(e) => setCompanyAddress(e.target.value)}
											disabled={userData?.state !== UserRequestBusinessmanState.Waiting}
										/>
									</FieldBoxContentForm>
									
									{/* 업태 */}
									<FieldBoxContentForm>
										<Label>업태</Label>
										<Input
											type="textarea"
											className="form-control"
											value={businessCondition}
											onChange={(e) => setBusinessCondition(e.target.value)}
											disabled={userData?.state !== UserRequestBusinessmanState.Waiting}
										/>
									</FieldBoxContentForm>
									
									{/* 종목 */}
									<FieldBoxContentForm>
										<Label>종목</Label>
										<Input
											type="textarea"
											className="form-control"
											value={sectors}
											onChange={(e) => setSectorsValue(e.target.value)}
											disabled={userData?.state !== UserRequestBusinessmanState.Waiting}
										/>
									</FieldBoxContentForm>
								</FieldBoxContent>
							</FieldBox>
						</Field>

						{/* 문의 내용 */}
						<RequestContent>
							{/* 문의자 */}
							<RequestContentForm>
								<Label>문의자</Label>
								<Input
									type="text"
									className="form-control"
									value={userData?.name ?? ""}
									disabled={true}
								/>
							</RequestContentForm>
							
							{/* 문의자 연락처 */}
							<RequestContentForm>
								<Label>문의자 연락처</Label>
								<Input
									type="text"
									className="form-control"
									value={userData?.phone ?? ""}
									disabled={true}
								/>
							</RequestContentForm>

							{/* 문의 내용 */}
							<RequestContentForm>
								<Label>문의 내용</Label>
								<Input
									type="textarea"
									className="form-control"
									value={userData?.requestNote ?? ""}
									disabled={true}
								/>
							</RequestContentForm>
						</RequestContent>
						
						
						{/* 버튼 영역 (수정대기면 활성화) */}
						{userData?.state === UserRequestBusinessmanState.Waiting && (
							<Buttons>
								<Button
									color="danger"
									style={{ flex: 1 }}
									onClick={reject}
								>
									반려
								</Button>

								<Button
									color="primary"
									style={{ flex: 1 }}
									onClick={approve}
								>
									수정 및 승인
								</Button>
							</Buttons>
						)}
					</Box>
				</Main>
			</Container>
		</React.Fragment>
	  )
}

const Main = styled.div`
	margin-top: 94px;
	margin-bottom: 94px;
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 20px;
	background-color: #f9f9f9;
	display: flex;
	align-items: center;
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #FFFFFF;
	padding: 20px 20px;
	box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
	gap: 14px;
`;

const StateLabel = ({ state }: { state?: UserRequestBusinessmanState }) => {
    if (state === UserRequestBusinessmanState.Processed) {
      return (
        <h4>
          <Badge color="primary">수정완료</Badge>
        </h4>
      )
    } else if (state === UserRequestBusinessmanState.Refusal) {
      return (
        <h4>
          <Badge color="danger">반려완료</Badge>
        </h4>
      )
    } else {
      return (
        <h4>
          <Badge color="warning">처리 중</Badge>
        </h4>
      )
    }
}

const Title = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
`;

const TitleText = styled.h2`
	flex: 1;
`;

const TitleDate = styled.span`
	flex: 1;
	text-align: right;
	font-size: 14px;
	font-weight: bold;
`;

const Field = styled.fieldset`
	width: 100%;
	border: 1px solid #000000;
	border-radius: 7px;
	padding: 10px;
	display: flex;
	
`;

const FieldLegend = styled.legend`
	font-size: 20px;
	font-weight: bold;
	padding-left: 10px;
	padding-right: 10px;
`;

const FieldBox = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	padding: 0px 10px 10px;
	gap: 10px;
`;

const FieldBoxImageArea = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const FieldBoxImageAreaImage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FieldBoxImageAreaButton = styled.div<HTMLAttributes<HTMLDivElement>>`
	border: 1px solid #000000;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 0px;
	cursor: pointer;
`;

const FieldBoxContent = styled.div`
	flex 2;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const FieldBoxContentForm = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const RequestContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`;

const RequestContentForm = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 10px;
`;