import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { apiError } from '../../reducers/Auth/login'
import { registerUser, registerUserFailed } from '../../reducers/Auth/register'

import { Link } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux'
import profileImg from '../../assets/images/profile-img.png'

// availity-reactstrap-validation

// action

// Redux

// import images

const Register = props => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    password: ''
  })
  const [errors, setErrors] = useState('')
  const { username, email, phone, password } = formData

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = e => {
    e.preventDefault()

    const newUser = {
      username,
      email,
      phone,
      password,
      admin: true
    }

    axios
      .post('/auth/signup/local', newUser)
      .then(result => {
      })
      .catch(err => {
        alert.show(err.response.data.invalid.phone)
        if (err.response.data.invalid.phone) {
          setErrors(err.response.data.invalid.phone)
        } else if (err.response.data.invalid.password) {
          setErrors(err.response.data.invalid.password)
        }
      })
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">회원가입</h5>
                        <p>페이워크 어드민으로 등록하세요</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/*<div>*/}
                  {/*  <Link to="/">*/}
                  {/*    <div className="avatar-md profile-user-wid mb-4">*/}
                  {/*      <span className="avatar-title rounded-circle bg-light">*/}
                  {/*        <img*/}
                  {/*          src={logoImg}*/}
                  {/*          alt=""*/}
                  {/*          className="rounded-circle"*/}
                  {/*          height="34"*/}
                  {/*        />*/}
                  {/*      </span>*/}
                  {/*    </div>*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={onSubmit}
                    >
                      {props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}
                      {props.registrationError && props.registrationError ? (
                        <Alert color="danger">{props.registrationError}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="username"
                          label="이름"
                          type="text"
                          required
                          value={username}
                          onChange={onChange}
                          placeholder="이름을 입력해주세요"
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="이메일"
                          className="form-control"
                          placeholder="이메일주소를 입력해주세요"
                          type="email"
                          value={email}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="phone"
                          label="핸드폰 번호"
                          className="form-control"
                          placeholder="'-' 을 포함하여 핸드폰 번호를 입력하세요"
                          type="text"
                          value={phone}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="비밀번호"
                          type="password"
                          required
                          value={password}
                          onChange={onChange}
                          placeholder="비밀번호를 입력해주세요"
                        />
                      </div>

                      {errors !== '' && <Alert color="success">{errors}</Alert>}
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          회원가입
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          <Link to="#" className="text-primary">
                            이용약관
                          </Link>
                          &nbsp; 내용을 숙지, 이에 동의합니다
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  이미 계정이 있나요?
                  <Link to="/login" className="font-weight-medium text-primary">
                    로그인
                  </Link>
                </p>
                <p>© {new Date().getFullYear()} 페이워크 어드민</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed
})(Register)
