import React, { Fragment, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import { getTaxInvoiceList, setTaxInvoiceIsBusinessman } from '../../reducers/taxInvoice'
import { setClientIsBusinessman } from '../../reducers/workOrder'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Table
} from 'reactstrap'

import TaxInvoiceList from './TaxInvoiceList'
import LinkPaginator from '../../components/Common/LinkPaginator'

const TaxInvoice = ({ state, getTaxInvoiceList, taxInvoiceList, setClientIsBusinessman }) => {
  const location = useLocation()
  const history = useHistory()

  const params = new URLSearchParams(location.search)

  const [page, setPage] = useState(
    params.get('page') ? Number(params.get('page')) : 0
  )
  
  const [clientType, setClientType] = useState(params.get('clientType') ?? '')
  const [search, setSearch] = useState(params.get('search') ?? '')
  const [startMoney, setStartMoney] = useState(
    params.get('startMoney') ? Number(params.get('startMoney')) : 0
  )
  const [endMoney, setEndMoney] = useState(
    params.get('endMoney') ? Number(params.get('endMoney')) : 0
  )
  const [startDate, setStartDate] = useState(params.get('startDate') ?? '')
  const [endDate, setEndDate] = useState(params.get('endDate') ?? '')
  const [totalListCount, setTotalListCount] = useState(0)
  const [taxInvoiceData, setTaxInvoiceData] = useState([])
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('')
  const fetchingAmount = 10
  const isFetchingRef = useRef(false)

  const handleUrl = changingClientType => {
    //clientType은 드롭다운 클릭이어서 별도로 뺌.
    const editedParams = new URLSearchParams()
    editedParams.append(
      'clientType',
      changingClientType === ''
        ? ''
        : changingClientType
        ? changingClientType
        : clientType
    )
    editedParams.append('page', page)
    editedParams.append('search', search)
    editedParams.append('startMoney', startMoney)
    editedParams.append('endMoney', endMoney)
    editedParams.append('startDate', startDate)
    editedParams.append('endDate', endDate)
    window.location.replace(`?${editedParams.toString()}`)
  }

  const handleEnter = e => {
    if (e.key === 'Enter') {
      if (!isFetchingRef.current) {
        isFetchingRef.current = true
        handleUrl()
      }
    }
  }

  useEffect(() => {
    getTaxInvoiceList(
      page,
      fetchingAmount,
      search,
      clientType,
      startMoney,
      endMoney,
      startDate,
      endDate
    )
  }, [])

  useEffect(() => {
    switch (state) {
      case 'SUCCESS_GET_TAXINVOICE_LIST':
        setTaxInvoiceData(taxInvoiceList.taxInvoices)
        setTotalListCount(taxInvoiceList.count)
        break

      case 'SUCCESS_SET_TAXINVOICE_IS_BUSINESSMAN':
      case 'SUCCESS_SET_CLIENT_IS_BUSINESSMAN':
        // handleUrl()
        break

      default:
        break
    }
  }, [state])

  // 차트 제목 hook
  useEffect(() => {
    setBreadcrumbTitle(`세금계산서 (${totalListCount? totalListCount.toLocaleString() : '-'}건)`)
  }, [totalListCount])

  const handleChangeBusiness = (flag, id) => {
    setClientIsBusinessman(id, flag.value)
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={breadcrumbTitle}
            breadcrumbItem="TaxInvoice List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            placeholder={'회원명/고객명/전화번호'}
                            onKeyUp={handleEnter}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div
                    className="table-responsive"
                    style={{ minHeight: '800px' }}
                  >
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: '10%' }}>
                            생성일
                          </th>
                          <th scope="col" style={{ width: '6%' }}>
                            공급자 상호명
                          </th>
                          <th scope="col" style={{ width: '6%' }}>
                            공급받는자 상호명
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            상태
                          </th>
                          <th scope="col" style={{ width: "6%" }}>
                            발행 타입
                          </th>
                          <th scope="col" style={{ width: '6%' }}>
                            영수/청구 타입
                          </th>
                          <th scope="col" style={{ width: '6%' }}>
                            과세 유형
                          </th>
                          <th scope="col" style={{ width: '11%' }}>
                            금액
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(taxInvoiceData) &&
                          taxInvoiceData.map((project, idx) => (
                            <tr key={'_Bill_' + idx}>
                              <TaxInvoiceList
                                history={history}
                                project={project}
                                taxInvoicePage={page}
                                taxInvoiceSearch={search}
                                handleChangeBusiness={handleChangeBusiness}
                              />
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <LinkPaginator
                      searchParams={{
                        currentPage: page,
                        search: search,
                        clientType: clientType,
                        startMoney: startMoney,
                        endMoney: endMoney,
                        startDate: startDate,
                        endDate: endDate
                      }}
                      visibleCount={fetchingAmount}
                      totalCount={Math.ceil(totalListCount / fetchingAmount)}
                      urlPrefix={'/taxInvoice'}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  state: state.appState.state,
  params: state.appState.params,
  data: state.appState.data,
  taxInvoiceList: state.appState.data.taxInvoiceList
})
const mapDispatchToProps = dispatch => ({
  getTaxInvoiceList: (
    page,
    amount,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate
  ) =>
    dispatch(
      getTaxInvoiceList(
        page,
        amount,
        search,
        clientType,
        startMoney,
        endMoney,
        startDate,
        endDate
      )
    ),
  setTaxInvoiceIsBusinessman: (id, isBusinessman) =>
    dispatch(setTaxInvoiceIsBusinessman(id, isBusinessman)),
  setClientIsBusinessman: (id, isBusinessman) =>
    dispatch(setClientIsBusinessman(id, isBusinessman))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaxInvoice)