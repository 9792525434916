export default [
    {
      options: [
        { label: "건설업", value: "건설업" },
        { label: "도소매업", value: "도소매업" },
        { label: "서비스업", value: "서비스업" },
        { label: "정보통신업", value: "정보통신업" },
        { label: "제조업", value: "제조업" },
        { label: "요식업", value: "요식업" },
        { label: "전자상거래업", value: "전자상거래업" },
        { label: "금융및보험업", value: "금융및보험업" },
        { label: "청소대행업", value: "청소대행업" },
        { label: "토목업", value: "토목업" },
        { label: "폐기물처리업", value: "폐기물처리업" },
        { label: "축산업", value: "축산업" },
        { label: "운송수송업", value: "운송수송업" },
        { label: "무역업", value: "무역업" },
        { label: "구매대행업", value: "구매대행업" },
        { label: "부동산업", value: "부동산업" },
        { label: "디자인업", value: "디자인업" },
        { label: "광고대행업", value: "광고대행업"},
        { label: "출판영상방송통신업", value: "출판영상방송통신업" },
        { label: "자재공급업", value: "자재공급업" },
        { label: "렌탈업", value: "렌탈업" },
        { label: "숙박업", value: "숙박업" },
        { label: "컨설팅업", value: "컨설팅업" },
        { label: "임대업(부동산 제외)", value: "임대업(부동산 제외)" },
        { label: "교육업", value: "교육업" },
        { label: "협회 및 단체", value: "협회 및 단체" },
        { label: "수리업", value: "수리업" },
        { label: "기타 개인 서비스업", value: "기타 개인 서비스업" },
        { label: "설치대행업", value: "설치대행업" },
        { label: "보건업", value: "보건업" },
        { label: "여가 관련 서비스업", value: "여가 관련 서비스업" },
      ]
    }
]