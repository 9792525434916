import { OptionHTMLAttributes, SelectHTMLAttributes, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Container, Form, Row, Label, Button } from "reactstrap";
import { getUserSbaAlternativeCreditEvaluation } from "../../../../api/users";
import { Opcode } from "@payworkteam/server-product-types";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { defaultOptions } from "../../../../helpers/chart";
import CommonTable from "../../../../components/Common/Table";

interface Props {
	username: string;
	userEmail: string;
}

/** 라벨 */
const labels = [
	'정산 요청 수',
	'정산 완료 수',
	'평균 결제 평균 금액',
	'전체 유저 결제 평균 금액',
	'세금계산서 발행 수',
	'세금계산서 발행 평균 금액',
	'현금영수증 발행 수',
	'현금영수증 발행 평균 금액',
	'주요 거래 거래처 개수',
	'평균 거래 금액'
];

/** sba 임상 신용평가 페이지 */
export default function UserDetailSbaAlternativeCreditEvaluation({
	username,
	userEmail
}: Props) {
	const userId = window.location.pathname.split('/')[2];

	/** states */
	/** 신용평가 신청 여부 */
	const [isCreditEvaluation, setIsCreditEvaluation] = useState(false);

	/** 표본 부족 대상 */
	const [isSampleLack, setIsSampleLack] = useState(false);

	/** 테이블 열기 */
	const [openTableEvaluation, setOpenTableEvaluation] = useState(false);

	/** hooks */

	/** querys */
	const {
		data: userSbaAlternativeCreditEvaluation,
	} = useQuery(["getUserSbaAlternativeCreditEvaluation", isCreditEvaluation], async () => {
		if (!isCreditEvaluation) {
			return;
		}

		/** 5초 정도 기다리기 */
		await new Promise(resolve => setTimeout(resolve, 5000));

		/** data call */
		const {
			opcode,
			message,
			data,
			recent3MonthScore,
			last3MonthScore,
			finalScore
		} = await getUserSbaAlternativeCreditEvaluation(userId);

		/** exception */
		if (opcode !== Opcode.SUCCESS) {
			console.error("$$ getUserSbaAlternativeCreditEvaluation error => ", message);

			/** 표본 부족 대상인지 확인 */
			if (message.includes("표본")) {
				setIsSampleLack(true);
			}

			return;
		}

		/** date 기준 오름차순 정렬 재실행 */
		const sortData = data.sort((a, b) => {
			return a.date > b.date ? 1 : -1;
		});

		/** 최근 6개월 데이터만 발췌 */
		// const recent6MonthData = sortData.slice(-6);

		// console.log("$$ recent6MonthData => ", recent6MonthData);

		return {
			data: sortData,
			recent3MonthScore,
			last3MonthScore,
			finalScore
		}
	});

	/** functions */
	const onClickCreditEvaluation = () => {
		setIsCreditEvaluation(true);
	}

	/** 대시보드 그래프 렌더링 */
	const seriesRender = () => {
		const data = userSbaAlternativeCreditEvaluation?.data;

		return [
			{
				name: labels[0],
				type: 'line',
				data: data?.map((i) => {
					return {
						x: `${i.date}`,
						y: `${i.totalPaymentRequestCount}건`
					}
				})
			},
			{
				name: labels[1],
				type: 'line',
				data: data?.map((i) => {
					return {
						x: `${i.date}`,
						y: `${i.paymentCount}건`
					}
				})
			},
			{
				name: labels[2],
				type: 'bar',
				data: data?.map(i => {
					return {
						x: `${i.date}`,
						y: `${i.avgPaymentAmount}원`
					}
				})
			},
			{
				name: labels[3],
				type: 'bar',
				data: data?.map(i => {
					return {
						x: `${i.date}`,
						y: `${i.allUserAvgPaymentAmount}원`
					}
				})
			},
			{
				name: labels[4],
				type: 'line',
				data: data?.map(i => {
					return {
						x: `${i.date}`,
						y: `${i.taxInvoiceCount}건`
					}
				})
			},
			{
				name: labels[5],
				type: 'bar',
				data: data?.map(i => {
					return {
						x: `${i.date}`,
						y: `${i.taxInvoiceAvgAmount}원`
					}
				})
			},
			{
				name: labels[6],
				type: 'line',
				data: data?.map(i => {
					return {
						x: `${i.date}`,
						y: `${i.cashReceiptCount}건`
					}
				})
			},
			{
				name: labels[7],
				type: 'bar',
				data: data?.map(i => {
					return {
						x: `${i.date}`,
						y: `${i.cashReceiptAvgAmount}원`
					}
				})
			},
			{
				name: labels[8],
				type: 'bar',
				data: data?.map(i => {
					return {
						x: `${i.date}`,
						y: `${i.repeatClientCount}명`
					}
				})
			},
			{
				name: labels[9],
				type: 'bar',
				data: data?.map(i => {
					return {
						x: `${i.date}`,
						y: `${i.repeatClientAvgAmount}원`
					}
				})
			},
		]
	}

	return (
		<Container fluid>
			{/* 신용평가 신청 */}
			{!isCreditEvaluation && <Form className="mb-4 mt-4" style={{ width: "100%" }}>
				{/* title */}
				<Row className="d-flex justify-content-center mb-4 mt-4">
					<Button color="success" className="btn-rounded" onClick={onClickCreditEvaluation}>
						임상 신용평가 신청
					</Button>
				</Row>
			</Form>}

			{/* 진행 중 혹은 오류 처리 */}
			{(isCreditEvaluation && !userSbaAlternativeCreditEvaluation) && <Form className="mb-4 mt-4" style={{ width: "100%" }}>
				{/* title */}
				<Row className="d-flex justify-content-center">
					<Label>
						<h4>
							{!isSampleLack ? "추이분석 임상 신용평가 진행 중..." : "해당 유저는 임상 신용평가를 위한 표본이 부족합니다."}
						</h4>
					</Label>
				</Row>
			</Form>}

			{/* 결과 */}
			{userSbaAlternativeCreditEvaluation && <Form className="mb-4 mt-4" style={{ width: "100%" }}>
				{/* title */}
				<Row className="d-flex justify-content-center">
					<Label>
						<h4>
							{username || userEmail}님의 임상 신용평가 결과
						</h4>
					</Label>
				</Row>

				{/* 그래프 */}
				<Wrapper>
					<ChartWrapper>
						{userSbaAlternativeCreditEvaluation && (
							<>
								<ReactApexChart
									key={'retention-monthly'}
									height={300}
									options={{
										...defaultOptions,
										plotOptions: {
											bar: {
												// horizontal: true
											}
										},
										dataLabels: {
											enabled: true,
											enabledOnSeries: [0, 1, 2]
										},
										yaxis: [
											{
												show: false,
												opposite: true,
												min: 0,
												max: 100,
												seriesName: 'Line Series'
											},
											{
												show: false,

												seriesName: 'Bar Series'
											},
										],
									}}
									series={seriesRender()}
								/>
							</>
						)}
					</ChartWrapper>

					<Row className="d-flex justify-content-center">
						<h4>
							종합 임상 신용평가 결과: 평균 {userSbaAlternativeCreditEvaluation.finalScore}점
						</h4>
					</Row>

					<TableWrapper>
						<TableToggleButtonWrapper>
							<TableToggleButton onClick={() => setOpenTableEvaluation(openTableEvaluation ? false : true)}>
								{openTableEvaluation ? '테이블 닫기' : '테이블 열기'}
							</TableToggleButton>
						</TableToggleButtonWrapper>
						{openTableEvaluation && (
							<CommonTable
								datas={[
									userSbaAlternativeCreditEvaluation.data.map((i) => i.totalPaymentRequestCount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.paymentCount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.avgPaymentAmount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.allUserAvgPaymentAmount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.taxInvoiceCount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.taxInvoiceAvgAmount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.cashReceiptCount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.cashReceiptAvgAmount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.repeatClientCount),
									userSbaAlternativeCreditEvaluation.data.map((i) => i.repeatClientAvgAmount),
								]}
								colHeaders={labels}
								rowNames={userSbaAlternativeCreditEvaluation.data.map(j => `${j.date}`)}
							/>
						)}
					</TableWrapper>
				</Wrapper>
			</Form>}
		</Container>
	)
}

const Wrapper = styled.div`
  min-height: 420px;
`

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  & > p {
    margin-bottom: 0;
  }
`

const RangeName = styled.span``

const RangeInput = styled.input`
  width: 40px;
  text-align: center;
`

const RangeDate = styled.span``

const Select = styled.select<SelectHTMLAttributes<HTMLSelectElement>>`
  margin: 0 10px;
`

const Option = styled.option<OptionHTMLAttributes<HTMLOptionElement>>``

const ChartWrapper = styled.div``

const TableWrapper = styled.div``
const TableToggleButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const TableToggleButton = styled.button.attrs({
	type: "button"
}) <OptionHTMLAttributes<HTMLButtonElement>>``;