import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import PlanGraphMonthly from './board';

export default function UserPlanDashboard() {
  const [type, setType] = useState('year');

  const onClickButton = useCallback((type: string) => {
    setType(type)
  }, []);

  return (
    <Wrapper>
      <h2>유료 가입자 수 대시보드</h2>

      {/* 기능 설명 */}
      {/* <p>
        유료 유입/이탈율의 판별 기준은 트라이얼을 제외한 당월/당일에 유료가입 혹은 해지를 한 유저를 대상으로 합니다.<br />
        전체 유입율 = ((당월/일 전체 누적 유료 회원 수) / (당월/일 유료가입 수)) * 100<br />
        전체 이탈율 = ((당월/일 전체 누적 유료 회원 수) / ((당월/일 유료해지 수) + (당월/일 결제실패 수))) * 100
      </p> */}
      
      <ButtonWrapper>
        <Button
          color={type === 'year' ? 'primary' : 'secondary'}
          active={type === 'year'}
          onClick={() => onClickButton('year')}
        >
          Monthly
        </Button>
        <Button
          color={type === 'week' ? 'primary' : 'secondary'}
          active={type === 'week'}
          onClick={() => onClickButton('week')}
        >
          Weekly
        </Button>
        <Button
          color={type === 'month' ? 'primary' : 'secondary'}
          active={type === 'month'}
          onClick={() => onClickButton('month')}
        >
          Daily
        </Button>
      </ButtonWrapper>

      <ChartWrapper>
        <PlanGraphMonthly dateType={type} />
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > button {
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`

const ChartWrapper = styled.div``