import { all, put, call, takeLatest, takeEvery } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'

import { setAppState, saveAppData } from '../reducers/appState'
import { GET_PROFILE } from '../reducers/auth'
import { getRatePlanList } from './users'

export function* getProfile() {
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''

  yield put(setAppState('START_GET_PROFILE', null, true))
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_PROFILE', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.auth.GET_PROFILE,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        deviceId: null
      }
    })

    if (response.data.opcode === 0) {
      yield call(getRatePlanList)

      yield put(saveAppData('profile', response.data.user))
      yield put(setAppState('SUCCESS_GET_PROFILE', response.data))
    } else {
      yield put(setAppState('FAILED_GET_PROFILE', response.data))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_PROFILE', e))
  }
}

const authSaga = [takeLatest(GET_PROFILE, getProfile)]

export default authSaga
