import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import * as dateFns from 'date-fns'
import ReactApexChart from 'react-apexcharts'
import { backoffice } from '../../../../global/constants'
import { years, months, daysInMonth } from '../../../../constant/chart'
import { defaultOptions, xaxisOption } from '../../../../helpers/chart'
import CommonTable from '../../../../components/Common/Table'

export default function DocumentGraphDaily() {
  const apiUrl = `${backoffice.GET_ACC_GRAPH_DASHBOARD}`

  const [docType, setDocType] = useState('견적서')
  const onChangeDocType = useCallback(e => {
    setDocType(e.target.value)
  }, [])

  const [year, setYear] = useState(dateFns.format(new Date(), 'yyyy'))
  const onChangeYear = useCallback(e => {
    setYear(e.target.value)
  }, [])
  const [month, setMonth] = useState(dateFns.format(new Date(), 'M'))
  const onChangeMonth = useCallback(e => {
    setMonth(e.target.value)
  }, [])

  const [data, setData] = useState(null)

  const callAPI = useCallback(async () => {
    const doc =
      docType === '견적서'
        ? 'Estimate'
        : docType === '거래명세서'
        ? 'TradingStatement'
        : docType === '영수증'
        ? 'Receipt'
        : docType === '청구서'
        ? 'Bill'
        : ''
    try {
      const url = `${apiUrl}?type=month&year=${year}&month=${month}&workDocumentType=${doc}`
      const res = await axios.get(url)
      setData(res.data.dashBoard)
    } catch (e) {
      throw e
    }
  }, [year, month, docType])

  const labels = ['', '']
  const docTypes = ['견적서', '거래명세서', '영수증', '청구서']
  const [openTable, setOpenTable] = useState(false)

  useEffect(() => {
    callAPI()
  }, [year, month, docType])

  return (
    <Wrapper>
      <DateWrapper>
        <p>문서종류</p>
        <Select value={docType} onChange={onChangeDocType}>
          {docTypes.map(i => (
            <Option key={i} value={i}>
              {i}
            </Option>
          ))}
        </Select>
        <p>날짜</p>
        <Select value={year} onChange={onChangeYear}>
          {years.map(i => (
            <Option key={i} value={i}>
              {i}년
            </Option>
          ))}
        </Select>
        <Select value={month} onChange={onChangeMonth}>
          {months.map(i => (
            <Option key={i} value={i}>
              {i}월
            </Option>
          ))}
        </Select>
      </DateWrapper>
      <ChartWrapper>
        {data ? (
          <>
            <ReactApexChart
              key={'user-newbie-graph-daily'}
              height={300}
              options={{
                ...defaultOptions,
                xaxis: xaxisOption(daysInMonth(month))
              }}
              series={[
                {
                  name: labels[0],
                  type: 'column',
                  data: data.map(i => {
                    return {
                      x: `${i.date}일`,
                      y: i.count
                    }
                  })
                },
                {
                  name: labels[1],
                  type: 'line',
                  data: data.map(i => {
                    return {
                      x: `${i.date}일`,
                      y: i.count
                    }
                  })
                }
              ]}
            />
            <TableWrapper>
              <TableToggleButtonWrapper>
                <TableToggleButton
                  onClick={() => setOpenTable(openTable ? false : true)}
                >
                  {openTable ? '테이블 닫기' : '테이블 열기'}
                </TableToggleButton>
              </TableToggleButtonWrapper>
              {openTable && (
                <CommonTable
                  datas={[data.map(i => i.count)]}
                  colHeaders={labels}
                  rowNames={data.map(j => `${j.date}일`)}
                />
              )}
            </TableWrapper>
          </>
        ) : (
          <></>
        )}
      </ChartWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 420px;
`

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  & > p {
    margin-bottom: 0;
  }
`

const Select = styled.select`
  margin: 0 10px;
`

const Option = styled.option``

const ChartWrapper = styled.div``

const TableWrapper = styled.div``
const TableToggleButtonWrapper = styled.div`
  text-align: right;
`

const TableToggleButton = styled.button``
