import { Carousel, CarouselControl, CarouselItem } from 'reactstrap'
import React, { useState, useEffect } from 'react'

const AttachmentSlider = ({ data, detail }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides =
    Array.isArray(data) &&
    data.map(item => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
        >
          <img
            src={`https://s3.ap-northeast-2.amazonaws.com/media.paywork.io/${item.feedImage?.path}/${item.feedImage?.filename}`}
            alt=""
            style={{
              minWidth: '100%',
              maxHeight: '600px',
              borderRadius: '4px'
            }}
          />
        </CarouselItem>
      )
    })

  const thumbnails =
    Array.isArray(data) &&
    data.map((item, idx) => {
      return (
        <img
          className="mr-2"
          src={`https://s3.ap-northeast-2.amazonaws.com/media.paywork.io/${item.feedImage?.path}/${item.feedImage?.filename}`}
          alt=""
          style={{
            width: '85px',
            height: '85px',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
          onClick={() => goToIndex(idx)}
        />
      )
    })

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselControl
        className="h-75"
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      {slides}
      <CarouselControl
        className="h-75"
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
      <div className="d-flex mt-2">{thumbnails}</div>
    </Carousel>
  )
}

export default AttachmentSlider
