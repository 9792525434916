import {
  put,
  call,
  takeLatest,
  takeEvery,
  select
} from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'
import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_BILL_DETAIL,
  GET_BILL_LIST,
  SET_BILL_IS_BUSINESSMAN
} from '../reducers/bill'
import { getWorkDocumentHtml } from '../sagas/workOrder'

export function* getBillList(actions) {
  const {
    page,
    amount,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate
  } = actions

  yield put(setAppState('START_GET_BILL_LIST', null, true))

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_BILL_LIST', null, false))
  }

  try {
    const params = {
      page: page,
      amount: amount,
      search: search,
      workDocumentType: 'Bill',
      filterBoClientType: clientType,
      filterTotalPriceStartEqualSign: 'More',
      filterTotalPriceStartValue: startMoney,
      filterStartDate: startDate,
      filterEndDate: endDate
    }
    if (endMoney > 0) {
      params.filterTotalPriceEndEqualSign = 'Less'
      params.filterTotalPriceEndValue = endMoney
    }

    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.GET_WORK_DOCUMENT,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('billList', response.data))
      yield put(setAppState('SUCCESS_GET_BILL_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_BILL_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_BILL_LIST', e, false))
  }
}

export function* getBillDetail(actions) {
  const { id } = actions
  yield put(setAppState('START_GET_BILL_DETAIL', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_BILL_DETAIL', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.BILL}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('billDetail', response.data.workOrderForQuantity))

      // const newData = { ...response.data.workOrderForQuantity, type: 'Bill' }

      const newData = {
        ...response.data.workOrderForQuantity
        // type: 'Bill'
      }

      yield call(getWorkDocumentHtml, { data: newData })
      // yield call(getWorkDocumentHtml, {
      //   projectId: response.data.workOrderForQuantity.id
      // })

      yield put(setAppState('SUCCESS_GET_BILL_DETAIL', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_BILL_DETAIL', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_BILL_DETAIL', e, false))
  }
}

export function* setBillIsBusinessman(actions) {
  const { id, isBusinessman } = actions
  yield put(setAppState('START_SET_BILL_IS_BUSINESSMAN', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_SET_BILL_IS_BUSINESSMAN', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.BILL}/clientIsBusinessman/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        clientIsBusinessman: isBusinessman
      }
    })

    if (response.data.opcode === 0) {
      yield put(
        setAppState('SUCCESS_SET_BILL_IS_BUSINESSMAN', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_SET_BILL_IS_BUSINESSMAN', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_BILL_IS_BUSINESSMAN', e, false))
  }
}

const tradingStatementSaga = [
  takeLatest(GET_BILL_LIST, getBillList),
  takeLatest(GET_BILL_DETAIL, getBillDetail),
  takeLatest(SET_BILL_IS_BUSINESSMAN, setBillIsBusinessman)
]

export default tradingStatementSaga
