import {
  put,
  call,
  takeLatest,
  takeEvery,
  select
} from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'
import { setAppState, saveAppData } from '../reducers/appState'
import {
  GET_TRADING_STATEMENT_DETAIL,
  GET_TRADING_STATEMENT_LIST,
  SET_TRADING_CLIENT_IS_BUSINESSMAN
} from '../reducers/tradingStatement'
import { getWorkDocumentHtml } from '../sagas/workOrder'

export function* getTradingStatementList(actions) {
  const {
    page,
    amount,
    search,
    clientType,
    startMoney,
    endMoney,
    startDate,
    endDate
  } = actions

  yield put(setAppState('START_GET_TRADING_STATEMENT_LIST', null, true))

  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_TRADING_STATEMENT_LIST', null, false))
  }

  try {
    const params = {
      page: page,
      amount: amount,
      search: search,
      workDocumentType: 'TradingStatement',
      filterBoClientType: clientType,
      filterTotalPriceStartEqualSign: 'More',
      filterTotalPriceStartValue: startMoney,
      filterStartDate: startDate,
      filterEndDate: endDate
    }
    if (endMoney > 0) {
      params.filterTotalPriceEndEqualSign = 'Less'
      params.filterTotalPriceEndValue = endMoney
    }

    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.GET_WORK_DOCUMENT,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('tradingStatementList', response.data))
      yield put(
        setAppState('SUCCESS_GET_TRADING_STATEMENT_LIST', response.data, false)
      )
    } else {
      yield put(
        setAppState('FAILED_GET_TRADING_STATEMENT_LIST', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_TRADING_STATEMENT_LIST', e, false))
  }
}

export function* getTradingStatementDetail(actions) {
  const { id } = actions
  yield put(setAppState('START_GET_TRADING_STATEMENT_DETAIL', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_TRADING_STATEMENT_DETAIL', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${constants.backoffice.TRADING_STATEMENT}/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (response.data.opcode === 0) {
      const newData = {
        ...response.data.workOrderForQuantity
      }

      yield call(getWorkDocumentHtml, { data: newData })

      yield put(
        saveAppData(
          'tradingStatementDetail',
          response.data.workOrderForQuantity
        )
      )

      yield put(
        setAppState(
          'SUCCESS_GET_TRADING_STATEMENT_DETAIL',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState('FAILED_GET_TRADING_STATEMENT_DETAIL', response.data, false)
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_TRADING_STATEMENT_DETAIL', e, false))
  }
}

export function* setTradingStatementClientIsBusinessman(actions) {
  const { id, isBusinessman } = actions
  yield put(setAppState('START_SET_TRADING_CLIENT_IS_BUSINESSMAN', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token') || ''
  if (!accessToken) {
    yield put(
      setAppState('FAILED_SET_TRADING_CLIENT_IS_BUSINESSMAN', null, false)
    )
  }

  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${constants.backoffice.TRADING_STATEMENT}/clientIsBusinessman/${id}`,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        clientIsBusinessman: isBusinessman
      }
    })

    // console.log('$$ setTradingStatementClientIsBusinessman', response)

    if (response.data.opcode === 0) {
      yield put(
        setAppState(
          'SUCCESS_SET_TRADING_CLIENT_IS_BUSINESSMAN',
          response.data,
          false
        )
      )
    } else {
      yield put(
        setAppState(
          'FAILED_SET_TRADING_CLIENT_IS_BUSINESSMAN',
          response.data,
          false
        )
      )
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_SET_TRADING_CLIENT_IS_BUSINESSMAN', e, false))
  }
}

const tradingStatementSaga = [
  takeLatest(GET_TRADING_STATEMENT_LIST, getTradingStatementList),
  takeLatest(GET_TRADING_STATEMENT_DETAIL, getTradingStatementDetail),
  takeLatest(
    SET_TRADING_CLIENT_IS_BUSINESSMAN,
    setTradingStatementClientIsBusinessman
  )
]

export default tradingStatementSaga
