import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { backoffice } from '../../../../global/constants'
import CommonPie from '../../../../components/Common/Pie'

export default function UserPatternClientTypeDashboard() {
  const [data, setData] = useState(null)
  const callAPI = useCallback(async () => {
    try {
      const url = `${backoffice.GET_CLIENT_IS_BUSINESS_MAN}`
      const res = await axios.get(url)
      setData(res.data)
    } catch (e) {
      throw e
    }
  }, [])

  const labels = ['개인', '사업자', '공기관', '협회/단체']

  const moveToEstimate = order => {
    const searchParams = new URLSearchParams()
    searchParams.append('clientType', order)
    window.location.href = `/work-order-for-quantity?page=0&${searchParams.toString()}`
  }

  useEffect(() => {
    callAPI()
  }, [])

  return (
    <Wrapper>
      <p>견적서 고객비율</p>
      {data && (
        <CommonPie
          type={'donut'}
          labels={labels}
          data={[
            data.individual,
            data.businessman,
            data.publicInstitutions,
            data.association
          ]}
        />
      )}
      <button onClick={() => moveToEstimate('Individual')}>개인</button>
      <button onClick={() => moveToEstimate('Businessman')}>사업자</button>
      <button onClick={() => moveToEstimate('PublicInstitutions')}>
        공기관
      </button>
      <button onClick={() => moveToEstimate('Association')}>협/단체</button>
    </Wrapper>
  )
}

const Wrapper = styled.div``
