import { put, call, takeLatest } from '@redux-saga/core/effects'
import axios from 'axios'

import * as constants from '../global/constants'
import { GET_TESTER_LIST } from '../reducers/tester'

import { setAppState, saveAppData } from '../reducers/appState'

export function* getTesterList() {
  yield put(setAppState('START_GET_TESTER_LIST', null, true))
  const accessToken = yield call([localStorage, 'getItem'], 'token')
  if (!accessToken) {
    yield put(setAppState('FAILED_GET_TESTER_LIST', null, false))
  }

  try {
    const response = yield call(axios, {
      method: 'GET',
      url: constants.backoffice.TESTER_LIST,
      validateStatus: () => true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {}
    })

    if (response.data.opcode === 0) {
      yield put(saveAppData('testerList', response.data.users))
      yield put(setAppState('SUCCESS_GET_TESTER_LIST', response.data, false))
    } else {
      yield put(setAppState('FAILED_GET_TESTER_LIST', response.data, false))
    }
  } catch (e) {
    console.log(e)
    yield put(setAppState('FAILED_GET_TESTER_LIST', e, false))
  }
}

const testerSagas = [takeLatest(GET_TESTER_LIST, getTesterList)]

export default testerSagas
